import user from '../assets/images/user.svg';
import lock from '../assets/images/lock.svg';
import logo from '../assets/images/logo.svg';
import envolope from '../assets/images/envolop.svg';
import call from '../assets/images/call.svg';

import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";
import { setUserDataWithToken } from "../Store/Reducers/CommonReducer";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const Login = () =>{
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //alert start

  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setEmail("");
    setPassword("");
  }

  const loginAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (email.trim() === '') {
      all_error_msg.push("Enter your username");

      if (flg === true) {
        flg = false;
      }
    }
    
    if (password.trim() === '') {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'login', {email: email, password: password}).then((result) => {
          if(result.status === true){
                setLoading(false);
                dispatch(setUserDataWithToken(result));
                sessionStorage.setItem("userData",JSON.stringify(result.data));
                sessionStorage.setItem("userId", result.data.id);
                sessionStorage.setItem("userToken", result.token);
                resetForm();
                navigate("/dashboard");
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

   return(

    <div className="authwapper">
        <div className="container">
        <CustomAlert
              header={"Login"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
                 
                      
        <div className="loginfrom">
       <h1>Welcome To 
          <span>Ashirbad Cloud</span> </h1  >
          <form onSubmit={loginAction}>
              <div className="form-group">
                <img className='formIcon' src={user}/>
                  <input type="text" placeholder="Username"  onChange={(event) => setEmail(event.target.value)}/>
              </div>
              <div className="form-group">
              <img className='formIcon' src={lock}/>
                  <input type="password" placeholder="Password"  onChange={(event) => setPassword(event.target.value)} />
              </div>
              <div className="remember-me">
                <Link to="/ForgotPassword" className="forgotpassword">Forgot Password ?</Link>
              </div>
              <button className="fill-btn" type='submit'>Login</button>
              <p>Don't have an account ?  <Link className='highlight' to="/signup">Sign up </Link> </p>
          </form>
          </div>
          <div className="companyinfo">
            
              <img src={logo}/>
           
            <h2>Ashirbad Cloud</h2>
            <p>A construct & concept thereof</p>
            <div className='contactinfo'>
              <a href='mailto:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={envolope}/>
                souvik.mukherjee@ashirbadtax.com</a><br/>
                <a href='tel:souvik.mukherjee@ashirbadtax.com'>
              <img  src={call}/>
              +91 0000000000</a>
            </div>
          </div>
        </div>
         
    </div>
  )
}
export default Login;