import { Link } from "react-router-dom";
import payment from "../../assets/images/payment.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Modal from 'react-modal';
import { useState } from "react";

const LinkDetails = () =>{
    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal =()=>{
        setIsOpen(true);
    }
      function closeModal() {
        setIsOpen(false);
      }
     return(
        <div className="viewInvoice linkdetails">
        <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Payment Link</h2>
        <div className="rightSec">
            <button type="button" className="fadebtn" onClick={openModal}>New Payment</button>
        </div>
        </div>
        <div className="tavBox">
            <Tabs>
            <div className="tableft">
            <TabList>
           <Tab>
             <h4>Jhon Duo</h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo </h4>
             <p>455GHFG </p>
            </Tab> 
            </TabList>
            </div>
            <div className="tabRight">
            <TabPanel> 
                <div className="tabdetails">
                    <div className="linkDetails">
                    <span>Jhon Deo</span>
                     <h3>RDFGF55485512</h3>
                     <div className="cardBorder">
                        <h4>Payment Amount <b>4254.00</b></h4>
                        <p><span className="copylink">http://zspay.co/3hVdpUqM3d <i class="fa fa-clone" aria-hidden="true"></i></span></p> 
                        <span className="editlink">http://zspay.co/3hVdpUqM3d <i class="fa fa-pencil" aria-hidden="true"></i></span>
                     </div>
                     <h3>OTHER DETAILS</h3>
                     <div className="cardBorder">
                        <p className="payitem">Created By <span>Souvik Mukherjee</span></p>
                        <p className="payitem">Created on <span>23-07-2022</span></p>
                        <p className="payitem">Place of Supply  <span>West Bengal</span></p>
                        <p className="payitem">Tax <span>GST18</span></p>
                        <p className="payitem">Description<span>Income Tax Payment</span></p>
                     </div>
                    </div>

                </div>
            </TabPanel>
            <TabPanel> 
            <div className="tabdetails">
            <div className="linkDetails">
                    <span>Jhon Deo</span>
                     <h3>RDFGF55485512</h3>
                     <div className="cardBorder">
                        <h4>Payment Amount <b>4254.00</b></h4>
                        <p><span className="copylink">http://zspay.co/3hVdpUqM3d <i class="fa fa-clone" aria-hidden="true"></i></span></p> 
                        <span className="editlink">http://zspay.co/3hVdpUqM3d <i class="fa fa-pencil" aria-hidden="true"></i></span>
                     </div>
                     <h3>OTHER DETAILS</h3>
                     <div className="cardBorder">
                        <p className="payitem">Created By <span>Souvik Mukherjee</span></p>
                        <p className="payitem">Created on <span>23-07-2022</span></p>
                        <p className="payitem">Place of Supply  <span>West Bengal</span></p>
                        <p className="payitem">Tax <span>GST18</span></p>
                        <p className="payitem">Description<span>Income Tax Payment</span></p>
                     </div>
                    </div>
                </div>
            </TabPanel>
            </div>
            </Tabs>
        </div>
                    {/* Notify */}
                    <Modal className="notifymodal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
            <div className="modalHeader">
            New Payment Link  <Link onClick={closeModal}><i class="fa fa-times" aria-hidden="true"></i></Link>
            </div>
     <div className='modalBody'>
      <form>
        <div className="row">
            <div className="col-12">
            <div className="form-group">
                <label>Customer Name <sub>*</sub></label>
            <div class="flxGroup"><select><option>Select or add customer</option></select><button type="button"><i class="fa fa-search" aria-hidden="true"></i></button></div>
            </div>
            </div>
            <div className="col-12">
            <div className="form-group">
                <label>Payment Amount</label>
                <input type="text" placeholder="Amount"/>
            </div>
            </div>
            <div className="col-12">
            <div className="form-group">
                <label>Link Expiration Date <sub>*</sub></label>
                <input type="date" placeholder="Amount"/>
            </div>
            </div>
            <div className="col-6">
            <div className="form-group">
                <label>Case ID</label>
                <select>
                    <option></option>
                </select>
            </div>
            </div>
            <div className="col-6">
            <div className="form-group">
                <label>Invoice Number</label>
                <select>
                    <option></option>
                </select>
            </div>
            </div>
            <div className="col-12">
            <div className="form-group">
                <label>Description <sub>*</sub></label>
                <textarea></textarea>
            </div>
            </div>
            <div className="col-12">
            <div className="form-group">
                <div className="btnsec">
                <button type="button" className="fillbtn">Generate Link</button>
                <button type="button" className="fadebtn">Save & Send</button>
                <button type="button" className="fadebtn">Cancel</button>
                </div>

            </div>
            </div>
        </div>
      </form>
     </div>
      </Modal>
    </div>
     )
}
export default LinkDetails;