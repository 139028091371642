import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.svg";
import bin from '../../assets/images/delete.svg';
import view from '../../assets/images/view.svg';
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const DocumentType = () =>{
      const navigate = useNavigate();
      const addService = () =>{
            navigate("/layout/adddocuments")
      }
      const opendocuments = () =>{
            navigate("/layout/opendocuments") 
      }

    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const contactListhead =[checkbox(), "Document Type", "Document Group", "Service", "Job", "Access to","Status","Action"];
      const contactlistdata =[
            {
               checkbox:checkbox(),
               date:"Doc",
               number:"Design Group",
               name:"Design",
               deg:"Development",
               role:"Admin",
               status:"Active",
            },
            {
                  checkbox:checkbox(),
                  date:"Doc",
               number:"Design Group",
               name:"Design",
               deg:"Development",
               role:"Admin",
               status:"Inactive",
               },
               {
                  checkbox:checkbox(),
                  date:"Doc",
                  number:"Design Group",
                  name:"Design",
                  deg:"Development",
                  role:"Admin",
                  status:"Active",
               },
               {
                  checkbox:checkbox(),
                  date:"Doc",
                  number:"Design Group",
                  name:"Design",
                  deg:"Development",
                  role:"Admin",
                  status:"Inactive",
                  },
                  {
                        checkbox:checkbox(),
                        date:"Doc",
                        number:"Design Group",
                        name:"Design",
                        deg:"Development",
                        role:"Admin",
                        status:"Active",
                     },
                     {
                        checkbox:checkbox(),
                        date:"Doc",
                        number:"Design Group",
                        name:"Design",
                        deg:"Development",
                        role:"Admin",
                        status:"Active",
                        },
                        {
                              checkbox:checkbox(),
                              date:"Doc",
                                number:"Design Group",
                                name:"Design",
                                deg:"Development",
                                role:"Admin",
                                status:"Active",
                           },
                           {
                              checkbox:checkbox(),
                              date:"Doc",
                                number:"Design Group",
                                name:"Design",
                                deg:"Development",
                                role:"Admin",
                                status:"Inactive",
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>Document Type</h2>
                  <div className="right-action">
                  <form>
             
                      <input type="text" placeholder="Account"/>
                      <input type="text" placeholder="Job"/>
                      <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                      </form>
                        <Link className="fadebtn" onClick={addService}>Add Document Type</Link>
                        <Link className="fillbtn">Bulk action</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index} onClick={opendocuments}>
                                    <td> {item.checkbox}
                                    </td>
                                    <td> {item.date}</td>
                                    <td>{item.number}</td>
                                    <td>{item.name}</td>
                                    <td>{item.deg}</td>
                                    <td>{item.role}</td>
                                    <td className={item.status == "Active"? "active": "inactive"}>{item.status}</td>
        
                                    <td> <Link><img src={bin}/></Link></td>
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default DocumentType;