import React, { useEffect, useState} from "react";
import { BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useDispatch} from 'react-redux';
import { setUserDataAfterLogin, localstorage_TokenAdd } from "./Store/Reducers/CommonReducer";
import './App.css';
// js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import ScrollToTop from "./component/ScrollToTop.js";

import Initial from './auth/Initial';
import Signup from './auth/Signup';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import ForgotPasswordOtp from './auth/ForgotPasswordOtp';
import ResetPassword from './auth/ResetPassword';

import Dashboard from './pages/Dashboard/Dashboard';
import Layout from './pages/layout/Layout';
import SingleMode from './pages/WorkStation/SingleMode';
import BulkAction from './pages/WorkStation/BulkAction/BulkAction';
import ContactList from "./pages/User/ContactList";
import AddUser from './pages/User/AddUser';
import EditUser from './pages/User/EditUser';
import ClientUser from "./pages/User/ClientUsers";
import Executives from "./pages/User/Executives";
import Roles from "./pages/User/role/Roles";
import AddRoles from "./pages/User/role/AddNewRoll";
import EditRoles from "./pages/User/role/EditRoll";

import Account from "./pages/Account/account/Account";
import AccountProfile from "./pages/Account/account/Profile";
import AccountBankDetails from './pages/Account/account/bank/BankDetails';
import AccountPartnerDetails from './pages/Account/account/partner/PartnerDetails';
import AccountStatutory from './pages/Account/account/statutory/Statutory';
import AccountBranch from './pages/Account/account/branch/Branch';
import AccountDocument from './pages/Account/account/document/AccountDocument';
import StatutoryDocAddEdit from './pages/Account/account/document/StatutoryDocAddEdit';
import AccountBankDocument from './pages/Account/account/document/bank/AccountBankDocument';
import BankDocAddEdit from './pages/Account/account/document/bank/BankDocAddEdit';
import AccountPartnerDocument from './pages/Account/account/document/partner/AccountPartnerDocument';
import PartnerDocAddEdit from './pages/Account/account/document/partner/PartnerDocAddEdit';
import AccountUser from './pages/Account/account/user/UserAccount';
import AccountUserAdd from './pages/Account/account/user/addEditUser';
import Overview from './pages/Account/account_overview/Overview';


import Case from './pages/Account/Case';
import AccountInvoice from './pages/Account/Invoice';
import AccountPayment from './pages/Account/Payment';
import StatusReport from './pages/Account/StatusReport';
import AddAccountCases from './pages/Account/AddCases';
import AddAccountProduct from './pages/Account/AddProduct';
import ViewInvoice from './pages/Account/ViewInvoice';
import AddInvoice from './pages/Account/AddInvoice';
import AddPayment from './pages/Account/AddPayment';
import ViewPayment from './pages/Account/ViewPayment';

import ServiceList from './pages/Services/services/ServiceList';
import AddService from './pages/Services/services/AddService';
import ProductList from './pages/Services/ProductList';
import AddProduct from './pages/Services/AddProduct';
import JobList from './pages/Services/job/JobList';
import AddJob from './pages/Services/job/AddJob';
import TaskList from './pages/Services/task/Task';
import AddTask from './pages/Services/task/AddTask';

import Invoice from './pages/Transactions/Invoices';
import Payments from './pages/Transactions/Payments';
import PaymentLink from './pages/Transactions/PaymentLink';
import Estimate from './pages/Transactions/Estimate';
import CostExpenses from './pages/Transactions/CostExpenses';
import Statement from './pages/Transactions/Statement';
import LinkDetails from './pages/Transactions/LinkDetails';
import AddEstimate from './pages/Transactions/AddEstimate';
import ViewEstimate from './pages/Transactions/ViewEstimate';
import NewCost from './pages/Transactions/NewCost';
import OpenCost from './pages/Transactions/OpenCost';
import DocumentHub from './pages/Documents/documenthub';
import DocumentRegister from './pages/Documents/DocumentsRegister';
import DocumentType from './pages/Documents/DocumentType';
import DocumentGroup from './pages/Documents/DocumentGroup';
import AddDocument from './pages/Documents/AddDocument';
import OpenDocuments from './pages/Documents/OpenDocuments';
import AddDocumentGroup from './pages/Documents/AddDocumentGroup';
import OpenDocumentsGroup from './pages/Documents/OpenDocumentGroup';
import EmailIntraction from './pages/Intraction/Email';
import SmsIntraction from './pages/Intraction/Sms';
import WhatAppIntraction from './pages/Intraction/WhatsApp';
import TelephonicsIntraction from './pages/Intraction/Telephonics';
import FaceToFace from './pages/Intraction/FaceToFace';
import Notification from './pages/Intraction/Notification';
import AccountHistory from './pages/History/AccountHistory';
import UserHistory from './pages/History/UserHistory';
import SystemHistory from './pages/History/SystemHistory';
import SettingHistory from './pages/History/SettingHistory';
import SmsNotification from './pages/Intraction/SmsNotification';
import WhatappNotification from './pages/Intraction/WhatappNotification';


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
      if(sessionStorage.getItem("userId") != null){
        dispatch(setUserDataAfterLogin(JSON.parse(sessionStorage.getItem("userData"))));
        dispatch(localstorage_TokenAdd(sessionStorage.getItem("userToken")));
      }
      
  	}, []);

      return (
        <BrowserRouter >
          <ScrollToTop>
            <Routes >
              <Route path="/" element={<Initial />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
              <Route path="/ForgotPasswordOtp" element={<ForgotPasswordOtp />}></Route>
              <Route path="/ResetPassword" element={<ResetPassword />}></Route>
              <Route path="/signup" element={<Signup />}></Route>
              <Route path="/dashboard" element={<Layout />}>
                 <Route path="" element={<Dashboard />} />
                 <Route path="singlemode" element={<SingleMode />} />
                 <Route path="bulkaction" element={<BulkAction />} />

                 <Route path="account" element={<Account />} />
                 <Route path="account/accountprofile" element={<AccountProfile />} />
                 <Route path="account/accountbank" element={<AccountBankDetails />} />
                 <Route path="account/accountpartner" element={<AccountPartnerDetails />} />
                 <Route path="account/accountstatutory" element={<AccountStatutory />} />
                 <Route path="account/accountbranch" element={<AccountBranch />} />
                 <Route path="account/accountdocument" element={<AccountDocument />} />
                 <Route path="account/statutorydoc" element={<StatutoryDocAddEdit />} />
                 <Route path="account/bankdocument" element={<AccountBankDocument />} />
                 <Route path="account/bankdoc" element={<BankDocAddEdit />} />
                 <Route path="account/partnerdocument" element={<AccountPartnerDocument />} />
                 <Route path="account/partnerdoc" element={<PartnerDocAddEdit />} />
                 
                 <Route path="account/accountuser" element={<AccountUser />} />
                 <Route path="account/accountuseradd" element={<AccountUserAdd />} />

                 

                 <Route path="account/overview" element={<Overview />} />
                 
                 <Route path="contactlist" element={<ContactList />} />
                 <Route path="contactlist/adduser" element={<AddUser />} />
                 <Route path="contactlist/edituser" element={<EditUser />} />

                 <Route path="clientuser" element={<ClientUser />} />
                 <Route path="clientuser/edituser" element={<EditUser />} />
                                  
                 <Route path="executive" element={<Executives />} />
                 <Route path="executive/edituser" element={<EditUser />} />

                 <Route path="role" element={<Roles />} />
                 <Route path="role/addrole" element={<AddRoles />} />
                 <Route path="role/editrole" element={<EditRoles />} />

                 <Route path="cases" element={<Case />} />
                 <Route path="invoices" element={<AccountInvoice />} />
                 <Route path="payment" element={<AccountPayment />} />
                 <Route path="statusreport" element={<StatusReport />} />
                 <Route path="addaccountcases" element={<AddAccountCases />} />
                 <Route path="addaccountproduct" element={<AddAccountProduct />} />
                 <Route path="viewinvoice" element={<ViewInvoice />} />
                 <Route path="addinvoice" element={<AddInvoice />} />
                 <Route path="addpayment" element={<AddPayment />} />
                 <Route path="viewpayment" element={<ViewPayment />} />

                 <Route path="servicelist" element={<ServiceList />} />
                 <Route path="servicelist/addservice" element={<AddService />} />

                 <Route path="productlist" element={<ProductList />} />
                 <Route path="productlist/addproduct" element={<AddProduct />} />

                 <Route path="joblist" element={<JobList />} />
                 <Route path="joblist/addjob" element={<AddJob />} />

                 <Route path="tasklist" element={<TaskList />} />
                 <Route path="tasklist/addtask" element={<AddTask />} />
                 
                 <Route path="invoice" element={<Invoice />} />
                 <Route path="transctionpayment" element={<Payments />} />
                 <Route path="paymentlink" element={<PaymentLink />} />
                 <Route path="estimate" element={<Estimate />} />
                 <Route path="costexpense" element={<CostExpenses />} />
                 <Route path="statement" element={<Statement />} />
                 <Route path="linkdetails" element={<LinkDetails />} />
                 <Route path="addestimate" element={<AddEstimate />} />
                 <Route path="viewestimate" element={<ViewEstimate />} />
                 <Route path="newcost" element={<NewCost />} />
                 <Route path="opencost" element={<OpenCost />} />
                 <Route path="docHub" element={<DocumentHub />} />
                 <Route path="documentregister" element={<DocumentRegister />} />
                 <Route path="documentstype" element={<DocumentType />} />
                 <Route path="documentstype" element={<DocumentType />} />
                 <Route path="documentsgroup" element={<DocumentGroup />} />
                 <Route path="adddocuments" element={<AddDocument />} />
                 <Route path="opendocuments" element={<OpenDocuments />} />
                 <Route path="adddocumentgroup" element={<AddDocumentGroup />} />
                 <Route path="opendocumentgroup" element={<OpenDocumentsGroup />} />
                 <Route path="email" element={<EmailIntraction />} />
                 <Route path="sms" element={<SmsIntraction />} />
                 <Route path="whatsapp" element={<WhatAppIntraction />} />
                 <Route path="telephonic" element={<TelephonicsIntraction />} />
                 <Route path="facetoface" element={<FaceToFace />} />
                 <Route path="notificationsetting" element={<Notification />} />
                 <Route path="accounthistory" element={<AccountHistory />} />
                 <Route path="userhistory" element={<UserHistory />} />
                 <Route path="systemhistory" element={<SystemHistory />} />
                 <Route path="settingshistory" element={<SettingHistory />} />
                 <Route path="smsnotification" element={<SmsNotification />} />
                 <Route path="whatappnotification" element={<WhatappNotification />} />
              </Route>
            </Routes> 
          </ScrollToTop>
         
        </BrowserRouter>    
    );

}

export default App;
