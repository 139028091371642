function Intractions(){
const taskHeading=[
"Account", "Last Interactions", "Date & Time", "Type",""
]
const task=[
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},
{
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
},  {
account:"Euro Scientific",
lastInt:"Document Interactions ",
date:"12/19/2023  7:00PM",
type:"SMS"
}
]
return(
<div className="transction-table">
<div className="tableResponsive">
<table>
<thead>
<tr>
{
taskHeading.map((item,index)=>{
return <th key={index}>{item}</th>
}) 
}
</tr>
</thead>
<tbody>
{
task.map((item, index)=>{
return <tr key={index}>
<td>{item.account}</td>
<td>{item.lastInt}</td>
<td>{item.date}</td>
<td>{item.type}</td>
<td><span className="budget">Manual</span></td>
</tr>
})
}
</tbody>
</table>
</div>
<div className="tablenav">
<p>See all 12 result </p>
<button type="button" className="fillBtn">Update</button>
</div>
</div> 
)
}
export default Intractions;