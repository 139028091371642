import edit from "../../../../assets/images/edit.svg";
import bin from '../../../../assets/images/delete.svg';
import view from '../../../../assets/images/view.svg';
import uploadPhoto from '../../../../assets/images/uploadPhoto.svg';
import userprofile from '../../../../assets/images/profile.png';
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken, postWithTokenFormData, postWithOutToken, postWithOutTokenWithoutData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

const AccountPartnerDetails =()=>{
    const navigate =useNavigate();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#C00305");
    
      const CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
      const override = CSSProperties;
    
      const dispatch = useDispatch();
      const location = useLocation();
    
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [account_id, setAccountId] = useState("");
    const [deleteType, setDeleteType] = useState(null);
    const [deleteIds, setDeleteIds] = useState(null);
    const [partner_id, setPartnerId] = useState(null);
    const [modalOwnerInfoOpen, setModelOwnerInfoOpen] = useState(false);
    const [owner_info, setOwnerInfoData] = useState(null);
    const [list_type, setListType] = useState(1);


    const [owner_type, setOwnerType] = useState("");
    const [owner_name, setOwnerName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [aadhaar_no, setAadhaarNo] = useState("");
    const [pan_no, setPanNo] = useState("");
    const [date_of_joining, setDateOfJoining] = useState("");
    const [din_no, setDinNo] = useState("");
    const [pin_code, setPinCode] = useState("");
    const [address_1, setAddressOne] = useState("");
    const [address_2, setAddressTwo] = useState("");
    const [country_id, setCountry] = useState("");
    const [state_id, setState] = useState("");
    const [city_id, setCity] = useState("");
    const [profile_image, setProfileImage] = useState("");
    const [profile_image_pre, setProfileImagePre] = useState("");
    const [signature_image, setSignatureImage] = useState("");
    const [signature_image_pre, setSignatureImagePre] = useState("");

    const [profile_image_pre_edit_value, setProfileImagePreEditValue] = useState("");
    const [signature_image_pre_edit_value, setSignatureImagePreEditValue] = useState("");
    const [is_profile_image_delete, setProfileImageDelete] = useState(0);
    const [is_signature_delete, setSignatureDelete] = useState(0);

    const [addowners, setAddOwner] = useState(false);
    const [addownerslist, setaddownerslist] = useState(true);
    const [ownerList, setOwnerList] = useState({IsLoading : true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        CountryListFetch();
    }, []);

    useEffect(() => {
        if(userToken != ''){
            //console.log(location);
            if(location.state.id != null){
                setAccountId(location.state.id)
                PartnerListFetch(0,list_limit, location.state.id, list_type); 
            }
        }
    }, [userToken]);
    
    //alert start
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const CountryListFetch = () => {
        postWithOutTokenWithoutData(API_URL, 'countries').then((result) => {
            if(result.status === true){
                    setCountryList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchState = (country_id) => {
        postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
            if(result.status === true){
                    setStateList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchCity = (state_id) => {
        postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
            if(result.status === true){
                setCityList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    
    const handleClickOpen = (id, type) => {

        setDeleteType(type);
  
       if(type == 'M'){
          const all_error_msg = [];
          let selected_arr = [];
  
          let checkbox_all_access_length = $('.partner_checkbox:checked').length;
  
          if(checkbox_all_access_length > 0){
                  setOpen(true);
                  $('.partner_checkbox:checked').each(function(){
                      selected_arr.push($(this).val());
                      
                  });
  
                  setDeleteIds(selected_arr);
          }else{
                  all_error_msg.push("Please select atleast one row");
                  onShowAlert("error", all_error_msg);
          }
        }else if(type == 'S'){
          setOpen(true);
          setDeleteIds(id);
        }
      
      };
      
    const handleClose = () => {
        setOpen(false);
    };

    const ChangeTab = (tab_url) => {
        const all_error_msg = [];
      
        if(location.state.id == null){
          all_error_msg.push("Please Create Account Profile First");
          onShowAlert("error", all_error_msg);
        }else{
          navigate(tab_url, {state: {id: location.state.id}})
        }
      }

    const addOwners =(action_type)=>{
        if(action_type == "add"){
            setPartnerId(null);
            setOwnerType("");
            setOwnerName("");
            setEmail("");
            setPhone("");
            setAadhaarNo("");
            setPanNo("");
            setDateOfJoining("");
            setDinNo("");
            setPinCode("");
            setAddressOne("");
            setAddressTwo("");
            setCountry("");
            setState("");
            setCity("");
            setProfileImage("");
            setProfileImagePre("");
            setProfileImagePreEditValue("");
            setSignatureImage("");
            setSignatureImagePre("");
            setSignatureImagePreEditValue("");
            setProfileImageDelete(0);
            setSignatureDelete(0);
        
        }
        setAddOwner(true);
        setaddownerslist(false);
    }
    
    const Cancel = () => {
        setAddOwner(false);
        setaddownerslist(true);

        reloadList();
    }

    const reloadList = ()=>{
        setAddOwner(false);
        setaddownerslist(true);
        setListType(1);
        setPageSelected(null);
        PartnerListFetch(0,list_limit, location.state.id, 1); 
    }

       const checkbox = ()=>{
            return <div className="checkbox">
            <input class="styled-checkbox" id="styled-checkbox-all" type="checkbox" value="" onChange={() => CheckAll('styled-checkbox-all','partner_checkbox')}/>
            <label for="styled-checkbox-all"></label>
            </div>
        }
        const contactListhead =[checkbox(), "Type", "Name", "Joining", "Contact", "Pan", "Aadhaar","Din","Status","Action"];
        const PartnerListFetch = (offset, limit, account_id, list_type) => {
            setLoading(true);
            postWithToken(API_URL, 'account-partners-list', {offset: offset, limit: limit, account_id: account_id, search_status: list_type}, userToken).then((result) => {
                console.log(result);
                setLoading(false);
                if(result.status === true){
                    setOwnerList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }
       
        const deletePartner = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];

            if(deleteType == 'M'){
                          
              postWithToken(API_URL, 'account-partners/multiple-delete', {ids: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                                           
                          $("#styled-checkbox-all").prop("checked", false);
                          $(".partner_checkbox").prop("checked", false);

                      if(id.length == list_limit){
                          //console.log(itemOffset);
               
                          let off = itemOffset;
                          off = off - list_limit;

                          //console.log(off);

                          if(itemOffset > 0){
                            setItemOffset(off);
                          
                            setPageSelected(page_selected - 1);
                            PartnerListFetch(off, list_limit, account_id, list_type);
                          }else{
                            setItemOffset(off);
                            setPageSelected(0);
                            PartnerListFetch(0, list_limit, account_id, list_type);
                          }
                      }else{
                          let arr = ownerList.list;

                          id.map((ids, index) => {
                              let newArray = arr.filter((item) => item.id !== parseInt(ids));
                              arr = newArray;

                              if(id.length == (index + 1)){

                                if(arr.length > 0){
                                  setOwnerList({IsLoading : false, list: newArray, total_count: (ownerList.total_count - id.length)});

                                }else{
                                    let off = itemOffset;
                                    off = off - list_limit;

                                    if(itemOffset > 0){
                                      setItemOffset(off);
                                    
                                      setPageSelected(page_selected - 1);
                                      PartnerListFetch(off, list_limit, account_id, list_type);
                                    }else{
                                      setItemOffset(off);
                                      setPageSelected(0);
                                      PartnerListFetch(0, list_limit, account_id, list_type);
                                    }
                                }

                              }
                          })
                      }
                         
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }else if(deleteType == 'S'){
              postWithToken(API_URL, 'account-partners/delete', {id: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                          const newArray = ownerList.list.filter((item) => item.id !== id);

                          if(newArray.length > 0){
                            setOwnerList({IsLoading : false, list: newArray, total_count: (ownerList.total_count - 1)});

                          }else{
                              let off = itemOffset;
                              off = off - list_limit;

                              if(itemOffset > 0){
                                setItemOffset(off);
                              
                                setPageSelected(page_selected - 1);
                                PartnerListFetch(off, list_limit, account_id, list_type);
                              }else{
                                setItemOffset(off);
                                setPageSelected(0);
                                PartnerListFetch(0, list_limit, account_id, list_type);
                              }
                          }
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }
        }

        function onSelectFile(event) {
            event.preventDefault();
            if (event.target.files && event.target.files[0]) {
              const reader = new window.FileReader();
              reader.readAsDataURL(event.target.files[0]);
              reader.onload = () => {
                //upload_profile(reader.result);
               // console.log(reader.result);
                setProfileImage(event.target.files[0]);
                setProfileImagePre(reader.result);
                //console.log(event.target.files[0]);
              };
            }
          }

        function onSelectSignFile(event){
            event.preventDefault();
            if (event.target.files && event.target.files[0]) {
              const reader = new window.FileReader();
              reader.readAsDataURL(event.target.files[0]);
              reader.onload = () => {
                //upload_profile(reader.result);
        
               // console.log(reader.result);
               setSignatureImage(event.target.files[0]);
               setSignatureImagePre(reader.result);
        
                //console.log(event.target.files[0]);
        
              };
            }
        }

        const addPartner = (event) => {
            event.preventDefault();
            
            let flg = true;
            const all_error_msg = [];
    
            if(owner_type.search(/\S/) == -1){
                all_error_msg.push("Please enter owner type");
                if(flg == true){flg = false;}
              }
        
            if(owner_name.search(/\S/) == -1){
                all_error_msg.push("Please enter Owner Name");
                if(flg == true){flg = false;}
            }

            if(email.search(/\S/) != -1){
                let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                if (reg.test(email) === false) {
                    all_error_msg.push("Please enter valid email Id");
                    if(flg == true){flg = false;}
                }
            }

            /*if(country_id == ""){
                all_error_msg.push("Please select country");
                if(flg == true){flg = false;}
            }

            if(state_id == ""){
                all_error_msg.push("Please select country");
                if(flg == true){flg = false;}
            }*/


            if(phone.search(/\S/) == -1){
                all_error_msg.push("Please enter Phone number");
                if(flg == true){flg = false;}
            }else{
                if(phone.length < 10){
                    all_error_msg.push("Please enter 10 digit Phone Number");
                    if(flg == true){flg = false;}
                }
            }

            if(pin_code.search(/\S/) == -1){
                all_error_msg.push("Please enter pin code");
                if(flg == true){flg = false;}
            }else{
                if(pin_code.length < 6){
                    all_error_msg.push("Please enter 6 digit pin code");
                    if(flg == true){flg = false;}
                }
            }

            if(date_of_joining.search(/\S/) == -1){
                all_error_msg.push("Please select joining date");
                if(flg == true){flg = false;}
            }

            if(pan_no.search(/\S/) == -1){
                all_error_msg.push("Please select pan number");
                if(flg == true){flg = false;}
            }
            
           /* if(aadhaar_no.search(/\S/) == -1){
                if(aadhaar_no.length < 12){
                    all_error_msg.push("Please enter 12 digit Aadhaar Number");
                    if(flg == true){flg = false;}
                }
            }*/
              
        
            if (flg === true) {
              setLoading(true);
        
                if(partner_id == null){
      
                    let post_data = {
                        account_id: account_id,
                        owner_type : owner_type,
                        owner_name: owner_name,
                        email: email,
                        phone: phone,
                        aadhaar_no: aadhaar_no,
                        pan_no: pan_no,
                        date_of_joining: date_of_joining,
                        din_no : din_no,
                        pin_code: pin_code,
                        address_1: address_1,
                        address_2: address_2,
                        country_id: country_id,
                        city_id: city_id,
                        state_id: state_id,
                        profile_image: profile_image,
                        signature: signature_image,
                    };
        
                    postWithTokenFormData(API_URL, 'account-partners/create', post_data, userToken).then((result) => {
                        if(result.status === true){
                              setLoading(false);
                              all_error_msg.push(result.message);
                              onShowAlert("success", all_error_msg);
    
                              setOwnerType("");
                              setOwnerName("");
                              setEmail("");
                              setPhone("");
                              setAadhaarNo("");
                              setPanNo("");
                              setDateOfJoining("");
                              setDinNo("");
                              setPinCode("");
                              setAddressOne("");
                              setAddressTwo("");
                              setCountry("");
                              setState("");
                              setCity("");
                              setProfileImage("");
                              setProfileImagePre("");
                              setSignatureImage("");
                              setSignatureImagePre("");
    
                              setPageSelected(null);
    
                              reloadList();
                             
                          }else{
                            setLoading(false);
                            all_error_msg.push(result.message);
                            onShowAlert("error", all_error_msg);
                          }
                      })
                      .catch((error) => {
                        console.log("Error : ", error);
                      });
                }else{
        
                    let post_data = {
                        id: partner_id,
                        account_id: account_id,
                        owner_type : owner_type,
                        owner_name: owner_name,
                        email: email,
                        phone: phone,
                        aadhaar_no: aadhaar_no,
                        pan_no: pan_no,
                        date_of_joining: date_of_joining,
                        din_no : din_no,
                        pin_code: pin_code,
                        address_1: address_1,
                        address_2: address_2,
                        country_id: country_id,
                        city_id: city_id,
                        state_id: state_id,
                        profile_image: profile_image,
                        signature: signature_image,
                        is_profile_image_delete: is_profile_image_delete,
                        is_signature_delete: is_signature_delete
                    };

                    /*console.log(post_data);
                    return false;*/
                  
                    postWithTokenFormData(API_URL, 'account-partners/update', post_data, userToken).then((result) => {
                    if(result.status === true){
                          setLoading(false);
                          all_error_msg.push(result.message);
                          onShowAlert("success", all_error_msg);

                              setOwnerType("");
                              setOwnerName("");
                              setEmail("");
                              setPhone("");
                              setAadhaarNo("");
                              setPanNo("");
                              setDateOfJoining("");
                              setDinNo("");
                              setPinCode("");
                              setAddressOne("");
                              setAddressTwo("");
                              setCountry("");
                              setState("");
                              setCity("");
                              setProfileImage("");
                              setProfileImagePre("");
                              setProfileImagePreEditValue("");
                              setSignatureImage("");
                              setSignatureImagePre("");
                              setSignatureImagePreEditValue("");
                              setProfileImageDelete(0);
                              setSignatureDelete(0);
                          
    
                          reloadList();
                         
                      }else{
                        setLoading(false);
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                      }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                }
              }else{
                onShowAlert("error", all_error_msg);
                
              } 
          }

          const [itemOffset, setItemOffset] = useState(0);
          function PaginatedItems({ itemsPerPage }) {
              // Here we use item offsets; we could also use page offsets
              // following the API or data you're working with.
             
              // Simulate fetching items from another resources.
              // (This could be items from props; or items loaded in a local state
              // from an API endpoint with useEffect and useState)
              const endOffset = itemOffset + itemsPerPage;
              //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
              //const currentItems = roleList.list.slice(itemOffset, endOffset);
              const pageCount = Math.ceil(ownerList.total_count / itemsPerPage);
            
              // Invoke when user click to request another page.
              const handlePageClick = (event) => {
                const newOffset = (event.selected * itemsPerPage) % ownerList.total_count;
                /*console.log(
                  `User requested page number ${event.selected}, which is offset ${newOffset}`
                );*/
  
                setPageSelected(event.selected);
                PartnerListFetch(newOffset, list_limit, account_id, list_type);
                setItemOffset(newOffset);
              };
            
              return (
                <>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=" < "
                    renderOnZeroPageCount={null}
                    forcePage={page_selected}
                  />
                </>
              );
            }

            const CheckBoxEdit = () => {
              
                   const all_error_msg = [];
           
                   let checkbox_all_access_length = $('.partner_checkbox:checked').length;
           
                   if(checkbox_all_access_length > 0){
                      
                       if(checkbox_all_access_length > 1){
                        all_error_msg.push("Please select only one row");
                        onShowAlert("error", all_error_msg);
                       }else{
                            $('.partner_checkbox:checked').each(function(){

                                EditOwnerDetails($(this).val());
                            });
                       }
                   }else{
                           all_error_msg.push("Please select atleast one row");
                           onShowAlert("error", all_error_msg);
                   }
                 
            }

            const EditOwnerDetails = (id) => {
                setLoading(true);
                const all_error_msg = [];
    
                postWithToken(API_URL, 'account-partners/view', {id: id, account_id: account_id}, userToken).then((result) => {
                      //console.log(result);
                      setLoading(false);
                      if(result.status === true){
    
                        setPartnerId(id);
                        setOwnerType(result.data.owner_type);
                        setOwnerName(result.data.owner_name);
                        setEmail(result.data.email);
                        setPhone(result.data.phone);
                        setAadhaarNo(result.data.aadhaar_no);
                        setPanNo(result.data.pan_no);
                        setDateOfJoining(result.data.date_of_joining);
                        setDinNo(result.data.din_no);
                        setPinCode(result.data.pin_code);
                        setAddressOne(result.data.address_1);
                        setAddressTwo(result.data.address_2);
                        if(result.data.country_id != null && result.data.country_id != ''){
                            setCountry(result.data.country_id);
                            fetchState(result.data.country_id)
                        }
                        if(result.data.state_id != null && result.data.state_id != ''){
                            setState(result.data.state_id);
                            fetchCity(result.data.state_id)
                        }
                        if(result.data.city_id != null && result.data.city_id != ''){
                            setCity(result.data.city_id);
                        }

                        if(result.data.profile_image_path != null && result.data.profile_image_path != ''){
                            setProfileImagePre(result.data.profile_image_path);
                            setProfileImagePreEditValue(result.data.profile_image_path);
                        }
                        if(result.data.signature_path != null && result.data.signature_path !=''){
                            setSignatureImagePre(result.data.signature_path);
                            setSignatureImagePreEditValue(result.data.signature_path);
                        }
    
                        addOwners('edit');
                           
                      }else{
                            all_error_msg.push(result.message);
                            onShowAlert("error", all_error_msg);
                      }
    
                })
                .catch((error) => {
                      console.log("Error : ", error);
                });
         } 

         function CloseOwnerInfoModel(){
            setModelOwnerInfoOpen(false);
          }
         
         const ViewOwnerInfo = (item) => {
            setOwnerInfoData(item);
            setModelOwnerInfoOpen(true);
         }
    
         const CheckAll = (id,checkbox_class) => {
            let checkbox_all_status = $('#' + id).is(":checked");
    
            if(checkbox_all_status === true){
                $("." + checkbox_class).prop("checked", true);
            }else{
                $("." + checkbox_class).prop("checked", false);
            }
        }

        const ChangeStatus = (index_id, id, current_status) => {

            setLoading(true);

            if(current_status == 1){
                  current_status = 0;
            }else{
                  current_status = 1;   
            }

            postWithToken(API_URL, 'account-partners/change-status', {id:id, status: current_status, account_id: account_id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = ownerList.list.filter((item) => item.id !== id);
                        setOwnerList({IsLoading : false, list: newArray, total_count: (ownerList.total_count - 1)});
                  }
              })
              .catch((error) => {
                  console.log("Error : ", error);
              });

      }

      const changeListType = (search_status) => {
        setListType(search_status);
        PartnerListFetch(0,list_limit, account_id, search_status);
      }

      const deleteProfileImage = () => {
        setProfileImage("");
        setProfileImagePre("");
        setProfileImagePreEditValue("");
        setProfileImageDelete(1);
      }

      const deleteSignatureImage = () => {
        setSignatureImage("");
        setSignatureImagePre("");
        setSignatureImagePreEditValue("");
        setSignatureDelete(1);
      }

      $(function(){
        var dtToday = new Date();
      
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
      
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
      
        var maxDate = year + '-' + month + '-' + day;    
        $('#joining_date').attr('max', maxDate);
      });
      

     return(
        <>
             <CustomAlert
                header={"Account Owner"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                    <div className="activityLoader">
                            <GridLoader
                            color={color}
                            loading={loading}
                            cssOverride={override}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            Loader="GridLoader"
                            /> 
                    </div>     
                 : null
                }

            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitle id="responsive-dialog-title">
                {"Delete Account Owner"}
                </DialogTitle>
                <DialogContent>
                        <DialogContentText>
                        Are you sure you want to delete this account owner
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            No
                        </Button>
                        <Button onClick={() => {deletePartner(deleteIds)}} autoFocus>
                            Yes
                        </Button>
                </DialogActions>
            </Dialog>

        <div className="accountProfileTabs">
            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer Owner</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountbank')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                    <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}} >Statutory</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                </div>
                <div className="accountbankdetails">

                { addowners === false ?  
                        <div className="partnetTable">
                            <div className="actionTable">
                                    <select value={list_type}  onChange={(e) => {changeListType(e.target.value)}} >
                                        <option value={1}>Active Owner</option>
                                        <option value={0}>Deactive Owner</option>
                                    </select>
                                <div className="tablebtngroup">
                                    <button onClick={() => addOwners('add')} className="fillbtn"><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Owners</button>
                                    <button className="fillbtn" onClick={() => CheckBoxEdit()} ><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                    <button className="fillbtn" onClick={() => handleClickOpen('','M')}><i className="fa fa-trash" aria-hidden="true"></i></button>
                                    <button className="fillbtn"><i className="fa fa-print" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div className="tableResponsive">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                contactListhead.map((item, index)=>{
                                                    let w = '';
                                                    if(index == 9){
                                                        w = 175;
                                                    }
                                                    return (<th key={index} style={{width: w}}>{item}</th>)
                                                })
                                            }
                                        </tr>

                                    </thead>

                                    {
                                        ownerList.IsLoading === false && ownerList.list.length > 0 ?
                                        <tbody>
                                            {
                                                ownerList.list.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                        <td> 
                                                                <div className="checkbox">
                                                                    <input type="checkbox" class="styled-checkbox partner_checkbox" id={'checkbox-partner-' + item.id} value={item.id}  />
                                                                    <label for={'checkbox-partner-' + item.id}></label>
                                                                </div>
                                                        </td>
                                                        <td >{item.owner_type}</td>
                                                        <td >{ item.owner_name}</td>
                                                        <td >{item.date_of_joining}</td>
                                                        <td >{item.phone.replace(/.(?=.{4})/g, 'x')}</td>
                                                        {
                                                            item.pan_no != null && item.pan_no != '' ?
                                                                <td >{item.pan_no.replace(/.(?=.{4})/g, 'x')}</td>
                                                                : 
                                                                <td >{'-'}</td>
                                                        }
                                                        {
                                                            item.aadhaar_no != null && item.aadhaar_no != '' ?
                                                            <td >{item.aadhaar_no.replace(/.(?=.{4})/g, 'x')}</td>
                                                            :
                                                            <td >{'-'}</td>
                                                        }
                                                        
                                                        <td >{item.din_no}</td>
                                                        <td onClick={() => {ChangeStatus(index, item.id, item.active)}}  className={item.active == 1 ? "active" :"inactive"}>{item.active == 1 ? "Active" :"Inactive"}</td>
                                                        <td >
                                                            <div style={{display: 'flex'}}>
                                                                <button onClick={() => EditOwnerDetails(item.id)} ><img src={edit}/></button>
                                                                <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id,'S')}><img src={bin}/></button>
                                                                <button style={{ marginLeft: 6}} onClick={() => {ViewOwnerInfo(item)}}><img src={view}/></button> 

                                                            </div>
                                                        </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            
                                            </tbody>
                                    : ownerList.IsLoading === false && ownerList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={9} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : ownerList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }
                                    
                                </table>
                            </div>
                            <div className="tablenav">
                                <div className="pagination_div">
                                    <PaginatedItems itemsPerPage={list_limit} />
                                </div>
                                <div className="btnGroup">
                                    <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountbank')}}>Previous</button>
                                    <button type="button" className="fillbtn" onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Next</button>
                                </div>
                            </div>
                        </div>
                    : null
                    }

                        {addowners === true ? 
                            <div className="addowners">
                                <form onSubmit={addPartner}>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="profile">
                                                <div className="userprofile" style={{ position: 'relative', }}>
                                                    {
                                                        profile_image_pre != "" ? 
                                                          <img src={profile_image_pre} />
                                                        : <img src={userprofile} />
                                                    }

                                                    <div className="partner_image_upload">
                                                       <input  type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectFile(e)} />
                                                       <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" ></i>
                                                    </div>
                                                </div>
                                                {
                                                    partner_id != null && profile_image_pre_edit_value != ""?
                                                        <div className="btn-action">
                                                            <a target="_blank" href={profile_image_pre_edit_value} download={profile_image_pre_edit_value}><img src={uploadPhoto} /></a>
                                                            <button type="button" onClick={() => deleteProfileImage()} ><img src={bin} /></button>
                                                        </div>
                                                    : null 
                                                }
                                                <div className="signupload" style={{ position: 'relative', }}>
                                                    {
                                                        signature_image_pre != "" ? 
                                                          <img src={signature_image_pre} />
                                                        : <span>Attach Sign....</span>
                                                    }

                                                    <div className="partner_image_upload">
                                                        <input  type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectSignFile(e)} />
                                                        <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" ></i>
                                                    </div>
                                                    
                                                </div>
                                                {
                                                    partner_id != null && signature_image_pre_edit_value != ""?
                                                        <div className="btn-action">
                                                            <a target="_blank" href={signature_image_pre_edit_value} download={signature_image_pre_edit_value}><img src={uploadPhoto} /></a>
                                                            <button onClick={() => deleteSignatureImage()}><img src={bin} /></button>
                                                        </div>
                                                        : null
                                                }
                                                <div className="btngrp">
                                                    <button type="button" className="fadebtn"  onClick={() => {Cancel()}}>Cancel</button>
                                                    <button type="submit" className="fillbtn" >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="partnerForm">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Owner Type<sub>*</sub></label>
                                                            <select value={owner_type} onChange={(e) => {setOwnerType(e.target.value)}}>
                                                                <option value="">Select Type</option>
                                                                <option key={'type_partner'} value="Partner">Partner</option>
                                                                <option key={'type_directors'} value="Directors">Directors</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Owner Name<sub>*</sub></label>
                                                            <input type="text"  placeholder="Name" value={owner_name} onChange={(event) => setOwnerName(event.target.value)}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Country<sub>*</sub></label>
                                                            <select value={country_id} onChange={(e) => {setCountry(e.target.value); fetchState(e.target.value)}}>
                                                                <option value="">Select Country</option>
                                                                {
                                                                    countryList.length > 0 ?
                                                                    countryList.map((item, key) => {
                                                                        return(
                                                                            <option key={'country_' + key} value={item.id}>{item.country_name}</option>
                                                                        );
                                                                    })
                                                                    : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Address1</label>
                                                            <input type="text"  placeholder="Address" value={address_1} onChange={(event) => setAddressOne(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Address2</label>
                                                            <input type="text"  placeholder="Address" value={address_2} onChange={(event) => setAddressTwo(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Pincode<sub>*</sub></label>
                                                            <input type="text"  placeholder="Pincode" maxLength={6} value={pin_code} onChange={(event) => setPinCode(event.target.value)} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>State<sub>*</sub></label>
                                                            <select value={state_id} onChange={(e) => {setState(e.target.value); fetchCity(e.target.value)}}>
                                                                <option value="">Select State</option>
                                                                {
                                                                    stateList.length > 0 ?
                                                                    stateList.map((item, key) => {
                                                                        return(
                                                                            <option key={'state_' + key} value={item.id}>{item.name}</option>
                                                                        );
                                                                    })

                                                                    : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>City<sub>*</sub></label>
                                                            <select value={city_id} onChange={(e) => {setCity(e.target.value)}}>
                                                                <option value="">Select City</option>
                                                                {
                                                                    cityList.length > 0 ?
                                                                    cityList.map((item, key) => {
                                                                        return(
                                                                            <option key={'city_' + key} value={item.id}>{item.name}</option>
                                                                        );
                                                                    })
                                                                    : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Email ID</label>
                                                            <input type="text"  placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Contact Number<sub>*</sub></label>
                                                            <input type="text"  placeholder="Contact Number" maxLength={10} value={phone} onChange={(event) => setPhone(event.target.value)}  onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Pan Number<sub>*</sub></label>
                                                            <input type="text"  placeholder="Pan Number"  value={pan_no} onChange={(event) => setPanNo(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Date of Joining<sub>*</sub></label>
                                                            <input type="date" id="joining_date"  placeholder="Date" value={date_of_joining} onChange={(event) => setDateOfJoining(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Din Number</label>
                                                            <input type="text"  placeholder="Din Number" value={din_no} onChange={(event) => setDinNo(event.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Aadhaar Number</label>
                                                            <input type="text"  placeholder="Aadhaar Number" maxLength={12} value={aadhaar_no} onChange={(event) => setAadhaarNo(event.target.value)} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    
                                        </div>
                                        </div>
                                    </form>
                                
                                </div>

                                : null
                        }           
                </div>
            </div>    
        </div> 

        <Modal isOpen={modalOwnerInfoOpen} onRequestClose={CloseOwnerInfoModel} contentLabel="Mobile OTP Modal" >
                <div className='modal_header'>
                Owner Details <button onClick={CloseOwnerInfoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                   
                        <div className='row'>
                            <div className='col-12' >

                              {
                                owner_info === null ?
                                <Skeleton height={24} />
                                : 

                                <div className="row">
                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Owner Type : </label>
                                           <span style={{display: 'flex', flex: 1}}>{owner_info.owner_type}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Owner Name : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.owner_name} </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>email : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.email}</span>  
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Phone : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.phone}</span>  
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Pan No : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.pan_no}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Aadhaar Number : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.aadhaar_no}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Date of Joining : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.date_of_joining}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Din No : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.din_no}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>address 1 : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.address_1}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>address 2 : </label>
                                            <span style={{display: 'flex', flex: 1}}>{owner_info.address_2}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Country : </label>
                                            {
                                                owner_info.country_id != null && owner_info.country_id > 0 ?
                                            
                                                <span style={{display: 'flex', flex: 1}}>{owner_info.country.country_name}</span> 
                                                :
                                                <span style={{display: 'flex', flex: 1}}>{'-'}</span> 
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>State : </label>
                                            {
                                                owner_info.state_id != null && owner_info.state_id > 0 ?
                                            
                                                <span style={{display: 'flex', flex: 1}}>{owner_info.state.name}</span> 
                                                :
                                                <span style={{display: 'flex', flex: 1}}>{'-'}</span> 
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>City : </label>
                                            {
                                                owner_info.city_id != null && owner_info.city_id > 0 ?
                                            
                                                <span style={{display: 'flex', flex: 1}}>{owner_info.city.name}</span> 
                                                :
                                                <span style={{display: 'flex', flex: 1}}>{'-'}</span> 
                                            }
                                        </div>
                                    </div>

                                    

                                </div>
                              }
                                
                            </div>
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseOwnerInfoModel}>Close</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>


       </>    
     )
}
export default AccountPartnerDetails;