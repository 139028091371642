import user from '../assets/images/user.svg';
import lock from '../assets/images/lock.svg';
import logo from '../assets/images/logo.svg';
import envolope from '../assets/images/envolop.svg';
import call from '../assets/images/call.svg';
import email from '../assets/images/email.svg';
import phone from '../assets/images/phone-call.svg';
import React, {useEffect, useState, CSSProperties, useRef} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import OtpInput from 'react-otp-input';

function Signup(){

  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const dispatch = useDispatch();

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [username, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [modalMobileEmailOpen, setModelMobileEmailOpen] = useState(false);
  const [mobile_otp, setMobileOtp] = useState('');
  const [email_otp, setEmailOtp] = useState('');
  const [email_otp_status, setEmailOTPStatus] = useState(false);
  const [register_id, setRegisterId] = useState("");

  const [minutes_mobile, setMinutesMobile] = useState(1);
  const [seconds_mobile, setSecondsMobile] = useState(30);

  const [minutes_email, setMinutesEmail] = useState(1);
  const [seconds_email, setSecondsEmail] = useState(30);

  
  useEffect(() => {
    if(modalMobileEmailOpen === true){
      const interval = setInterval(() => {
        if (seconds_mobile > 0) {
          setSecondsMobile(seconds_mobile - 1);
        }
      
        if (seconds_mobile === 0) {
        if (minutes_mobile === 0) {
          clearInterval(interval);
        } else {
          setSecondsMobile(59);
          setMinutesMobile(minutes_mobile - 1);
        }
        }
      }, 1000);
      
      return () => {
        clearInterval(interval);
      };
    }
    
	}, [seconds_mobile, modalMobileEmailOpen]);

  useEffect(() => {
    if(modalMobileEmailOpen === true){
      const interval_email = setInterval(() => {
        if (seconds_email > 0) {
          setSecondsEmail(seconds_email - 1);
        }
      
        if (seconds_email === 0) {
        if (minutes_email === 0) {
          clearInterval(interval_email);
        } else {
          setSecondsEmail(59);
          setMinutesEmail(minutes_email - 1);
        }
        }
      }, 1000);

      return () => {
        clearInterval(interval_email);
      };

    }
    
	}, [seconds_email, modalMobileEmailOpen]);

  

  function CloseMobileEmailModel(){
    setModelMobileEmailOpen(false);
    resetForm();
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setUserName("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  
  }

  const SignInAction = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if (username.search(/\S/) == -1) {
      all_error_msg.push("Enter your username");

      if (flg === true) {
        flg = false;
      }
    }

    if(phone.search(/\S/) == -1){
      all_error_msg.push("Please enter mobile number");
      if(flg == true){flg = false;} 
    }
  if(email.search(/\S/) != -1){
    setEmailOTPStatus(true);
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }
    }else{
      setEmailOTPStatus(false);
    }
    
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
	  const lowercaseRegExp   = /(?=.*?[a-z])/;
	  const digitsRegExp      = /(?=.*?[0-9])/;
	  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
	  const minLengthRegExp   = /.{8,}/;
    
    if (password.search(/\S/) == -1) {
      all_error_msg.push("Enter your password");
      if (flg === true) {
        flg = false;
      }
    }else if(password.search(/\S/) != -1){
      const passwordLength =      password.length;
      const uppercasePassword =   uppercaseRegExp.test(password);
      const lowercasePassword =   lowercaseRegExp.test(password);
      const digitsPassword =      digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword =   minLengthRegExp.test(password);
  
      let errMsg ="";
      let flgg = true;
      if(!uppercasePassword){
          errMsg= "password at least one uppercase";
          flgg = false;
      }else if(!lowercasePassword){
          errMsg= "password at least one lowercase";
          flgg = false;
      }else if(!digitsPassword){
          errMsg= "password at least one digit";
          flgg = false;
      }else if(!specialCharPassword){
          errMsg= "password at least one special characters";
          flgg = false;
      }else if(!minLengthPassword){
          errMsg= "password at least minimum eight characters";
          flgg = false;
      }else{
        errMsg="";
      }
  
      if(flgg == false){
        all_error_msg.push(errMsg);
        if(flg == true){flg = false;}
      }
    }

    if (confirm_password.search(/\S/) == -1) {
      all_error_msg.push("please confirm password");
      if (flg === true) {
        flg = false;
      }
    }

    if(password != confirm_password){
      all_error_msg.push("Mismatch confirm password");
      if(flg == true){flg = false;}
      
    }



    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'user-register', {name: username, email: email, phone: phone, password: password, password_confirmation: confirm_password}).then((result) => {
          if(result.status === true){
                setRegisterId(result.data.id);
                setLoading(false);
                setModelMobileEmailOpen(true);
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  const sendMObileOtp = () => {
    const all_error_msg = [];
      postWithOutToken(API_URL, 'resend-phone-otp', {phone: phone}).then((result) => {
          if(result.status === true){
              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg);

              setMinutesMobile(1);
              setSecondsMobile(30);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
  }

  const sendEmailOtp = () => {
    const all_error_msg = [];
      postWithOutToken(API_URL, 'resend-email-otp', {email: email}).then((result) => {
          if(result.status === true){
              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg); 
                          
              setMinutesEmail(1);
              setSecondsEmail(30);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
  }

  const VerifyMobileEmailOtp = () => {
    let flg = true;
    const all_error_msg = [];

    if(email_otp_status === true){
      if(email_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your email");
        if(flg == true){flg = false;}
      }

    }

    if(mobile_otp.length < 6){
      all_error_msg.push("Please enter 6 digit otp to verify your mobile");
      if(flg == true){flg = false;}
    }

    if(flg == true){
        setLoading(true);
        postWithOutToken(API_URL, 'user-otp-verify', {id: register_id,email_otp: email_otp, phone_otp: mobile_otp}).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                resetForm();
                setEmailOTPStatus(false);
                setModelMobileEmailOpen(false);

                all_error_msg.push(result.message);
                onShowAlert("success", all_error_msg);
                
                navigate("/");
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }


   return(
      <div className="authwapper">
        <div className="container">

        <CustomAlert
              header={"Login"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              

          <div className="loginfrom">
            <h1>Sign Up</h1>
            <form onSubmit={SignInAction}>
              <div className="form-group">
                <img className='formIcon' src={user}/>
                  <input type="text" placeholder="Username" onChange={(event) => setUserName(event.target.value)}/>
              </div>
              <div className="form-group">
                <img className='formIcon' src={email}/>
                <input type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)}/>
              </div>
              <div className="form-group">
                  <img className='formIcon' src={phone}/>
                  <input type="number" placeholder="Phone Number" maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                      return true;
                              }else{event.preventDefault();}}} onChange={(event) => setPhone(event.target.value)}/>
              </div>
              <div className="form-group">
                  <img className='formIcon' src={lock}/>
                  <input type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)}/>
              </div>
              <div className="form-group">
                  <img className='formIcon' src={lock}/>
                  <input type="password" placeholder="Confirm Password" onChange={(event) => setConfirmPassword(event.target.value)}/>
              </div>
              <button type="submit" className="fill-btn">SignIN</button>
              <p>Don't have an account ?    <Link className='highlight' to="/">Login</Link> </p>
            </form>
        </div>
        <div className="companyinfo">
          <a href=''><img src={logo}/></a>
          <h2>Ashirbad Cloud</h2>
          <p>A construct & concept thereof</p>
          <div className='contactinfo'>
            <a href='mailto:souvik.mukherjee@ashirbadtax.com'>
            <img className='' src={envolope}/>
              souvik.mukherjee@ashirbadtax.com</a><br/>
              <a href='tel:souvik.mukherjee@ashirbadtax.com'>
            <img className='' src={call}/>
            +91 0000000000</a>
          </div>
        </div>
      </div>

      <Modal isOpen={modalMobileEmailOpen} onRequestClose={CloseMobileEmailModel} contentLabel="Mobile OTP Modal" >
                <div className='modal_header'>
                  OTP Verification <button onClick={CloseMobileEmailModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
                <div className='modalBody'>
                   
                        <div className='row'>

                               <div className="col-12" style={{textAlign: 'center'}}>
                                  <h2>Enter Mobile OTP</h2>
                                </div>

                                <div className='col-12 otp_input_parent_div'>
                                    
                                    <OtpInput
                                        value={mobile_otp}
                                        onChange={setMobileOtp}
                                        numInputs={6}
                                        inputType={'tel'}
                                        renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                        renderInput={(props) => <input {...props} />}
                                        />
                                </div>


                                <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <>
                                {
                                  seconds_mobile > 0 || minutes_mobile > 0 ? 
                                  <span style={{textAlign: 'center'}} >Time Remaining: {minutes_mobile < 10 ? `0${minutes_mobile}` : minutes_mobile}:{seconds_mobile < 10 ? `0${seconds_mobile}` : seconds_mobile}</span>
                                  : <span style={{textAlign: 'center'}}>Didn't recieve code?</span>
                                }

                                  <div style={{ marginTop: 20, alignSelf: "center" }}>
                                      {
                                        seconds_mobile > 0 || minutes_mobile > 0 ?
                                        <button type="button" className='fadebtn_resend'>Resend Mobile OTP</button>
                                        : 
                                        <button type="button" className='fillbtn' onClick={sendMObileOtp}>Resend Mobile OTP</button>
                                        
                                        
                                      }
                                  </div>

                                  </>

                                </div>

                               

                            {
                              email_otp_status === true ?
                                <>
                                  <div className="col-12" style={{textAlign: 'center'}}>
                                        <h2>Enter Email OTP</h2>
                                  </div>

                                  <div className='col-12 otp_input_parent_div' >
                                    
                                      <OtpInput
                                          value={email_otp}
                                          onChange={setEmailOtp}
                                          numInputs={6}
                                          inputType={'tel'}
                                          renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                          renderInput={(props) => <input {...props} />}
                                          />
                                  </div>

                                  <div style={{ marginTop: 20, alignSelf: "center", width: '100%', display: 'flex', flexDirection: 'column'}}>
                                    <>
                                    {
                                      seconds_email > 0 || minutes_email > 0 ? 
                                      <span style={{textAlign: 'center'}} >Time Remaining: {minutes_email < 10 ? `0${minutes_email}` : minutes_email}:{seconds_email < 10 ? `0${seconds_email}` : seconds_email}</span>
                                      : <span style={{textAlign: 'center'}}>Didn't recieve code?</span>
                                    }

                                      <div style={{ marginTop: 20, alignSelf: "center" }}>
                                          {
                                            seconds_email > 0 || minutes_email > 0 ?
                                            <button type="button" className='fadebtn_resend'>Resend Email OTP</button>
                                            : 
                                            <button type="button" className='fillbtn' onClick={sendEmailOtp}>Resend Email OTP</button>
                                            
                                            
                                          }
                                      </div>

                                      </>

                                    </div>




                                </>   
                            : null
                            }
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseMobileEmailModel}>Cancel</button>
                                    <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyMobileEmailOtp} >Verify OTP</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>
       
      </div>
   )
}
export default Signup;