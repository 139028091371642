import edit from "../../../../assets/images/edit.svg";
import bin from '../../../../assets/images/delete.svg';
import view from '../../../../assets/images/view.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken, postWithOutTokenWithoutData, postWithOutToken} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';
import Multiselect from 'multiselect-react-dropdown';

const AccountBranch =()=>{

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [modalBranchInfoOpen, setModelBranchInfoOpen] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [branch_info, setBranchInfoData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [deleteIds, setDeleteIds] = useState(null);
    const [branch_id, setBranchId] = useState(null);
    const [account_id, setAccountId] = useState("");
    const [addView, setAddView]= useState(false);

    const [name, setName] = useState("");
    const [branch_location, setLocation] = useState("");
    const [address_one, setAddressOne] = useState("");
    const [address_two, setAddressTwo] = useState("");
    const [pin_code, setPinCode] = useState("");
    const [country_id, setCountry] = useState("");
    const [city_id, setCity] = useState("");
    const [state_id, setState] = useState("");
    const [status, setStatus] = useState("");


    const [branchList, setBranchList] = useState({IsLoading : true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [account_user, setAccountUser] = useState({options: []});

    const [selected_account_user, setSelectedAccoutUser] = useState([]);
    const [selected_account_user_primary_id, setSelectedUserPrimary] = useState(null);


    useEffect(() => {
        CountryListFetch();
    }, []);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                setAccountId(location.state.id);
                BranchListFetch(0,list_limit, location.state.id); 
                AccountUserListFetch(location.state.id);
            }
        }
    }, [userToken]);

    const AccountUserListFetch = (account_id) => {
        postWithToken(API_URL, 'account-branchs-users-list', {account_id: account_id}, userToken).then((result) => {
            console.log(result);
            if(result.status === true){
                setAccountUser({options:result.data});
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }


    const CountryListFetch = () => {
        postWithOutTokenWithoutData(API_URL, 'countries').then((result) => {
            if(result.status === true){
                    setCountryList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchState = (country_id) => {
        postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
            if(result.status === true){
                    setStateList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchCity = (state_id) => {
        postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
            if(result.status === true){
                setCityList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    

    const addBranchs =(action_type) =>{
        if(action_type == "add"){
            setBranchId(null);
          }
          setAddView(true);
    }
    const Cancel = () => {
        setAddView(false);
        reloadList();
    }


    const handleClickOpen = (id, type) => {

        setDeleteType(type);
        if(type == 'M'){
          const all_error_msg = [];
          let selected_arr = [];
  
          let checkbox_all_access_length = $('.branch_checkbox:checked').length;
  
          if(checkbox_all_access_length > 0){
                  setOpen(true);
                  $('.branch_checkbox:checked').each(function(){
                      selected_arr.push($(this).val());
                      
                  });
  
                  setDeleteIds(selected_arr);
          }else{
                  all_error_msg.push("Please select atleast one row");
                  onShowAlert("error", all_error_msg);
          }
        }else if(type == 'S'){
          setOpen(true);
          setDeleteIds(id);
        }
      
      };
      
        const handleClose = () => {
          setOpen(false);
        };



    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
    
    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-all" type="checkbox" value="" onChange={() => CheckAll('styled-checkbox-all','branch_checkbox')}/>
        <label for="styled-checkbox-all"></label>
        </div>
        }
        const contactListhead =[checkbox(), "Branch Name", "Location", "Primary  Contact Name", "Primary  Contact Number","Action"];

        const reloadList = ()=>{
            setAddView(false);
            setPageSelected(null);
            BranchListFetch(0,list_limit, location.state.id); 
        }
       
        const BranchListFetch = (offset, limit, account_id) => {
            setLoading(true);
            postWithToken(API_URL, 'account-branchs-list', {offset: offset, limit: limit, account_id: account_id}, userToken).then((result) => {
                console.log(result);
                setLoading(false);
                if(result.status === true){
                    setBranchList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteBranch = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];

            if(deleteType == 'M'){
               
              postWithToken(API_URL, 'account-branchs/multiple-delete', {ids: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                                           
                          $("#styled-checkbox-all").prop("checked", false);
                          $(".branch_checkbox").prop("checked", false);

                      if(id.length == list_limit){
                          //console.log(itemOffset);
               
                          let off = itemOffset;
                          off = off - list_limit;

                          //console.log(off);

                          if(itemOffset > 0){
                            setItemOffset(off);
                          
                            setPageSelected(page_selected - 1);
                            BranchListFetch(off, list_limit, account_id);
                          }else{
                            setItemOffset(off);
                            setPageSelected(0);
                            BranchListFetch(0, list_limit, account_id);
                          }
                      }else{
                          let arr = branchList.list;

                          id.map((ids, index) => {
                              let newArray = arr.filter((item) => item.id !== parseInt(ids));
                              arr = newArray;

                              if(id.length == (index + 1)){

                                if(arr.length > 0){
                                  setBranchList({IsLoading : false, list: newArray, total_count: (branchList.total_count - id.length)});

                                }else{
                                    let off = itemOffset;
                                    off = off - list_limit;

                                    if(itemOffset > 0){
                                      setItemOffset(off);
                                    
                                      setPageSelected(page_selected - 1);
                                      BranchListFetch(off, list_limit, account_id);
                                    }else{
                                      setItemOffset(off);
                                      setPageSelected(0);
                                      BranchListFetch(0, list_limit, account_id);
                                    }
                                }

                              }
                          })
                      }
                         
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }else if(deleteType == 'S'){
              postWithToken(API_URL, 'account-branchs/delete', {id: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                          const newArray = branchList.list.filter((item) => item.id !== id);

                          if(newArray.length > 0){
                            setBranchList({IsLoading : false, list: newArray, total_count: (branchList.total_count - 1)});

                          }else{
                              let off = itemOffset;
                              off = off - list_limit;

                              if(itemOffset > 0){
                                setItemOffset(off);
                              
                                setPageSelected(page_selected - 1);
                                BranchListFetch(off, list_limit, account_id);
                              }else{
                                setItemOffset(off);
                                setPageSelected(0);
                                BranchListFetch(0, list_limit, account_id);
                              }
                          }
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }
        }


        const ChangeTab = (tab_url) => {
            const all_error_msg = [];
          
            if(location.state.id == null){
              all_error_msg.push("Please Create Account Profile First");
              onShowAlert("error", all_error_msg);
            }else{
              navigate(tab_url, {state: {id: location.state.id}})
            }
          }

          const CreateBranchInfo = (event) => {
            event.preventDefault();
            
            let flg = true;
            const all_error_msg = [];

             if(status === ''){
                all_error_msg.push("Please select Branch Status");
                if(flg == true){flg = false;}
              }
        
            if(name.search(/\S/) == -1){
                all_error_msg.push("Please enter Branch Name");
                if(flg == true){flg = false;}
            }

            if(pin_code.search(/\S/) == -1){
                all_error_msg.push("Please enter Pin Code");
                if(flg == true){flg = false;}
            }else{
                if(pin_code.lenght < 6){
                    all_error_msg.push("Please enter 6 digit Pin Code");
                    if(flg == true){flg = false;}
                }
            }

            if(country_id === ''){
                all_error_msg.push("Please select Country");
                if(flg == true){flg = false;}
              }

              if(state_id === ''){
                all_error_msg.push("Please select State");
                if(flg == true){flg = false;}
              }

              if(city_id === ''){
                all_error_msg.push("Please select City");
                if(flg == true){flg = false;}
              }

              let user_arr = [];

              if(selected_account_user.length == 0){

                all_error_msg.push("Please select user");
                if(flg == true){flg = false;}

              }else{
                selected_account_user.map((item) => {
                    user_arr.push(item.user_id);
                })
              }

              if(selected_account_user_primary_id == null){
                all_error_msg.push("Please select at least one primary user");
                if(flg == true){flg = false;}
              }
        
            if (flg === true) {
                setLoading(true);
        
                if(branch_id == null){
        
                  let post_data = {
                    account_id: account_id,
                    name: name,
                    location: branch_location,
                    address_1: address_one,
                    address_2: address_two,
                    pin_code: pin_code,
                    country_id: country_id,
                    city_id: city_id,
                    state_id: state_id,
                    active: status,
                    user_ids: user_arr,
                    primary_user_id: selected_account_user_primary_id,

                  };
        
                     postWithToken(API_URL, 'account-branchs/create', post_data, userToken).then((result) => {
                        if(result.status === true){
                              setLoading(false);
                              all_error_msg.push(result.message);
                              onShowAlert("success", all_error_msg);

                                setName("");
                                setLocation("");
                                setAddressOne("");
                                setAddressTwo("");
                                setPinCode("");
                                setCountry("");
                                setCity("");
                                setState("");
                                setStatus("");
                                setSelectedAccoutUser([]);
                                setSelectedUserPrimary(null);

                              setPageSelected(null);

                              reloadList();
                             
                          }else{
                            setLoading(false);
                            all_error_msg.push(result.message);
                            onShowAlert("error", all_error_msg);
                          }
                      })
                      .catch((error) => {
                        console.log("Error : ", error);
                      });
                }else{
        
                  let post_data = {
                    id: branch_id,
                    account_id: account_id,
                    name: name,
                    location: branch_location,
                    address_1: address_one,
                    address_2: address_two,
                    pin_code: pin_code,
                    country_id: country_id,
                    city_id: city_id,
                    state_id: state_id,
                    active: status,
                    user_ids: user_arr,
                    primary_user_id: selected_account_user_primary_id,

                  };
        
                  
                  postWithToken(API_URL, 'account-branchs/update', post_data, userToken).then((result) => {
                    if(result.status === true){
                          setLoading(false);
                          all_error_msg.push(result.message);
                          onShowAlert("success", all_error_msg);

                          reloadList();
                         
                      }else{
                        setLoading(false);
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                      }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                }
              }else{
                onShowAlert("error", all_error_msg);
                
              } 
          }


          const [itemOffset, setItemOffset] = useState(0);
          function PaginatedItems({ itemsPerPage }) {
              // Here we use item offsets; we could also use page offsets
              // following the API or data you're working with.
             
              // Simulate fetching items from another resources.
              // (This could be items from props; or items loaded in a local state
              // from an API endpoint with useEffect and useState)
              const endOffset = itemOffset + itemsPerPage;
              //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
              //const currentItems = roleList.list.slice(itemOffset, endOffset);
              const pageCount = Math.ceil(branchList.total_count / itemsPerPage);
            
              // Invoke when user click to request another page.
              const handlePageClick = (event) => {
                const newOffset = (event.selected * itemsPerPage) % branchList.total_count;
                /*console.log(
                  `User requested page number ${event.selected}, which is offset ${newOffset}`
                );*/
  
                setPageSelected(event.selected);
                BranchListFetch(newOffset, list_limit, account_id);
                setItemOffset(newOffset);
              };
            
              return (
                <>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=" < "
                    renderOnZeroPageCount={null}
                    forcePage={page_selected}
                  />
                </>
              );
            }


     const EditBranchDetails = (id) => {
            setLoading(true);
            const all_error_msg = [];

            postWithToken(API_URL, 'account-branchs/view', {id: id, account_id: account_id}, userToken).then((result) => {
                  console.log(result);
                  setLoading(false);
                  if(result.status === true){

                    setBranchId(id);
                    setName(result.data.name);
                    setLocation(result.data.location);
                    setAddressOne(result.data.address_1);
                    setAddressTwo(result.data.address_2);
                    setPinCode(result.data.pin_code);

                    if(result.data.country_id != null && result.data.country_id != ''){
                        setCountry(result.data.country_id);
                        fetchState(result.data.country_id);
                    }

                    if(result.data.state_id != null && result.data.state_id != ''){
                        setState(result.data.state_id);
                        fetchCity(result.data.state_id);
                    }

                    setCity(result.data.city_id);
                    setStatus(result.data.active);


                    if(result.users.length > 0){
                        result.users.map((item) => {
                            if(item.is_primary_account == 1){
                                setSelectedUserPrimary(item.user_id);
                            }
                        });

                        setSelectedAccoutUser(result.users);
                    }
                    
                    addBranchs('edit');
                       
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
     }  

     const ViewBranchInfo = (item) => {
        setBranchInfoData(item);
        setModelBranchInfoOpen(true);
     }
     
    function CloseBranchInfoModel(){
        setModelBranchInfoOpen(false);
      }

     const CheckAll = (id,checkbox_class) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            $("." + checkbox_class).prop("checked", true);
        }else{
            $("." + checkbox_class).prop("checked", false);
        }
    }

    const onSelect = (selectedList, selectedItem) => {
        setSelectedAccoutUser(selectedList);
        console.log(selected_account_user);
    }
    
    const onRemove = (selectedList, removedItem) => {
        setSelectedAccoutUser(selectedList);
        if(selectedList.length == 0){
            setSelectedUserPrimary(null);
        }
    }


     return(
        <>
            <CustomAlert
                header={"Branch Info"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }

               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete Branch Details"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this branch
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    No
                              </Button>
                              <Button onClick={() => {deleteBranch(deleteIds)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>

        <div className="accountProfileTabs">
            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer Branch</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                    <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                </div>
         <div className="accountbankdetails">
            {  
            
                addView === false ?
                    <div className="tableList">
                        <div className="actionTable">
                            <div style={{width: 250}}></div>
                            <div className="tablebtngroup">
                                <button className="fillbtn" onClick={() => addBranchs('add')}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Branch</button>
                                <button className="fillbtn" onClick={() => handleClickOpen('','M')} ><i className="fa fa-trash" aria-hidden="true"></i></button>
                                <Link className="fillbtn">Bulk Action</Link>
                            </div>
                        </div>
                        <div className="tableResponsive">
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            contactListhead.map((item, index)=>{
                                            return <th key={index}>{item}</th>
                                            })
                                        }
                                    </tr>
 
                                </thead>
                                {
                                    branchList.IsLoading === false && branchList.list.length > 0 ?
                                        <tbody>
                                            {
                                                branchList.list.map((item, index) => {
                                                    return(
                                                        <tr key={index}>
                                                        <td> 
                                                                <div className="checkbox">
                                                                    <input type="checkbox" class="styled-checkbox branch_checkbox" id={'checkbox-branch-' + item.id} value={item.id}  />
                                                                    <label for={'checkbox-branch-' + item.id}></label>
                                                                </div>
                                                        </td>
                                                        <td >{item.name}</td>
                                                        <td >{ item.location}</td>
                                                        <td >{item.primary_contact_name}</td>
                                                        <td >{item.primary_contact_phone != null ? item.primary_contact_phone.replace(/.(?=.{4})/g, 'x') : null}</td>
                                                        <td >
                                                            <button onClick={() => EditBranchDetails(item.id)} ><img src={edit}/></button>
                                                            <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id,'S')}><img src={bin}/></button>
                                                            <button style={{ marginLeft: 6}} onClick={() => {ViewBranchInfo(item)}}><img src={view}/></button> 
                                                        </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                            
                                            </tbody>
                                            : branchList.IsLoading === false && branchList.list.length == 0 ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                                    </tr>
                                                </tbody>

                                            : branchList.IsLoading === true ?
                                            <tbody>
                                                <tr>
                                                    <td ><Skeleton height={24} /></td>
                                                    <td ><Skeleton height={24} /></td>
                                                    <td ><Skeleton height={24} /></td>
                                                    <td ><Skeleton height={24} /></td>
                                                    <td ><Skeleton height={24} /></td>
                                                    <td ><Skeleton height={24} /></td>
                                                </tr>
                                            </tbody>

                                        : null }

                            </table>
                        </div>
                        <div className="tablenav">
                            <div className="btnGroup">
                                <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Previous</button>
                                <button type="button" className="fillbtn" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Next</button>
                            </div>
                        </div>
                    </div>
                : null 
            }
           
            {
                addView === true ?  
                    <div className="addBranch">
                        <form onSubmit={CreateBranchInfo}>
                            <div className="row">
                                <div className="col-4">
                                    <div class="form-group"><label>Status<sub>*</sub></label>
                                        <select value={status} onChange={(e) => {setStatus(e.target.value)}}>
                                            <option value="">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Branch Name<sub>*</sub></label>
                                        <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Location/Description</label>
                                        <input type="text" value={branch_location} onChange={(event) => setLocation(event.target.value)}/>
                                    </div>
                                </div>
                               
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Address 1</label>
                                        <input type="text" value={address_one} onChange={(event) => setAddressOne(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Address 2</label>
                                        <input type="text" value={address_two} onChange={(event) => setAddressTwo(event.target.value)}/>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Pincode<sub>*</sub></label>
                                        <input type="text" maxLength={6} value={pin_code} onChange={(event) => setPinCode(event.target.value)} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>Country<sub>*</sub></label>
                                        <select value={country_id} onChange={(e) => {setCountry(e.target.value); fetchState(e.target.value)}}>
                                            <option value="">Select Country</option>
                                            {
                                                countryList.length > 0 ?
                                                countryList.map((item, key) => {
                                                    return(
                                                        <option key={'country_' + key} value={item.id}>{item.country_name}</option>
                                                    );
                                                })
                                                : null
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>State<sub>*</sub></label>
                                        <select value={state_id} onChange={(e) => {setState(e.target.value); fetchCity(e.target.value)}}>
                                            <option value="">Select State</option>
                                            {
                                                stateList.length > 0 ?
                                                stateList.map((item, key) => {
                                                    return(
                                                        <option key={'state_' + key} value={item.id}>{item.name}</option>
                                                    );
                                                })

                                                : null
                                            }
                                        </select>
                                    </div>
                                </div>
                               
                                <div className="col-4">
                                    <div class="form-group">
                                        <label>City<sub>*</sub></label>
                                        <select value={city_id} onChange={(e) => {setCity(e.target.value)}}>
                                            <option value="">Select City</option>
                                            {
                                                cityList.length > 0 ?
                                                cityList.map((item, key) => {
                                                    return(
                                                        <option key={'city_' + key} value={item.id}>{item.name}</option>
                                                    );
                                                })
                                                : null
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className="col-12">
                                   <div className='form-group-multiselect'>
                                        <label>Add User<sub>*</sub></label>
                                        <Multiselect
                                            options={account_user.options} // Options to display in the dropdown
                                            selectedValues={selected_account_user} // Preselected value to persist in dropdown
                                            displayValue="name" // Property name to display in the dropdown options
                                            onSelect={onSelect} // Function will trigger on select event
                                            onRemove={onRemove} // Function will trigger on remove event   
                                        />
                                    </div>
                               </div>
                                
                            </div>

                            {
                                selected_account_user.length > 0 ?

                                     <div className="tableResponsive" style={{width: '60%'}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                <th style={{width: 400}} key={'th_name'}>User Name</th>
                                                <th key={'th_primary'}>Primary</th>
                                                </tr>
            
                                            </thead>
                                            <tbody>
                                                {
                                                    selected_account_user.map((item, index)=>{
                                                        return (
                                                        <tr key={'user_' + index}>
                                                        <td> {item.name}</td>
                                                        <td>                              
                                                            <input style={{width: 'auto'}} type="radio" id={'selected_user_' + index} name="radio-group"  checked={selected_account_user_primary_id == item.user_id ? true : false} onChange={() => {setSelectedUserPrimary(item.user_id);}}/>
                                                        </td>
                                                        </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                :null
                            }        

                            <div className="col-4">
                                    <div className="btnGroup">
                                        <button type="button" className="fillbtn" onClick={() => {Cancel()}}>Cancel</button>
                                        {
                                            branch_id == null ?
                                                <button type="submit" className="fillbtn" >Add Branch</button>
                                            :
                                                <button type="submit" className="fillbtn" >Update Branch</button>
                                        }
                                    </div>
                            </div>

                        </form>
                    </div>
                    : null
                    }
                </div>
            </div>
         </div>
         </>
      
     )
}
export default AccountBranch;