import { Link } from "react-router-dom";
import pdf from '../../assets/images/pdf.svg';
import xls from '../../assets/images/xls.svg';
import searchicon from '../../assets/images/searchicon.svg';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
const StatusReport =()=>{
    const checkbox = ()=>{
        return <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
    const contactListhead =[checkbox(),"Case ID", "Product Name", "Leatest Status", "Document Status", "Bill Status","Payment Status"];
    
      return(
        <div className="caseAccount">
             <div className="pageTop">
                  <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Status Report</h2>
                  <div className="right-action">
                        <form>
                                <input type="text" placeholder="Account"/>
                                <input type="text" placeholder="Job"/>
                                <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                        </form>
                        <Link className="exlBtn"><img src={xls}/></Link>
                        <Link className="pdfBtn"><img src={pdf}/></Link>
                  </div>
            </div>
            <div className="tableResponsive">
       <table>
        <thead>
              <tr>
              {
              contactListhead.map((item, index)=>{
                    return <th key={index}>{item}</th>
              })
              }
              </tr>
              
        </thead>
        <tbody>
            <tr>
                <td colSpan={7}>
                <Accordion>
                        
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       <h2>Group Name</h2>
                
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <table>
                <tbody>
                  <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    </tbody>
                </table>
                </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       <h2>Group Name</h2>
                
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <table>
                <tbody>
                  <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    </tbody>
                </table>
                </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       <h2>Group Name</h2>
                
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <table>
                <tbody>
                  <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    <tr>
                      <td>12/14/2023</td>
                      <td>455GHFG</td>
                      <td>Jhon Deo</td>
                      <td>#54HBGHJJ</td>
                      <td>HGHH54141</td>
                      <td>Designer</td>
                    </tr>
                    </tbody>
                </table>
                </AccordionItemPanel>
                </AccordionItem>
                </Accordion>
               
                </td>
            </tr>
         
        </tbody>
       </table>
     </div>
     <div className="tablenav">
      <p>See all 12 result </p>
  </div>
        </div>
      )
}
export default StatusReport;