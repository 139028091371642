const Notification = () =>{
    return(
        <div className="notificationSetting">
                <div className="pageTop">
                  <h2><i class="fa fa-bell-o" aria-hidden="true"></i> Notification Settings</h2>
           
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label>Email Notification</label>
                        <select>
                        <option>Select Interval Time</option>
                        </select>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>SMS Notification</label>
                        <select>
                        <option>Select Interval Time</option>
                        </select>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Whats App Notification</label>
                        <select>
                            <option>Select Interval Time</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Notification;