function Filter(){
    return(
        <div className="filter">
            <form>
                <div className="form-group">
                    <label>Service<sub>*</sub></label>
                    <input type="text" placeholder=""/>
                </div>
                <div className="form-group">
                <label>Job<sub>*</sub></label>
                    <input type="text" placeholder=""/>
                </div>
                <div className="form-group">
                <label>Period<sub>*</sub></label>
                    <input type="date" placeholder=""/>
                </div>
                <button type="button">GO</button>
            </form>
        </div>
    )
}

export default Filter;