import profile_icon from "../../../../assets/images/profile.png";
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken, postWithOutTokenWithoutData, postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import OtpInput from 'react-otp-input';
/*import { Select, MenuItem} from '@mui/material';*/

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const AccountUserAdd =()=>{
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [user_id, setUserId] = useState(null);
    const [account_id, setAccountId] = useState("");

    const [NEU_id, setNEUId] = useState("");
    const [designation_user, setDesignationUser] = useState("");
    const [department, setDepartment] = useState("");
    const [sms_perimission, setSMS] = useState("");
    const [whatsapp_perimission, setWhatsapp] = useState("");
    const [email_perimission, setEmailUser] = useState("");
    const [is_primary_user, setPrimaryUser] = useState(false);

    const [document_perimissions, setDocumentPermission] = useState([]);
    const [invoice_perimissions, setInvoicePermission] = useState([]);
    const [case_perimissions, setCasePermission] = useState([]);
    const [user_perimissions, setUserPermission] = useState([]);

    const [document_perimissions_list, setDocumentPermissionList] = useState({options: []});
    const [invoice_perimissions_list, setInvoicePermissionList] = useState({options: []});
    const [case_perimissions_list, setCasePermissionList] = useState({options: []});
    const [user_perimissions_list, setUserPermissionList] = useState({options: []});

    const [permission_list, setPermissionList] = useState({IsLoading : true, list: []});
    const [NEU_list, setNEUList] = useState([]);

    /*add Client User*/
    const [modalEmailOpen, setModelEmailOpen] = useState(false);
    const [modalMobileOpen, setModelMobileOpen] = useState(false);
    const [mobile_otp, setMobileOtp] = useState('');
    const [email_otp, setEmailOtp] = useState('');

    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [email_verified, setEmailVerified] = useState(0);
    const [phone, setPhone] = useState("");
    const [phone_verified, setPhoneVerified] = useState(0);
    const [designation, setDesignation] = useState("");
    const [address_1, setAddressOne] = useState("");
    const [address_2, setAddressTwo] = useState("");
    const [pin_code, setPinCode] = useState("");
    const [country_id, setCountry] = useState("");
    const [state_id, setState] = useState("");
    const [city_id, setCity] = useState("");
    const [role_id, setRole] = useState("");
    const [profile_image, setProfileImage] = useState("");
    const [profile_image_pre, setProfileImagePre] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    const [modalIsOpen, setIsOpen] = useState(false);



    /*end Client User*/

    useEffect(() => {
        if(userToken != ''){
            if(location.state.account_id != null){
                setAccountId(location.state.account_id);
                if(location.state.id != null){
                    setUserId(location.state.id);
                    EditUserDetails(location.state.account_id, location.state.id);
                }
                PermissionListFetch();
                NEUUserListFetch(location.state.account_id);
            }
        }
    }, [userToken]);


    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

  const ChangeTab = (tab_url) => {
    const all_error_msg = [];
    if(location.state.account_id == null){
      all_error_msg.push("Please Create Account Profile First");
      onShowAlert("error", all_error_msg);
    }else{
      navigate(tab_url, {state: {id: location.state.account_id}})
    }
  }

  const NEUUserListFetch = (account_id) => {
    postWithToken(API_URL, 'account-users/non-executive-list', {account_id: account_id}, userToken).then((result) => {
        console.log(result);
        if(result.status === true){
            setNEUList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
  }


  const PermissionListFetch = () => {
    postWithToken(API_URL, 'account-users/permission-list', {}, userToken).then((result) => {
        console.log(result);
        if(result.status === true){
            setDocumentPermissionList({options: result.data.document_permission});
            setInvoicePermissionList({options: result.data.invoice_permission});
            setCasePermissionList({options: result.data.case_permission});
            setUserPermissionList({options: result.data.user_permission});
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
  }



const onSelect_document = (selectedList, selectedItem) => {
    setDocumentPermission(selectedList);

    console.log(selectedList);
}

const onRemove_document = (selectedList, removedItem) => {
    setDocumentPermission(selectedList);
}

const onSelect_invoice = (selectedList, selectedItem) => {
    setInvoicePermission(selectedList);
}

const onRemove_invoice = (selectedList, removedItem) => {
    setInvoicePermission(selectedList);
}

const onSelect_case = (selectedList, selectedItem) => {
    setCasePermission(selectedList);
}

const onRemove_case = (selectedList, removedItem) => {
    setCasePermission(selectedList);
}

const onSelect_user = (selectedList, selectedItem) => {
    setUserPermission(selectedList);
}

const onRemove_user = (selectedList, removedItem) => {
    setUserPermission(selectedList);
}

const CreateUserInfo = () => {

    
    let flg = true;
    const all_error_msg = [];

      if(NEU_id == ''){
        all_error_msg.push("Please select user");
        if(flg == true){flg = false;}
      }

      if(sms_perimission == ''){
        all_error_msg.push("Please select SMS Notification");
        if(flg == true){flg = false;}
      }

      if(whatsapp_perimission == ''){
        all_error_msg.push("Please select Whatsapp Notification");
        if(flg == true){flg = false;}
      }

      if(email_perimission == ''){
        all_error_msg.push("Please select Email Notification");
        if(flg == true){flg = false;}
      }

      if(document_perimissions.length == 0){
        all_error_msg.push("Please select Document Permission");
        if(flg == true){flg = false;}
      }

      if(invoice_perimissions.length == 0){
        all_error_msg.push("Please select Invoice Permission");
        if(flg == true){flg = false;}
      }

      if(case_perimissions.length == 0){
        all_error_msg.push("Please select Case Permission");
        if(flg == true){flg = false;}
      }

      if(user_perimissions.length == 0){
        all_error_msg.push("Please select User Permission");
        if(flg == true){flg = false;}
      }



    if (flg === true) {
      setLoading(true);

        if(user_id == null){

          let post_data = {
            account_id: account_id,
            user_id: NEU_id,
            designation: designation_user,
            department: department,
            sms_perimission: sms_perimission,
            whatsapp_perimission: whatsapp_perimission,
            email_perimission: email_perimission,
            document_perimissions: document_perimissions,
            invoice_perimissions: invoice_perimissions,
            case_perimissions: case_perimissions,
            user_perimissions: user_perimissions,
            is_primary_account: is_primary_user

          };

             postWithToken(API_URL, 'account-users/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      all_error_msg.push(result.message);
                      onShowAlert("success", all_error_msg);

                      setNEUId("");
                      setDesignationUser("");
                      setDepartment("");
                      setSMS("");
                      setWhatsapp("");
                      setEmailUser("");
                      setDocumentPermission([]);
                      setInvoicePermission([]);
                      setCasePermission([]);
                      setUserPermission([]);

                      ChangeTab('/dashboard/account/accountuser');
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
        }else{

          let post_data = {
            id: user_id,
            account_id: account_id,
            user_id: NEU_id,
            designation: designation_user,
            department: department,
            sms_perimission: sms_perimission,
            whatsapp_perimission: whatsapp_perimission,
            email_perimission: email_perimission,
            document_perimissions: document_perimissions,
            invoice_perimissions: invoice_perimissions,
            case_perimissions: case_perimissions,
            user_perimissions: user_perimissions,
            is_primary_account: is_primary_user
          };

          
          postWithToken(API_URL, 'account-users/update', post_data, userToken).then((result) => {
            if(result.status === true){
                  setLoading(false);
                  all_error_msg.push(result.message);
                  onShowAlert("success", all_error_msg);

                  ChangeTab('/dashboard/account/accountuser');
                 
              }else{
                setLoading(false);
                all_error_msg.push(result.message);
                onShowAlert("error", all_error_msg);
              }
          })
          .catch((error) => {
            console.log("Error : ", error);
          });
        }
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  const CheckPrimaryUser = (id) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            setPrimaryUser(1);
        }else{
          setPrimaryUser(0);
        }
  }

  const EditUserDetails = (account_id, id) => {
    setLoading(true);
    const all_error_msg = [];

    postWithToken(API_URL, 'account-users/view', {id: id, account_id: account_id}, userToken).then((result) => {
          console.log(result);
          setLoading(false);
          if(result.status === true){

            setUserId(result.data.id);
            setNEUId(result.data.user_id);
            setDesignationUser(result.data.designation);
            setDepartment(result.data.department);
            setSMS(result.user_permission_list.user_sms_permission);
            setWhatsapp(result.user_permission_list.user_whatsapp_permission);
            setEmailUser(result.user_permission_list.user_email_permission);
            setPrimaryUser(result.data.is_primary_account);
        
            setDocumentPermission(result.user_permission_list.user_document_permission);
            setInvoicePermission(result.user_permission_list.user_invoice_permission);
            setCasePermission(result.user_permission_list.user_case_permission);
            setUserPermission(result.user_permission_list.user_user_permission);

            setTimeout(() => {
              if(result.data.is_primary_account == 1){
                console.log("dfsd");
                $(".primary-user").prop("checked", true);
              }
            }, 500);	

               
          }else{
                all_error_msg.push(result.message);
                onShowAlert("error", all_error_msg);
          }

    })
    .catch((error) => {
          console.log("Error : ", error);
    });
} 


/*add Client User*/

  function openModal() {
    CountryListFetch();
    RoleListFetch();
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

    function CloseMobileModel(){
        setModelMobileOpen(false);
    }

    function CloseEmailModel(){
        setModelEmailOpen(false);
    }

  const RoleListFetch = () => {
    postWithToken(API_URL, 'active-roles-list', {}, userToken).then((result) => {
        if(result.status === true){
            setRoleList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
}

const CountryListFetch = () => {
    postWithOutTokenWithoutData(API_URL, 'countries').then((result) => {
        if(result.status === true){
                setCountryList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
}

const fetchState = (country_id) => {
    postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
        if(result.status === true){
                setStateList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
}

const fetchCity = (state_id) => {
    postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
        if(result.status === true){
            setCityList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
}

function onSelectFile(event) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const reader = new window.FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        //upload_profile(reader.result);

       // console.log(reader.result);
        setProfileImage(event.target.files[0]);
        setProfileImagePre(reader.result);

        //console.log(event.target.files[0]);

      };
    }
  }

  const addUser = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if(category == ''){
        all_error_msg.push("Please select User Category");
        if(flg == true){flg = false;}
    }

    if(name.search(/\S/) == -1){
        all_error_msg.push("Please enter user name");
        if(flg == true){flg = false;}
    }

    if(phone.search(/\S/) == -1){
        all_error_msg.push("Please enter mobile number");
        if(flg == true){flg = false;} 
    }else{
        if(phone_verified === false){
            all_error_msg.push("Please verify your mobile number");
            if(flg == true){flg = false;}  
        }
    }

   if(email.search(/\S/) != -1){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }else if(email_verified === false){
          all_error_msg.push("Please verify your email");
            if(flg == true){flg = false;}
      }
    }   

    if(category == 1){
        if(designation.search(/\S/) == -1){
            all_error_msg.push("Please enter your designation");
            if(flg == true){flg = false;}  
        }
    
        if(role_id == ''){
            all_error_msg.push("Please select user role");
            if(flg == true){flg = false;}  
        }
    }

    if (flg === true) {
      setLoading(true);

      let post_data = {
        category: category,
        name: name,
        email: email,
        phone: phone,
        is_email_verified: email_verified,
        is_phone_verified: phone_verified,
        designation: designation,
        address_1: address_1,
        address_2: address_2,
        pin_code: pin_code,
        country_id: country_id,
        state_id: state_id,
        city_id: city_id,
        role_id: role_id,
        profile_image: profile_image,
      };

      postWithTokenFormData(API_URL, 'user/create', post_data, userToken).then((result) => {
          if(result.status === true){
                setLoading(false);

                setCategory("");
                setName("");
                setEmail("");
                setEmailVerified(0);
                setPhone("");
                setPhoneVerified(0);
                setDesignation("");
                setAddressOne("");
                setAddressTwo("");
                setPinCode("");
                setCountry("");
                setState("");
                setCity("");
                setRole("");
                setProfileImage("");
                setProfileImagePre("");
            
                setIsOpen(false);

                NEUUserListFetch(account_id);
                setNEUId(result.data.id);

            }else{
              setLoading(false);
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

   const GenrateMobileOtp = () => {

    let flg = true;
    const all_error_msg = [];

    if(phone.search(/\S/) == -1){
        all_error_msg.push("Please enter your mobile number");
        if(flg == true){flg = false;}
    }else{
        if(phone.length > 10 || phone.length < 10){
            all_error_msg.push("Please enter 10 digit mobile number");
            if(flg == true){flg = false;}
        }
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-phone-verify-otp-send', {phone: phone}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelMobileOpen(true);
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }

  }

  const VerifyMobileOtp = () =>{
    let flg = true;
    const all_error_msg = [];

    if(mobile_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your mobile number");
        if(flg == true){flg = false;}
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-phone-otp-verify', {phone: phone, otp: mobile_otp}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelMobileOpen(false);
                setPhoneVerified(1);
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }

  const GenrateEmailOtp = () => {
    let flg = true;
    const all_error_msg = [];

    //setModelEmailOpen(true);

    if(email.search(/\S/) == -1){
		all_error_msg.push("Please enter your email Id");
		if(flg == true){flg = false;}
	}else if(email.search(/\S/) != -1){
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(email) === false) {
			all_error_msg.push("Please enter valid email Id");
			if(flg == true){flg = false;}
		}
	}

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-email-verify-otp-send', {email: email}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelEmailOpen(true);
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }

  const VerifyEmailOtp = () =>{
    let flg = true;
    const all_error_msg = [];

    if(email_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your email");
        if(flg == true){flg = false;}
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-email-otp-verify', {email: email, otp: email_otp}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelEmailOpen(false);
                setEmailVerified(1);
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }




return(
    <>
    <CustomAlert
                header={"Account User"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }


    <div className="accountProfileTabs">
        <div className="pageTop">
            <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer User</h2> 
            <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountuser')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
        </div>
        <div className="customTab">
            <div className="account_button_tab">
            <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
            <button className="active"  onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
        </div>


        <div className="accountbankdetails">
             <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i>User  {
                                        user_id == null ? 'Add' : 'Edit'}</h2></div>

                    <div className='row'>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>User Name</label>
                                        {/*<select value={NEU_id}  onChange={(event) => setNEUId(event.target.value)}>
                                            <option value="">Select User</option>
                                           {
                                            NEU_list.length > 0 ?
                                              NEU_list.map((item, index) => {

                                                return(
                                                  <option key={'user_' + index} value={item.id}>{item.name}</option>
                                                )

                                              })
                                            : null
                                           }

                                        </select>*/}
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={NEU_id}
                                                        label="Select User"
                                                        onChange={(event) => setNEUId(event.target.value)}
                                                    >
                                                        <MenuItem key={'user'} value="">Select User</MenuItem>
                                                        {
                                                        NEU_list.length > 0 ?
                                                        NEU_list.map((item, index) => {

                                                            return(
                                                            <MenuItem key={'user_' + index} value={item.id}>{item.name}</MenuItem>
                                                            )

                                                        })
                                                        : null
                                                    }
                                                        <MenuItem style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><button type='button' className='fillbtn' onClick={() => openModal()}>Add User +</button></MenuItem>
                                                    </Select>
                                            </FormControl>
                                        </Box>

                                    </div>
                                </div>
                                {/*<div className='col-6'>
                                     <div className='form-group'>
                                        <label></label>
                                            <button type='button' className='fillbtn' style={{marginTop: 18}} onClick={() => openModal()} >Add</button>
                                     </div>       

                                </div>*/}

                                <div className='col-12' style={{marginBottom: 20}}>
                                    <div className='checkbox'>
                                        <input class="styled-checkbox primary-user" id="primary-user" type="checkbox"  onChange={() => CheckPrimaryUser('primary-user')}/>
                                        <label for="primary-user">Primary User</label>
                                    </div>
                                </div>


                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>Designation</label>
                                        <input type='text' placeholder='Enter Designation' value={designation_user} onChange={(event) => setDesignationUser(event.target.value)}/>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>Department</label>
                                        <input type='text' placeholder='Enter Department'  value={department} onChange={(event) => setDepartment(event.target.value)}/>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>SMS Notification <sub>*</sub></label>
                                        <select value={sms_perimission} onChange={(event) => setSMS(event.target.value)}>
                                            <option key={'sms_'} value="">Select </option>
                                            <option key={'sms_1'} value="1">Yes</option>
                                            <option key={'sms_0'} value="0">No</option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>Email Notification<sub>*</sub></label>
                                        <select value={email_perimission} onChange={(event) => setEmailUser(event.target.value)}>
                                            <option key={'email_'} value="">Select</option>
                                            <option key={'email_1'} value="1">Yes</option>
                                            <option key={'email_0'} value="0">No</option>

                                        </select>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label>WhatsApp Notification<sub>*</sub></label>
                                        <select value={whatsapp_perimission} onChange={(event) => setWhatsapp(event.target.value)}>
                                            <option key={'whatsapp_'} value="">Select</option>
                                            <option key={'whatsapp_1'} value="1">Yes</option>
                                            <option key={'whatsapp_0'} value="0">No</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='col-8'>
                                    <div className='form-group-multiselect'>
                                        <label>Documents Permission<sub>*</sub></label>
                                        <Multiselect
                                            options={document_perimissions_list.options} // Options to display in the dropdown
                                            selectedValues={document_perimissions} // Preselected value to persist in dropdown
                                            displayValue="name" // Property name to display in the dropdown options
                                            onSelect={onSelect_document} // Function will trigger on select event
                                            onRemove={onRemove_document} // Function will trigger on remove event   
                                        />
                                    </div>
                                </div>

                                <div className='col-8'>
                                        <div className='form-group-multiselect'>
                                            <label>Invoice Permission<sub>*</sub></label>
                                            <Multiselect
                                                options={invoice_perimissions_list.options} // Options to display in the dropdown
                                                selectedValues={invoice_perimissions} // Preselected value to persist in dropdown
                                                displayValue="name" // Property name to display in the dropdown options
                                                onSelect={onSelect_invoice} // Function will trigger on select event
                                                onRemove={onRemove_invoice} // Function will trigger on remove event   
    
                                            />
                                        </div>
                                </div>

                                <div className='col-8'>
                                        <div className='form-group-multiselect'>
                                            <label>Case Permission<sub>*</sub></label>
                                            <Multiselect
                                                options={case_perimissions_list.options} // Options to display in the dropdown
                                                selectedValues={case_perimissions} // Preselected value to persist in dropdown
                                                displayValue="name" // Property name to display in the dropdown options
                                                onSelect={onSelect_case} // Function will trigger on select event
                                                onRemove={onRemove_case} // Function will trigger on remove event   
    
                                            />
                                        </div>
                                </div>

                                <div className='col-8'>
                                        <div className='form-group-multiselect'>
                                            <label>User Permission<sub>*</sub></label>
                                            <Multiselect
                                                options={user_perimissions_list.options} // Options to display in the dropdown
                                                selectedValues={user_perimissions} // Preselected value to persist in dropdown
                                                displayValue="name" // Property name to display in the dropdown options
                                                onSelect={onSelect_user} // Function will trigger on select event
                                                onRemove={onRemove_user} // Function will trigger on remove event   
    
                                            />

                                        </div>
                                </div>
                                
                            </div>

                            <div className='col-6'>
                                <div className='form-group flxgrd'>
                                    <button type='button' className='fadebtn' onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>Cancel</button>
                                    {
                                        user_id == null ?
                                        <button type='button' className='fillbtn' onClick={CreateUserInfo} >Add</button>
                                        :
                                        <button type='button' className='fillbtn' onClick={CreateUserInfo} >Update</button>
                                    }
                                    
                                </div>
                            </div>
           

            {/* Add User */}
                <Modal className="addClientUserModel" isOpen={modalIsOpen} onRequestClose={closeModal}  contentLabel="User Add"  >
                    <div className='modal_header'>
                        <div>Add User </div>
                        <button onClick={closeModal} className='closebtn'><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <div className='modalBody' style={{height: '90vh'}}>
                    <form onSubmit={addUser}>
                        <div className="photoUpload">
                            <div className="uploadePic">
                                {
                                    profile_image_pre != '' ?

                                    <img src={profile_image_pre} />

                                    : <img src={profile_icon} />
                                }
                                
                            </div>
                            <div className="fileUpload">
                                <span>Upload Photo</span>
                                <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectFile(e)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Category<sub>*</sub></label>

                                        <select value={category} onChange={(e) => {setCategory(e.target.value)}}>
                                            <option value="">Select Category</option>
                                            <option key={'non_executive'}  value="2">Non-Executive</option>
                                        </select>
                                    
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Name<sub>*</sub></label>
                                    <input type="text" placeholder="Name" onChange={(event) => setName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Mobile Number<sub>*</sub></label>
                                    {
                                        phone_verified == 1 ?
                                    <input type="text" placeholder="Mobile Number" maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                return true;
        }else{event.preventDefault();}}} onChange={(event) => setPhone(event.target.value)} readOnly />
                                        :

                                        <input type="text" placeholder="Mobile Number" maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                            return true;
                                    }else{event.preventDefault();}}} onChange={(event) => setPhone(event.target.value)} />

                                    }

                                    {
                                        phone_verified == 1 ?
                                        <button type="button" className="verifiedfld" style={{width: 'auto'}}>Verified</button>
                                        : 
                                        <button type="button" className="verifyfld" style={{width: 'auto'}} onClick={() => {GenrateMobileOtp()}}>Verify</button>
                                    }
                                    
                                    
                                </div>
                            </div>
                            {
                                category == 1 ?
                                <>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Role<sub>*</sub></label>
                                            <select onChange={(e) => {setRole(e.target.value)}}>
                                                <option value="">Select Role</option>
                                                
                                                {roleList.length > 0 ?

                                                    roleList.map((item, key) => {

                                                        return(
                                                            <option key={'role_' + key} value={item.id}>{item.name}</option>
                                                        );

                                                    })

                                                    : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Designation<sub>*</sub></label>
                                            <input type="text" placeholder="Designation" onChange={(event) => setDesignation(event.target.value)}/>
                                        </div>
                                    </div>
                                </>
                            : null
                            }    
                            <div className="col-4">
                                <div className="form-group">
                                    <label>EMail ID</label>
                                    {
                                        email_verified == 1 ?
                                        <input type="text" placeholder="Email Id" onChange={(event) => setEmail(event.target.value)} readOnly/>
                                        :
                                        <input type="text" placeholder="Email Id" onChange={(event) => setEmail(event.target.value)}/>
                                    }
                                    {
                                        email_verified == 1 ?
                                        <button type="button" className="verifiedfld" style={{width: 'auto'}}>Verified</button>
                                        :
                                        <button type="button" className="verifyfld" style={{width: 'auto'}} onClick={() => GenrateEmailOtp()} >Verify</button>
                                    }
                                                                
                                </div>
                            </div>
                            
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Address1</label>
                                    <input type="text" placeholder="Address1" onChange={(event) => setAddressOne(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Address2</label>
                                    <input type="text" placeholder="Address2" onChange={(event) => setAddressTwo(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Pincode</label>
                                    <input type="text" placeholder="Pincode" onChange={(event) => setPinCode(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select onChange={(e) => {setCountry(e.target.value); fetchState(e.target.value)}}>
                                        <option value="">Select Country</option>
                                        {
                                            countryList.length > 0 ?

                                            countryList.map((item, key) => {

                                                return(
                                                    <option key={'country_' + key} value={item.id}>{item.country_name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>State</label>
                                    <select onChange={(e) => {setState(e.target.value); fetchCity(e.target.value)}}>
                                        <option value="">Select State</option>
                                        {
                                            stateList.length > 0 ?
                                            stateList.map((item, key) => {
                                                return(
                                                    <option key={'state_' + key} value={item.id}>{item.name}</option>
                                                );
                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                                
                            <div className="col-4">
                            <div className="form-group">
                                    <label>City</label>
                                    <select onChange={(e) => {setCity(e.target.value)}}>
                                        <option value="">Select City</option>
                                        {
                                            cityList.length > 0 ?

                                            cityList.map((item, key) => {

                                                return(
                                                    <option key={'city_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div className='col-12'>
                            <div className='form-group flxgrd'>
                                <button type='button' className='fadebtn' onClick={() => closeModal()}>Cancel</button>
                                <button type='submit' className='fillbtn' >Add</button>
                            </div>
                        </div>
                     </form>

                    </div>
                </Modal>




                <Modal isOpen={modalMobileOpen} onRequestClose={CloseMobileModel} contentLabel="Mobile OTP Modal" >
                <div className='modal_header'>
                Mobile Verification OTP <button onClick={CloseMobileModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody'>
                   
                        <div className='row'>
                            <div className='col-12 otp_input_parent_div' >
                                <OtpInput
                                    value={mobile_otp}
                                    onChange={setMobileOtp}
                                    numInputs={6}
                                    inputType={'tel'}
                                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                    renderInput={(props) => <input {...props} />}
                                    />
                            </div>
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseMobileModel}>Cancel</button>
                                    <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyMobileOtp} >Verify OTP</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>

            <Modal isOpen={modalEmailOpen} onRequestClose={CloseEmailModel} contentLabel="Email OTP Modal" >
                <div className='modal_header'>
                 Email Verification OTP <button onClick={CloseEmailModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody'>
                <form>
                    <div className='row'>
                        <div className='col-12 otp_input_parent_div'>
                            <OtpInput
                                value={email_otp}
                                onChange={setEmailOtp}
                                numInputs={6}
                                inputType={'tel'}
                                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                                />
                        </div>
                                           
                        <div className='col-12'>
                            <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseEmailModel}>Cancel</button>
                                <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyEmailOtp} >Verify OTP</button>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </Modal>


               
            </div>
        </div>
    </div>
    </>
)
}
export default AccountUserAdd;