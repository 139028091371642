
function Task(){
const taskHeading=[
"Account", "Data Entry", "GSTR1 Filing", "Payments", "GSTR 3B Filing" 
]
const task=[
"Euro Scientific", "Rix N Rups", "Blossom", "Rix N Rups", "Softech" ,"Sayona"
]
return(
<div className="task-table">
<div className="tableResponsive">
<table>
<thead>
<tr>
{
taskHeading.map((item,index)=>{
return <th key={index}>{item}</th>
}) 
}
</tr>
</thead>
<tbody>
{
task.map((item, index)=>{
return <tr key={index}>
<td>{item}</td>
<td>
<select></select>
</td>
<td>
<select></select>
</td>
<td>
<select></select>
</td>
<td>
<select></select>
</td>
</tr>
})
}
</tbody>
</table>
</div>
<div className="tablenav">
<p>See all 12 result</p>
<button type="button" className="fillBtn">Update</button>
</div>
</div>
)
}
export default Task;