import { Component } from "react";
import  bag from "../../assets/images/bag.svg"
import  priceup from "../../assets/images/priceup.svg"
import  pricehand from "../../assets/images/pricehand.svg"
import  graph from "../../assets/images/graph.png"
import  explore from "../../assets/images/explore.png"
import Layout from "../layout/Layout";
export default class Dashboard extends Component{
      constructor(){
         super();
      }
      cast = [
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        },
        {
            caseid:"455HFHJM",
             name:"Jhon Deo",
             status:"Yes"
        }
      ]

    render(){
        return(
  <div className="dashboRd">
                 <div className="row">
                    <div className="col-4">
                     <div className="dashboard-item">
                        <div className="dashboardTop">
                            <div className="icon bck-red">
                               <img src={priceup}/>
                            </div>
                            <h2>Sales</h2>
                        </div>
                        <div className="dashboard-mdl">
                            <h3>52585875.00</h3> <div className="graph">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text</p>
                     </div>
                    </div>
                    <div className="col-4">
                     <div className="dashboard-item">
                        <div className="dashboardTop">
                            <div className="icon bck-grn">
                               <img src={pricehand}/>
                            </div>
                            <h2>Payment</h2>
                        </div>
                        <div className="dashboard-mdl">
                            <h3>78425212.00</h3> <div className="graph">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text</p>
                     </div>
                    </div>
                    <div className="col-4">
                     <div className="dashboard-item">
                        <div className="dashboardTop">
                            <div className="icon bck-ylw">
                               <img src={bag}/>
                            </div>
                            <h2>Cases</h2>
                        </div>
                        <div className="dashboard-mdl ">
                            <h3>45241.00</h3> <div className="graph">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <p>Lorem Ipsum is simply dummy text</p>
                     </div>
                    </div>
                 </div>
                 <div className="row">
                    <div className="col-8">
                      <div className="dashboard-card">
                         <h3>Payment Performance</h3>
                         <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                         <img src={graph}/>
                      </div>
                      <div className="tableResponsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Account Name</th>
                                    <th>Latest Status</th>
                                </tr>
                            </thead>
                            <tbody>
                               {
                                this.cast.map((item, index)=>{
                                    return <tr key={index}>
                                           <td>{item.caseid}</td>
                                           <td>{item.name}</td>
                                           <td>{item.status}</td>
                                    </tr>
                                })
                               } 
                            </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-4">
                    <div className="dashboard-card">
                    <h3>Quick Action</h3>
                    <div className="toggleItem">
                        <label  className="forlbl">Email Notification</label>
                        <div className="tglBtn">
                          <input type="checkbox" id="switch" /><label for="switch">Toggle</label>
                        </div>
                    </div>
                    <div className="toggleItem">
                        <label className="forlbl">SMS Notification</label>
                        <div className="tglBtn">
                          <input type="checkbox" id="switch1" /><label for="switch1">Toggle</label>
                        </div>
                    </div>
                    <div className="toggleItem">
                        <label  className="forlbl">WhatsApp Notification</label>
                        <div className="tglBtn">
                          <input type="checkbox" id="switch2" /><label for="switch2">Toggle</label>
                        </div>
                    </div>
                    <div className="exploresec">
                        <img src={explore} />
                    </div>
                     </div>
                    </div>
                 </div>
            </div>
        )
    }
}