
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from "react";
import bin from '../../assets/images/delete.svg';
import { Link } from 'react-router-dom';
const OpenDocumentsGroup = () =>{

     return(
        <div className="viewInvoice linkdetails">
        <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Document</h2>
    
        </div>
        <div className="tavBox">
            <Tabs>
            <div className="tableft">
                <h3>Document Group Name </h3>
            <TabList>
           <Tab>
             <h4>Design Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            </TabList>
            </div>
            <div className="tabRight">
            <TabPanel> 
                <div className="tabdetails">
                <div className='row'>
                    <div className='col-4'>
                        <label>Document Type</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Service Name</label>
                        <p>Designer</p>
                    </div>
                    <div className='col-4'>
                        <label>Jon Name</label>
                        <p>Here is your document type </p>
                    </div>
                   <div className='col-12'>
                    <div className='openGroup'>
                        <div className='form-group'>
                            <select>
                                <option>Select Type</option>
                            </select>
                            <button type='button' className='fillbtn'>Add</button>
                        </div>
                        <div className='tableResponsive'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Document Type Name</th>
                                        <th>Access to</th>
                                        <th>Date & Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        Document Name
                                        </td>
                                        <td>Jhon Due</td>
                                        <td>12:50AM, 12/22/2023</td>
                                        <td><Link><img src={bin}/></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                   </div>
                  </div>
                </div>
            </TabPanel>
            <TabPanel> 
               <div className="tabdetails">
                  <div className='row'>
                    <div className='col-4'>
                        <label>Document Type</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Service Name</label>
                        <p>Designer</p>
                    </div>
                    <div className='col-4'>
                        <label>Jon Name</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Access</label>
                        <p>Jhon Duo</p>
                    </div>
                  </div>
                </div>
            </TabPanel>
            </div>
            </Tabs>
        </div>

    </div>
     )
}
export default OpenDocumentsGroup;