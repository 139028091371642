import sms from '../../assets/images/sms.png';
const SmsNotification = () =>{
    return(
        <div className="notificationseting">
            <div className="pageTop">
                  <h2><i class="fa fa-comments-o" aria-hidden="true"></i>Sms Notification</h2>
                  </div>
                  <div className="notificationBox">
                     <div className="row">
                        <div className="col-6">
                    <form>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Account</label>
                                    <select>
                                        <option></option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Job</label>
                                    <select>
                                        <option></option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Case</label>
                                    <select>
                                        <option></option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Send notification for</label>
                                    <select>
                                        <option></option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Send SMS to:</label>
                                    <div className="chk">
                                    <div className="checkbox">
                                    <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
                                    <label for="styled-checkbox-1">Ashirbad Consultancy | <small>8420073410</small> </label>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <p><b>Note:</b>
The %InvoiceURL% shown in the SMS Preview will be generated 
automatically when you send the invoice. It will be a public URL and 
anyone who clicks on the URL will be able to view, print, or download it.
</p>
<p>Approximately 2 SMS units will be used to send this SMS notification.
 If the message contains any special characters, more SMS units may 
be used.</p>
                                </div>
                                <div className="form-group">
                                    <div className="btnsec">
                                        <button type="button" className="fillbtn"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                                        <button type="button" className="fadebtn">Cancel</button>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </form>
                        </div>
                        <div className="col-6">
                             <div className='notificationImg'>
                                <img src={sms}/>
                             </div>
                            </div>
                     </div>
                  </div>
        </div>
    )
}

export default SmsNotification;