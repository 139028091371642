const DocumentHub = () =>{
    return(
        <div className="documentHub">
    <div className="pageTop">
    <h2><i className="fa fa-file-text-o" aria-hidden="true"></i>Document Hub</h2>
    <div className="right-action">
        <button type="button" className="fillbtn"><i className="fa fa-folder-o" aria-hidden="true"></i> New Folder</button>
    </div>
    </div>
    <div className="documentHubfolder">
        <div className="row">
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
            <div className="col-4">
                <div className="documentItem">
                <i className="fa fa-folder-open" aria-hidden="true"></i>
                    <label>Account   item</label>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}
export default DocumentHub;