import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState, CSSProperties} from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const AddService = () =>{

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [full_name, setFullName] = useState("");
    const [short_name, setShortName] = useState("");
    const [service_code, setServiceCode] = useState("");
    const [applicable_to_business, setAppToBus] = useState(1);
    const [status, setStatus] = useState(1);
    const [service_id, setServiceId] = useState(null);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                DataFetch();
                setServiceId(location.state.id);
            }
        }
    }, [userToken]);

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'services/view', {id: location.state.id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setFullName(result.data.name);
                setShortName(result.data.short_name);
                setServiceCode(result.data.service_code);
                setAppToBus(result.data.applicable_to_business);
                setStatus(result.data.active);
             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const addService = (event) => {
        event.preventDefault();
    
        let flg = true;
        const all_error_msg = [];
    
        if(full_name.search(/\S/) == -1){
            all_error_msg.push("Please enter service full name");
            if(flg == true){flg = false;}
        }

        if(short_name.search(/\S/) == -1){
            all_error_msg.push("Please enter service short name");
            if(flg == true){flg = false;}
        }

        if(service_code.search(/\S/) == -1){
            all_error_msg.push("Please enter service code");
            if(flg == true){flg = false;}
        }else{
            if(service_code.length < 3){
                all_error_msg.push("Please enter 3 alphabets service code");
                if(flg == true){flg = false;}
            }
        }
    
       
    
        if (flg === true) {
          setLoading(true);
    

          if(service_id == null){
            let post_data = {
                name: full_name,
                short_name: short_name,
                service_code: service_code,
                applicable_to_business: applicable_to_business,
                active: status
              };
            postWithToken(API_URL, 'services/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/servicelist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
           
          }else{
            let post_data = {
                id: service_id,
                name: full_name,
                short_name: short_name,
                service_code: service_code,
                applicable_to_business: applicable_to_business,
                active: status
              };
            postWithToken(API_URL, 'services/update', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/servicelist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
          }
        }else{
            onShowAlert("error", all_error_msg);
        } 
    
    }
     
    return(
        <>

        <CustomAlert
        header={"Add Service"}
        btnText={"Accept"}
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
        />  
        {
          loading === true ?
          <div className="activityLoader">
                <GridLoader
                  color={color}
                  loading={loading}
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  Loader="GridLoader"
                /> 
         </div>     
         : null
        }


        <div className="serviceList">
            <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>{service_id == null ? "Add" : "Edit"} Services</h2>
            </div>
            <div className="addServiceForm">
                <form onSubmit={addService}>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Full Name<sub>*</sub></label>
                                <input type="text" placeholder="" value={full_name} onChange={(event) => setFullName(event.target.value)}/>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Short Name<sub>*</sub></label>
                                <input type="text" placeholder="" value={short_name} onChange={(event) => setShortName(event.target.value)}/>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Code<sub>* CAP Letter 3 Code for Service </sub></label>
                                <input type="text" placeholder="" maxLength={3} value={service_code} onChange={(event) => { const re = /^[A-Za-z]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
        setServiceCode(event.target.value.toUpperCase());
    }}}/>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Status</label>
                                <div className="checkBoxGroup">
                                    <div className="checkBoxGroupItem">
                                        <input type="radio" id="test1" name="radio-group" value="1" checked={status == 1 ? true : false} onChange={() => {setStatus(1)}}/>
                                        <label for="test1">Active</label>
                                    </div>
                                    <div className="checkBoxGroupItem">
                                        <input type="radio" id="test2" name="radio-group" value="0" checked={status == 0 ? true : false} onChange={() => {setStatus(0)}}/>
                                        <label for="test2">Incctive</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Applicable to Business</label>
                                <div className="checkBoxGroup">
                                    <div className="checkBoxGroupItem">
                                        <input type="radio" id="test3" name="radio-group-bus" value="1" checked={applicable_to_business == 1 ? true : false} onChange={() => {setAppToBus(1)}}/>
                                        <label for="test3">Yes</label>
                                    </div>
                                    <div className="checkBoxGroupItem">
                                        <input type="radio" id="test4" name="radio-group-bus" value="0" checked={applicable_to_business == 0 ? true : false} onChange={() => {setAppToBus(0)}}/>
                                        <label for="test4">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-12">
                            <div className="form-group">
                            
                            <div className="btnsec">
                                <button type="button" className="fadebtn" onClick={() => {navigate("/dashboard/servicelist")}}>Cancel</button>
                                <button type="submit" className="fillbtn">Save</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
              
        </div>

        </>
    )
}
export default AddService;