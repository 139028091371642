import gallery from "../../assets/images/gallery.svg"
import Upload from "../../assets/images/upload.svg"
const NewCost = () =>{
    return(
        <div className="addJob">
        <div className="pageTop">
             <h2><i class="fa fa-archive" aria-hidden="true"></i>New Cost/ Expenses</h2>
             </div>
             <form>
               <div className="productsec">
        
                   <div className="row">
                       <div className="col-4">
                           <div className="form-group">
                               <label>Date</label>
                               <input type="date" />
                           </div>
                       </div>
                       <div className="col-4">
                           <div className="form-group">
                               <label>Account Name</label>
                               <select>
                                <option></option>
                               </select>
                           </div>
                       </div>
                       <div className="col-4">
                           <div className="form-group">
                               <label>Job Name</label>
                               <select>
                                <option></option>
                               </select>
                           </div>
                       </div>
                       <div className="col-4">
                           <div className="form-group">
                               <label>Case ID</label>
                               <select>
                                   <option></option>
                                </select>
                           </div>
                       </div>
                       <div className="col-4">
                           <div className="form-group">
                               <label>Cost Name</label>
                                <select>
                                   <option></option>
                                </select>
                           </div>
                       </div>
                       <div className="col-4">
                           <div className="form-group">
                               <label>Amount</label>
                               <input type="text" />
                           </div>
                       </div>
                    
                       <div className="col-4">
                           <div className="form-group">
                               <div className="picUpload">

                                <span>
                                    <img src={gallery}/>
                                    <label>Drag or Drop your Receipts</label>
                                <small>Maximum file size allowed is 7MB</small>
                                 <small><img src={Upload}/>Upload File</small>
                                </span>
                               <input type="file" />
                               </div>
                              
                           </div>
                       </div>
                       <div className="col-12">
                           <div className="btnGroup">
                          
                               <button type="button" className="fillbtn">Save</button>
                               <button type="button" className="fadebtn">Cancel</button>
                           </div>
                       </div>
                   </div>
               </div>
        
        
     
             </form>
   </div>
    )
}
export default NewCost;