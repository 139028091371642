import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {useSelector } from 'react-redux';
 
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigate = useNavigate();
  const { API_URL, LANGUAGE, LANG_ID, userData} = useSelector(state => state.common);
 
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      API_URL={API_URL}
      LANGUAGE={LANGUAGE}
      LANG_ID={LANG_ID}
      userData={userData}

    />
  );
};
 
export default withRouter;