import { Link } from "react-router-dom";
import bin from '../../assets/images/delete.svg';
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const TelephonicsIntraction = () =>{
      const navigate = useNavigate();
      const addNewcon = () =>{
            navigate("/layout/adddocuments")
      }
 

    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const contactListhead =[checkbox(), "Date & Time", "Account", "Job", "Case Id", "Interaction Type","Interaction Details","Raised By", "Status", "Action"];
      const contactlistdata =[
            {
               checkbox:checkbox(),
               date:"12/14/2023",
               acc:"Jhon Deo",
               job:"Designer",
               id:"5425HFGHB",
               type:"Email",
               intd:"Email Details",
               role:"Admin",
               status:"Active",
            },
            {
                  checkbox:checkbox(),
                  date:"12/14/2023",
                  acc:"Jhon Deo",
                  job:"Designer",
                  id:"5425HFGHB",
                  type:"Email",
                  intd:"Email Details",
                  role:"Admin",
                  status:"Active",
               },
               {
                  checkbox:checkbox(),
                  date:"12/14/2023",
                  acc:"Jhon Deo",
                  job:"Designer",
                  id:"5425HFGHB",
                  type:"Email",
                  intd:"Email Details",
                  role:"Admin",
                  status:"Active",
               },
               {
                  checkbox:checkbox(),
                  date:"12/14/2023",
                  acc:"Jhon Deo",
                  job:"Designer",
                  id:"5425HFGHB",
                  type:"Email",
                  intd:"Email Details",
                  role:"Admin",
                  status:"Active",
                  },
                  {
                        checkbox:checkbox(),
                        date:"12/14/2023",
                        acc:"Jhon Deo",
                        job:"Designer",
                        id:"5425HFGHB",
                        type:"Email",
                        intd:"Email Details",
                        role:"Admin",
                        status:"Active",
                     },
                     {
                        checkbox:checkbox(),
                        date:"12/14/2023",
                  acc:"Jhon Deo",
                  job:"Designer",
                  id:"5425HFGHB",
                  type:"Email",
                  intd:"Email Details",
                  role:"Admin",
                  status:"Active",
                        },
                        {
                              checkbox:checkbox(),
                              date:"12/14/2023",
                  acc:"Jhon Deo",
                  job:"Designer",
                  id:"5425HFGHB",
                  type:"Email",
                  intd:"Email Details",
                  role:"Admin",
                  status:"Inactive",
                           },
                           {
                              checkbox:checkbox(),
                              date:"12/14/2023",
                              acc:"Jhon Deo",
                              job:"Designer",
                              id:"5425HFGHB",
                              type:"Email",
                              intd:"Email Details",
                              role:"Admin",
                              status:"Active",
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-volume-control-phone" aria-hidden="true"></i> Telephonic</h2>
                  <div className="right-action">
                  <form>
             
                      <input type="text" placeholder="Account"/>
                      <input type="text" placeholder="Job"/>
                      <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                      </form>
                        <Link className="fillbtn" onClick={addNewcon}>Add New Conversation</Link>
                        <Link className="fillbtn">Bulk action</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index} >
                                    <td> {item.checkbox}
                                    </td>
                                    <td> {item.date}</td>
                                    <td>{item.acc}</td>
                                    <td>{item.job}</td>
                                    <td>{item.id}</td>
                                    <td>{item.type}</td>
                                    <td>{item.intd}</td>
                                    <td>{item.role}</td>
                                    <td className={item.status == "Active"? "active": "inactive"}>{item.status}</td>
        
                                    <td> <Link><img src={bin}/></Link></td>
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default TelephonicsIntraction;