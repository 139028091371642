import email_icon from '../assets/images/email.svg';
import logo from '../assets/images/logo.svg';
import envolope from '../assets/images/envolop.svg';
import call from '../assets/images/call.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";
import { setUserDataWithToken } from "../Store/Reducers/CommonReducer";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const ForgotPassword = () =>{
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [email, setEmail] = useState("");

  //alert start

  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end  

  const resetForm = () => {
    setIsLoading(false);
    setEmail("");
  }

  const CheckEmail = (event) => {
    event.preventDefault();
    let flg = true;
    const all_error_msg = [];
    if(email.search(/\S/) == -1){
      all_error_msg.push("Please enter your email Id");
      if(flg == true){flg = false;}
    }else if(email.search(/\S/) != -1){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'forgot-password', {email: email}).then((result) => {
          if(result.status === true){
                setLoading(false);

                all_error_msg.push(result.message);

                onShowAlert('success',all_error_msg); 
            
                setEmail("");               
                setTimeout(function() {onCloseAlert(); }, 4000);

                setTimeout(() => {
                  navigate("/ForgotPasswordOtp", {state: {email: email}});
                }, 2000);

            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
          resetForm();
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }
 
   return(
    <div className="authwapper">
        <div className="container">
        <CustomAlert
              header={"Forgot Password"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
          <div className="loginfrom">
            <h1>Forgot Password</h1  >
            <form onSubmit={CheckEmail}>
              <div className="form-group">
                <img className='formIcon' src={email_icon}/>
                  <input type="text" placeholder="Enter your email" onChange={(event) => setEmail(event.target.value)}/>
              </div>
                <button type="submit"className="fill-btn">Submit</button>
            </form>
          </div>
          <div className="companyinfo">
            <img src={logo}/>
            <h2>Ashirbad Cloud</h2>
            <p>A construct & concept thereof</p>
            <div className='contactinfo'>
              <a href='mailto:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={envolope}/>
                souvik.mukherjee@ashirbadtax.com</a><br/>
                <a href='tel:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={call}/>
              +91 0000000000</a>
            </div>
          </div>
        </div>
         
    </div>
  )
}
export default ForgotPassword;