import { useState, } from "react";
import { useNavigate } from "react-router-dom";

 const AddAccountProduct = () =>{
const navigation = useNavigate()
    const addnow = () =>{
        navigation('/layout/accounttab')
    }
      return(
         <div className="addcaseaccount">
        <div className="pageTop"><h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Add Product</h2></div>
            <form>
            <div className="row">
            <div className="col-4">
            <div className="form-group"><label>Account Name</label>
            <input type="text" readOnly value="Jhon Duo"/>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Service Name</label>
            <select>
                <option>Select Service</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Job Name</label>
            <select>
                <option>Select Job</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Product Name </label>
            <select>
                <option>Select Product</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Product Inception date </label>
            <input type="date"  placeholder="date"/>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Cycle Start from </label>
            <input type="date"  placeholder="date"/>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Service Start from </label>
            <input type="date"  placeholder="date"/>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Status</label>
            <select>
                <option>Select Status</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Add all cases til current ?</label>
            <select>
                <option>Select Product</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Assigned to</label>
            <select>
                <option>Select Product</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Report To</label>
            <select className="disabledselect">
                <option disabled>Jhon Deo</option>
            </select>
            </div>
            </div>
            <div className="col-12">
            <div className="btnGroup">
                <button type="button" className="fillbtn" onClick={addnow}>Add Cases</button>
            </div>
            </div>
            </div>
            </form>
        
         </div>
      )
 }
 export default AddAccountProduct;