import { Link } from "react-router-dom";
import edit from "../../assets/images/edit.svg";
import bin from '../../assets/images/delete.svg';
import view from '../../assets/images/view.svg';
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const Statement = () =>{
      const navigate = useNavigate();
      const addService = () =>{
            navigate("/layout/addtask")
      }
    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const contactListhead =[checkbox(), "Date", "Ref No", "Account Name", "Job Name", "Product Name","Debit Amount" ,"Credit Amount","Balance"];
      const contactlistdata =[
            {
               checkbox:checkbox(),
               date:"12/12/2023",
               number:"54552HFGNN",
               ref:"Jhon Deo",
               acname:"Designer",
               invoice:"Ashirbad Product",
               caseid:"2000.00",
               amount:"5000.00",
               balance:"3000.00",
            },
            {
                  checkbox:checkbox(),
                  date:"12/12/2023",
               number:"54552HFGNN",
               ref:"Jhon Deo",
               acname:"Designer",
               invoice:"Ashirbad Product",
               caseid:"2000.00",
               amount:"5000.00",
               balance:"3000.00",
               },
               {
                  checkbox:checkbox(),
                  date:"12/12/2023",
                  number:"54552HFGNN",
                  ref:"Jhon Deo",
                  acname:"Designer",
                  invoice:"Ashirbad Product",
                  caseid:"2000.00",
                  amount:"5000.00",
                  balance:"3000.00",
               },
               {
                  checkbox:checkbox(),
                  date:"12/12/2023",
                  number:"54552HFGNN",
                  ref:"Jhon Deo",
                  acname:"Designer",
                  invoice:"Ashirbad Product",
                  caseid:"2000.00",
                  amount:"5000.00",
                  balance:"3000.00",
                  },
                  {
                        checkbox:checkbox(),
                        date:"12/12/2023",
                        number:"54552HFGNN",
                        ref:"Jhon Deo",
                        acname:"Designer",
                        invoice:"Ashirbad Product",
                        caseid:"2000.00",
                        amount:"5000.00",
                        balance:"3000.00",
                     },
                     {
                        checkbox:checkbox(),
                        date:"12/12/2023",
                        number:"54552HFGNN",
                        ref:"Jhon Deo",
                        acname:"Designer",
                        invoice:"Ashirbad Product",
                        caseid:"2000.00",
                        amount:"5000.00",
                        balance:"3000.00",
                        },
                        {
                              checkbox:checkbox(),
                              date:"12/12/2023",
                              number:"54552HFGNN",
                              ref:"Jhon Deo",
                              acname:"Designer",
                              invoice:"Ashirbad Product",
                              caseid:"2000.00",
                              amount:"5000.00",
                              balance:"3000.00",
                           },
                           {
                              checkbox:checkbox(),
                              date:"12/12/2023",
                              number:"54552HFGNN",
                              ref:"Jhon Deo",
                              acname:"Designer",
                              invoice:"Ashirbad Product",
                              caseid:"2000.00",
                              amount:"5000.00",
                              balance:"3000.00",
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>Statement</h2>
                  <div className="right-action">
                  <form>
                  <input type="text" placeholder="Job"/>
                      <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                      </form>
                        <Link className="fillbtn">Bulk action</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index}>
                                    <td> {item.checkbox}
                                    </td>
                                    <td> {item.date}</td>
                                    <td>{item.number}</td>
                                    <td>{item.ref}</td>
                                    <td>{item.acname}</td>
                                    <td>{item.invoice}</td>
                                    <td>{item.caseid}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.balance}</td>
                                  
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default Statement;