import edit from "../../../assets/images/edit.svg";
import bin from '../../../assets/images/delete.svg';
import searchicon from '../../../assets/images/searchicon.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Modal, Form} from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

import Moment from 'moment';

const JobList = () =>{
      Moment.locale('en');
      const [open, setOpen] = React.useState(false);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
      const handleClickOpen = (id) => {
          setOpen(true);
          setDeleteIds(id);
      };
      
        const handleClose = () => {
          setOpen(false);
        };
  
      const navigate = useNavigate();
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#C00305");
    
      const CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
      const override = CSSProperties;
    
      const dispatch = useDispatch();
    
      const { API_URL, userData, userToken} = useSelector(state => state.common);
      const [deleteType, setDeleteType] = useState(null);
      const [isLoading, setIsLoading] = useState(false);
      const [open_err, setOpenErr] = useState(false);
      const [err_msg, setErrMsg] = useState("");
      const [deleteIds, setDeleteIds] = useState(null);
      const [search_text, setSearchText] = useState({text: '', status: false});
  
      const [jobList, setJobList] = useState({IsLoading : true, list: [], total_count: 0});
      const [list_limit, setListLimit] = useState(20);
      const [page_selected, setPageSelected] = useState(null);
  
      useEffect(() => {
        if(userToken != ''){
                JobListFetch(0,list_limit); 
          }
        }, [userToken]);
  
         //alert start
    
      const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
      
    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const contactListhead =["Job Name", "Short Code", "Job Code", "Service Name", "Status","Recurrence" ,"Recurrence Type","Start Date", "Action"];
      
      const reloadList = ()=>{
            setPageSelected(null);
            JobListFetch(0,list_limit); 
        }
       
        const JobListFetch = (offset, limit) => {
            setLoading(true);
            postWithToken(API_URL, 'jobs-list', {offset: offset, limit: limit, search_name: search_text.text}, userToken).then((result) => {
                //console.log(result);
                setLoading(false);
                if(result.status === true){
                    setJobList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteJob = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];
     
              postWithToken(API_URL, 'jobs/delete', {id: id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                          const newArray = jobList.list.filter((item) => item.id !== id);

                          if(newArray.length > 0){
                            setJobList({IsLoading : false, list: newArray, total_count: (jobList.total_count - 1)});

                          }else{
                              let off = itemOffset;
                              off = off - list_limit;

                              if(itemOffset > 0){
                                setItemOffset(off);
                              
                                setPageSelected(page_selected - 1);
                                JobListFetch(off, list_limit);
                              }else{
                                setItemOffset(off);
                                setPageSelected(0);
                                JobListFetch(0, list_limit);
                              }
                          }
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            
        }

        const [itemOffset, setItemOffset] = useState(0);
          function PaginatedItems({ itemsPerPage }) {
              // Here we use item offsets; we could also use page offsets
              // following the API or data you're working with.
             
              // Simulate fetching items from another resources.
              // (This could be items from props; or items loaded in a local state
              // from an API endpoint with useEffect and useState)
              const endOffset = itemOffset + itemsPerPage;
              //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
              //const currentItems = roleList.list.slice(itemOffset, endOffset);
              const pageCount = Math.ceil(jobList.total_count / itemsPerPage);
            
              // Invoke when user click to request another page.
              const handlePageClick = (event) => {
                const newOffset = (event.selected * itemsPerPage) % jobList.total_count;
                /*console.log(
                  `User requested page number ${event.selected}, which is offset ${newOffset}`
                );*/
  
                setPageSelected(event.selected);
                JobListFetch(newOffset, list_limit);
                setItemOffset(newOffset);
              };
            
              return (
                <>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=" < "
                    renderOnZeroPageCount={null}
                    forcePage={page_selected}
                  />
                </>
              );
            }


            const ChangeStatus = (index_id, id, current_status) => {

                  setLoading(true);
      
                  if(current_status == 1){
                        current_status = 0;
                  }else{
                        current_status = 1;   
                  }
      
                  postWithToken(API_URL, 'jobs/change-status', {id:id, status: current_status}, userToken).then((result) => {
                        //console.log(result);
                        setLoading(false);
                        if(result.status === true){
                              jobList.list[index_id].active = current_status;
                        }
                    })
                    .catch((error) => {
                        console.log("Error : ", error);
                    });
      
            }


            const searchJob = (event) => {
                  event.preventDefault();
        
                  let flg = true;
                  const all_error_msg = [];
    
                  if(search_text.text == ""){
                      all_error_msg.push("Please enter search text");
                      if(flg == true){flg = false;}
                  }
    
                  if(flg == true){
                      postWithToken(API_URL, 'jobs-list', {offset: itemOffset, limit: list_limit, search_name: search_text.text}, userToken).then((result) => {
                            //console.log(result);
                            
                            setSearchText({text: '', status: true});
                            if(result.status === true){
                              setJobList({IsLoading : false, list: result.data, total_count: result.total_count});
                            }
                        })
                        .catch((error) => {
                            console.log("Error : ", error);
                        });
                  }else{
                      onShowAlert("error", all_error_msg); 
                  }
              }

              const searchText = (val) => {
                  setSearchText({text:val, status: false})

                  if(val.length == 0){
                        JobListFetch(0,list_limit); 
                  }
              }

    return(
            <>
            <CustomAlert
                header={"Job"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }

               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete Job Details"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this Job
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    No
                              </Button>
                              <Button onClick={() => {deleteJob(deleteIds)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>
      


        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>Job List</h2>
                  <div className="right-action">
                        <form onSubmit={searchJob}>
                              <input type="text" placeholder="Search Text" onChange={(event) => searchText(event.target.value)}/>
                              <button type="submit"><img src={searchicon}/></button>
                        </form>
                        <button className="fillbtn" onClick={() => {navigate("/dashboard/joblist/addjob",{state: {id: null}})}}><i class="fa fa-plus-circle" aria-hidden="true"></i> Create Job</button>
                        <button className="fillbtn">Bulk action</button>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                              contactListhead.map((item, index)=>{
                                    let w = ""
                                    if(index == 8){
                                          w = 150;
                                    }else if(index == 0){
                                          w = 225;
                                    }
                                    return (
                                          <th key={index} style={{width: w}}>{item}</th>
                                    );
                                    
                              })
                        }
                        </tr>
                        
                  </thead>
                  
                  {
                            jobList.IsLoading === false && jobList.list.length > 0 ?
                                <tbody>
                                    {
                                        jobList.list.map((item, index) => {

                                          let recurrence_type = "";

                                          if(item.recurrence_type == 1){
                                                recurrence_type = "Monthly";
                                          }else if(item.recurrence_type == 2){
                                                recurrence_type = "Quarterly";
                                          }else if(item.recurrence_type == 3){
                                                recurrence_type = "Half Yearly";
                                          }else if(item.recurrence_type == 4){
                                                recurrence_type = "Yearly";
                                          }

                                            return(
                                                <tr key={index}>
                                                <td >{item.name}</td>
                                                <td >{ item.short_name}</td>
                                                <td >{item.job_code}</td>
                                                <td >{item.service.name}</td>
                                                <td onClick={() => {ChangeStatus(index, item.id, item.active)}} className={item.active == 1 ? "active" :"inactive"}>{item.active == 1 ? "Active" :"Inactive"}</td>
                                                <td >{item.recurrence == 0 ? "Recurring" : "Single"}</td>
                                                <td >{recurrence_type}</td>
                                                <td >{Moment(item.start_date).format('DD-MM-YYYY')}</td>
                                                <td >
                                                    <button onClick={() => {navigate("/dashboard/joblist/addjob",{state: {id: item.id}})}} ><img src={edit}/></button>
                                                    <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id)}><img src={bin}/></button>
                                                </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    
                                    </tbody>
                                    : jobList.IsLoading === false && jobList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={9} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : jobList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }
                 </table>
               </div>
               <div className="pagination_div">
                             <PaginatedItems itemsPerPage={list_limit} />
               </div>
            </div>
      </>
    )
}
export default JobList;