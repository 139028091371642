
import edit from "../../../assets/images/edit.svg";
import bin from '../../../assets/images/delete.svg';
import searchicon from '../../../assets/images/searchicon.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Modal, Form} from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

function Roles(){

      const [open, setOpen] = React.useState(false);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
      const handleClickOpen = (id) => {
        setOpen(true);
        setDeleteRoleId(id);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const navigate = useNavigate();
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#C00305");
    
      const CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
      const override = CSSProperties;
    
      const dispatch = useDispatch();
    
      const { API_URL, userData, userToken} = useSelector(state => state.common);
      const [deleteRoleId, setDeleteRoleId] = useState(null);
      const [isLoading, setIsLoading] = useState(false);
      const [open_err, setOpenErr] = useState(false);
      const [err_msg, setErrMsg] = useState("");
      const [roleList, setRoleList] = useState({IsLoading : true, list: [], total_count: 0});
      const [list_limit, setListLimit] = useState(20);
      const [page_selected, setPageSelected] = useState(null);

      useEffect(() => {
            if(userToken != ''){
                  roleListFetch(0,list_limit); 
            }
        }, [userToken]);

      //alert start
  
      const [alert, setAlert] = useState({
            type: null,
            text: [],
            show: false
      })
      
      function onCloseAlert(){
            setAlert({
            show: false,
            type: null,
            text: [],
            })
      }
      
      function onShowAlert(type,msg){
            setAlert({
            type: type,
            text: msg,
            show: true
            })
      }
      
      //alert end 

      const contactListhead =["Roll Name", "Drescription", "Status", "Number of uses","Action"];

      const roleListFetch = (offset, limit) => {
            postWithToken(API_URL, 'roles-list', {offset: offset, limit: limit}, userToken).then((result) => {
                //console.log(result);
                if(result.status === true){
                    setRoleList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteRole = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];
            postWithToken(API_URL, 'roles/delete', {id: id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = roleList.list.filter((item) => item.id !== id);
                        setRoleList({IsLoading : false, list: newArray, total_count: (roleList.total_count - 1)});
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
        }



        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
            const [itemOffset, setItemOffset] = useState(0);
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(roleList.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % roleList.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              roleListFetch(newOffset, list_limit);
              setItemOffset(newOffset);
            };
          
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }

      return (
      <div className="contactList">

          <CustomAlert
              header={"Role"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

                  <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete Role"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this role
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    NO
                              </Button>
                              <Button onClick={() => {deleteRole(deleteRoleId)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>


            <div className="pageTop">
                  <h2><i class="fa fa-user-circle-o" aria-hidden="true"></i>Role List</h2>
                  <div className="right-action">
                           
                        <Link to="/dashboard/role/addrole" className="fadebtn">Add New Role</Link>
                        <Link className="fillbtn">Bulk action</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                              contactListhead.map((item, index)=>{
                                    return <th key={index}>{item}</th>
                              })
                        }
                        </tr>
                        
                  </thead>
                        
                        {
                       roleList.IsLoading === false && roleList.list.length > 0 ?
                          <tbody>
                            {
                                roleList.list.map((item, index) => {

                                    return(
                                        <tr key={index}>
                                          <td> {item.name}</td>
                                          <td>{item.description}</td>
                                          <td className={item.active == 1?"sucess":"inactive" }>{item.active == 1? "Active" : "Inactive"}</td>
                                          <td>{item.users_count}</td>
                                          <td>
                                                {
                                                      item.id != 1 ?
                                                       <>
                                                      <button onClick={() => {navigate("/dashboard/role/editrole", {state: {id: item.id}})}}><img src={edit}/></button> 
                                                      <button style={{marginLeft: '5px'}} onClick={() => handleClickOpen(item.id)} ><img src={bin}/></button>
                                                      </>
                                                      : null
                                                }
                                          </td>

                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : roleList.IsLoading === false && roleList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : roleList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                
                                     <td ><Skeleton height={24} /></td>
                               
                                    <td ><Skeleton height={24} /></td>

                                    <td ><Skeleton height={24} /></td>

                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                 </table>
               </div>

               
               <div className="pagination_div">
                {/*<p>See all 12 result </p>*/}
                        <PaginatedItems itemsPerPage={list_limit} />
               </div>
            </div>
      );
}
export default Roles;