const AddDocument = () =>{
   return(
    <div className="addDocument">
          <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>Document Type</h2>
                  
            </div>
            <div className="row">
                <div className="col-4">
                  <div className="form-group">
                   <label>Document Type</label>
                   <input type="text"/>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                   <label>Service</label>
                   <select>
                    <option></option>
                   </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                   <label>Job</label>
                   <select>
                    <option></option>
                   </select>
                   </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                   <label>Access to</label>
                   <select>
                    <option></option>
                   </select>
                   </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <din className="btnsec">
                    <button type="button" className="fillbtn">Add</button>

                    </din>
                   </div>
                </div>
            </div>
    </div>
   )
} 
export default AddDocument;