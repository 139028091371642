import pape from "../../../assets/images/pape.png";

import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken, postWithTokenWithoutData, postWithOutTokenWithoutData, postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';

const AccountProfile =()=>{

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");

    const [account_id, setAccountId] = useState(null);
    const [coustomer_id, setCoustomerId] = useState(null);
    const [account_number, setAccountNumber] = useState(null);

    const [parent_id, setParentId] = useState("");
    const [name, setName] = useState("");
    const [display_name, setDisplayName] = useState("");
    const [short_name, setShortName] = useState("");
    const [email, setEmail] = useState("");
    const [date_of_joining, setDateOfJoining] = useState("");
    const [billing_address, setBillingAddress] = useState("");
    const [billing_pin_code, setBillingPinCode] = useState("");
    const [billing_country_id, setBillingCountryId] = useState("");
    const [billing_state_id, setBillingStateId] = useState("");
    const [billing_city_id, setBillingCityId] = useState("");
    const [shipping_address, setShippingAddress] = useState("");
    const [shipping_pin_code, setShippingPinCode] = useState("");
    const [shipping_country_id, setShippingCountryId] = useState("");
    const [shipping_state_id, setShippingStateId] = useState("");
    const [shipping_city_id, setShippingCityId] = useState("");
    const [is_gst_applicable, setGST] = useState("");
    const [income_tax_status, setTaxStatus] = useState("");

    const [countryBillingList, setBillingCountryList] = useState([]);
    const [stateBillingList, setBillingStateList] = useState([]);
    const [cityBillingList, setBillingCityList] = useState([]);

    const [countryShippingList, setShippingCountryList] = useState([]);
    const [stateShippingList, setShippingStateList] = useState([]);
    const [cityShippingList, setShippingCityList] = useState([]);
    const [primary_accounts_list, setPrimaryAccountList] = useState([]);

    const [account_data, setAccountData]  = useState({});


    const DataFetch = () => {
      setLoading(true);
      postWithToken(API_URL, 'accounts/view', {id: location.state.id}, userToken).then((result) => {
          console.log(result);
          setLoading(false);
          if(result.status === true){
              setAccountData({IsLoading : false, list: result.data});

              setAccountId(result.data.id);
              setCoustomerId(result.data.coustomer_id);
              setAccountNumber(result.data.account_number);

              setParentId(result.data.parent_id);
              setName(result.data.name);
              setDisplayName(result.data.display_name);
              setShortName(result.data.short_name);
              setEmail(result.data.email);
              setDateOfJoining(result.data.date_of_joining);
              setBillingAddress(result.data.billing_address);
              setBillingPinCode(result.data.billing_pin_code);
              setBillingCountryId(result.data.billing_country_id);
              setBillingStateId(result.data.billing_state_id);
              setBillingCityId(result.data.billing_city_id);
              setShippingAddress(result.data.shipping_address);
              setShippingPinCode(result.data.shipping_pin_code);
              setShippingCountryId(result.data.shipping_country_id);
              setShippingStateId(result.data.shipping_state_id);
              setShippingCityId(result.data.shipping_city_id);
              setGST(result.data.is_gst_applicable);
              setTaxStatus(result.data.income_tax_status);

              if(result.data.billing_country_id > 0){
                  fetchBillingState(result.data.billing_country_id);
              }
             
              if(result.data.billing_state_id > 0){
                  fetchBillingCity(result.data.billing_state_id);
              }

              if(result.data.shipping_country_id > 0){
                fetchShippingState(result.data.shipping_country_id);
              }
            
              if(result.data.shipping_state_id > 0){
                  fetchShippingCity(result.data.shipping_state_id);
              }
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }


    //alert start
  
    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 

  useEffect(() => {
    CountryListFetch();
    FetchPrimaryAccounts();
  }, []);

  useEffect(() => {
      if(userToken != ''){
          if(location.state.id != null){
              setAccountId(location.state.id);
              DataFetch();
          }
      }
  }, [userToken]);

  const FetchPrimaryAccounts = () => {
    postWithToken(API_URL, 'primary-accounts-list', {}, userToken).then((result) => {
      console.log(result);
        if(result.status === true){
          setPrimaryAccountList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
  }

  const CountryListFetch = () => {
      postWithOutTokenWithoutData(API_URL, 'countries').then((result) => {
          if(result.status === true){
            setBillingCountryList(result.data);
            setShippingCountryList(result.data);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const fetchBillingState = (country_id) => {
      postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
          if(result.status === true){
               setBillingStateList(result.data);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const fetchBillingCity = (state_id) => {
      postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
          if(result.status === true){
              setBillingCityList(result.data);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const fetchShippingState = (country_id) => {
    postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
        if(result.status === true){
            setShippingStateList(result.data);
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
  }

  const fetchShippingCity = (state_id) => {
      postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
          if(result.status === true){
              setShippingCityList(result.data);
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const CreateAccount = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if(name.search(/\S/) == -1){
        all_error_msg.push("Please enter name");
        if(flg == true){flg = false;}
    }

    if(display_name.search(/\S/) == -1){
      all_error_msg.push("Please enter display name");
      if(flg == true){flg = false;}
    }

    if(short_name.search(/\S/) == -1){
      all_error_msg.push("Please enter short_name");
      if(flg == true){flg = false;}
    }

    if(email.search(/\S/) == -1){
      all_error_msg.push("Please enter email Id");
      if(flg == true){flg = false;}
    }else if(email.search(/\S/) != -1){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }
    } 

    if(parent_id === ''){
      console.log(parent_id);
        all_error_msg.push("Please select User Under");
        if(flg == true){flg = false;}
    }
    
    if(billing_country_id == ''){
      all_error_msg.push("Please select billing country");
      if(flg == true){flg = false;}
    }

    if(billing_address.search(/\S/) == -1){
      all_error_msg.push("Please enter Billing Address");
      if(flg == true){flg = false;}
    }

    if(billing_pin_code.search(/\S/) == -1){
      all_error_msg.push("Please enter Billing Address Pin Code");
      if(flg == true){flg = false;}
    }else if(billing_pin_code.length < 6){
      all_error_msg.push("Please enter 6 digit Billing Address Pin Code");
      if(flg == true){flg = false;}
    }

    if(billing_state_id == ''){
      all_error_msg.push("Please select billing state");
      if(flg == true){flg = false;}
    }

    if(billing_city_id == ''){
      all_error_msg.push("Please select billing city");
      if(flg == true){flg = false;}
    }

    if(shipping_country_id == ''){
      all_error_msg.push("Please select billing country");
      if(flg == true){flg = false;}
    }

    if(shipping_address.search(/\S/) == -1){
      all_error_msg.push("Please enter Billing Address");
      if(flg == true){flg = false;}
    }

    if(shipping_pin_code.search(/\S/) == -1){
      all_error_msg.push("Please enter Shipping Address Pin Code");
      if(flg == true){flg = false;}
    }else if(billing_pin_code.length < 6){
      all_error_msg.push("Please enter 6 digit Shipping Address Pin Code");
      if(flg == true){flg = false;}
    }

    if(shipping_state_id == ''){
      all_error_msg.push("Please select billing state");
      if(flg == true){flg = false;}
    }

    if(shipping_city_id == ''){
      all_error_msg.push("Please select billing city");
      if(flg == true){flg = false;}
    }

    if(is_gst_applicable == ''){
      all_error_msg.push("Please select GST Applicable");
      if(flg == true){flg = false;}
    }

    if (flg === true) {
      setLoading(true);

        if(account_id == null){

          let post_data = {
            parent_id: parent_id,
            name: name,
            display_name: display_name,
            short_name: short_name,
            email: email,
            date_of_joining: date_of_joining,
            billing_address: billing_address,
            billing_pin_code: billing_pin_code,
            billing_country_id: billing_country_id,
            billing_state_id: billing_state_id,
            billing_city_id: billing_city_id,
            shipping_address: shipping_address,
            shipping_pin_code: shipping_pin_code,
            shipping_country_id: shipping_country_id,
            shipping_state_id: shipping_state_id,
            shipping_city_id: shipping_city_id,
            is_gst_applicable: is_gst_applicable,
            income_tax_status: income_tax_status
          };

            postWithToken(API_URL, 'accounts/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      setAccountId(result.data.id);
                      setCoustomerId(result.data.coustomer_id);
                      setAccountNumber(result.data.account_number);

                      setAccountData({IsLoading : false, list: result.data});

                      all_error_msg.push(result.message);
                      onShowAlert("success", all_error_msg);

                      //navigate("/dashboard/clientuser");
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
        }else{

          let post_data = {
            id: account_id,
            parent_id: parent_id,
            name: name,
            display_name: display_name,
            short_name: short_name,
            email: email,
            date_of_joining: date_of_joining,
            billing_address: billing_address,
            billing_pin_code: billing_pin_code,
            billing_country_id: billing_country_id,
            billing_state_id: billing_state_id,
            billing_city_id: billing_city_id,
            shipping_address: shipping_address,
            shipping_pin_code: shipping_pin_code,
            shipping_country_id: shipping_country_id,
            shipping_state_id: shipping_state_id,
            shipping_city_id: shipping_city_id,
            is_gst_applicable: is_gst_applicable,
            income_tax_status: income_tax_status
          };

          
          postWithToken(API_URL, 'accounts/update', post_data, userToken).then((result) => {
            if(result.status === true){
                  setLoading(false);

                  setAccountData({IsLoading : false, list: result.data});

                  all_error_msg.push(result.message);
                  onShowAlert("success", all_error_msg);

                  //navigate("/dashboard/clientuser");
              }else{
                setLoading(false);
                all_error_msg.push(result.message);
                onShowAlert("error", all_error_msg);
              }
          })
          .catch((error) => {
            console.log("Error : ", error);
          });
        }
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

  const SameAsBillingAddress = (id,checkbox_class) => {
    let checkbox_status = $('#' + id).is(":checked");

    if(checkbox_status === true){

      fetchShippingState(billing_country_id);

      setShippingAddress(billing_address);
      setShippingPinCode(billing_pin_code);
      setShippingCountryId(billing_country_id);
      setShippingStateId(billing_state_id);
      setShippingCityId(billing_city_id);

      fetchShippingCity(billing_state_id);

    }else{
      setShippingStateList([]);
      setShippingAddress("");
      setShippingPinCode("");
      setShippingCountryId("");
      setShippingStateId("");
      setShippingCityId("");
    }
}

const ChangeTab = (tab_url) => {
  const all_error_msg = [];
  if(account_id == null){
    all_error_msg.push("Please Create Account Profile First");
    onShowAlert("error", all_error_msg);
  }else{
    navigate(tab_url, {state: {id: account_id}})
  }
}

$(function(){
  var dtToday = new Date();

  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate();
  var year = dtToday.getFullYear();

  if(month < 10)
      month = '0' + month.toString();
  if(day < 10)
      day = '0' + day.toString();

  var maxDate = year + '-' + month + '-' + day;    
  $('#joining_date').attr('max', maxDate);
});


    return(
        <>

      <CustomAlert
              header={"Add Account"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

     <div className="accountProfileTabs">
        <div className="pageTop">
            <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer Profile Basic</h2>
            { 
              location.state.id != null ?
              <button type='button' className='backBtn' onClick={() => {navigate("/dashboard/account/overview", {state: {id: location.state.id}})}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            :
              <button type='button' className='backBtn' onClick={() => {navigate("/dashboard/account")}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            }
            </div>
        <div className="customTab">
            <div className="account_button_tab">
              <button className="active" >Profile</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}}>Partner/ Director Details</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
              <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
          </div>
     
       <div className="accountProfile">
         <div className="row">
          <div className="col-6">
            <div className="formsec">
              {
                coustomer_id != null ?
                     <>
                      <div className="form-group">
                        <label>Coustomer ID</label>
                        <input type="text" value={coustomer_id} readOnly/>
                      </div>
                      <div className="form-group">
                        <label>Account Number</label>
                        <input type="text" value={account_number} readOnly/>
                      </div>
                      </>      
                  : null
              }
                  <div className="form-group">
                    <label>Client Name<span>*</span></label>
                    <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
                  </div>
                  <div className="form-group">
                  <label>Dispaly Name<span>*</span></label>
                    <input type="text" value={display_name} onChange={(event) => setDisplayName(event.target.value)}/>
                  </div>
                  <div className="form-group">
                  <label>Short Name<span>*</span></label>
                    <input type="text" value={short_name} onChange={(event) => setShortName(event.target.value)}/>
                  </div>
                  <div className="form-group">
                      <label>Date of joining</label>
                      <input type="date" id="joining_date" value={date_of_joining} onChange={(event) => setDateOfJoining(event.target.value)}/>
                  </div>
                  <div className="form-group">
                      <label>Under<span>*</span></label>
                      <select value={parent_id} onChange={(e) => {setParentId(e.target.value)}}>
                            <option value="">Select under</option>
                            <option key={'primary'} value="0">Primary</option>
                            {
                                primary_accounts_list.length > 0 ?
                                primary_accounts_list.map((item, key) => {
                                      return(
                                          <option key={'under_' + key} value={item.id}>{item.name}</option>
                                      );
                                  })
                                : null
                            }
                        </select>

                  </div>
                  <div className="form-group">
                  <label>Email ID<span>*</span></label>
                    <input type="email" value={email} onChange={(event) => setEmail(event.target.value)}/>
                  </div>
                  <h2>Address  
                    {
                      coustomer_id == null ?
                      <div className="checkbox">
                        <input className="styled-checkbox" id="same_as_billing_address" type="checkbox" onChange={() => SameAsBillingAddress('same_as_billing_address')}/>
                        <label for="same_as_billing_address">Same as Billing Address</label>
                      </div>
                      : null
                    }
                  </h2>
                  <div className="form-group">
                  <label>Country</label>
                      <select value={billing_country_id} onChange={(e) => {setBillingCountryId(e.target.value); fetchBillingState(e.target.value)}}>
                          <option value="">Select Country</option>
                          {
                              countryBillingList.length > 0 ?
                              countryBillingList.map((item, key) => {
                                    return(
                                        <option key={'country_' + key} value={item.id}>{item.country_name}</option>
                                    );
                                })
                              : null
                          }
                      </select>
                  </div>
                  <div className="form-group">
                  <label>Country</label>
                      <select value={shipping_country_id}  onChange={(e) => {setShippingCountryId(e.target.value); fetchShippingState(e.target.value)}}>
                          <option value="">Select Country</option>
                          {
                              countryShippingList.length > 0 ?
                              countryShippingList.map((item, key) => {
                                    return(
                                        <option key={'ship_country_' + key} value={item.id}>{item.country_name}</option>
                                    );
                                })
                              : null
                          }
                      </select>
                  </div>
                  <div className="form-group">
                  <label>Billing Address</label>
                    <input type="text" value={billing_address} onChange={(event) => setBillingAddress(event.target.value)} />
                  </div>
                  <div className="form-group">
                  <label>Shipping Address</label>
                    <input type="text" value={shipping_address} onChange={(event) => setShippingAddress(event.target.value)} />
                  </div>
                  <div className="form-group">
                  <label>Pin Code</label>
                    <input type="text" value={billing_pin_code} maxLength={6} minLength={6} onChange={(event) => setBillingPinCode(event.target.value)} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}} />
                  </div>
                  <div className="form-group">
                  <label>Pin Code</label>
                    <input type="text" value={shipping_pin_code} maxLength={6} minLength={6} onChange={(event) => setShippingPinCode(event.target.value)} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}} />
                  </div>
                  <div className="form-group">
                  <label>State</label>
                        <select value={billing_state_id} onChange={(e) => {setBillingStateId(e.target.value); fetchBillingCity(e.target.value)}}>
                            <option value="">Select State</option>
                            {
                                stateBillingList.length > 0 ?
                                stateBillingList.map((item, key) => {
                                    return(
                                        <option key={'billing_state_' + key} value={item.id}>{item.name}</option>
                                    );
                                })
                                : null
                            }
                        </select>
                  </div>
                  <div className="form-group">
                  <label>State</label>
                      <select value={shipping_state_id} onChange={(e) => {setShippingStateId(e.target.value); fetchShippingCity(e.target.value)}}>
                            <option value="">Select State</option>
                            {
                                stateShippingList.length > 0 ?
                                stateShippingList.map((item, key) => {
                                    return(
                                        <option key={'shipping_state_' + key} value={item.id}>{item.name}</option>
                                    );
                                })

                                : null
                            }
                        </select>
                  </div>
                  <div className="form-group">
                        <label>City</label>
                        <select value={billing_city_id} onChange={(e) => {setBillingCityId(e.target.value)}}>
                              <option value="">Select City</option>
                              {
                                  cityBillingList.length > 0 ?
                                    cityBillingList.map((item, key) => {
                                        return(
                                            <option key={'billing_city_' + key} value={item.id}>{item.name}</option>
                                        );
                                    })
                                  : null
                              }
                          </select>
                  </div>
                  <div className="form-group">
                      <label>City</label>
                        <select value={shipping_city_id} onChange={(e) => {setShippingCityId(e.target.value)}}>
                              <option value="">Select City</option>
                              {
                                  cityShippingList.length > 0 ?
                                    cityShippingList.map((item, key) => {
                                        return(
                                            <option key={'shipping_city_' + key} value={item.id}>{item.name}</option>
                                        );
                                    })
                                  : null
                              }
                          </select>
                  </div>
                    <div className="form-group">
                      <label>GST Applicablity</label>
                          <select value={is_gst_applicable} onChange={(e) => {setGST(e.target.value)}}>
                            <option value="" >Select</option>
                            <option value="1">Applicable</option>
                            <option value="0">Not Applicable</option>
                          </select>
                    </div>
                    <div className="form-group">
                      <label>Status for income Tax</label>
                        <select  value={income_tax_status} onChange={(e) => {setTaxStatus(e.target.value)}}>
                          <option value="" >Select</option>
                          <option value="Individual">Individual</option>
                          <option value="Firm">Firm</option>
                          <option value="Company">Company</option>
                          <option value="Society">Society</option>
                        </select>
                    </div>
                    <div className="btnGroup">
                      <button type="button" className="fillbtn" onClick={() => {navigate("/dashboard/account")}} >Cancel</button>
                      <button type="button" className="fadebtn" onClick={CreateAccount}>Save</button>
                      <button type="button" className="fillbtn" onClick={() => {ChangeTab('/dashboard/account/accountbank')}}>Next</button>
                    </div>

              </div>
          </div>
         
         </div>
       </div>
      

            </div>
      </div> 

      </>
    )
}
export default AccountProfile;  