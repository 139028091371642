import Filter from "./Filter";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import { Link } from "react-router-dom";
function SingleMode(){
return(
<div className="singleMode">
<div class="pageTop"><h2><i class="fa fa-file-text-o" aria-hidden="true"></i>Single Mode</h2></div>
<div className="search">
<Filter/>
</div>
<div className="tabsec">
<Tabs>
<div className="tab-left">
<TabList>
<Tab>Ashirbad Consultancy</Tab>
<Tab>Biswakarma Enterprise</Tab>
<Tab>Blossom</Tab>
<Tab>Euro Scientific</Tab>
<Tab>Rix N Rups</Tab>
<Tab>Softech</Tab>
<Tab>Biswakarma Enterprise</Tab>
<Tab>Euro Scientific</Tab>
</TabList>
</div>
<div className="tab-right">
<TabPanel> 
<Accordion>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
<h2>Task</h2>
<Link>New Transaction</Link>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<table>
<tr>
<th>Data Entry</th>
<td>
<select>
<option>Sale Date</option>
</select>
</td>
<td>
<Link>Send SMS</Link>
</td>
</tr>
<tr>
<th>GSTR</th>
<td>
<select>
<option>GSTR RTF</option>
</select>
</td>
<td>
<Link>Send SMS</Link>
</td>
</tr>
<tr>
<th>Payments</th>
<td>
<select>
<option>Bill Not Sent</option>
</select>
</td>
<td>
<Link>Send SMS</Link>
</td>
</tr>
</table>
</AccordionItemPanel>
</AccordionItem>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
<h2>Transction</h2>
<Link>Add Transaction</Link>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<table>
<thead>
<tr>
<th>Date</th>
<th>Type </th>
<th>Ref No</th>
<th>Amount</th>
<th>Balance</th>
</tr>
</thead>
<tbody>
<tr>
<td>12/18/2023</td>
<td>Invoice</td>
<td>AC254552HFFGH</td>
<td>5900.00</td>
<td>5900.00</td>
</tr>
</tbody>
</table>
</AccordionItemPanel>
</AccordionItem>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
<h2>Documents</h2>
<Link>Upload</Link>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<table>
<thead>
<tr>
<th>Date & Time</th>
<th>Doc ID</th>
<th>Document Name</th>
<th>File</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr>
<td>12/18/2023</td>
<td>ADC55544</td>
<td>File .doc</td>
<td>File Name</td>
<td><Link>Download</Link></td>
</tr>
</tbody>
</table>
</AccordionItemPanel>
</AccordionItem>
<AccordionItem>
<AccordionItemHeading>
<AccordionItemButton>
<h2>Interaction</h2>
<Link>Send Message</Link>
</AccordionItemButton>
</AccordionItemHeading>
<AccordionItemPanel>
<table>
<thead>
<tr>
<th>Date & Time</th>
<th>ID</th>
<th>Mode</th>
<th>Interaction</th>
<th>Raise By</th>
</tr>
</thead>
<tbody>
<tr>
<td>12/18/2023</td>
<td>ADC55544</td>
<td>WhatsApp</td>
<td>Dear, ranjan....</td>
<td>Jhon Deo</td>
</tr>
</tbody>
</table>
</AccordionItemPanel>
</AccordionItem>
</Accordion>
</TabPanel>
</div>
</Tabs>
</div>
</div>
)
}
export default SingleMode;