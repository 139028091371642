import bin from '../../../../assets/images/delete.svg';
import downloaddocument from "../../../../assets/images/downloaddocument.svg";
import uploaddocument from '../../../../assets/images/uploaddocument.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';
const AccountDocument =()=>{

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = (id) => {
        setOpen(true);
        setDeleteIds(id);
    };
    
      const handleClose = () => {
        setOpen(false);
      };

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [deleteIds, setDeleteIds] = useState(null);
    const [account_id, setAccountId] = useState("");
    const [statutoryList, setStatutoryList] = useState({IsLoading : true, list: []});

    useEffect(() => {
      if(userToken != ''){
          if(location.state.id != null){
              setAccountId(location.state.id);
              StatutoryListFetch(location.state.id); 
          }
      }
  }, [userToken]);

  const StatutoryListFetch = (account_id) => {
    setLoading(true);
    postWithToken(API_URL, 'account-documents-list', {account_id: account_id}, userToken).then((result) => {
        console.log(result);
        setLoading(false);
        if(result.status === true){
            setStatutoryList({IsLoading : false, list: result.data});
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
}

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
       

    const ChangeTab = (tab_url) => {
        const all_error_msg = [];
        if(location.state.id == null){
          all_error_msg.push("Please Create Account Profile First");
          onShowAlert("error", all_error_msg);
        }else{
          navigate(tab_url, {state: {id: location.state.id}})
        }
      }
        const contactListhead =[ "Statutory Details", "Statutory Number", "Document Available", "Document Status","Action"];

        const deleteStatutory = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];
    
            postWithToken(API_URL, 'account-documents/delete', {id: id, account_id: account_id}, userToken).then((result) => {
                  setLoading(false);
                  if(result.status == true){
                    StatutoryListFetch(account_id);
                  }else{
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
                  
            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
      }

     return(

      <>

      <CustomAlert
        header={"Statutory Info"}
        btnText={"Accept"}
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
        />  
        {
          loading === true ?
          <div className="activityLoader">
                <GridLoader
                  color={color}
                  loading={loading}
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  Loader="GridLoader"
                /> 
         </div>     
         : null
        }

       <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          >
                <DialogTitle id="responsive-dialog-title">
                {"Delete Statutory Document"}
                </DialogTitle>
                <DialogContent>
                      <DialogContentText>
                      Are you sure you want to delete this document
                      </DialogContentText>
                </DialogContent>
                <DialogActions>
                      <Button autoFocus onClick={handleClose}>
                            Disagree
                      </Button>
                      <Button onClick={() => {deleteStatutory(deleteIds)}} autoFocus>
                            Agree
                      </Button>
                </DialogActions>
          </Dialog>

        <div className="accountProfileTabs">
            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer User</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                    <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                </div>

                <div className="customTab">
                    <div className="account_button_tab">
                        <button  className="active" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}  >Statutory</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/bankdocument')}} >Bank</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/partnerdocument')}} >Partner/ Director Details</button>
                    </div>

                      <div className="accountProdfileBranch">
                          <div className="tableResponsive">
                            <table>
                              <thead>
                                <tr>
                                {
                                contactListhead.map((item, index)=>{
                                return <th key={index}>{item}</th>
                                })
                                }
                                </tr>
                              </thead>
                             
                                  {
                                        statutoryList.IsLoading === false && statutoryList.list.length > 0 ?
                                        <tbody>
                                            {
                                                statutoryList.list.map((item, index) => {

                                                    return(
                                                      <tr key={index}>
                          
                                                      <td> {item.name}</td>
                                                      <td>{item.number}</td>
                                                      <td>{item.document_available}{item.document_file_path != null ?<a target="_blank" href={item.document_file_path} download><img src={downloaddocument}/></a> : null } <button type="button" onClick={() => {navigate('/dashboard/account/statutorydoc', {state: {account_id: location.state.id, id: item.id, name: item.name}})}}><img src={uploaddocument}/></button></td>
                                                      <td>{item.document_status}</td>
                                                      <td><button type="button" onClick={() => handleClickOpen(item.id)}><img src={bin}/></button></td>
                                                      </tr>
                                                    );
                                                })
                                            }

                                    
                                    </tbody>
                                    : statutoryList.IsLoading === false && statutoryList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : statutoryList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }

                            </table>
                          </div>
                          
                      </div>      

                </div>

           </div>
        </div>  

        </>      
     )
}
export default AccountDocument;