import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  SITE_URL:"https://ashirbad.acuitysoftware.co/",
  API_URL : "https://ashirbad.acuitysoftware.co/ashirbad-cloud-api/api/",
  google_map_api_key: "AIzaSyAwWmYexCDWu-rTj2J2ngCje4JP0MFnYSk",
  userData: null,
  userToken: "",
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    setUserDataWithToken: (state, action) => {
      //console.log("action data : ", action.payload);
      state.userData = action.payload.data;
      state.userToken = action.payload.token;
    },
    setUserDataWithoutToken: (state, action) => {
      //console.log(action.payload.data);
      state.userData = action.payload.data;
    },

    setUserDataAfterLogin: (state, action) => {
      //console.log(action.payload);
      state.userData = action.payload;
    },

    clearUserData: (state) => {
      state.userData = null;
      state.userToken = "";
    },

    localstorage_TokenAdd: (state, action) => {
      state.userToken = action.payload;
    },
    localstorage_UserdetailsAdd: (state, action) => {
      state.userData = action.payload;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDataWithToken,
  clearUserData,
  setUserDataWithoutToken,
  setUserDataAfterLogin,
  localstorage_TokenAdd,
  localstorage_UserdetailsAdd,

} = commonReducer.actions;

export default commonReducer.reducer;
