import pdf from "../../assets/images/pdf.svg"
import xlx from "../../assets/images/xls.svg"
import payment from "../../assets/images/payment.png"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const ViewPayment = () =>{
      return(
        <div className="viewInvoice">
            <div className="pageTop"><h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Payment</h2>
            <div className="rightSec">
                <button type="button" className="pdfbtn"><img src={pdf}/></button>
                <button type="button" className="xlxbtn"><img src={xlx}/></button>
                <button type="button" className="bdrbtn"><i class="fa fa-envelope-o" aria-hidden="true"></i> Send</button><button type="button" className="bdrbtn"><i class="fa fa-pencil" aria-hidden="true"></i> Edit</button>
            </div>
            </div>
            <div className="tavBox">
                <Tabs>
                <div className="tableft">
                <TabList>
               <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                <Tab>
                 <h4>Jhon Duo <b>₹ 500.00</b></h4>
                 <p>455GHFG <span><i className="fa fa-calendar" aria-hidden="true"></i> 12/221/2023</span></p>
                </Tab> 
                </TabList>
                </div>
                <div className="tabRight">
                <TabPanel> 
                    <div className="tabdetails">
                        <img src={payment}/>
                    </div>
                </TabPanel>
                <TabPanel> 
                <div className="tabdetails">
                        <img src={payment}/>
                    </div>
                </TabPanel>
                </div>
                </Tabs>
            </div>
        </div>
      )
}
export default ViewPayment;