import edit from "../../../../assets/images/edit.svg";
import bin from '../../../../assets/images/delete.svg';
import view from '../../../../assets/images/view.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';
import Multiselect from 'multiselect-react-dropdown';

const AccountUser =()=>{
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = (id) => {
        setOpen(true);
        setDeleteIds(id);
    
    };
    
      const handleClose = () => {
        setOpen(false);
      };

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [modalUserInfoOpen, setModelUserInfoOpen] = useState(false);
    const [user_info, setUserInfoData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [deleteIds, setDeleteIds] = useState(null);
    const [user_id, setUserId] = useState(null);
    const [account_id, setAccountId] = useState("");

    const [userList, setUserList] = useState({IsLoading : true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                setAccountId(location.state.id);
                UserListFetch(0,list_limit, location.state.id); 
            }
        }
    }, [userToken]);


    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

const [modalIsOpen, setIsOpen] = useState(false);

const checkbox = ()=>{
    return <div className="checkbox">
    <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
    <label for="styled-checkbox-1"></label>
    </div>
}
const contactListhead =["User Name", "Designation", "Mobile","Email","Action"];

function openModal(action_type) {
    if(action_type == 'add'){
        setUserId(null);
    }
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const reloadList = ()=>{
    setIsOpen(false);
    setPageSelected(null);
    UserListFetch(0,list_limit, location.state.id); 
  }
  const ChangeTab = (tab_url) => {
    const all_error_msg = [];
    if(location.state.id == null){
      all_error_msg.push("Please Create Account Profile First");
      onShowAlert("error", all_error_msg);
    }else{
      navigate(tab_url, {state: {id: location.state.id}})
    }
  }

  const UserListFetch = (offset, limit, account_id) => {
    setLoading(true);
    postWithToken(API_URL, 'account-users-list', {offset: offset, limit: limit, account_id: account_id}, userToken).then((result) => {
        //console.log(result);
        setLoading(false);
        if(result.status === true){
            setUserList({IsLoading : false, list: result.data, total_count: result.total_count});
        }
    })
    .catch((error) => {
        console.log("Error : ", error);
    });
  }


const deleteUser = (id) => {
    handleClose();
    setLoading(true);
    const all_error_msg = [];
    
      postWithToken(API_URL, 'account-users/delete', {id: id, account_id: account_id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                  const newArray = userList.list.filter((item) => item.id !== id);

                  if(newArray.length > 0){
                    setUserList({IsLoading : false, list: newArray, total_count: (userList.total_count - 1)});

                  }else{
                      let off = itemOffset;
                      off = off - list_limit;

                      if(itemOffset > 0){
                        setItemOffset(off);
                      
                        setPageSelected(page_selected - 1);
                        UserListFetch(off, list_limit, account_id);
                      }else{
                        setItemOffset(off);
                        setPageSelected(0);
                        UserListFetch(0, list_limit, account_id);
                      }
                  }
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }

      })
      .catch((error) => {
            console.log("Error : ", error);
      });
    
}



const [itemOffset, setItemOffset] = useState(0);
function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
   
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //const currentItems = roleList.list.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(userList.total_count / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % userList.total_count;
      /*console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );*/

      setPageSelected(event.selected);
      UserListFetch(newOffset, list_limit, account_id);
      setItemOffset(newOffset);
    };
  
    return (
      <>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" > "
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel=" < "
          renderOnZeroPageCount={null}
          forcePage={page_selected}
        />
      </>
    );
  }


function CloseUserInfoModel(){
  setModelUserInfoOpen(false);
}

const ViewUserInfo = (id) => {

  setLoading(true);
  const all_error_msg = [];

  postWithToken(API_URL, 'account-users/view', {id: id, account_id: account_id}, userToken).then((result) => {
        setLoading(false);
        if(result.status === true){

          setUserInfoData(result);
          setModelUserInfoOpen(true);
             
        }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
        }

  })
  .catch((error) => {
        console.log("Error : ", error);
  });

}



return(
    <>
    <CustomAlert
                header={"Account User"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }

               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete User Details"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this user
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    Disagree
                              </Button>
                              <Button onClick={() => {deleteUser(deleteIds)}} autoFocus>
                                    Agree
                              </Button>
                        </DialogActions>
                  </Dialog>

    <div className="accountProfileTabs">
        <div className="pageTop">
            <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer User</h2> 
            <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
        </div>
        <div className="customTab">
            <div className="account_button_tab">
            <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
            <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
            <button className="active"  onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
        </div>


        <div className="accountbankdetails">
            <div className="actionTable">
                 <div style={{width: 250}}></div>
                <div className="tablebtngroup">
                    <button type="button" className="fillbtn" onClick={() => navigate("/dashboard/account/accountuseradd", {state: {account_id: location.state.id, id: null}})}><i class="fa fa-plus-circle" aria-hidden="true"></i> Add User</button>
                    <Link className="fillbtn">Bulk Action</Link>
                </div>
            </div>
            <div className="tableResponsive">
                <table>
                    <thead>
                        <tr>
                        {
                                contactListhead.map((item, index)=>{
                                    let w = '';
                                    if(index == 5){
                                        w = 165;
                                    }else if(index == 0){
                                      w = 225;
                                    }
                                    return (<th key={index} style={{width: w}}>{item}</th>)
                                })
                        }
                        </tr>

                    </thead>
                    
                    {
                            userList.IsLoading === false && userList.list.length > 0 ?
                                <tbody>
                                    {
                                        userList.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                <td >{item.name}</td>
                                                <td >{ item.designation}</td>
                                                <td >{item.phone.replace(/.(?=.{4})/g, 'x')}</td>
                                                <td >{item.email}</td>
                                                <td >
                                                    <button onClick={() => navigate("/dashboard/account/accountuseradd", {state: {account_id: location.state.id, id: item.id}})} ><img src={edit}/></button>
                                                    <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id)}><img src={bin}/></button>
                                                    <button style={{ marginLeft: 6}} onClick={() => {ViewUserInfo(item.id)}}><img src={view}/></button> 
                                                </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    
                                    </tbody>
                                    : userList.IsLoading === false && userList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : userList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }

                </table>
            </div>
            <div className="tablenav">
                <div className="btnGroup">
                    <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Previous</button>
                </div>
            </div>

            <div className="pagination_div">
                    <PaginatedItems itemsPerPage={list_limit} />
            </div>


                <Modal isOpen={modalUserInfoOpen} onRequestClose={CloseUserInfoModel} contentLabel="User" >
                <div className='modal_header'>
                User Details <button onClick={CloseUserInfoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody'>
                   
                        <div className='row'>
                            <div className='col-12' >

                              {
                                user_info === null ?
                                  <Skeleton height={24} />
                                : 

                                <div className="row">
                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>User Name : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.data.user.name}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Designation : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.data.designation}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Department : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.data.department}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>SMS Notification : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.user_permission_list.user_sms_permission == 1 ? 'Yes' : 'No'}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Email Notification : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.user_permission_list.user_email_permission == 1 ? 'Yes' : 'No'}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Whatsapp Notification : </label>
                                           <span style={{display: 'flex', flex: 1}}>{user_info.user_permission_list.user_whatsapp_permission == 1 ? 'Yes' : 'No'}</span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group-permission-view" style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Document Permission : </label>
                                           <span style={{display: 'flex', flex: 1}}>
                                            {
                                            user_info.user_permission_list.user_document_permission.length > 0 && 
                                             
                                              <ul>
                                              {user_info.user_permission_list.user_document_permission.map((item, index) => {

                                                return(
                                                  <li>{item.name}</li>
                                                )

                                              }) }

                                              </ul>
                                           
                                          }
                                          </span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group-permission-view" style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Invoice Permission : </label>
                                           <span style={{display: 'flex', flex: 1}}>
                                            {
                                            user_info.user_permission_list.user_invoice_permission.length > 0 && 
                                             
                                              <ul>
                                              {user_info.user_permission_list.user_invoice_permission.map((item, index) => {

                                                return(
                                                  <li>{item.name}</li>
                                                )

                                              }) }

                                              </ul>
                                           
                                          }
                                          </span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group-permission-view" style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Case Permission : </label>
                                           <span style={{display: 'flex', flex: 1}}>
                                            {
                                            user_info.user_permission_list.user_case_permission.length > 0 && 
                                             
                                              <ul>
                                              {user_info.user_permission_list.user_case_permission.map((item, index) => {

                                                return(
                                                  <li>{item.name}</li>
                                                )

                                              }) }

                                              </ul>
                                           
                                          }
                                          </span> 
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div class="form-group-permission-view" style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>User Permission : </label>
                                           <span style={{display: 'flex', flex: 1}}>
                                            {
                                            user_info.user_permission_list.user_user_permission.length > 0 && 
                                             
                                              <ul>
                                              {user_info.user_permission_list.user_user_permission.map((item, index) => {

                                                return(
                                                  <li>{item.name}</li>
                                                )

                                              }) }

                                              </ul>
                                           
                                          }
                                          </span> 
                                        </div>
                                    </div>

                                </div>
                              }
                                
                            </div>
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseUserInfoModel}>Close</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>

            </div>
        </div>
    </div>
    </>
)
}
export default AccountUser;