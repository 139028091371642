import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState, CSSProperties} from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import $ from 'jquery';

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const AddJob = ()=>{

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [full_name, setFullName] = useState("");
    const [job_id, setJobId] = useState(null);
    const [short_name, setShortName] = useState("");
    const [job_code, setJobCode] = useState("");
    const [service_id, setServiceId] = useState("");
    const [status, setStatus] = useState(1);
    const [recurrence, setRecurrence] = useState("");
    const [recurrence_type, setRecurrenceType] = useState("");
    const [year_type, setYearType] = useState("");
    const [job_start_date, setJobStartDate] = useState("");
    const [current_cycle_start_date, setCurrentCycleStartDate] = useState("");
    const [current_cycle_end_date, setCurrentCycleEndDate] = useState("");
    const [service_start_date, setServiceStartDate] = useState("");
    const [service_end_date, setServiceEndDate] = useState("");
    const [no_of_tasks, setNoOfTasks] = useState("");
    const [report_to, setReportTo] = useState("");
   
    const [service_list, setServiceList] = useState([]);
    const [operative_list, setOperativeList] = useState([]);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                DataFetch();
                setJobId(location.state.id);
            }

            ServiceListFetch();
            operativeListFetch();
        }
       
    }, [userToken]);

    const operativeListFetch = () => {
        postWithToken(API_URL, 'operative-list', {}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setOperativeList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const ServiceListFetch = () => {
        postWithToken(API_URL, 'active-services-list', {}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setServiceList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'jobs/view', {id: location.state.id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setFullName(result.data.name);
                setShortName(result.data.short_name);
                setJobCode(result.data.job_code);
                setServiceId(result.data.service_id);
                setStatus(result.data.active);
                setRecurrence(result.data.recurrence);
                setRecurrenceType(result.data.recurrence_type);
                setYearType(result.data.year_type);
                setJobStartDate(result.data.start_date);
                setCurrentCycleStartDate(result.data.current_cycle_start_date);
                setCurrentCycleEndDate(result.data.current_cycle_end_date);
                setServiceStartDate(result.data.service_start_date);
                setServiceEndDate(result.data.service_end_date);
                setNoOfTasks(result.data.no_of_tasks);
                setReportTo(result.data.report_to);
            
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const addJob = (event) => {
        event.preventDefault();
    
        let flg = true;
        const all_error_msg = [];

        if(full_name.search(/\S/) == -1){
            all_error_msg.push("Please enter service full name");
            if(flg == true){flg = false;}
        }

        if(short_name.search(/\S/) == -1){
            all_error_msg.push("Please enter service short name");
            if(flg == true){flg = false;}
        }

        if(job_code.search(/\S/) == -1){
            all_error_msg.push("Please enter job code");
            if(flg == true){flg = false;}
        }else{
            if(job_code.length < 5){
                all_error_msg.push("Please enter 5 alphabets job code");
                if(flg == true){flg = false;}
            }
        }

        if(service_id == ""){
            all_error_msg.push("Please select service");
            if(flg == true){flg = false;}
        }

        if(status == ""){
            all_error_msg.push("Please select job status");
            if(flg == true){flg = false;}
        }
       

        if(recurrence.length < 1){
            all_error_msg.push("Please select recurrence");
            if(flg == true){flg = false;}
        }

        if(recurrence_type == ""){
            all_error_msg.push("Please select recurrence type");
            if(flg == true){flg = false;}
        }

        if(year_type == ""){
            all_error_msg.push("Please select year type");
            if(flg == true){flg = false;}
        }

        if(job_start_date == ""){
            all_error_msg.push("Please select job start date");
            if(flg == true){flg = false;}
        }

        if(current_cycle_start_date == ""){
            all_error_msg.push("Please select current cycle start date");
            if(flg == true){flg = false;}
        }

        if(current_cycle_end_date == ""){
            all_error_msg.push("Please select current cycle end date");
            if(flg == true){flg = false;}
        }

        if(service_start_date == ""){
            all_error_msg.push("Please select service start date");
            if(flg == true){flg = false;}
        }

        if(service_end_date == ""){
            all_error_msg.push("Please select service end date");
            if(flg == true){flg = false;}
        }

        if(no_of_tasks == ""){
            all_error_msg.push("Please select number of task");
            if(flg == true){flg = false;}
        }

        if(report_to == ""){
            all_error_msg.push("Please select report to");
            if(flg == true){flg = false;}
        }
    
        if (flg === true) {
          setLoading(true);

          if(job_id == null){
            let post_data = {
                name: full_name,
                short_name: short_name,
                job_code: job_code,
                service_id: service_id,
                recurrence: recurrence,
                recurrence_type: recurrence_type,
                year_type: year_type,
                start_date: job_start_date,
                current_cycle_start_date: current_cycle_start_date,
                current_cycle_end_date: current_cycle_end_date,
                service_start_date: service_start_date,
                service_end_date: service_end_date,
                no_of_tasks: no_of_tasks,
                report_to: report_to,
                active: status
              };
            postWithToken(API_URL, 'jobs/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/joblist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
           
          }else{
            let post_data = {
                id: job_id,
                name: full_name,
                short_name: short_name,
                job_code: job_code,
                service_id: service_id,
                recurrence: recurrence,
                recurrence_type: recurrence_type,
                year_type: year_type,
                start_date: job_start_date,
                current_cycle_start_date: current_cycle_start_date,
                current_cycle_end_date: current_cycle_end_date,
                service_start_date: service_start_date,
                service_end_date: service_end_date,
                no_of_tasks: no_of_tasks,
                report_to: report_to,
                active: status
              };
            postWithToken(API_URL, 'jobs/update', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/joblist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
          }
        }else{
            onShowAlert("error", all_error_msg);
        } 
    
    }




    $(function(){
        var todaysDate = new Date();    // Get today's date.

        var year = todaysDate.getFullYear();            // yyyy

        var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2); // mm

        var day = ("0" + todaysDate.getDate()).slice(-2);   // dd

        var dtToday = (year + "-" + month + "-" + day); // Results in yyyy-mm-dd

        //$(".Past input").attr('max', dtToday);

        //$(".Future input").attr('min', dtToday);
   
        $('#job_start_date').attr('min', dtToday);
        $('#current_cycle_start_date').attr('min', dtToday);
        $('#current_cycle_end_date').attr('min', dtToday);
        $('#service_start_date').attr('min', dtToday);
        $('#service_end_date').attr('min', dtToday);
      });

      const cycleEndDate = (cycle_start_date) => {

        let selected = cycle_start_date.split("-")

        setCurrentCycleStartDate(cycle_start_date);

        var selectedDate = new Date(selected[0],selected[1],selected[2]);

        if(recurrence_type == 1){
            var month_add = 1;
            var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
            var new_date_define = new Date(new_date);
            var d = new_date_define.getDate();
            var m = new_date_define.getMonth();
            var y = new_date_define.getFullYear();
            console.log(y + "-" + m + "-" + d)
            if(m < 10){
                m = "0" +m;
            }
            if(d < 10){
                d = "0" + d;
            }
            setCurrentCycleEndDate(y + "-" + m + "-" + d);

        }else if(recurrence_type == 2){

            var month_add = 3;
            var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
            var new_date_define = new Date(new_date);
            var d = new_date_define.getDate();
            var m = new_date_define.getMonth();
            var y = new_date_define.getFullYear();
            console.log(y + "-" + m + "-" + d)
            if(m < 10){
                m = "0" +m;
            }
            if(d < 10){
                d = "0" + d;
            }
            setCurrentCycleEndDate(y + "-" + m + "-" + d);

        }else if(recurrence_type == 3){
            var month_add = 6;
            var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
            var new_date_define = new Date(new_date);
            var d = new_date_define.getDate();
            var m = new_date_define.getMonth();
            var y = new_date_define.getFullYear();
            console.log(y + "-" + m + "-" + d)
            if(m < 10){
                m = "0" +m;
            }
            if(d < 10){
                d = "0" + d;
            }
            setCurrentCycleEndDate(y + "-" + m + "-" + d);
        }else if(recurrence_type == 4){
            var month_add = 12;
            var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
            var new_date_define = new Date(new_date);
            var d = new_date_define.getDate();
            var m = new_date_define.getMonth();
            var y = new_date_define.getFullYear();
            console.log(y + "-" + m + "-" + d)
            if(m < 10){
                m = "0" +m;
            }
            if(d < 10){
                d = "0" + d;
            }
            setCurrentCycleEndDate(y + "-" + m + "-" + d);
        }

      }

      const changeRecurrenceType = (val) => {

        if(current_cycle_start_date != ""){

            let selected = current_cycle_start_date.split("-")

            var selectedDate = new Date(selected[0],selected[1],selected[2]);

            if(val == 1){
                var month_add = 1;
                var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
                var new_date_define = new Date(new_date);
                var d = new_date_define.getDate();
                var m = new_date_define.getMonth();
                var y = new_date_define.getFullYear();
                console.log(y + "-" + m + "-" + d)
                if(m < 10){
                    m = "0" +m;
                }
                if(d < 10){
                    d = "0" + d;
                }
                setCurrentCycleEndDate(y + "-" + m + "-" + d);

            }else if(val == 2){

                var month_add = 3;
                var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
                var new_date_define = new Date(new_date);
                var d = new_date_define.getDate();
                var m = new_date_define.getMonth();
                var y = new_date_define.getFullYear();
                console.log(y + "-" + m + "-" + d)
                if(m < 10){
                    m = "0" +m;
                }
                if(d < 10){
                    d = "0" + d;
                }
                setCurrentCycleEndDate(y + "-" + m + "-" + d);

            }else if(val == 3){
                var month_add = 6;
                var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
                var new_date_define = new Date(new_date);
                var d = new_date_define.getDate();
                var m = new_date_define.getMonth();
                var y = new_date_define.getFullYear();
                console.log(y + "-" + m + "-" + d)
                if(m < 10){
                    m = "0" +m;
                }
                if(d < 10){
                    d = "0" + d;
                }
                setCurrentCycleEndDate(y + "-" + m + "-" + d);
            }else if(val == 4){
                var month_add = 12;
                var new_date = selectedDate.setMonth(selectedDate.getMonth() + month_add);
                var new_date_define = new Date(new_date);
                var d = new_date_define.getDate();
                var m = new_date_define.getMonth();
                var y = new_date_define.getFullYear();
                console.log(y + "-" + m + "-" + d)
                if(m < 10){
                    m = "0" +m;
                }
                if(d < 10){
                    d = "0" + d;
                }
                setCurrentCycleEndDate(y + "-" + m + "-" + d);
            }
        }

      }

    return(
        <>
        
        <CustomAlert
              header={"Add Job"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
       
        <div className="addJob">
             <div className="pageTop">
                  <h2><i class="fa fa-archive" aria-hidden="true"></i>{ job_id != null ? "Edit" : "Add"} Job</h2>
                  </div>
                  <form onSubmit={addJob}>
                    <div className="productsec">
                        <h2>Basic Details</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Full Name<sub>*</sub></label>
                                    <input type="text" value={full_name} onChange={(event) => setFullName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Short Name<sub>*</sub></label>
                                    <input type="text" value={short_name} onChange={(event) => setShortName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Code<sub>* CAP Letter 5 Code for Job </sub></label>
                                    <input type="text" maxLength={5} value={job_code} onChange={(event) => { const re = /^[A-Za-z]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
        setJobCode(event.target.value.toUpperCase());
    }}}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Name<sub>*</sub></label>
                                    <select value={service_id} onChange={(e) => {setServiceId(e.target.value)}}>
                                        <option value="">Select Service</option>
                                        {
                                            service_list.length > 0 ?

                                            service_list.map((item, key) => {

                                                return(
                                                    <option key={'service_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Job Status </label>
                                    <select value={status} onChange={(e) => {setStatus(e.target.value)}}>
                                        <option key={'active'} value="1">Active</option>
                                        <option  key={'inactive'} value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Recurrence</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Recurrence<sub>*</sub></label>
                                    <select value={recurrence} onChange={(e) => {setRecurrence(e.target.value)}}>
                                       <option key={'blank'} value="">Select Recurrence</option>
                                        <option key={'Recurring'} value="0">Recurring</option>
                                        <option  key={'Single'} value="1">Single</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Recurrence Type</label>
                                    <select value={recurrence_type} onChange={(e) => {setRecurrenceType(e.target.value); changeRecurrenceType(e.target.value);}}>
                                       <option key={'blank_re_type'} value="">Select Recurrence Type</option>
                                        <option key={'Monthly'} value="1">Monthly</option>
                                        <option  key={'Quarterly'} value="2">Quarterly</option>
                                        <option key={'Half'} value="3">Half Yearly</option>
                                        <option  key={'Yearly'} value="4">Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Year Type</label>
                                    <select value={year_type} onChange={(e) => {setYearType(e.target.value)}}>
                                       <option key={'blank_year'} value="">Select Year Type</option>
                                        <option key={'Financial'} value="1">Financial Year</option>
                                        <option  key={'Assessment'} value="2">Assessment Year</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Job Start date  </label>
                                    <input type="date" id="job_start_date" value={job_start_date} onChange={(event) => setJobStartDate(event.target.value)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Current Cycle Start date  </label>
                                    <input type="date" id="current_cycle_start_date" value={current_cycle_start_date} onChange={(event) => {cycleEndDate(event.target.value)}} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Current  Cycle End date </label>
                                    <input type="date" id="current_cycle_end_date" readOnly value={current_cycle_end_date} onChange={(event) => setCurrentCycleEndDate(event.target.value)} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Start date </label>
                                    <input type="date" id="service_start_date" value={service_start_date} onChange={(event) => setServiceStartDate(event.target.value)} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Due date </label>
                                    <input type="date" id="service_end_date" value={service_end_date} onChange={(event) => setServiceEndDate(event.target.value)} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Other Details</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Number of Primary Tasks</label>
                                     <select value={no_of_tasks} onChange={(e) => {setNoOfTasks(e.target.value)}}>
                                        <option key={'key'} value="" >Select</option>
                                        <option key={'key_3'} value="3" >3</option>
                                        <option key={'key_4'} value="4" >4</option>
                                        <option key={'key_5'} value="5" >5</option>
                                     </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Report To</label>
                                    <select value={report_to} onChange={(e) => {setReportTo(e.target.value)}}>
                                        <option value="">Select</option>
                                        {
                                            operative_list.length > 0 ?

                                            operative_list.map((item, key) => {

                                                return(
                                                    <option key={'service_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btnGroup">
                                    <button type="button" className="fadebtn" onClick={() => {navigate("/dashboard/joblist")}}>Cancel</button>
                                    <button type="submit" className="fillbtn">{job_id != null ? "Update" : "Add"}</button>
                                    {/*<button type="button" className="fillbtn">Add Task</button>
                                    <button type="button" className="fillbtn">Import Task</button>
                                    <button type="button" className="fillbtn">Add Product</button>*/ }
                                </div>
                            </div>
                        </div>
                    </div>
          
                  </form>
        </div>

        </>
    )
}
export default AddJob;