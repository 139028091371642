import edit from "../../../assets/images/edit.svg";
import bin from '../../../assets/images/delete.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Modal, Form} from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

function Account(){

      const [open, setOpen] = React.useState(false);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
      const handleClickOpen = () => {

            const all_error_msg = [];

            let checkbox_all_access_length = $('.user_checkbox:checked').length;

            if(checkbox_all_access_length > 0){
                  setOpen(true);
                  $('.user_checkbox:checked').each(function(){
                        setDeleteIds(parseInt($(this).val()));
                  });
            }else{
                  all_error_msg.push("Please select atleast one row");
                  onShowAlert("error", all_error_msg);
            }
        
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const navigate = useNavigate();
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#C00305");
    
      const CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
      const override = CSSProperties;
    
      const dispatch = useDispatch();
    
      const { API_URL, userData, userToken} = useSelector(state => state.common);
      const [search_status, setSearchStatus] = useState(1);
      const [deleteIds, setDeleteIds] = useState(0);
      const [isLoading, setIsLoading] = useState(false);
      const [open_err, setOpenErr] = useState(false);
      const [err_msg, setErrMsg] = useState("");
      const [accountList, setAccountList] = useState({IsLoading : true, list: [], total_count: 0});
      const [list_limit, setListLimit] = useState(20);
      const [page_selected, setPageSelected] = useState(null);

      useEffect(() => {
            if(userToken != ''){
              AccountListFetch(0,list_limit, search_status); 
            }
        }, [userToken]);

      //alert start
  
      const [alert, setAlert] = useState({
            type: null,
            text: [],
            show: false
      })
      
      function onCloseAlert(){
            setAlert({
            show: false,
            type: null,
            text: [],
            })
      }
      
      function onShowAlert(type,msg){
            setAlert({
            type: type,
            text: msg,
            show: true
            })
      }
      
      //alert end 

      const AccountListFetch = (offset, limit, search_status) => {
            postWithToken(API_URL, 'accounts-list', {offset: offset, limit: limit, search_status: search_status}, userToken).then((result) => {
                //console.log(result);
                if(result.status === true){
                  setAccountList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteUser = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];

            postWithToken(API_URL, 'accounts/delete', {id: id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = accountList.list.filter((item) => item.id !== id);
                        setAccountList({IsLoading : false, list: newArray, total_count: (accountList.total_count - 1)});
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
        }


        const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(accountList.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % accountList.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              AccountListFetch(newOffset, list_limit, search_status);
              setItemOffset(newOffset);
            };
          
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }


      const checkbox = () => {
            return (
                  <div className="checkbox">
                        <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
                        <label for="styled-checkbox-1"></label>
                  </div>
            )
          }

      const contactListhead =["","Account No", "Account Name", "Contact Number", "Active Jobs ", "Account Update", "Balance Due"];

      const CheckSingleOnly = (id,checkbox_class) => {
             let checkbox_all_status = $('#' + id).is(":checked");

            if(checkbox_all_status === true){
                  $("." + checkbox_class).prop("checked", false);
                  $("#" + id).prop("checked", true);
            }else{
                  $("." + checkbox_class).prop("checked", false);
            }
      }

      const ChangeStatus = (index_id, id, current_status) => {

            setLoading(true);

            if(current_status == 1){
                  current_status = 0;
            }else{
                  current_status = 1;   
            }

            postWithToken(API_URL, 'accounts/change-status', {id:id, status: current_status}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = accountList.list.filter((item) => item.id !== id);
                        setAccountList({IsLoading : false, list: newArray, total_count: (accountList.total_count - 1)});
                  }
              })
              .catch((error) => {
                  console.log("Error : ", error);
              });

      }

      const changeListType = (search_status) => {
            AccountListFetch(0,list_limit, search_status);
      }
         
      return (
        <div className="accounttList">
           <CustomAlert
              header={"Account"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

                  <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete User"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this account
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    No
                              </Button>
                              <Button onClick={() => {deleteUser(deleteIds)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>


            <div className="pageTop">
                  <h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Account</h2>
                  <div className="right-action">
                        <div className="checkBoxGroup">
                            <div className="checkBoxGroupItem">
                              <input type="radio" id="test1" name="radio-group" value="1" checked={search_status == 1 ? true : false} onChange={() => {setSearchStatus(1); changeListType(1);}}/>
                              <label for="test1">Active Account</label>
                            </div>
                            <div className="checkBoxGroupItem">
                              <input type="radio" id="test2" name="radio-group" value="0" checked={search_status == 0 ? true : false} onChange={() => {setSearchStatus(0); changeListType(0);}}/>
                              <label for="test2">Inactive Account</label>
                            </div>
                            <div className="checkBoxGroupItem">
                              <input type="radio" id="test3" name="radio-group" value="2" checked={search_status == 2 ? true : false} onChange={() => {setSearchStatus(2); changeListType(2);}} />
                              <label for="test3">Close Account</label>
                            </div>
                        </div>
                        <div className="   ">
                          <Link className="fadebtn">Bulk Action</Link>
                          <button type="button" className="fillbtn" onClick={() => {navigate("/dashboard/account/accountprofile", {state: {id: null}})}}><i class="fa fa-plus-circle" aria-hidden="true"></i> Add Customer</button>
                          <Link className="fillbtn"><i className="fa fa-print" aria-hidden="true"></i></Link>
                          <button className="fillbtn" onClick={() => handleClickOpen()}><i className="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                              contactListhead.map((item, index)=>{
                                    return <th key={index}>{item}</th>
                              })
                        }
                        </tr>
                        
                  </thead>
                    {
                       accountList.IsLoading === false && accountList.list.length > 0 ?
                          <tbody>
                            {
                                accountList.list.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                          <td> 
                                                <div className="checkbox">
                                                      <input type="checkbox" class="styled-checkbox user_checkbox" id={'checkbox-user-' + item.id} value={item.id}  onChange={() => CheckSingleOnly('checkbox-user-' + item.id,'user_checkbox')}/>
                                                      <label for={'checkbox-user-' + item.id}></label>
                                                </div>
                                          </td>
                                          <td onClick={() => {navigate("/dashboard/account/overview", {state: {id: item.id}})}}>{item.account_number}</td>
                                          <td onClick={() => {navigate("/dashboard/account/overview", {state: {id: item.id}})}}>{ item.name}</td>
                                          <td onClick={() => {navigate("/dashboard/account/overview", {state: {id: item.id}})}}>{item.phone}</td>
                                          <td onClick={() => {navigate("/dashboard/account/overview", {state: {id: item.id}})}}>{item.active_jobs}</td>
                                          <td onClick={() => {ChangeStatus(index, item.id, item.active)}} className={item.active == 1 ? "active" :"inactive"}>{item.active == 1 ? "Active" :"Inactive"}</td>
                                          <td onClick={() => {navigate("/dashboard/account/overview", {state: {id: item.id}})}} className={item.blance_due == 0 ? "sucess":"reject"}>{item.blance_due}
                                             <span>{item.blance_due_description}</span></td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : accountList.IsLoading === false && accountList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={7} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : accountList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }


                 </table>
               </div>
               <div className="pagination_div">
                        <PaginatedItems itemsPerPage={list_limit} />
               </div>
            </div>
      );
}
export default Account;