import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState, CSSProperties} from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../Service/service";

import $ from 'jquery';

import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
const AddProduct = ()=>{

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [product_id, setProductId] = useState(null);
    const [full_name, setFullName] = useState("");
    const [description, setDescription] = useState("");
    const [product_code, setProductCode] = useState("");
    const [service_id, setServiceId] = useState("");
    const [job_id, setJobId] = useState("");
    const [recurrence, setRecurrence] = useState("");
    const [recurrence_type, setRecurrenceType] = useState("");
    const [year_type, setYearType] = useState("");
    const [product_start_date, setProductStartDate] = useState("");
    const [current_cycle_start_date, setCurrentCycleStartDate] = useState("");
    const [current_cycle_end_date, setCurrentCycleEndDate] = useState("");
    const [service_start_date, setServiceStartDate] = useState("");
    const [service_end_date, setServiceEndDate] = useState("");
    const [fee_type, setFeeType] = useState("");
    const [fee_without_gst, setFeeWithoutGst] = useState("");
    const [no_of_cost, setNoOfCost] = useState(1);
    const [is_additional_cost, setIsAdditionalCost] = useState("");
    const [billing_type, setBillingType] = useState("");
    const [billing_day, setBillingDay] = useState("");
    const [billing_month, setBillingMonth] = useState("");
    const [hsn_no, setHSNNo] = useState("");
    const [tax_rate, setTaxRate] = useState("");
    const [cost_names, setCostName] = useState([{name: ''}]);

    const [no_of_tasks, setNoOfTasks] = useState("");
    const [report_to, setReportTo] = useState("");
   
    const [service_list, setServiceList] = useState([]);
    const [operative_list, setOperativeList] = useState([]);
    const [job_list, setJobList] = useState([]);

    const month_arr = [
                        {id: 1, name: 'January', day: 31}, 
                        {id: 2, name: 'February', day:28}, 
                        {id: 3, name: 'March', day:31},
                        {id: 4, name: 'April', day:30},
                        {id: 5, name: 'May', day:31},
                        {id: 6, name: 'June', day:30},
                        {id: 7, name: 'July', day:31},
                        {id: 8, name: 'August', day:31},
                        {id: 9, name: 'September', day:30},
                        {id: 10, name: 'October', day:31},
                        {id: 11, name: 'November', day:30},
                        {id: 12, name: 'December', day:31},
                    ]
    const days_arr  = [0,31,28,31,30,31,30,31,31,30,31,30,31]              
    const [days, setDays] = useState([]);   
    
    const fetchDay = (index) => {

        setBillingDay("");

        let days_val = days_arr[index];

        for(var i=0; i < days_val; i++){
            days.push({id: (i+1)});
        }

        setDays(days);

    }


    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                DataFetch();
                setProductId(location.state.id);
            }

            ServiceListFetch();
            operativeListFetch();
        }
       
    }, [userToken]);

    const operativeListFetch = () => {
        postWithToken(API_URL, 'operative-list', {}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setOperativeList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const ServiceListFetch = () => {
        postWithToken(API_URL, 'active-services-list', {}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setServiceList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const JobListFetch = (service_id) => {
        postWithToken(API_URL, 'active-jobs-list', {service_id: service_id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setJobList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'products/view', {id: location.state.id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                /*setFullName(result.data.name);
                setShortName(result.data.short_name);
                setJobCode(result.data.job_code);
                setServiceId(result.data.service_id);
                setStatus(result.data.active);
                setRecurrence(result.data.recurrence);
                setRecurrenceType(result.data.recurrence_type);
                setYearType(result.data.year_type);
                setJobStartDate(result.data.start_date);
                setCurrentCycleStartDate(result.data.current_cycle_start_date);
                setCurrentCycleEndDate(result.data.current_cycle_end_date);
                setServiceStartDate(result.data.service_start_date);
                setServiceEndDate(result.data.service_end_date);
                setNoOfTasks(result.data.no_of_tasks);
                setReportTo(result.data.report_to);*/
            
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const addProduct = (event) => {
        event.preventDefault();
    
        let flg = true;
        const all_error_msg = [];

        if(full_name.search(/\S/) == -1){
            all_error_msg.push("Please enter service full name");
            if(flg == true){flg = false;}
        }

        if(product_code.search(/\S/) == -1){
            all_error_msg.push("Please enter job code");
            if(flg == true){flg = false;}
        }else{
            if(product_code.length < 5){
                all_error_msg.push("Please enter 5 alphabets job code");
                if(flg == true){flg = false;}
            }
        }

        if(service_id == ""){
            all_error_msg.push("Please select service");
            if(flg == true){flg = false;}
        }

        if(job_id == ""){
            all_error_msg.push("Please select job ");
            if(flg == true){flg = false;}
        }
          

        if(product_start_date == ""){
            all_error_msg.push("Please select product start date");
            if(flg == true){flg = false;}
        }

        if(product_start_date == ""){
            all_error_msg.push("Please select product start date");
            if(flg == true){flg = false;}
        }

        if(fee_type == ""){
            all_error_msg.push("Please select fee type");
            if(flg == true){flg = false;}
        }

        if(fee_without_gst.search(/\S/) == -1){
            all_error_msg.push("Please enter Basic Rate of Fees");
            if(flg == true){flg = false;}
        }

        if(no_of_cost < 0){
            all_error_msg.push("Please enter number to cost");
            if(flg == true){flg = false;}
        }

        if(is_additional_cost == ""){
            all_error_msg.push("Please select Additional cost be added");
            if(flg == true){flg = false;}
        }

        if(billing_type == ""){
            all_error_msg.push("Please select billing type");
            if(flg == true){flg = false;}
        }

        if(billing_month == ""){
            all_error_msg.push("Please select billing month");
            if(flg == true){flg = false;}
        }

        if(billing_day == ""){
            all_error_msg.push("Please select billing day");
            if(flg == true){flg = false;}
        }

        if(hsn_no.search(/\S/) == -1){
            all_error_msg.push("Please enter HSN No");
            if(flg == true){flg = false;}
        }

        if(tax_rate.search(/\S/) == -1){
            all_error_msg.push("Please enter tex rate");
            if(flg == true){flg = false;}
        }

        if(cost_names.length > 0){
            cost_names.map((item, index) => {
              if(item.name.search(/\S/) == -1){
                all_error_msg.push("Please enter Cost Name ");
                if(flg == true){flg = false;}
              }

            })

          }

    
        if (flg === true) {
          setLoading(true);

          if(product_id == null){
            let post_data = {
                name: full_name,
                description: description,
                product_code: product_code,
                service_id: service_id,
                job_id: job_id,
                recurrence: recurrence,
                recurrence_type: recurrence_type,
                year_type: year_type,
                start_date: product_start_date,
                current_cycle_start_date: current_cycle_start_date,
                current_cycle_end_date: current_cycle_end_date,
                service_start_date: service_start_date,
                service_end_date: service_end_date,
                no_of_tasks: no_of_tasks,
                report_to: report_to,
                fee_type: fee_type,
                fee_without_gst: fee_without_gst,
                no_of_cost: no_of_cost,
                is_additional_cost: is_additional_cost,
                billing_type: billing_type,
                billing_day: billing_day,
                billing_month: billing_month,
                hsn_no: hsn_no,
                tax_rate: tax_rate,
                cost_names: cost_names,
              };
            postWithToken(API_URL, 'products/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/productlist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
           
          }else{
            let post_data = {
                id: product_id,
                name: full_name,
                description: description,
                product_code: product_code,
                service_id: service_id,
                job_id: job_id,
                recurrence: recurrence,
                recurrence_type: recurrence_type,
                year_type: year_type,
                start_date: product_start_date,
                current_cycle_start_date: current_cycle_start_date,
                current_cycle_end_date: current_cycle_end_date,
                service_start_date: service_start_date,
                service_end_date: service_end_date,
                no_of_tasks: no_of_tasks,
                report_to: report_to,
                fee_type: fee_type,
                fee_without_gst: fee_without_gst,
                no_of_cost: no_of_cost,
                is_additional_cost: is_additional_cost,
                billing_type: billing_type,
                billing_day: billing_day,
                billing_month: billing_month,
                hsn_no: hsn_no,
                tax_rate: tax_rate,
                cost_names: cost_names,
              };
            postWithToken(API_URL, 'products/update', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/productlist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
          }
        }else{
            onShowAlert("error", all_error_msg);
        } 
    
    }


    $(function(){
        var todaysDate = new Date();    // Get today's date.

        var year = todaysDate.getFullYear();            // yyyy

        var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2); // mm

        var day = ("0" + todaysDate.getDate()).slice(-2);   // dd

        var dtToday = (year + "-" + month + "-" + day); // Results in yyyy-mm-dd

        //$(".Past input").attr('max', dtToday);

        //$(".Future input").attr('min', dtToday);
   
        $('#product_start_date').attr('min', dtToday);
      });

     
     
      const AddAnyOther = (val) => {

        if(val > 1){

            for(var i=1; i < val; i++ ){
                let arr = {name: ''};
                cost_names.push(arr);
            }

            setCostName(cost_names);

        }else{
            setCostName([{name: ''}]);
        }

     }

     const fetchJobData = (id) => {
        setLoading(true);
        postWithToken(API_URL, 'jobs/view', {id: id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setRecurrence(result.data.recurrence);
                setRecurrenceType(result.data.recurrence_type);
                setYearType(result.data.year_type);
                setCurrentCycleStartDate(result.data.current_cycle_start_date);
                setCurrentCycleEndDate(result.data.current_cycle_end_date);
                setServiceStartDate(result.data.service_start_date);
                setServiceEndDate(result.data.service_end_date);
                setNoOfTasks(result.data.no_of_tasks);
                setReportTo(result.data.report_to);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
     }

     const chageNewValue = (index, val, id) => {
          cost_names[index].name = val;
  
          $('#' + id).val(val);
      }


    return(

        <>
        
        <CustomAlert
              header={"Add Product"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

        <div className="addProduct">
             <div className="pageTop">
                  <h2><i class="fa fa-archive" aria-hidden="true"></i>Add Product</h2>
                  </div>
                  <form onSubmit={addProduct}>
                    <div className="productsec">
                        <h2>Basic Details</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Full Name<sub>*</sub></label>
                                    <input type="text" value={full_name} onChange={(event) => setFullName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Description<sub>*</sub></label>
                                    <input type="text" value={description} onChange={(event) => setDescription(event.target.value)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Code<sub>* CAP Letter 5 Code for Producr </sub></label>
                                    <input type="text" maxLength={5} value={product_code} onChange={(event) => { const re = /^[A-Za-z]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
        setProductCode(event.target.value.toUpperCase());
    }}}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Name<sub>*</sub></label>
                                    <select value={service_id} onChange={(e) => {setServiceId(e.target.value); JobListFetch(e.target.value)}}>
                                        <option value="">Select Service</option>
                                        {
                                            service_list.length > 0 ?

                                            service_list.map((item, key) => {

                                                return(
                                                    <option key={'service_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Job Name</label>
                                    <select value={job_id} onChange={(e) => {setJobId(e.target.value); fetchJobData(e.target.value);}}>
                                        <option value="">Select Job</option>
                                        {
                                            job_list.length > 0 ?

                                            job_list.map((item, key) => {

                                                return(
                                                    <option key={'job_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Recurrence</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Recurrence<sub>*</sub></label>
                                    <select value={recurrence} disabled >
                                       <option key={'blank'} value="">Select Recurrence</option>
                                        <option key={'Recurring'} value="0">Recurring</option>
                                        <option  key={'Single'} value="1">Single</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Recurrence Type</label>
                                    <select value={recurrence_type} disabled >
                                       <option key={'blank_re_type'} value="">Select Recurrence Type</option>
                                        <option key={'Monthly'} value="1">Monthly</option>
                                        <option  key={'Quarterly'} value="2">Quarterly</option>
                                        <option key={'Half'} value="3">Half Yearly</option>
                                        <option  key={'Yearly'} value="4">Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Year Type</label>
                                    <select value={year_type} disabled >
                                       <option key={'blank_year'} value="">Select Year Type</option>
                                        <option key={'Financial'} value="1">Financial Year</option>
                                        <option  key={'Assessment'} value="2">Assessment Year</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Product Start date </label>
                                    <input type="date" id="product_start_date" value={product_start_date} onChange={(event) => setProductStartDate(event.target.value)} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Current Start date </label>
                                    <input type="date" id="current_cycle_start_date" readOnly value={current_cycle_start_date} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Current End date </label>
                                    <input type="date" id="current_cycle_end_date" readOnly value={current_cycle_end_date} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Start date  </label>
                                    <input type="date" id="service_start_date" readOnly value={service_start_date} />

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Due date  </label>
                                    <input type="date" id="service_end_date" readOnly value={service_end_date} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Fees</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Fees Type</label>
                                     <select value={fee_type} onChange={(e) => {setFeeType(e.target.value)}}>
                                        <option key={'fees'} value="">Select Fees Type</option>
                                        <option key={'fiexed'} value="1">Fiexed</option>
                                        <option key={'on_product_cost'} value="2">On Product Cose</option>
                                     </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Basic Rate of Fees (Without GST){fee_type == 2 ? "(%)" : null}</label>
                                     <input type="text" value={fee_without_gst} onKeyDown={(event) => {if (/^[0-9]*\.?[0-9]*$/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => setFeeWithoutGst(event.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Cost</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>No of Cost</label>
                                    <input type="text" value={no_of_cost} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => {setNoOfCost(event.target.value); AddAnyOther(event.target.value)}}/>
                                   
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label>Additional cost be added</label>
                                    <select value={is_additional_cost} onChange={(e) => {setIsAdditionalCost(e.target.value)}}>
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                     </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                   
                                </div>
                            </div>

                            {
                                no_of_cost > 0 ?

                                cost_names.map((item, index) => {

                                        return(
                                         <div className="col-4">
                                            <div className="form-group">
                                                <label>Cost Name {index + 1}</label>
                                                <input type="text" id={'cost_name_' + index} onChange={(event) => {chageNewValue(index, event.target.value, 'cost_name_' + index)}}/>
                                            </div>
                                        </div>
                                        );
                                })

                                : null
                            }
                            
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Billing</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Billing Type</label>
                                    <select value={billing_type} onChange={(event) => setBillingType(event.target.value)}>
                                        <option value="">Select</option>
                                        <option value="1">Automatic</option>
                                        <option value="2">Manual</option>
                                     </select>

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Billing Date</label>
                                    <select value={billing_month} onChange={(event) => {setBillingMonth(event.target.value); fetchDay(event.target.value)}}>
                                        <option value="">Select</option>
                                        {
                                            month_arr.map((item, index) => {

                                                return(
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })
                                            
                                        }
                                    </select>
                                   
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Billing Day</label>
                                   
                                    <select value={billing_day} onChange={(event) => {setBillingDay(event.target.value)}}>
                                        <option value="">Select</option>
                                        {
                                           days.map((item, index) => {

                                            return(
                                                <option value={item.id}>{item.id}</option>
                                            )
                                        }) 
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>HSN Code</label>
                                    <input type="text" maxLength={8} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => setHSNNo(event.target.value)}/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="productsec">
                        <h2>Task</h2>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Tax Rate</label>
                                    <input type="text" onKeyDown={(event) => {if (/^[0-9]*\.?[0-9]*$/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => setTaxRate(event.target.value)} />
                                   
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Number of Primary Tasks</label>
                                    <input type="text" readOnly value={no_of_tasks}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Task </label>
                                    <input type="text"  />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Report To </label>
                                    <select value={report_to} disabled >
                                        <option value="">Select</option>
                                        {
                                            operative_list.length > 0 ?

                                            operative_list.map((item, key) => {

                                                return(
                                                    <option key={'service_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btnGroup">
                                    <button type="button" className="fadebtn" onClick={() => {navigate("/dashboard/productlist")}}>Cancel</button>
                                    <button type="submit" className="fillbtn">Add</button>
                                    {/*<button type="button" className="fillbtn">Add Product</button>
                                    <button type="button" className="fillbtn">Add Document</button>
                        <button type="button" className="fillbtn">Add Group</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                  </form>
        </div>
       </> 
    )
}
export default AddProduct;