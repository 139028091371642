import search from "../../assets/images/search.svg";
import notification from "../../assets/images/notification.svg";
import arrowdown from "../../assets/images/arrow-down.svg";
import user from "../../assets/images/profile.png";
import { Component, useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useNavigate} from "react-router-dom";
import { setUserDataAfterLogin, clearUserData } from "../../Store/Reducers/CommonReducer";
 

function Header (){
const navigate = useNavigate();
const dispatch = useDispatch();
const { API_URL, userData, userToken} = useSelector(state => state.common);
const newRef = useRef();
const [open, setOpen] = useState(false);

useEffect(() => {
    if(sessionStorage.getItem("userId") == null){
        navigate("/login");
    }
    console.log(userToken);
    console.log(userData);
},[userData]);


useEffect(() => {
    let hander =(event)=>{
        if(newRef.current.contains(event.target)){
           console.log("ok");
           setOpen(true)
        }
        if(!newRef.current.contains(event.target)){
            setOpen(false)
        }
    }
  document.addEventListener("mousedown", hander)
   return ()=> document.removeEventListener("mousedown",hander)

},[]);

function globalSearch(event){
    event.preventdefault()
}

const  goToLogout = () => {
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("userId");
    dispatch(clearUserData());
}
  
    return(
        <header className="header">
          {console.log(open)}
            <ul className="navigation">
                <li><Link>Dashboard</Link></li>
            </ul>
            <div className="rightSec">
                <form onSubmit={globalSearch}>
                    <input type="text" placeholder="Search"/>
                    <button type="Submit">
                        <img src={search} />
                    </button>
                </form>
                <div className="notification">
                    <Link>
                    <img src={notification} />
                    <span>01</span>
                    </Link>
                </div>
                 <div className="user">
                        <div className="usericon">
                            {
                               userData != null ?
                               
                               userData.profile_image != null ?
                                <img src={userData.profile_image}/>
                               :
                               <img src={user}/>
                               :null

                            }
                            
                        </div>
                        <div className="userdetails" >
                          
                            <label>{userData != null ? userData.first_name :  null}</label>
                        <Link className="action"  onClick={()=>{setOpen(!open
                        )}}>
                     <img src={arrowdown} />
                     <ul className={open? "open":""} ref={newRef}>
                        <li >
                            <Link><i className="fa fa-user-o" aria-hidden="true"></i>Profile</Link>
                        </li>
                        <li >
                            <Link><i className="fa fa-bell-o" aria-hidden="true"></i>Notification</Link>
                        </li>
                        <li >
                        <Link><i className="fa fa-cog" aria-hidden="true"></i>Setting</Link>
                        </li>
                        <li>
                        <Link onClick={() => goToLogout()}><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</Link>
                        </li>
                       
                    </ul>
                    </Link>
                        </div>
                    </div>
            </div>
        </header>
    )
  }
export default Header