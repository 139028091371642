
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';

function EditRoles(props){

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
  
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [accessList, setAccessList] = useState({IsLoading : true, list: []});
    const [reportList, setReportList] = useState({IsLoading : true, list: []});
    const [role_data, setRoleData] = useState({IsLoading: true, list: []});
    const [role_name, setRoleName] = useState("");
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(1);
    const [access_arr, setAccessArr] = useState([]);
    const [report_arr, setReportArr] = useState([]);

    const [access_dashboardList, setAccessDashbaordList] = useState({IsLoading : true, list: []});
    const [access_work_stationList, setAccessWorkStationList] = useState({IsLoading : true, list: []});
    const [access_customerList, setAccessCustomerList] = useState({IsLoading : true, list: []});
    const [access_user_accountList, setAccessUserAccountList] = useState({IsLoading : true, list: []});
    const [access_serviceList, setAccessServiceList] = useState({IsLoading : true, list: []});
    const [access_documentsList, setAccessDocumentsList] = useState({IsLoading : true, list: []});
    const [access_historyList, setAccessHistoryList] = useState({IsLoading : true, list: []});
    const [access_interactionList, setAccessInteractionList] = useState({IsLoading : true, list: []});
    const [access_settingsList, setAccessSettingsList] = useState({IsLoading : true, list: []});
    const [access_transactionList, setAccessTransactionList] = useState({IsLoading : true, list: []});

    const [report_company_businessList, setReportCompanyBusinessList] = useState({IsLoading : true, list: []});
    const [report_customer_statementList, setReportCustomerStatementList] = useState({IsLoading : true, list: []});
    const [report_document_reportsList, setReportDocumentReportsList] = useState({IsLoading : true, list: []});
    const [report_history_reportsList, setReportHistoryReportsList] = useState({IsLoading : true, list: []});
    const [report_interaction_statementList, setReportInteractionStatementList] = useState({IsLoading : true, list: []});
    const [report_service_statementList, setReportServiceStatementList] = useState({IsLoading : true, list: []});
    const [report_transaction_reportsList, setReportTransactionReportsList] = useState({IsLoading : true, list: []});


    
    useEffect(() => {
        /*setTimeout(() => PermissionListFetch(), 5000);*/
        if(userToken != ''){
            PermissionListFetch();
            DataFetch();
        }
    }, [userToken]);

    useEffect(() => {
        if(role_data.IsLoading == false){
            //console.log(role_data.list);
            if(role_data.list.permissions.length > 0){
                role_data.list.permissions.map((per_item,per_index) => {
                        $("." + per_item.name).prop("checked", true);
                })
            }
        }
    }, [role_data]);

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'roles/view', {id: location.state.id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setRoleData({IsLoading : false, list: result.data});
                setRoleName(result.data.name);
                if(result.data.description != null){
                    setDescription(result.data.description);
                }
                setStatus(result.data.active);

            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const PermissionListFetch = () => {
      
        postWithToken(API_URL, 'permissions-list', {}, userToken).then((result) => {
            if(result.status === true){

                setAccessDashbaordList({IsLoading : false, list: result.data.access[0].dashboard});
                setAccessWorkStationList({IsLoading : false, list: result.data.access[0].work_station});
                setAccessCustomerList({IsLoading : false, list: result.data.access[0].customer});
                setAccessUserAccountList({IsLoading : false, list: result.data.access[0].user_account});
                setAccessServiceList({IsLoading : false, list: result.data.access[0].service});
                setAccessDocumentsList({IsLoading : false, list: result.data.access[0].documents});
                setAccessHistoryList({IsLoading : false, list: result.data.access[0].history});
                setAccessInteractionList({IsLoading : false, list: result.data.access[0].interaction});
                setAccessSettingsList({IsLoading : false, list: result.data.access[0].settings});
                setAccessTransactionList({IsLoading : false, list: result.data.access[0].transaction});

                setReportCompanyBusinessList({IsLoading : false, list: result.data.report[0].company_business});
                setReportCustomerStatementList({IsLoading : false, list: result.data.report[0].customer_statement});
                setReportDocumentReportsList({IsLoading : false, list: result.data.report[0].document_reports});
                setReportHistoryReportsList({IsLoading : false, list: result.data.report[0].history_reports});
                setReportInteractionStatementList({IsLoading : false, list: result.data.report[0].interaction_statement});
                setReportServiceStatementList({IsLoading : false, list: result.data.report[0].service_statement});
                setReportTransactionReportsList({IsLoading : false, list: result.data.report[0].transaction_reports});

            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const accessCheckAll = (id,checkbox_class) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            $("." + checkbox_class).prop("checked", true);
        }else{
            $("." + checkbox_class).prop("checked", false);
        }
    }

    const accessCheckAllFull = (id,checkbox_class_arr, th_id_arr) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            checkbox_class_arr.map((item) => {
                $("." + item).prop("checked", true);
            })
            th_id_arr.map((item) => {
                $("#" + item).prop("checked", true);
            })
        }else{
            checkbox_class_arr.map((item) => {
                $("." + item).prop("checked", false);
            })

            th_id_arr.map((item) => {
                $("#" + item).prop("checked", false);
            })
        }
    }

    const checkedRow = (id, id_arr, th_arr) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            id_arr.map((item) => {
                $("#" + item).prop("checked", true);
            })
        }else{
            id_arr.map((item) => {
                $("#" + item).prop("checked", false);
            });
            th_arr.map((item) => {
                $("#" + item).prop("checked", false);
            });
        }
    }

    const singleChecked = (id, th_arr, full_id, id_arr) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            let anyonefalse = false;

            id_arr.map((item) => {
                let checkbox_status = $('#' + item).is(":checked");

                if(checkbox_status === true){

                }else{
                    anyonefalse = true;
                }
            })

            if(anyonefalse === false){
                $("#" + full_id).prop("checked", true);
            }
        }else{

            $("#" + full_id).prop("checked", false);
           
            th_arr.map((item) => {
                $("#" + item).prop("checked", false);
            })
        }
    }

    const EditRole = (event) => {
        event.preventDefault();
        
        let flg = true;
        const all_error_msg = [];
    
         if(role_name.search(/\S/) == -1){
          all_error_msg.push("Please enter role name");
          if(flg == true){flg = false;}
        }

        /*if(description.search(/\S/) == -1){
            all_error_msg.push("Please enter role description");
            if(flg == true){flg = false;}
        }*/

        let access_selected_arr = [];

        let checkbox_all_access_length = $('.access_checkbox:checked').length;

        if(checkbox_all_access_length > 0){
            let i = 0;
            $('.access_checkbox:checked').each(function(i){
                //access_selected_arr[i] = $(this).val();
                access_selected_arr.push($(this).val());
            });
        }

        let checkbox_all_report_length = $('.report_checkbox:checked').length;

        if(checkbox_all_report_length > 0){
            $('.report_checkbox:checked').each(function(i){
                //access_selected_arr[i] = $(this).val();
                access_selected_arr.push($(this).val());
            });
        }

        //console.log(access_selected_arr);
    
        if (flg === true) {
          setLoading(true);
          postWithToken(API_URL, 'roles/update', {id: location.state.id,name: role_name, description: description, active: status, permission_list: access_selected_arr}, userToken).then((result) => {
              if(result.status === true){
                    setLoading(false);
                    navigate("/dashboard/role");
                }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
                  setLoading(false);
                }
            })
            .catch((error) => {
              console.log("Error : ", error);
            });
          }else{
            onShowAlert("error", all_error_msg);
            
          } 
      }


      return (
      
      <div className="contactList">
            <CustomAlert
              header={"Role"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }



            <div className="pageTop">
                  <h2><i class="fa fa-user-circle-o" aria-hidden="true"></i>Edit Roll</h2>
            </div>
            <form onSubmit={EditRole}>
            <div className="foleform">
                    <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <label>Roll Name</label>
                            <input type="text" placeholder="" value={role_name} onChange={(e) => {setRoleName(e.target.value)}}/>
                          </div>
                          <div className="form-group">
                            <label>Status</label>
                            <select onChange={(e) => {setStatus(e.target.value)}}>
                                <option value="1" selected={status == 1 ? 'selected' : ''}>Active</option>
                                <option  value="0" selected={status == 0 ? 'selected' : ''}>Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-8">
                        <div className="form-group">
                        <label>Description</label>
                        <textarea onChange={(e) => {setDescription(e.target.value)}} value={description} />
                        </div> 
                        </div>
                    </div>
            </div>
            <div className="accesstable">
                <h2>Access</h2>
                <h3>Dashboard</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-dashboard-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-dashboard-1', ['access_dashboard_full', 'access_dashboard_view', 'access_dashboard_operate'], ['styled-checkbox-access-dashboard-2', 'styled-checkbox-access-dashboard-3'])}/>
                                        <label for="styled-checkbox-access-dashboard-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-dashboard-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-dashboard-2','access_dashboard_view')}/>
                                        <label for="styled-checkbox-access-dashboard-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Operate
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-dashboard-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-dashboard-3','access_dashboard_operate')}/>
                                        <label for="styled-checkbox-access-dashboard-3"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_dashboardList.IsLoading === false && access_dashboardList.list.length > 0 ?
                          <tbody>
                            {
                                access_dashboardList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_dashboard_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_dashboard_full ' + item.full} onChange={() => checkedRow('access-dashboard-' + index + '-' + item.full, ['access-dashboard-' + index + '-' + item.view, 'access-dashboard-' + index + '-' + item.operate], ['styled-checkbox-access-dashboard-1','styled-checkbox-access-dashboard-2', 'styled-checkbox-access-dashboard-3'])} id={'access-dashboard-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-dashboard-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_dashboard_view ' + item.view} id={'access-dashboard-' + index + '-' + item.view} onChange={() => singleChecked('access-dashboard-' + index + '-' + item.view, ['styled-checkbox-access-dashboard-1','styled-checkbox-access-dashboard-2'], 'access-dashboard-' + index + '-' + item.full, ['access-dashboard-' + index + '-' + item.view, 'access-dashboard-' + index + '-' + item.operate])}  type="checkbox" value={item.view}/>
                                                    <label for={'access-dashboard-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_dashboard_operate ' + item.operate} id={'access-dashboard-' + index + '-' + item.operate} onChange={() => singleChecked('access-dashboard-' + index + '-' + item.operate, ['styled-checkbox-access-dashboard-1','styled-checkbox-access-dashboard-3'], 'access-dashboard-' + index + '-' + item.full, ['access-dashboard-' + index + '-' + item.view, 'access-dashboard-' + index + '-' + item.operate])} type="checkbox" value={item.operate}/>
                                                    <label for={'access-dashboard-' + index + '-' + item.operate}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_dashboardList.IsLoading === false && access_dashboardList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : access_dashboardList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                
                                     <td ><Skeleton height={24} /></td>
                               
                                    <td ><Skeleton height={24} /></td>

                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                                        
                 
                 </table>
               </div>
               <h3>Work Station</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-work-station-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-work-station-1',['access_work_station_full', 'access_work_station_view', 'access_work_station_operate'], ["styled-checkbox-access-work-station-2", "styled-checkbox-access-work-station-3"])}/>
                                        <label for="styled-checkbox-access-work-station-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-work-station-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-work-station-2','access_work_station_view')}/>
                                        <label for="styled-checkbox-access-work-station-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Operate
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-work-station-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-work-station-3','access_work_station_operate')}/>
                                        <label for="styled-checkbox-access-work-station-3"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_work_stationList.IsLoading === false && access_work_stationList.list.length > 0 ?
                          <tbody>
                            {
                                access_work_stationList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_work_station_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_work_station_full ' + item.full} onChange={() => checkedRow('access-work-station-' + index + '-' + item.full, ['access-work-station-' + index + '-' + item.view, 'access-work-station-' + index + '-' + item.operate], ["styled-checkbox-access-work-station-1", "styled-checkbox-access-work-station-2", "styled-checkbox-access-work-station-3"])} id={'access-work-station-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-work-station-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_work_station_view ' + item.view} id={'access-work-station-' + index + '-' + item.view} onChange={() => singleChecked('access-work-station-' + index + '-' + item.view, ["styled-checkbox-access-work-station-1", "styled-checkbox-access-work-station-2"], 'access-work-station-' + index + '-' + item.full, ['access-work-station-' + index + '-' + item.view, 'access-work-station-' + index + '-' + item.operate])} type="checkbox" value={item.view}/>
                                                    <label for={'access-work-station-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_work_station_operate ' + item.operate} id={'access-work-station-' + index + '-' + item.operate} onChange={() => singleChecked('access-work-station-' + index + '-' + item.operate, ["styled-checkbox-access-work-station-1", "styled-checkbox-access-work-station-3"], 'access-work-station-' + index + '-' + item.full, ['access-work-station-' + index + '-' + item.view, 'access-work-station-' + index + '-' + item.operate])} type="checkbox" value={item.operate}/>
                                                    <label for={'access-work-station-' + index + '-' + item.operate}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_work_stationList.IsLoading === false && access_work_stationList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_work_stationList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Customers</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-customers-1',['access_customers_full', 'access_customers_view', 'access_customers_create', 'access_customers_edit', 'access_customers_delete', 'access_customers_export'], ['styled-checkbox-access-customers-2', 'styled-checkbox-access-customers-3', 'styled-checkbox-access-customers-4', 'styled-checkbox-access-customers-5', 'styled-checkbox-access-customers-6'])}/>
                                        <label for="styled-checkbox-access-customers-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-customers-2','access_customers_view')}/>
                                        <label for="styled-checkbox-access-customers-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-customers-3','access_customers_create')}/>
                                        <label for="styled-checkbox-access-customers-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-customers-4','access_customers_edit')}/>
                                        <label for="styled-checkbox-access-customers-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-customers-5','access_customers_delete')}/>
                                        <label for="styled-checkbox-access-customers-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-customers-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-customers-6','access_customers_export')}/>
                                        <label for="styled-checkbox-access-customers-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_customerList.IsLoading === false && access_customerList.list.length > 0 ?
                          <tbody>
                            {
                                access_customerList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_customers_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_full ' + item.full} onChange={() => checkedRow('access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export], ['styled-checkbox-access-customers-1', 'styled-checkbox-access-customers-2', 'styled-checkbox-access-customers-3', 'styled-checkbox-access-customers-4', 'styled-checkbox-access-customers-5', 'styled-checkbox-access-customers-6'])}  id={'access-customer-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-customer-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_view ' + item.view} id={'access-customer-' + index + '-' + item.view} onChange={() => singleChecked('access-customer-' + index + '-' + item.view, ['styled-checkbox-access-customers-1','styled-checkbox-access-customers-2'], 'access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-customer-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_create ' + item.create} id={'access-customer-' + index + '-' + item.create} onChange={() => singleChecked('access-customer-' + index + '-' + item.create, ['styled-checkbox-access-customers-1','styled-checkbox-access-customers-3'], 'access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-customer-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_edit ' + item.edit} id={'access-customer-' + index + '-' + item.edit} onChange={() => singleChecked('access-customer-' + index + '-' + item.edit, ['styled-checkbox-access-customers-1','styled-checkbox-access-customers-4'], 'access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-customer-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_delete ' + item.delete} id={'access-customer-' + index + '-' + item.delete} onChange={() => singleChecked('access-customer-' + index + '-' + item.delete, ['styled-checkbox-access-customers-1','styled-checkbox-access-customers-5'], 'access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-customer-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_customers_export ' + item.export} id={'access-customer-' + index + '-' + item.export} onChange={() => singleChecked('access-customer-' + index + '-' + item.export, ['styled-checkbox-access-customers-1','styled-checkbox-access-customers-6'], 'access-customer-' + index + '-' + item.full, ['access-customer-' + index + '-' + item.view, 'access-customer-' + index + '-' + item.create, 'access-customer-' + index + '-' + item.edit, 'access-customer-' + index + '-' + item.delete, 'access-customer-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-customer-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_customerList.IsLoading === false && access_customerList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_customerList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>User Account</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-user-account-1',['access_user_account_full', 'access_user_account_view', 'access_user_account_create', 'access_user_account_edit', 'access_user_account_delete', 'access_user_account_export'], ['styled-checkbox-access-user-account-2', 'styled-checkbox-access-user-account-3', 'styled-checkbox-access-user-account-4', 'styled-checkbox-access-user-account-5', 'styled-checkbox-access-user-account-6'])}/>
                                        <label for="styled-checkbox-access-user-account-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-user-account-2','access_user_account_view')}/>
                                        <label for="styled-checkbox-access-user-account-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-user-account-3','access_user_account_create')}/>
                                        <label for="styled-checkbox-access-user-account-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-user-account-4','access_user_account_edit')}/>
                                        <label for="styled-checkbox-access-user-account-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-user-account-5','access_user_account_delete')}/>
                                        <label for="styled-checkbox-access-user-account-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-user-account-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-user-account-6','access_user_account_export')}/>
                                        <label for="styled-checkbox-access-user-account-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_user_accountList.IsLoading === false && access_user_accountList.list.length > 0 ?
                          <tbody>
                            {
                                access_user_accountList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_user_account_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_full ' + item.full} onChange={() => checkedRow('access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export], ['styled-checkbox-access-user-account-1', 'styled-checkbox-access-user-account-2', 'styled-checkbox-access-user-account-3', 'styled-checkbox-access-user-account-4', 'styled-checkbox-access-user-account-5', 'styled-checkbox-access-user-account-6'])} id={'access-user-account-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-user-account-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_view ' + item.view} id={'access-user-account-' + index + '-' + item.view} onChange={() => singleChecked('access-user-account-' + index + '-' + item.view, ['styled-checkbox-access-user-account-1','styled-checkbox-access-user-account-2'], 'access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-user-account-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_create ' + item.create} id={'access-user-account-' + index + '-' + item.create} onChange={() => singleChecked('access-user-account-' + index + '-' + item.create, ['styled-checkbox-access-user-account-1','styled-checkbox-access-user-account-3'], 'access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-user-account-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_edit ' + item.edit} id={'access-user-account-' + index + '-' + item.edit} onChange={() => singleChecked('access-user-account-' + index + '-' + item.edit, ['styled-checkbox-access-user-account-1','styled-checkbox-access-user-account-4'], 'access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-user-account-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_delete ' + item.delete}  id={'access-user-account-' + index + '-' + item.delete} onChange={() => singleChecked('access-user-account-' + index + '-' + item.delete, ['styled-checkbox-access-user-account-1','styled-checkbox-access-user-account-5'], 'access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-user-account-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_user_account_export ' + item.export} id={'access-user-account-' + index + '-' + item.export} onChange={() => singleChecked('access-user-account-' + index + '-' + item.export, ['styled-checkbox-access-user-account-1','styled-checkbox-access-user-account-6'], 'access-user-account-' + index + '-' + item.full, ['access-user-account-' + index + '-' + item.view, 'access-user-account-' + index + '-' + item.create, 'access-user-account-' + index + '-' + item.edit, 'access-user-account-' + index + '-' + item.delete, 'access-user-account-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-user-account-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_user_accountList.IsLoading === false && access_user_accountList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_user_accountList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Service</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-service-1',['access_service_full', 'access_service_view', 'access_service_create', 'access_service_edit', 'access_service_delete', 'access_service_export'], ['styled-checkbox-access-service-2', 'styled-checkbox-access-service-3', 'styled-checkbox-access-service-4', 'styled-checkbox-access-service-5', 'styled-checkbox-access-service-6'])}/>
                                        <label for="styled-checkbox-access-service-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-service-2','access_service_view')}/>
                                        <label for="styled-checkbox-access-service-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-service-3','access_service_create')}/>
                                        <label for="styled-checkbox-access-service-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-service-4','access_service_edit')}/>
                                        <label for="styled-checkbox-access-service-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-service-5','access_service_delete')}/>
                                        <label for="styled-checkbox-access-service-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-service-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-service-6','access_service_export')}/>
                                        <label for="styled-checkbox-access-service-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_serviceList.IsLoading === false && access_serviceList.list.length > 0 ?
                          <tbody>
                            {
                                access_serviceList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_service_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_full ' + item.full} onChange={() => checkedRow('access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export], ['styled-checkbox-access-service-1', 'styled-checkbox-access-service-2', 'styled-checkbox-access-service-3', 'styled-checkbox-access-service-4', 'styled-checkbox-access-service-5', 'styled-checkbox-access-service-6'])} id={'access-service-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-service-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_view ' + item.view} id={'access-service-' + index + '-' + item.view}  onChange={() => singleChecked('access-service-' + index + '-' + item.view, ['styled-checkbox-access-service-1','styled-checkbox-access-service-2'], 'access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-service-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_create ' + item.create} id={'access-service-' + index + '-' + item.create} onChange={() => singleChecked('access-service-' + index + '-' + item.create, ['styled-checkbox-access-service-1','styled-checkbox-access-service-3'], 'access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-service-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_edit ' + item.edit} id={'access-service-' + index + '-' + item.edit} onChange={() => singleChecked('access-service-' + index + '-' + item.edit, ['styled-checkbox-access-service-1','styled-checkbox-access-service-4'], 'access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-service-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_delete ' + item.delete} id={'access-service-' + index + '-' + item.delete} onChange={() => singleChecked('access-service-' + index + '-' + item.delete, ['styled-checkbox-access-service-1','styled-checkbox-access-service-5'], 'access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-service-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_service_export ' + item.export} id={'access-service-' + index + '-' + item.export} onChange={() => singleChecked('access-service-' + index + '-' + item.export, ['styled-checkbox-access-service-1','styled-checkbox-access-service-6'], 'access-service-' + index + '-' + item.full, ['access-service-' + index + '-' + item.view, 'access-service-' + index + '-' + item.create, 'access-service-' + index + '-' + item.edit, 'access-service-' + index + '-' + item.delete, 'access-service-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-service-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_serviceList.IsLoading === false && access_serviceList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_serviceList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Transaction</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-transaction-1',['access_transaction_full', 'access_transaction_view', 'access_transaction_create', 'access_transaction_edit', 'access_transaction_delete', 'access_transaction_export'], ['styled-checkbox-access-transaction-2', 'styled-checkbox-access-transaction-3', 'styled-checkbox-access-transaction-4', 'styled-checkbox-access-transaction-5', 'styled-checkbox-access-transaction-6'])}/>
                                        <label for="styled-checkbox-access-transaction-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-transaction-2','access_transaction_view')}/>
                                        <label for="styled-checkbox-access-transaction-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-transaction-3','access_transaction_create')}/>
                                        <label for="styled-checkbox-access-transaction-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-transaction-4','access_transaction_edit')}/>
                                        <label for="styled-checkbox-access-transaction-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-transaction-5','access_transaction_delete')}/>
                                        <label for="styled-checkbox-access-transaction-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-transaction-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-transaction-6','access_transaction_export')}/>
                                        <label for="styled-checkbox-access-transaction-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_transactionList.IsLoading === false && access_transactionList.list.length > 0 ?
                          <tbody>
                            {
                                access_transactionList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_transaction_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_full ' + item.full} onChange={() => checkedRow('access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export], ['styled-checkbox-access-transaction-1', 'styled-checkbox-access-transaction-2', 'styled-checkbox-access-transaction-3', 'styled-checkbox-access-transaction-4', 'styled-checkbox-access-transaction-5', 'styled-checkbox-access-transaction-6'])} id={'access-transaction-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-transaction-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_view ' + item.view} id={'access-transaction-' + index + '-' + item.view} onChange={() => singleChecked('access-transaction-' + index + '-' + item.view, ['styled-checkbox-access-transaction-1','styled-checkbox-access-transaction-2'], 'access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-transaction-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_create ' + item.create} id={'access-transaction-' + index + '-' + item.create} onChange={() => singleChecked('access-transaction-' + index + '-' + item.create, ['styled-checkbox-access-transaction-1','styled-checkbox-access-transaction-3'], 'access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-transaction-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_edit ' + item.edit} id={'access-transaction-' + index + '-' + item.edit} onChange={() => singleChecked('access-transaction-' + index + '-' + item.edit, ['styled-checkbox-access-transaction-1','styled-checkbox-access-transaction-4'], 'access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-transaction-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_delete ' + item.delete} id={'access-transaction-' + index + '-' + item.delete} onChange={() => singleChecked('access-transaction-' + index + '-' + item.delete, ['styled-checkbox-access-transaction-1','styled-checkbox-access-transaction-5'], 'access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-transaction-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_transaction_export ' + item.export} id={'access-transaction-' + index + '-' + item.export} onChange={() => singleChecked('access-transaction-' + index + '-' + item.export, ['styled-checkbox-access-transaction-1','styled-checkbox-access-transaction-6'], 'access-transaction-' + index + '-' + item.full, ['access-transaction-' + index + '-' + item.view, 'access-transaction-' + index + '-' + item.create, 'access-transaction-' + index + '-' + item.edit, 'access-transaction-' + index + '-' + item.delete, 'access-transaction-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-transaction-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_transactionList.IsLoading === false && access_transactionList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_transactionList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Documents</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-documents-1',['access_documents_full', 'access_documents_view', 'access_documents_create', 'access_documents_edit', 'access_documents_delete', 'access_documents_export'], ['styled-checkbox-access-documents-2', 'styled-checkbox-access-documents-3', 'styled-checkbox-access-documents-4', 'styled-checkbox-access-documents-5', 'styled-checkbox-access-documents-6'])}/>
                                        <label for="styled-checkbox-access-documents-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-documents-2','access_documents_view')}/>
                                        <label for="styled-checkbox-access-documents-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-documents-3','access_documents_create')}/>
                                        <label for="styled-checkbox-access-documents-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-documents-4','access_documents_edit')}/>
                                        <label for="styled-checkbox-access-documents-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-documents-5','access_documents_delete')}/>
                                        <label for="styled-checkbox-access-documents-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-documents-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-documents-6','access_documents_export')}/>
                                        <label for="styled-checkbox-access-documents-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_documentsList.IsLoading === false && access_documentsList.list.length > 0 ?
                          <tbody>
                            {
                                access_documentsList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_documents_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_full ' + item.full} onChange={() => checkedRow('access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export], ['styled-checkbox-access-documents-1', 'styled-checkbox-access-documents-2', 'styled-checkbox-access-documents-3', 'styled-checkbox-access-documents-4', 'styled-checkbox-access-documents-5', 'styled-checkbox-access-documents-6'])} id={'access-documents-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-documents-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_view ' + item.view}  id={'access-documents-' + index + '-' + item.view} onChange={() => singleChecked('access-documents-' + index + '-' + item.view, ['styled-checkbox-access-documents-1','styled-checkbox-access-documents-2'], 'access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-documents-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_create ' + item.create} id={'access-documents-' + index + '-' + item.create} onChange={() => singleChecked('access-documents-' + index + '-' + item.create, ['styled-checkbox-access-documents-1','styled-checkbox-access-documents-3'], 'access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-documents-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_edit ' + item.edit} id={'access-documents-' + index + '-' + item.edit} onChange={() => singleChecked('access-documents-' + index + '-' + item.edit, ['styled-checkbox-access-documents-1','styled-checkbox-access-documents-4'], 'access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-documents-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_delete ' + item.delete} id={'access-documents-' + index + '-' + item.delete} onChange={() => singleChecked('access-documents-' + index + '-' + item.delete, ['styled-checkbox-access-documents-1','styled-checkbox-access-documents-5'], 'access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-documents-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_documents_export ' + item.export} id={'access-documents-' + index + '-' + item.export} onChange={() => singleChecked('access-documents-' + index + '-' + item.export, ['styled-checkbox-access-documents-1','styled-checkbox-access-documents-5'], 'access-documents-' + index + '-' + item.full, ['access-documents-' + index + '-' + item.view, 'access-documents-' + index + '-' + item.create, 'access-documents-' + index + '-' + item.edit, 'access-documents-' + index + '-' + item.delete, 'access-documents-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-documents-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_documentsList.IsLoading === false && access_documentsList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_documentsList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Interaction</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-interaction-1',['access_interaction_full', 'access_interaction_view', 'access_interaction_create', 'access_interaction_edit', 'access_interaction_delete', 'access_interaction_export'],['styled-checkbox-access-interaction-2', 'styled-checkbox-access-interaction-3', 'styled-checkbox-access-interaction-4', 'styled-checkbox-access-interaction-5', 'styled-checkbox-access-interaction-6'])}/>
                                        <label for="styled-checkbox-access-interaction-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-interaction-2','access_interaction_view')}/>
                                        <label for="styled-checkbox-access-interaction-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-interaction-3','access_interaction_create')}/>
                                        <label for="styled-checkbox-access-interaction-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-interaction-4','access_interaction_edit')}/>
                                        <label for="styled-checkbox-access-interaction-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-interaction-5','access_interaction_delete')}/>
                                        <label for="styled-checkbox-access-interaction-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-interaction-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-interaction-6','access_interaction_export')}/>
                                        <label for="styled-checkbox-access-interaction-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_interactionList.IsLoading === false && access_interactionList.list.length > 0 ?
                          <tbody>
                            {
                                access_interactionList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_interaction_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_full ' + item.full} onChange={() => checkedRow('access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delete, 'access-interaction-' + index + '-' + item.export],['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-2', 'styled-checkbox-access-interaction-3', 'styled-checkbox-access-interaction-4', 'styled-checkbox-access-interaction-5', 'styled-checkbox-access-interaction-6'])} id={'access-interaction-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-interaction-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_view ' + item.view} id={'access-interaction-' + index + '-' + item.view} onChange={() => singleChecked('access-interaction-' + index + '-' + item.view, ['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-2'], 'access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delet, 'access-interaction-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-interaction-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_create ' + item.create} id={'access-interaction-' + index + '-' + item.create} onChange={() => singleChecked('access-interaction-' + index + '-' + item.create, ['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-3'], 'access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delet, 'access-interaction-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-interaction-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_edit ' + item.edit} id={'access-interaction-' + index + '-' + item.edit} onChange={() => singleChecked('access-interaction-' + index + '-' + item.edit, ['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-4'], 'access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delet, 'access-interaction-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-interaction-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_delete ' + item.delete} id={'access-interaction-' + index + '-' + item.delete} onChange={() => singleChecked('access-interaction-' + index + '-' + item.delete, ['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-5'], 'access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delet, 'access-interaction-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-interaction-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_interaction_export ' + item.export} id={'access-interaction-' + index + '-' + item.export} onChange={() => singleChecked('access-interaction-' + index + '-' + item.export, ['styled-checkbox-access-interaction-1','styled-checkbox-access-interaction-6'], 'access-interaction-' + index + '-' + item.full, ['access-interaction-' + index + '-' + item.view, 'access-interaction-' + index + '-' + item.create, 'access-interaction-' + index + '-' + item.edit, 'access-interaction-' + index + '-' + item.delet, 'access-interaction-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-interaction-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_interactionList.IsLoading === false && access_interactionList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_interactionList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>History</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-history-1',['access_history_full', 'access_history_view', 'access_history_create', 'access_history_edit', 'access_history_delete', 'access_history_export'], ['styled-checkbox-access-history-2', 'styled-checkbox-access-history-3', 'styled-checkbox-access-history-4', 'styled-checkbox-access-history-5', 'styled-checkbox-access-history-6'])}/>
                                        <label for="styled-checkbox-access-history-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-history-2','access_history_view')}/>
                                        <label for="styled-checkbox-access-history-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-history-3','access_history_create')}/>
                                        <label for="styled-checkbox-access-history-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-history-4','access_history_edit')}/>
                                        <label for="styled-checkbox-access-history-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-history-5','access_history_delete')}/>
                                        <label for="styled-checkbox-access-history-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-history-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-history-6','access_history_export')}/>
                                        <label for="styled-checkbox-access-history-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_historyList.IsLoading === false && access_historyList.list.length > 0 ?
                          <tbody>
                            {
                                access_historyList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_history_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_full ' + item.full} onChange={() => checkedRow('access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export], ['styled-checkbox-access-history-1', 'styled-checkbox-access-history-2', 'styled-checkbox-access-history-3', 'styled-checkbox-access-history-4', 'styled-checkbox-access-history-5', 'styled-checkbox-access-history-6'])} id={'access-history-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-history-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_view ' + item.view} id={'access-history-' + index + '-' + item.view} onChange={() => singleChecked('access-history-' + index + '-' + item.view, ['styled-checkbox-access-history-1','styled-checkbox-access-history-2'], 'access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-history-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_create ' + item.create} id={'access-history-' + index + '-' + item.create} onChange={() => singleChecked('access-history-' + index + '-' + item.create, ['styled-checkbox-access-history-1','styled-checkbox-access-history-3'], 'access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-history-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_edit ' + item.edit} id={'access-history-' + index + '-' + item.edit} onChange={() => singleChecked('access-history-' + index + '-' + item.edit, ['styled-checkbox-access-history-1','styled-checkbox-access-history-4'], 'access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-history-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_delete ' + item.delete} id={'access-history-' + index + '-' + item.delete} onChange={() => singleChecked('access-history-' + index + '-' + item.delete, ['styled-checkbox-access-history-1','styled-checkbox-access-history-5'], 'access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-history-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_history_export ' + item.export} id={'access-history-' + index + '-' + item.export} onChange={() => singleChecked('access-history-' + index + '-' + item.export, ['styled-checkbox-access-history-1','styled-checkbox-access-history-6'], 'access-history-' + index + '-' + item.full, ['access-history-' + index + '-' + item.view, 'access-history-' + index + '-' + item.create, 'access-history-' + index + '-' + item.edit, 'access-history-' + index + '-' + item.delete, 'access-history-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-history-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_historyList.IsLoading === false && access_historyList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_historyList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>

               <h3>Settings</h3>
                <div className="tableResponsive">
                 <table>
                    <thead>
                    <tr>
                            <th>Name</th>
                            <th>
                                <div className="flxth">Full Access 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-access-settings-1',['access_settings_full', 'access_settings_view', 'access_settings_create', 'access_settings_edit', 'access_settings_delete', 'access_settings_export'], ['styled-checkbox-access-settings-2', 'styled-checkbox-access-settings-3', 'styled-checkbox-access-settings-4', 'styled-checkbox-access-settings-5', 'styled-checkbox-access-settings-6'])}/>
                                        <label for="styled-checkbox-access-settings-1"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">View 
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-settings-2','access_settings_view')}/>
                                        <label for="styled-checkbox-access-settings-2"></label>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="flxth">Create
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-settings-3','access_settings_create')}/>
                                        <label for="styled-checkbox-access-settings-3"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Edit
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-settings-4','access_settings_edit')}/>
                                        <label for="styled-checkbox-access-settings-4"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Delete
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-settings-5','access_settings_delete')}/>
                                        <label for="styled-checkbox-access-settings-5"></label>
                                    </div>
                                </div> 
                            </th>
                            <th>
                                <div className="flxth">Download/Export
                                    <div className="checkbox">
                                        <input className="styled-checkbox" id="styled-checkbox-access-settings-6" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-access-settings-6','access_settings_export')}/>
                                        <label for="styled-checkbox-access-settings-6"></label>
                                    </div>
                                </div> 
                            </th>
                        </tr>
                    </thead>
                
                    {
                       access_settingsList.IsLoading === false && access_settingsList.list.length > 0 ?
                          <tbody>
                            {
                                access_settingsList.list.map((item, index) => {

                                    return(
                                        <tr key={'access_settings_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_full ' + item.full} onChange={() => checkedRow('access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export], ['styled-checkbox-access-settings-1', 'styled-checkbox-access-settings-2', 'styled-checkbox-access-settings-3', 'styled-checkbox-access-settings-4', 'styled-checkbox-access-settings-5', 'styled-checkbox-access-settings-6'])} id={'access-settings-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'access-settings-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_view ' + item.view} id={'access-settings-' + index + '-' + item.view} onChange={() => singleChecked('access-settings-' + index + '-' + item.view, ['styled-checkbox-access-settings-1','styled-checkbox-access-settings-2'], 'access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export])} type="checkbox" value={item.view}/>
                                                    <label for={'access-settings-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_create ' + item.create} id={'access-settings-' + index + '-' + item.create} onChange={() => singleChecked('access-settings-' + index + '-' + item.create, ['styled-checkbox-access-settings-1','styled-checkbox-access-settings-3'], 'access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export])} type="checkbox" value={item.create}/>
                                                    <label for={'access-settings-' + index + '-' + item.create}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_edit ' + item.edit} id={'access-settings-' + index + '-' + item.edit} onChange={() => singleChecked('access-settings-' + index + '-' + item.edit, ['styled-checkbox-access-settings-1','styled-checkbox-access-settings-4'], 'access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export])} type="checkbox" value={item.edit}/>
                                                    <label for={'access-settings-' + index + '-' + item.edit}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_delete ' + item.delete} id={'access-settings-' + index + '-' + item.delete} onChange={() => singleChecked('access-settings-' + index + '-' + item.delete, ['styled-checkbox-access-settings-1','styled-checkbox-access-settings-5'], 'access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export])} type="checkbox" value={item.delete}/>
                                                    <label for={'access-settings-' + index + '-' + item.delete}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox access_checkbox access_settings_export ' + item.export} id={'access-settings-' + index + '-' + item.export} onChange={() => singleChecked('access-settings-' + index + '-' + item.export, ['styled-checkbox-access-settings-1','styled-checkbox-access-settings-6'], 'access-settings-' + index + '-' + item.full, ['access-settings-' + index + '-' + item.view, 'access-settings-' + index + '-' + item.create, 'access-settings-' + index + '-' + item.edit, 'access-settings-' + index + '-' + item.delete, 'access-settings-' + index + '-' + item.export])} type="checkbox" value={item.export}/>
                                                    <label for={'access-settings-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                        </tr>

                                    );

                                })
                            }
                            
                            </tbody>
                            : access_settingsList.IsLoading === false && access_settingsList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>
                            : access_settingsList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                     <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>
                               </tbody>
                        : null }
                 </table>
               </div>
            </div>
            <div className="accesstable">
                <h2>Reports</h2>
                 <h3>Company Business Overview</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-company-business-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-company-business-1',['report_company_business_full', 'report_company_business_view', 'report_company_business_export', 'report_company_business_schedule', 'report_company_business_share'], ['styled-checkbox-report-company-business-2', 'styled-checkbox-report-company-business-3', 'styled-checkbox-report-company-business-4', 'styled-checkbox-report-company-business-5'])}/>
                                    <label for="styled-checkbox-report-company-business-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-company-business-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-company-business-2','report_company_business_view')} />
                                    <label for="styled-checkbox-report-company-business-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-company-business-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-company-business-3','report_company_business_export')}/>
                                    <label for="styled-checkbox-report-company-business-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-company-business-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-company-business-4','report_company_business_schedule')}/>
                                    <label for="styled-checkbox-report-company-business-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-company-business-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-company-business-5','report_company_business_share')}/>
                                    <label for="styled-checkbox-report-company-business-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_company_businessList.IsLoading === false && report_company_businessList.list.length > 0 ?
                          <tbody>
                            {
                                report_company_businessList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_company_business_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_company_business_full ' + item.full} onChange={() => checkedRow('company-business-' + index + '-' + item.full, ['company-business-' + index + '-' + item.view, 'company-business-' + index + '-' + item.export, 'company-business-' + index + '-' + item.schedule, 'company-business-' + index + '-' + item.share], ['styled-checkbox-report-company-business-1', 'styled-checkbox-report-company-business-2', 'styled-checkbox-report-company-business-3', 'styled-checkbox-report-company-business-4', 'styled-checkbox-report-company-business-5'])} id={'company-business-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'company-business-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_company_business_view ' + item.view} id={'company-business-' + index + '-' + item.view} onChange={() => singleChecked('company-business-' + index + '-' + item.view, ['styled-checkbox-report-company-business-1','styled-checkbox-report-company-business-2'], 'company-business-' + index + '-' + item.full, ['company-business-' + index + '-' + item.view, 'company-business-' + index + '-' + item.export, 'company-business-' + index + '-' + item.schedule, 'company-business-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'company-business-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_company_business_export ' + item.export} id={'company-business-' + index + '-' + item.export} onChange={() => singleChecked('company-business-' + index + '-' + item.export, ['styled-checkbox-report-company-business-1','styled-checkbox-report-company-business-3'], 'company-business-' + index + '-' + item.full, ['company-business-' + index + '-' + item.view, 'company-business-' + index + '-' + item.export, 'company-business-' + index + '-' + item.schedule, 'company-business-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'company-business-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_company_business_schedule ' + item.schedule} id={'company-business-' + index + '-' + item.schedule} onChange={() => singleChecked('company-business-' + index + '-' + item.schedule, ['styled-checkbox-report-company-business-1','styled-checkbox-report-company-business-4'], 'company-business-' + index + '-' + item.full, ['company-business-' + index + '-' + item.view, 'company-business-' + index + '-' + item.export, 'company-business-' + index + '-' + item.schedule, 'company-business-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'company-business-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_company_business_share ' + item.share} id={'company-business-' + index + '-' + item.share} onChange={() => singleChecked('company-business-' + index + '-' + item.share, ['styled-checkbox-report-company-business-1','styled-checkbox-report-company-business-5'], 'company-business-' + index + '-' + item.full, ['company-business-' + index + '-' + item.view, 'company-business-' + index + '-' + item.export, 'company-business-' + index + '-' + item.schedule, 'company-business-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'company-business-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_company_businessList.IsLoading === false && report_company_businessList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_company_businessList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>Customer Statement</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-customer-statement-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-customer-statement-1',['report_customer_statement_full', 'report_customer_statement_view', 'report_customer_statement_export', 'report_customer_statement_schedule', 'report_customer_statement_share'], ['styled-checkbox-report-customer-statement-2', 'styled-checkbox-report-customer-statement-3', 'styled-checkbox-report-customer-statement-4', 'styled-checkbox-report-customer-statement-5'])}/>
                                    <label for="styled-checkbox-report-customer-statement-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-customer-statement-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-customer-statement-2','report_customer_statement_view')} />
                                    <label for="styled-checkbox-report-customer-statement-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-customer-statement-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-customer-statement-3','report_customer_statement_export')}/>
                                    <label for="styled-checkbox-report-customer-statement-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-customer-statement-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-customer-statement-4','report_customer_statement_schedule')}/>
                                    <label for="styled-checkbox-report-customer-statement-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-customer-statement-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-customer-statement-5','report_customer_statement_share')}/>
                                    <label for="styled-checkbox-report-customer-statement-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_customer_statementList.IsLoading === false && report_customer_statementList.list.length > 0 ?
                          <tbody>
                            {
                                report_customer_statementList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_customer_statement_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_customer_statement_full ' + item.full} onChange={() => checkedRow('customer-statement-' + index + '-' + item.full, ['customer-statement-' + index + '-' + item.view, 'customer-statement-' + index + '-' + item.export, 'customer-statement-' + index + '-' + item.schedule, 'customer-statement-' + index + '-' + item.share], ['styled-checkbox-report-customer-statement-1', 'styled-checkbox-report-customer-statement-2', 'styled-checkbox-report-customer-statement-3', 'styled-checkbox-report-customer-statement-4', 'styled-checkbox-report-customer-statement-5'])} id={'customer-statement-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'customer-statement-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_customer_statement_view ' + item.view} id={'customer-statement-' + index + '-' + item.view} onChange={() => singleChecked('customer-statement-' + index + '-' + item.view, ['styled-checkbox-report-customer-statement-1','styled-checkbox-report-customer-statement-2'], 'customer-statement-' + index + '-' + item.full, ['customer-statement-' + index + '-' + item.view, 'customer-statement-' + index + '-' + item.export, 'customer-statement-' + index + '-' + item.schedule, 'customer-statement-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'customer-statement-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_customer_statement_export ' + item.export} id={'customer-statement-' + index + '-' + item.export} onChange={() => singleChecked('customer-statement-' + index + '-' + item.export, ['styled-checkbox-report-customer-statement-1','styled-checkbox-report-customer-statement-3'], 'customer-statement-' + index + '-' + item.full, ['customer-statement-' + index + '-' + item.view, 'customer-statement-' + index + '-' + item.export, 'customer-statement-' + index + '-' + item.schedule, 'customer-statement-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'customer-statement-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_customer_statement_schedule ' + item.schedule} id={'customer-statement-' + index + '-' + item.schedule} onChange={() => singleChecked('customer-statement-' + index + '-' + item.schedule, ['styled-checkbox-report-customer-statement-1','styled-checkbox-report-customer-statement-4'], 'customer-statement-' + index + '-' + item.full, ['customer-statement-' + index + '-' + item.view, 'customer-statement-' + index + '-' + item.export, 'customer-statement-' + index + '-' + item.schedule, 'customer-statement-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'customer-statement-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_customer_statement_share ' + item.share} id={'customer-statement-' + index + '-' + item.share} onChange={() => singleChecked('customer-statement-' + index + '-' + item.share, ['styled-checkbox-report-customer-statement-1','styled-checkbox-report-customer-statement-5'], 'customer-statement-' + index + '-' + item.full, ['customer-statement-' + index + '-' + item.view, 'customer-statement-' + index + '-' + item.export, 'customer-statement-' + index + '-' + item.schedule, 'customer-statement-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'customer-statement-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_customer_statementList.IsLoading === false && report_customer_statementList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_customer_statementList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>Service Statement</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-service-statement-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-service-statement-1',['report_service_statement_full', 'report_service_statement_view', 'report_service_statement_export', 'report_service_statement_schedule', 'report_service_statement_share'], ['styled-checkbox-report-service-statement-2', 'styled-checkbox-report-service-statement-3', 'styled-checkbox-report-service-statement-4', 'styled-checkbox-report-service-statement-5'])}/>
                                    <label for="styled-checkbox-report-service-statement-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-service-statement-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-service-statement-2','report_service_statement_view')} />
                                    <label for="styled-checkbox-report-service-statement-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-service-statement-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-service-statement-3','report_service_statement_export')}/>
                                    <label for="styled-checkbox-report-service-statement-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-service-statement-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-service-statement-4','report_service_statement_schedule')}/>
                                    <label for="styled-checkbox-report-service-statement-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-service-statement-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-service-statement-5','report_service_statement_share')}/>
                                    <label for="styled-checkbox-report-service-statement-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_service_statementList.IsLoading === false && report_service_statementList.list.length > 0 ?
                          <tbody>
                            {
                                report_service_statementList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_service_statement_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_service_statement_full ' + item.full} onChange={() => checkedRow('service-statement-' + index + '-' + item.full, ['service-statement-' + index + '-' + item.view, 'service-statement-' + index + '-' + item.export, 'service-statement-' + index + '-' + item.schedule, 'service-statement-' + index + '-' + item.share], ['styled-checkbox-report-service-statement-1', 'styled-checkbox-report-service-statement-2', 'styled-checkbox-report-service-statement-3', 'styled-checkbox-report-service-statement-4', 'styled-checkbox-report-service-statement-5'])} id={'service-statement-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'service-statement-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_service_statement_view ' + item.view} id={'service-statement-' + index + '-' + item.view} onChange={() => singleChecked('service-statement-' + index + '-' + item.view, ['styled-checkbox-report-service-statement-1','styled-checkbox-report-service-statement-2'], 'service-statement-' + index + '-' + item.full, ['service-statement-' + index + '-' + item.view, 'service-statement-' + index + '-' + item.export, 'service-statement-' + index + '-' + item.schedule, 'service-statement-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'service-statement-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_service_statement_export ' + item.export} id={'service-statement-' + index + '-' + item.export} onChange={() => singleChecked('service-statement-' + index + '-' + item.export, ['styled-checkbox-report-service-statement-1','styled-checkbox-report-service-statement-3'], 'service-statement-' + index + '-' + item.full, ['service-statement-' + index + '-' + item.view, 'service-statement-' + index + '-' + item.export, 'service-statement-' + index + '-' + item.schedule, 'service-statement-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'service-statement-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_service_statement_schedule ' + item.schedule} id={'service-statement-' + index + '-' + item.schedule} onChange={() => singleChecked('service-statement-' + index + '-' + item.schedule, ['styled-checkbox-report-service-statement-1','styled-checkbox-report-service-statement-4'], 'service-statement-' + index + '-' + item.full, ['service-statement-' + index + '-' + item.view, 'service-statement-' + index + '-' + item.export, 'service-statement-' + index + '-' + item.schedule, 'service-statement-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'service-statement-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_service_statement_share ' + item.share} id={'service-statement-' + index + '-' + item.share} onChange={() => singleChecked('service-statement-' + index + '-' + item.share, ['styled-checkbox-report-service-statement-1','styled-checkbox-report-service-statement-5'], 'service-statement-' + index + '-' + item.full, ['service-statement-' + index + '-' + item.view, 'service-statement-' + index + '-' + item.export, 'service-statement-' + index + '-' + item.schedule, 'service-statement-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'service-statement-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_service_statementList.IsLoading === false && report_service_statementList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_service_statementList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>Transaction Report</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-transaction-report-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-transaction-report-1',['report_transaction_report_full', 'report_transaction_report_view', 'report_transaction_report_export', 'report_transaction_report_schedule', 'report_transaction_report_share'], ['styled-checkbox-report-transaction-report-2', 'styled-checkbox-report-transaction-report-3', 'styled-checkbox-report-transaction-report-4', 'styled-checkbox-report-transaction-report-5'])}/>
                                    <label for="styled-checkbox-report-transaction-report-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-transaction-report-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-transaction-report-2','report_transaction_report_view')} />
                                    <label for="styled-checkbox-report-transaction-report-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-transaction-report-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-transaction-report-3','report_transaction_report_export')}/>
                                    <label for="styled-checkbox-report-transaction-report-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-transaction-report-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-transaction-report-4','report_transaction_report_schedule')}/>
                                    <label for="styled-checkbox-report-transaction-report-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-transaction-report-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-transaction-report-5','report_transaction_report_share')}/>
                                    <label for="styled-checkbox-report-transaction-report-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_transaction_reportsList.IsLoading === false && report_transaction_reportsList.list.length > 0 ?
                          <tbody>
                            {
                                report_transaction_reportsList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_transaction_report_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_transaction_report_full ' + item.full} onChange={() => checkedRow('transaction-report-' + index + '-' + item.full, ['transaction-report-' + index + '-' + item.view, 'transaction-report-' + index + '-' + item.export, 'transaction-report-' + index + '-' + item.schedule, 'transaction-report-' + index + '-' + item.share], ['styled-checkbox-report-transaction-report-1', 'styled-checkbox-report-transaction-report-2', 'styled-checkbox-report-transaction-report-3', 'styled-checkbox-report-transaction-report-4', 'styled-checkbox-report-transaction-report-5'])} id={'transaction-report-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'transaction-report-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_transaction_report_view ' + item.view} id={'transaction-report-' + index + '-' + item.view} onChange={() => singleChecked('transaction-report-' + index + '-' + item.view, ['styled-checkbox-report-transaction-report-1','styled-checkbox-report-transaction-report-2'], 'transaction-report-' + index + '-' + item.full, ['transaction-report-' + index + '-' + item.view, 'transaction-report-' + index + '-' + item.export, 'transaction-report-' + index + '-' + item.schedule, 'transaction-report-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'transaction-report-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_transaction_report_export ' + item.export} id={'transaction-report-' + index + '-' + item.export} onChange={() => singleChecked('transaction-report-' + index + '-' + item.export, ['styled-checkbox-report-transaction-report-1','styled-checkbox-report-transaction-report-3'], 'transaction-report-' + index + '-' + item.full, ['transaction-report-' + index + '-' + item.view, 'transaction-report-' + index + '-' + item.export, 'transaction-report-' + index + '-' + item.schedule, 'transaction-report-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'transaction-report-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_transaction_report_schedule ' + item.schedule} id={'transaction-report-' + index + '-' + item.schedule} onChange={() => singleChecked('transaction-report-' + index + '-' + item.schedule, ['styled-checkbox-report-transaction-report-1','styled-checkbox-report-transaction-report-4'], 'transaction-report-' + index + '-' + item.full, ['transaction-report-' + index + '-' + item.view, 'transaction-report-' + index + '-' + item.export, 'transaction-report-' + index + '-' + item.schedule, 'transaction-report-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'transaction-report-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_transaction_report_share ' + item.share} id={'transaction-report-' + index + '-' + item.share} onChange={() => singleChecked('transaction-report-' + index + '-' + item.share, ['styled-checkbox-report-transaction-report-1','styled-checkbox-report-transaction-report-5'], 'transaction-report-' + index + '-' + item.full, ['transaction-report-' + index + '-' + item.view, 'transaction-report-' + index + '-' + item.export, 'transaction-report-' + index + '-' + item.schedule, 'transaction-report-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'transaction-report-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_transaction_reportsList.IsLoading === false && report_transaction_reportsList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_transaction_reportsList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>Interaction Statement</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-interaction-statement-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-interaction-statement-1',['report_interaction_statement_full', 'report_interaction_statement_view', 'report_interaction_statement_export', 'report_interaction_statement_schedule', 'report_interaction_statement_share'], ['styled-checkbox-report-interaction-statement-2', 'styled-checkbox-report-interaction-statement-3', 'styled-checkbox-report-interaction-statement-4', 'styled-checkbox-report-interaction-statement-5'])}/>
                                    <label for="styled-checkbox-report-interaction-statement-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-interaction-statement-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-interaction-statement-2','report_interaction_statement_view')} />
                                    <label for="styled-checkbox-report-interaction-statement-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-interaction-statement-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-interaction-statement-3','report_interaction_statement_export')}/>
                                    <label for="styled-checkbox-report-interaction-statement-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-interaction-statement-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-interaction-statement-4','report_interaction_statement_schedule')}/>
                                    <label for="styled-checkbox-report-interaction-statement-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-interaction-statement-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-interaction-statement-5','report_interaction_statement_share')}/>
                                    <label for="styled-checkbox-report-interaction-statement-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_interaction_statementList.IsLoading === false && report_interaction_statementList.list.length > 0 ?
                          <tbody>
                            {
                                report_interaction_statementList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_interaction_statement_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_interaction_statement_full ' + item.full} onChange={() => checkedRow('interaction-statement-' + index + '-' + item.full, ['interaction-statement-' + index + '-' + item.view,'interaction-statement-' + index + '-' + item.export, 'interaction-statement-' + index + '-' + item.schedule, 'interaction-statement-' + index + '-' + item.share ], ['styled-checkbox-report-interaction-statement-1', 'styled-checkbox-report-interaction-statement-2', 'styled-checkbox-report-interaction-statement-3', 'styled-checkbox-report-interaction-statement-4', 'styled-checkbox-report-interaction-statement-5'])} id={'interaction-statement-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'interaction-statement-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_interaction_statement_view ' + item.view} id={'interaction-statement-' + index + '-' + item.view} onChange={() => singleChecked('interaction-statement-' + index + '-' + item.view, ['styled-checkbox-report-interaction-statement-1','styled-checkbox-report-interaction-statement-2'], 'interaction-statement-' + index + '-' + item.full, ['interaction-statement-' + index + '-' + item.view, 'interaction-statement-' + index + '-' + item.export, 'interaction-statement-' + index + '-' + item.schedule, 'interaction-statement-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'interaction-statement-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_interaction_statement_export ' + item.export} id={'interaction-statement-' + index + '-' + item.export} onChange={() => singleChecked('interaction-statement-' + index + '-' + item.export, ['styled-checkbox-report-interaction-statement-1','styled-checkbox-report-interaction-statement-3'], 'interaction-statement-' + index + '-' + item.full, ['interaction-statement-' + index + '-' + item.view, 'interaction-statement-' + index + '-' + item.export, 'interaction-statement-' + index + '-' + item.schedule, 'interaction-statement-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'interaction-statement-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_interaction_statement_schedule ' + item.schedule} id={'interaction-statement-' + index + '-' + item.schedule} onChange={() => singleChecked('interaction-statement-' + index + '-' + item.schedule, ['styled-checkbox-report-interaction-statement-1','styled-checkbox-report-interaction-statement-4'], 'interaction-statement-' + index + '-' + item.full, ['interaction-statement-' + index + '-' + item.view, 'interaction-statement-' + index + '-' + item.export, 'interaction-statement-' + index + '-' + item.schedule, 'interaction-statement-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'interaction-statement-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_interaction_statement_share ' + item.share} id={'interaction-statement-' + index + '-' + item.share} onChange={() => singleChecked('interaction-statement-' + index + '-' + item.share, ['styled-checkbox-report-interaction-statement-1','styled-checkbox-report-interaction-statement-5'], 'interaction-statement-' + index + '-' + item.full, ['interaction-statement-' + index + '-' + item.view, 'interaction-statement-' + index + '-' + item.export, 'interaction-statement-' + index + '-' + item.schedule, 'interaction-statement-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'interaction-statement-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_interaction_statementList.IsLoading === false && report_interaction_statementList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_interaction_statementList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>Document Report</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-document-report-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-document-report-1',['report_document_report_full', 'report_document_report_view', 'report_document_report_export', 'report_document_report_schedule', 'report_document_report_share'], ['styled-checkbox-report-document-report-2', 'styled-checkbox-report-document-report-3', 'styled-checkbox-report-document-report-4', 'styled-checkbox-report-document-report-5'])}/>
                                    <label for="styled-checkbox-report-document-report-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-document-report-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-document-report-2','report_document_report_view')} />
                                    <label for="styled-checkbox-report-document-report-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-document-report-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-document-report-3','report_document_report_export')}/>
                                    <label for="styled-checkbox-report-document-report-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-document-report-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-document-report-4','report_document_report_schedule')}/>
                                    <label for="styled-checkbox-report-document-report-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-document-report-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-document-report-5','report_document_report_share')}/>
                                    <label for="styled-checkbox-report-document-report-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_document_reportsList.IsLoading === false && report_document_reportsList.list.length > 0 ?
                          <tbody>
                            {
                                report_document_reportsList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_document_report_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_document_report_full ' + item.full} onChange={() => checkedRow('document-report-' + index + '-' + item.full, ['document-report-' + index + '-' + item.view,'document-report-' + index + '-' + item.export, 'document-report-' + index + '-' + item.schedule,'document-report-' + index + '-' + item.share], ['styled-checkbox-report-document-report-1', 'styled-checkbox-report-document-report-2', 'styled-checkbox-report-document-report-3', 'styled-checkbox-report-document-report-4', 'styled-checkbox-report-document-report-5'])} id={'document-report-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'document-report-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_document_report_view ' + item.view} id={'document-report-' + index + '-' + item.view} onChange={() => singleChecked('document-report-' + index + '-' + item.view, ['styled-checkbox-report-document-report-1','styled-checkbox-report-document-report-2'], 'document-report-' + index + '-' + item.full, ['document-report-' + index + '-' + item.view, 'document-report-' + index + '-' + item.export, 'document-report-' + index + '-' + item.schedule, 'document-report-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'document-report-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_document_report_export ' + item.export} id={'document-report-' + index + '-' + item.export} onChange={() => singleChecked('document-report-' + index + '-' + item.export, ['styled-checkbox-report-document-report-1','styled-checkbox-report-document-report-3'], 'document-report-' + index + '-' + item.full, ['document-report-' + index + '-' + item.view, 'document-report-' + index + '-' + item.export, 'document-report-' + index + '-' + item.schedule, 'document-report-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'document-report-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_document_report_schedule ' + item.schedule} id={'document-report-' + index + '-' + item.schedule} onChange={() => singleChecked('document-report-' + index + '-' + item.schedule, ['styled-checkbox-report-document-report-1','styled-checkbox-report-document-report-4'], 'document-report-' + index + '-' + item.full, ['document-report-' + index + '-' + item.view, 'document-report-' + index + '-' + item.export, 'document-report-' + index + '-' + item.schedule, 'document-report-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'document-report-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_document_report_share ' + item.share} id={'document-report-' + index + '-' + item.share} onChange={() => singleChecked('document-report-' + index + '-' + item.share, ['styled-checkbox-report-document-report-1','styled-checkbox-report-document-report-4'], 'document-report-' + index + '-' + item.full, ['document-report-' + index + '-' + item.view, 'document-report-' + index + '-' + item.export, 'document-report-' + index + '-' + item.schedule, 'document-report-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'document-report-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_document_reportsList.IsLoading === false && report_document_reportsList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_document_reportsList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>

                <h3>History Reports</h3>
                <div className="tableResponsive">
                 <table>
                 <thead>
                    <tr>
                        <th>Name</th>
                        <th>
                            <div className="flxth">Full Access 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-history-reports-1" type="checkbox" onChange={() => accessCheckAllFull('styled-checkbox-report-history-reports-1',['report_history_reports_full', 'report_history_reports_view', 'report_history_reports_export', 'report_history_reports_schedule', 'report_history_reports_share'], ['styled-checkbox-report-history-reports-2', 'styled-checkbox-report-history-reports-3', 'styled-checkbox-report-history-reports-4', 'styled-checkbox-report-history-reports-5'])}/>
                                    <label for="styled-checkbox-report-history-reports-1"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">View 
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-history-reports-2" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-history-reports-2','report_history_reports_view')} />
                                    <label for="styled-checkbox-report-history-reports-2"></label>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className="flxth">Export
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-history-reports-3" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-history-reports-3','report_history_reports_export')}/>
                                    <label for="styled-checkbox-report-history-reports-3"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Schedule
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-history-reports-4" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-history-reports-4','report_history_reports_schedule')}/>
                                    <label for="styled-checkbox-report-history-reports-4"></label>
                                </div>
                            </div> 
                        </th>
                        <th>
                            <div className="flxth">Share
                                <div className="checkbox">
                                    <input className="styled-checkbox" id="styled-checkbox-report-history-reports-5" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-report-history-reports-5','report_history_reports_share')}/>
                                    <label for="styled-checkbox-report-history-reports-5"></label>
                                </div>
                            </div> 
                        </th>
                    </tr>
                 </thead>

                 {
                       report_history_reportsList.IsLoading === false && report_history_reportsList.list.length > 0 ?
                          <tbody>
                            {
                                report_history_reportsList.list.map((item, index) => {

                                    return(
                                        <tr key={'report_history_reports_' + index}>
                                            <td><b>{item.name}</b></td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_history_reports_full ' + item.full} onChange={() => checkedRow('history-reports-' + index + '-' + item.full,['history-reports-' + index + '-' + item.view, 'history-reports-' + index + '-' + item.export, 'history-reports-' + index + '-' + item.schedule, 'history-reports-' + index + '-' + item.share],['styled-checkbox-report-history-reports-1', 'styled-checkbox-report-history-reports-2', 'styled-checkbox-report-history-reports-3', 'styled-checkbox-report-history-reports-4', 'styled-checkbox-report-history-reports-5'])} id={'history-reports-' + index + '-' + item.full} type="checkbox" value={item.full}/>
                                                    <label for={'history-reports-' + index + '-' + item.full}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_history_reports_view ' + item.view} id={'history-reports-' + index + '-' + item.view} onChange={() => singleChecked('history-reports-' + index + '-' + item.view, ['styled-checkbox-report-history-reports-1','styled-checkbox-report-history-reports-2'], 'history-reports-' + index + '-' + item.full, ['history-reports-' + index + '-' + item.view, 'history-reports-' + index + '-' + item.export, 'history-reports-' + index + '-' + item.schedule, 'history-reports-' + index + '-' + item.share])} type="checkbox" value={item.view}/>
                                                    <label for={'history-reports-' + index + '-' + item.view}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_history_reports_export '+ item.export} id={'history-reports-' + index + '-' + item.export}  onChange={() => singleChecked('history-reports-' + index + '-' + item.export, ['styled-checkbox-report-history-reports-1','styled-checkbox-report-history-reports-3'], 'history-reports-' + index + '-' + item.full, ['history-reports-' + index + '-' + item.view, 'history-reports-' + index + '-' + item.export, 'history-reports-' + index + '-' + item.schedule, 'history-reports-' + index + '-' + item.share])} type="checkbox" value={item.export}/>
                                                    <label for={'history-reports-' + index + '-' + item.export}></label>
                                                </div>
                                            </td>
                                            <td> 
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_history_reports_schedule ' + item.schedule} id={'history-reports-' + index + '-' + item.schedule} onChange={() => singleChecked('history-reports-' + index + '-' + item.schedule, ['styled-checkbox-report-history-reports-1','styled-checkbox-report-history-reports-4'], 'history-reports-' + index + '-' + item.full, ['history-reports-' + index + '-' + item.view, 'history-reports-' + index + '-' + item.export, 'history-reports-' + index + '-' + item.schedule, 'history-reports-' + index + '-' + item.share])} type="checkbox" value={item.schedule}/>
                                                    <label for={'history-reports-' + index + '-' + item.schedule}></label>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="checkbox">
                                                    <input className={'styled-checkbox report_checkbox report_history_reports_share ' + item.share} id={'history-reports-' + index + '-' + item.share} onChange={() => singleChecked('history-reports-' + index + '-' + item.share, ['styled-checkbox-report-history-reports-1','styled-checkbox-report-history-reports-5'], 'history-reports-' + index + '-' + item.full, ['history-reports-' + index + '-' + item.view, 'history-reports-' + index + '-' + item.export, 'history-reports-' + index + '-' + item.schedule, 'history-reports-' + index + '-' + item.share])} type="checkbox" value={item.share}/>
                                                    <label for={'history-reports-' + index + '-' + item.share}></label>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : report_history_reportsList.IsLoading === false && report_history_reportsList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={6} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : report_history_reportsList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                    
                    </table>
                </div>
        
            </div>

            <div className="row">
                            <div className="col-8">
                                <div className="form-group flxFld">
                                    <button style={{width: '200px' }} type='submit'>Update Role</button>
                                </div>
                            </div>
                        </div>  
                  </form>     

            </div>
      );
}
export default EditRoles;