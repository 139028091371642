

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from "react";

const OpenCost = () =>{

     return(
        <div className="viewInvoice linkdetails">
        <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Cost/ Expenses</h2>
    
        </div>
        <div className="tavBox">
            <Tabs>
            <div className="tableft">
            <TabList>
           <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab>  
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            <Tab>
             <h4>Jhon Duo <b>₹ 5422.00</b></h4>
             <p>455GHFG </p>
            </Tab> 
            </TabList>
            </div>
            <div className="tabRight">
            <TabPanel> 
                <div className="tabdetails">
                    <div className='expense'>
                    <label>Expense Amount</label>
                    <h3>₹ 5422.00</h3>
                    </div>
                </div>
            </TabPanel>
            <TabPanel> 
            <div className="tabdetails">
            <div className='expense'>
                    <label>Expense Amount</label>
                    <h3>₹ 5422.00</h3>
                    </div>
                </div>
            </TabPanel>
            </div>
            </Tabs>
        </div>

    </div>
     )
}
export default OpenCost;