
import edit from "../../../assets/images/edit.svg";
import bin from '../../../assets/images/delete.svg';
import view from '../../../assets/images/view.svg';
import pape from "../../../assets/images/pape.png"
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken, postWithTokenWithoutData, postWithOutTokenWithoutData, postWithToken, postWithTokenFormData} from "../../../Service/service";

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';

function Overview(){
      const [modalIsOpen, setIsOpen] = useState(false);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const location = useLocation();
    
      const { API_URL, userData, userToken} = useSelector(state => state.common);
      const [account_id, setAccountId] = useState(null);

      useEffect(() => {
            if(userToken != ''){
                if(location.state.id != null){
                    setAccountId(location.state.id);
                }
            }
        }, [userToken]);

        const editNav =()=>{
            navigate("/layout/accountprofiletabs")
        }
        const openModal =()=>{
            setIsOpen(true);
        }
          function closeModal() {
            setIsOpen(false);
          }

      return(
            <div className="accountTabs">
            <div className="pageTop">
                  <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Account</h2> 
            </div>
            <div className="customTab">
                  <div className="account_button_tab">
                        <button className="active" >Overview</button>
                        <button >Interaction</button>
                        <button >Transaction</button>
                        <button >Service</button>
                        <button >Documents</button>
                        <button >Tickets</button>
                        <button >History</button>
                  </div>


                  <div className="overviewsec">
            <div className="overviewTop">
                  <div className="overviewTopflex">
                        <h3>Ashirbad Consultancy<span>ASHI</span>
                        <small>(Souvik Mukherjee)</small>
                        </h3>
                        <button className="editBtn" onClick={() => {navigate("/dashboard/account/accountprofile", {state: {id: account_id}})}}>
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                  </div>
            </div>
            <div className="overViewDetails">
            <div className="overViewDetailsItem">
            <label>Customer ID</label>
            <p>215478841245</p>
            <label>Account Number</label>
            <p>54875512054</p>
            </div> 
            <div className="overViewDetailsItem">
            <label>Primary User / Contact</label>
            <p><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:9836989425">9836989425</a></p>
            <p><i className="fa fa-phone" aria-hidden="true"></i><a href="tel:8420073410">8420073410</a></p>
            <p><i className="fa fa-envelope" aria-hidden="true"></i><a href="mailto:souvik.27@gmail.com">souvik.27@gmail.com</a></p>
            </div>  
            <div className="overViewDetailsItem">
            <label>Billing Address</label>
            <p className="flxp"><i className="fa fa-map-marker" aria-hidden="true"></i><span>108/1 Iswar Chatterjee 
Road # 6 Uttarpally Sodepur, 
1 7 Kolkata, West Bengal 2 
Pin- 700110, India</span></p>
            </div>  
            <div className="overViewDetailsItem">
            <label>Shipping Address</label>
            <p className="flxp"><i className="fa fa-map-marker" aria-hidden="true"></i><span>108/1 Iswar Chatterjee 
Road # 6 Uttarpally Sodepur, 
1 7 Kolkata, West Bengal 2 
Pin- 700110, India</span></p>
            </div>   
            <div className="overViewDetailsItem">
            <label>Designated Email</label>
            <p><a href="mailto:info@ashirbadtax.com">info@ashirbadtax.com</a></p>
            </div> 
            <div className="overViewDetailsItem">
            <label>GSTIN</label>
            <p>19AMYPM5540S1ZQ</p>
            </div> 
            <div className="overViewDetailsItem">
            <label>Status</label>
            <p>Individual</p>
            </div> 
            <div className="overViewDetailsItem">
            <label>GST</label>
            <p>Applicable</p>
            </div> 
            </div>
            <div className="paymentDetails">
                  <label>Payment Details</label>
                  <h4><span>Outstanding Receivable  <b>₹ 5,045.00</b></span><span>Outstanding Receivable  <b>₹ 5,045.00</b></span></h4>
                  <div className="notifysec">
                        <Link onClick={openModal}>Notify</Link> ₹ 5,045.00
                  </div>
            </div>
            <div className="row">
                  <div className="col-6">
                        <div className="card">
                              <div className="card-header">
                               <label>List of Linked Accounts</label>
                              </div>  
                              <div className="card-body">
                                <ul>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                    <li>
                                     <span>Souvik Mukherjee (Main)</span>
                                     <span>28-08-2017</span>
                                    </li>
                                </ul>
                              </div>  
                        </div>
                  </div>
                  <div className="col-6">
                        <div className="card">
                              <div className="card-header">
                               <label>Statutory</label>
                              </div>  
                              <div className="card-body">
                                <ul>
                                    <li>
                                     <span>PAN Number</span>
                                     <span>AMYPM5540S</span>
                                    </li>
                                    <li>
                                     <span>Aadhaar Number</span>
                                     <span>1154-8748-5587</span>
                                    </li>
                                    <li>
                                     <span>Voter ID Number</span>
                                     <span>BHR5085558</span>
                                    </li>
                                    <li>
                                     <span>GST Number</span>
                                     <span>19AMYPM5540S1ZQ</span>
                                    </li>
                                    <li>
                                     <span>PTAX EC Number</span>
                                     <span>124584244441</span>
                                    </li>
                                    <li>
                                     <span>Import Export Code</span>
                                     <span>AMYPM55405</span>
                                    </li>
                                </ul>
                              </div>  
                        </div>
                  </div>
                  <div className="col-6">
                        <div className="card">
                              <div className="card-header">
                               <label>Attached Services</label>
                              </div>  
                              <div className="card-body">
                                <ul>
                                    <li>
                                     <span>Accounts (ITX)</span>
                                     <span>AMYPM5540S</span>
                                    </li>
                                    <li>
                                     <span>Goods & Service Tax (GST)</span>
                                     <span>1154-8748-5587</span>
                                    </li>
                                    <li>
                                     <span>Profession Tax (PTX)</span>
                                     <span>BHR5085558</span>
                                    </li>
                                    <li>
                                     <span>Registration (REG)</span>
                                     <span>19AMYPM5540S1ZQ</span>
                                    </li>
                                    <li>
                                     <span>ID & Documents (IDS)</span>
                                     <span>124584244441</span>
                                    </li>
                                    <li>
                                     <span>Import Export Code</span>
                                     <span>AMYPM55405</span>
                                    </li>
                                </ul>
                              </div>  
                        </div>
                  </div>
                  <div className="col-6">
                        <div className="card">
                              <div className="card-header">
                               <label>List of Branches</label>
                              </div>  
                              <div className="card-body no-record">
                                 <p><img src={pape}/>No Records Found</p>
                              </div>  
                        </div>
                  </div>
                  <div className="col-12">
                  <div className="card">
                  <div className="card-header">
                  <label>List of Branches</label>
                   </div>  
                   <div className="tableResponsive">
                        <table>
                              <thead>
                                    <tr>
                                    <th>Bank Name</th>
                                    <th>Branch Name</th>
                                    <th>IFSC Code Number</th>
                                    <th>Account Number</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr>
                                          <td>Axis Bank</td>
                                          <td>Panihati</td>
                                          <td>UTIB0000437</td>
                                          <td>918020020599719</td>
                                    </tr>
                                    <tr>
                                          <td>Axis Bank</td>
                                          <td>Panihati</td>
                                          <td>UTIB0000437</td>
                                          <td>918020020599719</td>
                                    </tr>
                              </tbody>
                        </table>
                    </div>
                  </div>
                   
                  </div>
                  <div className="col-12">
                  <div className="card">
                  <div className="card-header">
                  <label>Owner Details</label>
                   </div>  
                   <div className="tableResponsive">
                        <table>
                              <thead>
                                    <tr>
                                    <th>Type </th>
                                    <th>Name</th>
                                    <th>Joining </th>
                                    <th>Contact</th>
                                    <th>Pan</th>
                                    <th>Aadhaar</th>
                                    <th>Din</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr>
                                          <td>Director</td>
                                          <td>Samaresh Nandi</td>
                                          <td>17-12-1999</td>
                                          <td>9845451214</td>
                                          <td>AMYPM7052Q</td>
                                          <td>5541-5546-9978</td>
                                          <td>0984521</td>
                                          <td>Active</td>
                                          <td><Link><img src={edit}/></Link> <Link><img src={view}/></Link> <Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                          <td>Director</td>
                                          <td>Samaresh Nandi</td>
                                          <td>17-12-1999</td>
                                          <td>9845451214</td>
                                          <td>AMYPM7052Q</td>
                                          <td>5541-5546-9978</td>
                                          <td>0984521</td>
                                          <td>Active</td>
                                          <td><Link><img src={edit}/></Link> <Link><img src={view}/></Link> <Link><img src={bin}/></Link></td>
                                    </tr>
                                    <tr>
                                          <td>Director</td>
                                          <td>Samaresh Nandi</td>
                                          <td>17-12-1999</td>
                                          <td>9845451214</td>
                                          <td>AMYPM7052Q</td>
                                          <td>5541-5546-9978</td>
                                          <td>0984521</td>
                                          <td>Active</td>
                                          <td><Link><img src={edit}/></Link> <Link><img src={view}/></Link> <Link><img src={bin}/></Link></td>
                                    </tr>
                              </tbody>
                        </table>
                    </div>
                  </div>
                   
                  </div>
            </div>
            {/* Notify */}
            <Modal className="notifymodal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
            <div className="modalHeader">
            Notify  <Link onClick={closeModal}><i className="fa fa-times" aria-hidden="true"></i></Link>
            </div>
     <div className='modalBody'>
      <div className="checkBoxGroup">
      <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1">SMS</label>
        </div>
        <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value1"/>
        <label for="styled-checkbox-2">Email</label>
        </div>
        <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value1"/>
        <label for="styled-checkbox-3">WhatsApp</label>
        </div>
        <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value1"/>
        <label for="styled-checkbox-4">Telephonic</label>
        </div>
        <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value1"/>
        <label for="styled-checkbox-5">Face to Face</label>
        </div>
      </div>
      <div className="inlineForm">
            <label>Interaction Type</label>
            <select>
                  <option>Select Interaction</option>
            </select>
      </div>
      <div className="preViewMsg">
      <label>Preview</label>
      <div className="prvBox">
        <p>Dear Tridib Kumar Mondal,</p>
        <p>This is a friendly reminder about your current outstanding balance of Rs.0.00 after 
        deducting the available credits. Please pay at the earliest.</p>
        <p>Thank you.</p>
        <p>- Ashirbad Consultancy</p>
      </div>
      <button type="button" className="fillBtn">Notify</button>
      </div>

     </div>
      </Modal>
      </div>
      
      
                  </div>
            
            </div> 
      );
     
}
export default Overview;