import { Link } from "react-router-dom";
import bin from '../../assets/images/delete.svg';
import searchicon from '../../assets/images/searchicon.svg';
const Case =()=>{
    const contactListhead =["Case ID", "Account", "Job", "Leatest Status", "Bill Status","Payment Status","Due Date", "Action"];
    const contactlistdata =[
          {  
             name:"HGHH55412",
             contact:"Jhon Deo" ,
             account:"Designer" ,
             designation:"Active" ,
             status:"Success",
             pstatus:"Inactive"  ,
             date:"12/14/2023"    
          },
          {
            name:"HGHH55412",
             contact:"Jhon Deo" ,
             account:"Designer" ,
             designation:"Inactive" ,
             status:"Reject",
             pstatus:"Active"  ,
             date:"12/14/2023"  
             },
             {   name:"HGHH55412",
             contact:"Jhon Deo" ,
             account:"Designer" ,
             designation:"Active" ,
             status:"Success",
             pstatus:"Inactive"  ,
             date:"12/14/2023"
             },
             { name:"HGHH55412",
             contact:"Jhon Deo" ,
             account:"Designer" ,
             designation:"Active" ,
             status:"Reject",
             pstatus:"Active"  ,
             date:"12/14/2023"   
                },
                {  name:"HGHH55412",
                contact:"Jhon Deo" ,
                account:"Designer" ,
                designation:"Inactive" ,
                status:"Success",
                pstatus:"Active"  ,
                date:"12/14/2023"
                   },
                   {   name:"HGHH55412",
                   contact:"Jhon Deo" ,
                   account:"Designer" ,
                   designation:"Active" ,
                   status:"Reject",
                   pstatus:"Inactive"  ,
                   date:"12/14/2023"
                      },
                      {   name:"HGHH55412",
                      contact:"Jhon Deo" ,
                      account:"Designer" ,
                      designation:"Active" ,
                      status:"Success",
                      pstatus:"Active"  ,
                      date:"12/14/2023"    
                         },
                         {  name:"HGHH55412",
                         contact:"Jhon Deo" ,
                         account:"Designer" ,
                         designation:"Active" ,
                         status:"Reject",
                         pstatus:"Inactive"  ,
                         date:"12/14/2023" 
                            },
         

    ]
      return(
        <div className="caseAccount">
             <div className="pageTop">
                  <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Case</h2>
                  <div className="right-action">
                        <form>
                                <input type="text" placeholder="Account"/>
                                <input type="text" placeholder="Job"/>
                                <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                        </form>
                        <Link className="fillbtn"> Add New Cases</Link>
                  </div>
            </div>
            <div className="tableResponsive">
       <table>
        <thead>
              <tr>
              {
              contactListhead.map((item, index)=>{
                    return <th key={index}>{item}</th>
              })
              }
              </tr>
              
        </thead>
        <tbody>
          {
              contactlistdata.map((item, index)=>{
                    return <tr key={index}>
                          <td> {item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.account}</td>
                          <td className={item.designation == "Active"? "active":"inactive"}>{item.designation}</td>
                          <td className={item.status == "Success"? "sucess":"reject"}>{item.status}</td>
                          <td className={item.pstatus == "Active"? "active":"inactive"}>{item.pstatus}</td>
                          <td>{item.date}</td>
                          <td><Link><img src={bin}/></Link></td>
                    </tr>
              })
          }
        </tbody>
       </table>
     </div>
     <div className="tablenav">
      <p>See all 12 result </p>
  </div>
        </div>
      )
}
export default Case;