
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from "react";

const OpenDocuments = () =>{

     return(
        <div className="viewInvoice linkdetails">
        <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Document</h2>
    
        </div>
        <div className="tavBox">
            <Tabs>
            <div className="tableft">
                <h3>Filter by  Group </h3>
            <TabList>
           <Tab>
             <h4>Design Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab> 
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            <Tab>
             <h4>Design & Development Group</h4>
            </Tab>  
            </TabList>
            </div>
            <div className="tabRight">
            <TabPanel> 
                <div className="tabdetails">
                <div className='row'>
                    <div className='col-4'>
                        <label>Document Type</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Service Name</label>
                        <p>Designer</p>
                    </div>
                    <div className='col-4'>
                        <label>Jon Name</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Access</label>
                        <p>Jhon Duo</p>
                    </div>
                  </div>
                </div>
            </TabPanel>
            <TabPanel> 
               <div className="tabdetails">
                  <div className='row'>
                    <div className='col-4'>
                        <label>Document Type</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Service Name</label>
                        <p>Designer</p>
                    </div>
                    <div className='col-4'>
                        <label>Jon Name</label>
                        <p>Here is your document type </p>
                    </div>
                    <div className='col-4'>
                        <label>Access</label>
                        <p>Jhon Duo</p>
                    </div>
                  </div>
                </div>
            </TabPanel>
            </div>
            </Tabs>
        </div>

    </div>
     )
}
export default OpenDocuments;