import add from "../../assets/images/add.svg"
import upload from "../../assets/images/upload.svg"
import razorpay from "../../assets/images/razorpay.svg"
import paytm from "../../assets/images/paytm.svg"
import eazypay from "../../assets/images/eazypay.svg"
import instamojo from "../../assets/images/instamojo.svg"
import { createElement } from 'react';
import { Link } from "react-router-dom"
const AddPayment = () =>{
 
    return(
        <div className="addInvoice">
               <div className="pageTop">
                  <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i>Add Payment</h2>
                  </div>
                  <form>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Customer Name<sub>*</sub></label>
                                <div className="flxGroup">
                                <select>
                                    <option>Select or add customer</option>
                                </select>
                                <button type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                                </div> 
                               
                            </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Branch</label>
                        <input type="text" placeholder="Enter Branch"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Amount Received<sub>*</sub></label>
                        <input type="text" placeholder="Enter Amount"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Bank Charges (if any)</label>
                        <input type="text" placeholder="Bank Charges"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Payment Date<sub>*</sub></label>
                        <input type="date" placeholder="Enter Payment Date"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Receipt Number</label>
                       <input type="texe" value="HO/HF" readOnly/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Payment Mode</label>
                        <select>
                            <option>Select Payment Mode</option>
                         </select>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Deposit To<sub>*</sub></label>
                         <select>
                            <option>Select Deposit to</option>
                         </select>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Reference</label>
                        <input type="text" placeholder="Enter Reference"/>
                        </div>
                        </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-12">
                    <div className="tableResponsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice Number</th>
                                    <th>Branch</th>
                                    <th>Invoice Amount</th>
                                    <th>Amount Due</th>
                                    <th>Payment</th>
                                </tr>
                            </thead>
                            <tbody id="addTable">
                                <tr>
                                    <td>
                                    07-04-2022
                                    <small>Due Date: 07-04-2022</small>
                                    </td>
                                    <td>
                                    AC22230007
                                    </td>
                                    <td>
                                    Head Office
                                    </td>
                                    <td>
         
                                            2,270
                              
                                    </td>
                                    <td>
                   
                                            2,270
         
                                        </td>
                                    <td>
                                        <div className="payfull">
                                        <input type="text" value="0.00"/>
                                        <Link>Pay Full</Link>
                                        </div>
                                   
                                    
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    07-04-2022
                                    <small>Due Date: 07-04-2022</small>
                                    </td>
                                    <td>
                                    AC22230007
                                    </td>
                                    <td>
                                    Head Office
                                    </td>
                                    <td>
         
                                            2,270
                              
                                    </td>
                                    <td>
                   
                                            2,270
         
                                        </td>
                                    <td>
                                        <div className="payfull">
                                        <input type="text" value="0.00"/>
                                        <Link>Pay Full</Link>
                                        </div>
                                   
                                    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <div className="row">
                      <div className="col-7">
                        <div className="form-group">
                            <label>Customer Notes</label>
                            <textarea></textarea>
                        </div>
                        <div className="paymentsec">
                        <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-5">Notify via SMS</label>
                            </div>
                            <div className="checkbox" style={{marginTop:"10px"}}>
                            <input className="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-6">Email a "thank you" note for this payment</label>
                            </div>
                            <div className="checkbox borderInline">
                            <input className="styled-checkbox" id="styled-checkbox-7" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-7">Ashirbad Consultancy info@ashirbadtax.com</label>
                            </div>
                        </div>
                     
                            <div className="btngrp">
                                <button type="button" className="fillbtn">Save</button>
                                <button type="button" className="fadebtn">Cancel</button>
                            </div>
                        </div>
                        <div className="col-5">
                        <div className="form-group">
                            <label>Attach File(s) to Invoice</label>
                            <div className="fileUpload">
                                <span><img src={upload}/>Upload File</span>
                            </div>
                            <small>You can upload a maximum of 10 files, 5MB each</small>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                        <div className="subTotal">
                        <div className="subTotaltop">
                            <h3>Total <b>0.00</b></h3>
                            <div className="priceItem">
                                <div className="labelLeft">
                                <label>Amount Received</label> 
                                </div>
                                <div className="priceright">
                                    <b className="">0.00</b>
                                </div>
                            </div>
                            <div className="priceItem">
                                <div className="labelLeft">
                                <label>Amount used for Payments</label> 
                                </div>
                                <div className="priceright">
                                    <b className="">0.00</b>
                                </div>
                            </div>
                            <div className="priceItem">
                                <div className="labelLeft">
                                <label>Amount Refunded</label> 
                                </div>
                                <div className="priceright">
                                    <b className="">0.00</b>
                                </div>
                            </div>
                  
                            </div>
                            <div className="total">
                                <h3>Amount in Excess</h3>
                                <b>0.00</b>
                            </div>
                        </div>
                        
                    </div>
                  </div>
        </div>
    )
}
export default AddPayment;