import edit from "../../assets/images/edit.svg";
import bin from '../../assets/images/delete.svg';
import view from '../../assets/images/view.svg';
import profile_icon from "../../assets/images/profile.png";
import searchicon from '../../assets/images/searchicon.svg';

import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate} from "react-router-dom";
import { Form} from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../Service/service";

import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';

function ContactList(){

      const [open, setOpen] = React.useState(false);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
      const handleClickOpen = (id) => {
        setOpen(true);
        setDeleteIds(id);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const navigate = useNavigate();
      let [loading, setLoading] = useState(false);
      let [color, setColor] = useState("#C00305");
    
      const CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
      const override = CSSProperties;
    
      const dispatch = useDispatch();
    
      const { API_URL, userData, userToken} = useSelector(state => state.common);
      const [search_text, setSearchText] = useState({text: '', status: false});
      const [deleteIds, setDeleteIds] = useState(null);
      const [isLoading, setIsLoading] = useState(false);
      const [open_err, setOpenErr] = useState(false);
      const [err_msg, setErrMsg] = useState("");
      const [userList, setUserList] = useState({IsLoading : true, list: [], total_count: 0});
      const [list_limit, setListLimit] = useState(20);
      const [page_selected, setPageSelected] = useState(null);
      const [user_info, setUserInfoData] = useState(null);
      const [modalUserInfoOpen, setModelUserInfoOpen] = useState(false);

      useEffect(() => {
            if(userToken != ''){
                  userListFetch(0,list_limit); 
            }
        }, [userToken]);

      //alert start
  
      const [alert, setAlert] = useState({
            type: null,
            text: [],
            show: false
      })
      
      function onCloseAlert(){
            setAlert({
            show: false,
            type: null,
            text: [],
            })
      }
      
      function onShowAlert(type,msg){
            setAlert({
            type: type,
            text: msg,
            show: true
            })
      }
      
      //alert end 

      const userListFetch = (offset, limit) => {
            postWithToken(API_URL, 'contact-list', {offset: offset, limit: limit, search_name: search_text.text}, userToken).then((result) => {
                console.log(result);
                if(result.status === true){
                  setUserList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteUser = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];
            postWithToken(API_URL, 'user/delete', {id: id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = userList.list.filter((item) => item.id !== id);
                        setUserList({IsLoading : false, list: newArray, total_count: (userList.total_count - 1)});
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
        }


        const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(userList.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % userList.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              userListFetch(newOffset, list_limit);
              setItemOffset(newOffset);
            };
          
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }

          const accessCheckAll = (id,checkbox_class) => {
            let checkbox_all_status = $('#' + id).is(":checked");
    
            if(checkbox_all_status === true){
                $("." + checkbox_class).prop("checked", true);
            }else{
                $("." + checkbox_class).prop("checked", false);
            }
        }


         const checkbox = ()=>{
            return <div className="checkbox">
                     <input class="styled-checkbox" id="styled-checkbox-all" type="checkbox" onChange={() => accessCheckAll('styled-checkbox-all','user_checkbox')}/>
                        <label for="styled-checkbox-all"></label>
                   </div>
          }
          const contactListhead =["User Name", "Contact Type",  "Mobile Number", "Email", "Status", "Action"];

          const searchUser = (event) => {
              event.preventDefault();
    
              let flg = true;
              const all_error_msg = [];

              if(search_text.text == ""){
                  all_error_msg.push("Please enter user name for search");
                  if(flg == true){flg = false;}
              }

              if(flg == true){
                  postWithToken(API_URL, 'contact-list', {offset: itemOffset, limit: list_limit, search_name: search_text.text}, userToken).then((result) => {
                        console.log(result);
                        
                        setSearchText({text: '', status: true});
                        if(result.status === true){
                          setUserList({IsLoading : false, list: result.data, total_count: result.total_count});
                        }
                    })
                    .catch((error) => {
                        console.log("Error : ", error);
                    });
              }else{
                  onShowAlert("error", all_error_msg); 
              }
          }

          const ChangeStatus = (index_id, id, current_status) => {

            setLoading(true);

            if(current_status == 1){
                  current_status = 0;
            }else{
                  current_status = 1;   
            }

            postWithToken(API_URL, 'account-users/change-status', {id:id, status: current_status}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        userList.list[index_id].active = current_status;
                  }
              })
              .catch((error) => {
                  console.log("Error : ", error);
              });

      }

        const searchText = (val) => {
            setSearchText({text:val, status: false})

            if(val.length == 0){
                  userListFetch(0,list_limit); 
            }
        }

        const ViewUserInfo = (id) => {

            setLoading(true);
            postWithToken(API_URL, 'user/view', {id: id}, userToken).then((result) => {
                  console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        setUserInfoData(result.data);
                        setModelUserInfoOpen(true);
                  }
            })
            .catch((error) => {
                  console.log("Error : ", error);
            });

         }

         function CloseUserInfoModel(){
            setModelUserInfoOpen(false);
          }

         
      return (
      <div className="contactList">
         <CustomAlert
              header={"Role"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

                  <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete User"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this user
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    No
                              </Button>
                              <Button onClick={() => {deleteUser(deleteIds)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>

            <div className="pageTop">
                  <h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Contact List</h2>
                  <div className="right-action">
                        <form onSubmit={searchUser}>
                              <input type="text" placeholder="User Name" onChange={(event) => searchText(event.target.value)}/>
                              <button type="submit"><img src={searchicon}/></button>
                        </form>
                        <button onClick={() => {navigate("/dashboard/contactlist/adduser", {state: {page: 'contactlist'}})}}  className="fadebtn">Add Contact</button>
                        <button className="fillbtn">Bulk action</button>
                  </div>
            </div>
            {
                  search_text.status == true ?
                        <div className="pageTop">
                              <div className="right-action">
                                    <form onSubmit={searchUser}>
                                          <label>Search Text: {search_text.text}</label>
                                          <button type="submit" style={{color: '#fff',borderRadius: '8px',  height: 35, width: 'auto', marginLeft: 20}}>Clear Search</button>
                                    </form>
                              </div>
                        </div>
                  : null
            }
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                              contactListhead.map((item, index)=>{
                                    let w = '';
                                    if(index == 5){
                                          w = 150;
                                    }else if(index == 0){
                                          w = 200;   
                                    }
                                    return (<th key={index} style={{width: w}}>{item}</th>)
                              })
                        }
                        </tr>
                        
                  </thead>
                  {
                       userList.IsLoading === false && userList.list.length > 0 ?
                          <tbody>
                            {
                                userList.list.map((item, index) => {
                                    return(
                                        <tr key={index}>
                                          {/*<td> 
                                                <div className="checkbox">
                                                <input class="styled-checkbox user_checkbox" id={'checkbox-user-' + item.id} type="checkbox" value={item.id}/>
                                                <label for={'checkbox-user-' + item.id}></label>
                                                </div>
                                          </td>*/}
                                          <td> {item.name}</td>
                                          <td>{ item.category == 1 ? 'Executive' : item.category == 2 ? 'Non Executive' : null}</td>
                                          <td>{item.phone}</td>
                                          <td>{item.email}</td>
                                          <td onClick={() => {ChangeStatus(index, item.id, item.active)}} className={item.active == 1 ? "active" :"inactive"}>{item.active == 1 ? "Active" :"Inactive"}</td>
                                          <td>
                                                <button onClick={() => {navigate("/dashboard/contactlist/edituser", {state: {id: item.id, page: 'contactlist'}})}}><img src={edit}/></button> 
                                                {/*<button style={{marginLeft: '5px'}} onClick={() => handleClickOpen(item.id)} ><img src={bin}/></button>*/}
                                                <button style={{ marginLeft: 6}} onClick={() => {ViewUserInfo(item.id)}}><img src={view}/></button> 
                                          </td>
                                        </tr>
                                    );
                                })
                            }
                            
                            </tbody>
                            : userList.IsLoading === false && userList.list.length == 0 ?
                               <tbody>
                                    <tr>
                                        <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                    </tr>
                                </tbody>

                            : userList.IsLoading === true ?
                               <tbody>
                                <tr>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                    <td ><Skeleton height={24} /></td>
                                </tr>

                               </tbody>

                        : null }
                 </table>
               </div>
               <div className="pagination_div">
                        <PaginatedItems itemsPerPage={list_limit} />
               </div>

               <Modal isOpen={modalUserInfoOpen} onRequestClose={CloseUserInfoModel} contentLabel="User Details" >
                              <div className='modal_header'>
                              User Details <button onClick={CloseUserInfoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                              </div>
                              <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                              
                                    <div className='row'>
                                          <div className='col-12' >

                                          {
                                          user_info === null ?
                                                <div>
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                <Skeleton height={24} style={{marginBottom: 20}} />
                                                </div>
                                          : 

                                          <div className="row" >
                                                <div className="col-12" >
                                                      <div className="photoUpload" style={{alignItems: 'center', justifyContent: 'center'}}>
                                                            <div className="uploadePic">
                                                                  {
                                                                        user_info.profile_image != null ?

                                                                        <img src={user_info.profile_image} />

                                                                        : <img src={profile_icon} />
                                                                  }
                                                                  
                                                            </div>
                                                            
                                                      </div>
                                                </div>
                                                
                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Contact Type : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.category == 1 ? 'Executive': user_info.caegory == 2 ? 'Non-Executive' :  null}</span> 
                                                      </div>
                                                </div> 

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Name : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.name}</span> 
                                                      </div>
                                                </div> 

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Mobile Number : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.phone}</span> 
                                                      </div>
                                                </div> 

                                                { user_info.category == 1 ?
                                                      <>
                                                            <div className="col-12">
                                                                  <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                  <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Role : </label>
                                                                  <span style={{display: 'flex', flex: 1}}>{user_info.roles[0].id}</span> 
                                                                  </div>
                                                            </div> 
                                                            <div className="col-12">
                                                                  <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                  <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Designation : </label>
                                                                  <span style={{display: 'flex', flex: 1}}>{user_info.designation}</span> 
                                                                  </div>
                                                            </div>
                                                      </>      

                                                      : null

                                                }

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Address1 : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.address_1}</span> 
                                                      </div>
                                                </div>

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Address2 : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.address_2}</span> 
                                                      </div>
                                                </div>

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Country : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.country_id}</span> 
                                                      </div>
                                                </div>

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Pincode : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.pin_code}</span> 
                                                      </div>
                                                </div>

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>State : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.state_id}</span> 
                                                      </div>
                                                </div>

                                                <div className="col-12">
                                                      <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                      <label style={{fontWeight: 700, display: 'flex', flex: 1}}>City : </label>
                                                      <span style={{display: 'flex', flex: 1}}>{user_info.city_id}</span> 
                                                      </div>
                                                </div>

                                                

                                          </div>
                                          }
                                          
                                          </div>
                                                      
                                          <div className='col-12'>
                                          <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                                <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseUserInfoModel}>Close</button>
                                          </div>
                                          </div>
                                    </div>
                              
                              </div>
                        </Modal>




            </div>


      );
}
export default ContactList;
