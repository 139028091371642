import { Link } from "react-router-dom";
import bin from '../../assets/images/delete.svg';
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const DocumentGroup = () =>{
      const navigate = useNavigate();
      const opendocumentgroup = () =>{
            navigate("/layout/opendocumentgroup")
      }
      const adddocumentgroup = () =>{
            navigate("/layout/adddocumentgroup")
      }

      const contactListhead =[ "Document Group", "Service", "Job", "Status", "No of Type","Action"];
      const contactlistdata =[
            {
               date:"Ashirdab Consultancy",
               number:"Service Name ",
               name:"Job Name",
               status:"Active",
               type:"Document Type ",
            },
            {
                date:"Ashirdab Consultancy",
                number:"Service Name ",
                name:"Job Name",
                status:"Active",
                type:"Document Type ",
               },
               {
                date:"Ashirdab Consultancy",
               number:"Service Name ",
               name:"Job Name",
               status:"Active",
               type:"Document Type ",
               },
               {
                date:"Ashirdab Consultancy",
                number:"Service Name ",
                name:"Job Name",
                status:"Active",
                type:"Document Type ",
                  },
                  {
                    date:"Ashirdab Consultancy",
                    number:"Service Name ",
                    name:"Job Name",
                    status:"Active",
                    type:"Document Type ",
                     },
                     {
                        date:"Ashirdab Consultancy",
                        number:"Service Name ",
                        name:"Job Name",
                        status:"Active",
                        type:"Document Type ",
                        },
                        {
                            date:"Ashirdab Consultancy",
                            number:"Service Name ",
                            name:"Job Name",
                            status:"Active",
                            type:"Document Type ",
                           },
                           {
                            date:"Ashirdab Consultancy",
                            number:"Service Name ",
                            name:"Job Name",
                            status:"Active",
                            type:"Document Type ",
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i className="fa fa-headphones" aria-hidden="true"></i>Document Group</h2>
                  <div className="right-action">
            
          
                        <Link className="fillbtn" onClick={adddocumentgroup}>Add Document Group</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index} onClick={opendocumentgroup}>
                              
                                    <td> {item.date}</td>
                                    <td>{item.number}</td>
                                    <td>{item.name}</td>
                                    <td className={item.status == "Active"? "active": "inactive"}>{item.status}</td>
                                    <td>{item.type}</td>
                                    <td> <Link><img src={bin}/></Link></td>
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default DocumentGroup;