import lock from '../assets/images/lock.svg';
import logo from '../assets/images/logo.svg';
import envolope from '../assets/images/envolop.svg';
import call from '../assets/images/call.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import withRouter from '../component/withRouter';
import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const ResetPassword = (props) =>{
  const location = useLocation();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const { API_URL, userData} = useSelector(state => state.common);
  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

    //alert start
    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  //alert end  

  const resetPassword = (event) =>{
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;

	if(password.search(/\S/) == -1){
		all_error_msg.push("Please enter your password");
		if(flg == true){flg = false;}
	}else if(password.search(/\S/) != -1){
		const passwordLength =      password.length;
		const uppercasePassword =   uppercaseRegExp.test(password);
		const lowercasePassword =   lowercaseRegExp.test(password);
		const digitsPassword =      digitsRegExp.test(password);
		const specialCharPassword = specialCharRegExp.test(password);
		const minLengthPassword =   minLengthRegExp.test(password);

		let errMsg ="";
		let flgg = true;
		if(!uppercasePassword){
				//errMsg= "password at least one uppercase";
        errMsg = "password at least one uppercase/lowercase/digit/special character and mininum eight characters";
				flgg = false;
		}else if(!lowercasePassword){
				//errMsg= "password at least one lowercase";
        errMsg = "password at least one uppercase/lowercase/digit/special character and mininum eight characters";
				flgg = false;
		}else if(!digitsPassword){
				//errMsg= "password at least one digit";
        errMsg = "password at least one uppercase/lowercase/digit/special character and mininum eight characters";
				flgg = false;
		}else if(!specialCharPassword){
				//errMsg= "password at least one special characters";
        errMsg = "password at least one uppercase/lowercase/digit/special character and mininum eight characters";
				flgg = false;
		}else if(!minLengthPassword){
				//errMsg= "password at least minimum eight characters";
        errMsg = "password at least one uppercase/lowercase/digit/special character and mininum eight characters";
				flgg = false;
		}else{
			errMsg="";
		}

		if(flgg == false){
			all_error_msg.push(errMsg);
			if(flg == true){flg = false;}
		}
	}

    if(confirm_password.search(/\S/) == -1){
      all_error_msg.push("please enter confirm password");
      if(flg == true){flg = false;}
    }

    if(password != confirm_password){
      all_error_msg.push("Mismatch confirm password");
      if(flg == true){flg = false;}
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'reset-password', {email: location.state.email, password: password, password_confirmation: confirm_password}).then((result) => {
          if(result.status === true){
                setLoading(false);
                all_error_msg.push(result.message);
                onShowAlert('success',all_error_msg); 
                setTimeout(function() {onCloseAlert(); }, 4000);
                setTimeout(() => {
                  navigate("/login");
                }, 2000);
            }else{
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
      }else{
        onShowAlert("error", all_error_msg);
      } 
  }
 
   return(
    <div className="authwapper">
        <div className="container">
             <CustomAlert
                  header={"Reset Password"}
                  btnText={"Accept"}
                  text={alert.text}
                  type={alert.type}
                  show={alert.show}
                  onClosePress={onCloseAlert}
                  pressCloseOnOutsideClick={true}
                  alertStyles={{}}
                  headerStyles={{}}
                  textStyles={{}}
                  buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className="loginfrom">
       <h1>Reset Password </h1  >
          <form onSubmit={resetPassword}>
            <div className="form-group">
            <img className='formIcon' src={lock}/>
                <input type="password" placeholder="New Password" onChange={(event) => setPassword(event.target.value)} />
            </div>
            <div className="form-group">
                <img className='formIcon' src={lock}/>
                <input type="password" placeholder="Confirm Password"  onChange={(event) => setConfirmPassword(event.target.value)}/>
            </div>
            <button type="submit"className="fill-btn">Submit</button>
          </form>
          </div>
          <div className="companyinfo">
           
              <img src={logo}/>
           
            <h2>Ashirbad Cloud</h2>
            <p>A construct & concept thereof</p>
            <div className='contactinfo'>
              <a href='mailto:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={envolope}/>
                souvik.mukherjee@ashirbadtax.com</a><br/>
                <a href='tel:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={call}/>
              +91 0000000000</a>
            </div>
          </div>
        </div>
         
    </div>
  )
}
export default withRouter(ResetPassword);