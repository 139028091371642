import { Link } from "react-router-dom"
import download from "../../../assets/images/download.svg"
import upload from "../../../assets/images/upload.svg"
function Documents(){
const taskHeading=[
"Account", "Service Documents", "Customer Documents"
]
const task=[
"Euro Scientific", "Rix N Rups", "Blossom", "Rix N Rups", "Softech" ,"Sayona"
]
return(
<div className="transction-table">
<div className="tableResponsive">
<table>
<thead>
<tr>
{
taskHeading.map((item,index)=>{
return <th key={index}>{item}</th>
}) 
}
</tr>
</thead>
<tbody>
{
task.map((item, index)=>{
return <tr key={index}>
<td>{item}</td>
<td>
<div className="inpflid">
<input type="text" />
<Link><img src={upload}/></Link>
<Link><img src={download}/></Link>
</div>
</td>
<td>
<div className="inpflid">
<input type="text" />
<Link><img src={upload}/></Link>
<Link><img src={download}/></Link>
</div>
</td>
</tr>
})
}
</tbody>
</table>
</div>
<div className="tablenav">
<p>See all 12 result </p>
<button type="button" className="fillBtn">Update</button>
</div>
</div>
)
}
export default Documents;