import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import React, {useEffect, useState, CSSProperties} from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../Service/service";

import $ from 'jquery';

import CustomAlert from "../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
const AddTask = ()=>{

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [full_name, setFullName] = useState("");
    const [task_id, setTaskId] = useState(null);
    const [short_name, setShortName] = useState("");
    const [short_code, setShortCode] = useState("");
    const [service_id, setServiceId] = useState("");
    const [job_id, setJobId] = useState("");
    const [parent_id, setParentId] = useState(0);
    const [description, setDescription] = useState("");
    const [sorting_no, setSortingNo] = useState("");
   
    const [service_list, setServiceList] = useState([]);
    const [job_list, setJobList] = useState([]);
    const [primary_list, setPrimaryList] = useState([]);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                DataFetch();
                setTaskId(location.state.id);
            }else{
                PrimaryListFetch(null);
            }

            ServiceListFetch();
        }
       
    }, [userToken]);

    const PrimaryListFetch = (id) => {
        postWithToken(API_URL, 'active-tasks-list', {id: id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setPrimaryList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const JobListFetch = (service_id) => {
        postWithToken(API_URL, 'active-jobs-list', {service_id: service_id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setJobList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const ServiceListFetch = () => {
        postWithToken(API_URL, 'active-services-list', {}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                setServiceList(result.data);             
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'tasks/view', {id: location.state.id}, userToken).then((result) => {
            //console.log(result);
            setLoading(false);
            if(result.status === true){
                PrimaryListFetch(result.data.id);
                setFullName(result.data.name);
                setShortName(result.data.short_name);
                setShortCode(result.data.short_code);
                setServiceId(result.data.service_id);
                setJobId(result.data.job_id);
                setParentId(result.data.parent_id);
                setDescription(result.data.description);
                setSortingNo(result.data.sorting_no);
            
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const addTask = (event) => {
        event.preventDefault();
    
        let flg = true;
        const all_error_msg = [];

        if(full_name.search(/\S/) == -1){
            all_error_msg.push("Please enter task full name");
            if(flg == true){flg = false;}
        }

        if(short_code.search(/\S/) == -1){
            all_error_msg.push("Please enter task short code");
            if(flg == true){flg = false;}
        }else{
            if(short_code.length < 5){
                all_error_msg.push("Please enter 5 alphabets short code");
                if(flg == true){flg = false;}
            }
        }

        if(service_id == ""){
            all_error_msg.push("Please select service");
            if(flg == true){flg = false;}
        }

        if(job_id == ""){
            all_error_msg.push("Please select job");
            if(flg == true){flg = false;}
        }

        if(sorting_no.search(/\S/) == -1){
            all_error_msg.push("Please enter sorting no");
            if(flg == true){flg = false;}
        }
              
    
        if (flg === true) {
          setLoading(true);

          if(task_id == null){
            let post_data = {
                name: full_name,
                short_name: short_name,
                job_id: job_id,
                service_id: service_id,
                parent_id: parent_id,
                description: description,
                short_code: short_code,
                sorting_no: sorting_no

              };
            postWithToken(API_URL, 'tasks/create', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/tasklist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
           
          }else{
            let post_data = {
                id: task_id,
                name: full_name,
                short_name: short_name,
                job_id: job_id,
                service_id: service_id,
                parent_id: parent_id,
                description: description,
                short_code: short_code,
                sorting_no: sorting_no
              };
            postWithToken(API_URL, 'tasks/update', post_data, userToken).then((result) => {
                if(result.status === true){
                      setLoading(false);
                      navigate("/dashboard/tasklist");
                     
                  }else{
                    setLoading(false);
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
                  }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
          }
        }else{
            onShowAlert("error", all_error_msg);
        } 
    
    }

    return(
        <>
        
        <CustomAlert
              header={"Add Job"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className="addJob">
             <div className="pageTop">
                  <h2><i class="fa fa-archive" aria-hidden="true"></i>{ task_id != null ? "Edit" : "Add"} Task</h2>
                  </div>
                  <form onSubmit={addTask}>
                    <div className="productsec">
             
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Full Name<sub>*</sub></label>
                                    <input type="text" value={full_name} onChange={(event) => setFullName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Service Name<sub>*</sub></label>
                                    <select value={service_id} onChange={(e) => {setServiceId(e.target.value); JobListFetch(e.target.value)}}>
                                        <option value="">Select Service</option>
                                        {
                                            service_list.length > 0 ?

                                            service_list.map((item, key) => {

                                                return(
                                                    <option key={'service_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Job Name<sub>*</sub></label>
                                    <select value={job_id} onChange={(e) => {setJobId(e.target.value)}}>
                                        <option value="">Select Job</option>
                                        {
                                            job_list.length > 0 ?

                                            job_list.map((item, key) => {

                                                return(
                                                    <option key={'job_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Under<sub>*</sub></label>
                                    <select value={parent_id} onChange={(e) => {setParentId(e.target.value)}}>
                                        <option value="0">Primary</option>
                                        {
                                            primary_list.length > 0 ?

                                            primary_list.map((item, key) => {

                                                return(
                                                    <option key={'primary_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Description</label>
                                    <input type="text" value={description} onChange={(event) => setDescription(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Short Name</label>
                                    <input type="text" value={short_name} onChange={(event) => setShortName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Short Code<sub>* CAP Letter 5 Code for Job </sub></label>
                                    <input type="text" maxLength={5} value={short_code} onChange={(event) => { const re = /^[A-Za-z]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
        setShortCode(event.target.value.toUpperCase());
    }}}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Short<sub>*</sub></label>
                                    <input type="text" value={sorting_no} onKeyDown={(event) => {if (/[1-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => setSortingNo(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btnGroup">
                                    <button type="button" className="fadebtn" onClick={() => {navigate("/dashboard/tasklist")}}>Cancel</button>
                                    <button type="submit" className="fillbtn">{task_id != null ? "Update" : "Add"} Task</button>
                                    {/*<button type="button" className="fillbtn">Add Task</button>
                                    <button type="button" className="fillbtn">Add Document</button>
                        <button type="button" className="fillbtn">Add Group </button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
             
             
          
                  </form>
        </div>
        </>
    )
}
export default AddTask;