
import { Component, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import Menu from './Menu';
import Header from './Header'
import {Outlet} from "react-router-dom";

function Layout (){ 

  
    const [isactive,setIsActive] = useState(true);
   function habdelClick(){
      setIsActive(!isactive)
   }
        return(
        
            <div className="wapper">
            <div id="navigation" className={isactive? "open":""}>
                <nav >
                  <Link to="/layout" className="logo">
                    <img src={logo}/>
                  </Link>
                  <Menu/>
                  <button type="button" className="handel" onClick={habdelClick}><i className={isactive? "fa fa-angle-left": "fa fa-angle-right"} aria-hidden="true"></i>
                  
                  </button>
                </nav>
            </div>
            <div id="pagewapper" className={isactive? " expend":""}>
             <Header />
             <div className="mainbody">
             <div className="mainbodyinner">
              <Outlet />
             </div>
             </div>
            </div>
            </div>
        )
    
}

export default Layout;