import add from "../../assets/images/add.svg"
import upload from "../../assets/images/upload.svg"
import razorpay from "../../assets/images/razorpay.svg"
import paytm from "../../assets/images/paytm.svg"
import eazypay from "../../assets/images/eazypay.svg"
import instamojo from "../../assets/images/instamojo.svg"
import { createElement } from 'react';
const AddInvoice = () =>{
    const addRow = () =>{
      return  createElement("tr", null, createElement('td',null,createElement()))
    }
    return(
        <div className="addInvoice">
               <div className="pageTop">
                  <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i>Add Invoice</h2>
                  </div>
                  <form>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Customer Name<sub>*</sub></label>
                                <div className="flxGroup">
                                <select>
                                    <option>Select or add customer</option>
                                </select>
                                <button type="button"><i className="fa fa-search" aria-hidden="true"></i></button>
                                </div> 
                               
                            </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Branch</label>
                        <input type="text" placeholder="Enter Branch"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Invoice<sub>*</sub></label>
                        <input type="text" placeholder="Enter Invoice Number"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Order Number</label>
                        <input type="text" placeholder="Enter Order Number"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Invoice Date<sub>*</sub></label>
                        <input type="date" placeholder="Enter Invoice Date"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Terms</label>
                         <select>
                            <option>Select Terms</option>
                         </select>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Due Date</label>
                        <input type="date" placeholder="Enter due Date"/>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Salesperson</label>
                         <select>
                            <option>Select Salesperson</option>
                         </select>
                        </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                        <label>Subject</label>
                        <input type="text" placeholder="Enter Subject"/>
                        </div>
                        </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-12">
                    <div className="tableResponsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Ouantity</th>
                                    <th>Rate</th>
                                    <th>Discount</th>
                                    <th>Tax</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody id="addTable">
                                <tr>
                                    <td>
                                        <input type="text" placeholder="Enter Product Name"/>
                                    </td>
                                    <td>
                                        <input type="text" placeholder="" value="0.00" />
                                    </td>
                                    <td>
                                        <input type="text" placeholder="" value="0.00"  style={{textAlign: "right"}}/>
                                    </td>
                                    <td>
                                        <select>
                                            <option>
                                            0.00 %
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                    <select>
                                            <option>
                                            Select Tax
                                            </option>
                                        </select>
                                        </td>
                                    <td>
                                    0.00
                                    </td>
                                </tr>
                                <tr className="addRowBlock">
                                    <td colSpan={6} onClick={addRow}> 
                                        <div className="addRow">
                                            <img src={add}/> Add New Row
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <div className="row">
                      <div className="col-7">
                        <div className="form-group">
                            <label>Customer Notes</label>
                            <textarea></textarea>
                        </div>
                        <div className="paymentsec">
                            <h4>Select an online payment option to get paid faster<span></span></h4>
                            <div className="paymentoption">
                            <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-1"><img src={razorpay}/></label>
                            </div>
                            <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-2"><img src={paytm}/></label>
                            </div>
                            <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-3"><img src={eazypay}/></label>
                            </div>
                            <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-4"><img src={instamojo}/></label>
                            </div>
                            </div>
                        </div>
                        <div className="checkbox">
                            <input className="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value1"/>
                            <label for="styled-checkbox-5">Allow customer to make partial payments for this invoice.</label>
                            </div>
                            <div className="btngrp">
                                <button type="button" className="fillbtn">Save</button>
                                <button type="button" className="fadebtn">Save & Send</button>
                                <button type="button" className="fadebtn">Cancel</button>
                            </div>
                        </div>
                        <div className="col-5">
                        <div className="form-group">
                            <label>Attach File(s) to Invoice</label>
                            <div className="fileUpload">
                                <span><img src={upload}/>Upload File</span>
                            </div>
                            <small>You can upload a maximum of 10 files, 5MB each</small>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-5">
                        <div className="subTotal">
                        <div className="subTotaltop">
                            <h3>Sub Total <b>0.00</b></h3>
                            <div className="priceItem">
                                <div className="labelLeft">
                                    <div className="customRedio">
                                    <input type="radio" id="test1" name="radio-group"  />
                                      <label for="test1">TDS</label>
                                    </div>
                                    <div className="customRedio">
                                    <input type="radio" id="test2" name="radio-group"  />
                                      <label for="test2">TCS</label>
                                    </div>
                                </div>
                                <div className="priceright">
                                    <b className="nagetive">-0.00</b>
                                </div>
                            </div>
                            <div className="priceItem">
                                <div className="labelLeft">
                                  <div className="priceInp">
                                    <input type="text" value="Adjustment"/>
                                    <input type="text" value=""/>
                                  </div>
                                  
                                </div>
                                <div className="priceright">
                                    <b className="">0.00</b>
                                </div>
                            </div>
                            </div>
                            <div className="total">
                                <h3>Total ( ₹ )</h3>
                                <b>0.00</b>
                            </div>
                        </div>
                        
                    </div>
                  </div>
        </div>
    )
}
export default AddInvoice