import { React,Component, useRef, useState } from "react";
import {NavLink, Link } from "react-router-dom";
import {Layout} from './Layout';
export default class Menu extends Component{
constructor(props){
super(props);
this.state ={
setIndex:0,
subMenuActive:null
}
}
// dropdown
setIsActive(index){
this.setState( {
setIndex:index
})
}
// Submenu Active
submenuActive(index){
this.setState({
subMenuActive:index
})
}
render(){
// navigation
const manu = [
    {
    label:"Dashboard",
    url:"/dashboard",
    icon:"fa fa-qrcode",
    },
    {
        label:"Work Station",
        url:"/workstation",
        icon:"fa fa-building-o",
        submenu:[
            {
                label:"Single Mode",
                url:"singlemode"
            },
            {
                label:"Bulk Action",
                url:"bulkaction"
            }
        ] 
    },
    {
        label:"Customers",
        url:"/customer",
        icon:"fa fa-user-o",
        submenu:[
                {
                label:"Accounts",
                url:"account"
                }  ,
                {
                label:"Cases",
                url:"cases"
                },
                {
                label:"Invoices",
                url:"invoices"
                },
                {
                label:"payment",
                url:"payment"
                },
                {
                label:"Status Report",
                url:"statusreport"
                }
        ] 
    },
    {
        label:"Users",
        url:"/users",
        icon:"fa fa-user-o",
        submenu:[
            {
            label:"Contact List",
            url:"contactlist"
            },
            {
            label:"Client Users (NEO)",
            url:"clientuser"
            },
            {
            label:"Executives (RO)",
            url:"executive"
            },
            {
            label:"Roles",
            url:"role"
            }
        ] 
    },
    {
        label:"Services",
        url:"/service",
        icon:"fa fa-headphones",
        submenu:[
            {
            label:"Services",
            url:"servicelist"
            },
            {
            label:"Jobs",
            url:"joblist"
            },
            {
            label:"Products",
            url:"productlist"
            },
            {
            label:"Tasks",
            url:"tasklist"
            }
        ] 
    },
    {
        label:"Transactions",
        url:"/transaction",
        icon:"fa fa-exchange",
        submenu:[
            {
            label:"Invoices",
            url:"invoice"
            },
            {
            label:"Payments",
            url:"transctionpayment"
            },
            {
            label:"Payment Links",
            url:"paymentlink"
            },
            {
            label:"Estimates",
            url:"estimate"
            },
            {
            label:"Cost / Expenses",
            url:"costexpense"
            },
            {
            label:"Statements",
            url:"statement"
            }
        ] 
    },
    {
        label:"Documents",
        url:"/document",
        icon:"fa fa-file-text-o",
        submenu:[
            {
            label:"DocHub",
            url:"docHub"
            },
            {
            label:"Document Register",
            url:"documentregister"
            },
            {
            label:"Documents Types",
            url:"documentstype"
            },
            {
            label:"Documents Groups",
            url:"documentsgroup"
            }
        ] 
    },
    {
        label:"Interactions",
        url:"/interaction",
        icon:"fa fa-comments-o",
        submenu:[
            {
            label:"Emails",
            url:"email"
            },
            {
            label:"SMS",
            url:"sms"
            },
            {
            label:"WhatsApp",
            url:"whatsapp"
            },
            {
            label:"Telephonic",
            url:"telephonic"
            },
            {
            label:"Face to Face",
            url:"facetoface"
            },
            {
            label:"notification",
            url:"notificationsetting"
            }
        ] 
    },
    {
        label:"History",
        url:"/history",
        icon:"fa fa-file-text-o",
        submenu:[
            {
            label:"Account History",
            url:"accounthistory"
            },
            {
            label:"User History",
            url:"userhistory"
            },
            {
            label:"System History",
            url:"systemhistory"
            },
            {
            label:"Settings History",
            url:"settingshistory"
            }
        ] 
    },
    {
    label:"Settings",
    url:"/settings",
    icon:"fa fa-cog"
    }
]
return(
<ul >
{
    manu.map((menuItem, indexP)=>{
    // const setIndex = index;
    return (
            <li key={indexP}  >

            { menuItem.submenu == undefined ?     
                <Link  to={menuItem.url} className={this.state.setIndex === indexP? "active":""} onClick={()=>this.setIsActive(indexP)}><i className={menuItem.icon} aria-hidden="true"></i>
                <span>{menuItem.label}</span> {menuItem.submenu && <small><b></b></small>}</Link>
                :

                <a  className={this.state.setIndex === indexP? "active":""} onClick={()=>this.setIsActive(indexP)}><i className={menuItem.icon} aria-hidden="true"></i>
                 <span>{menuItem.label}</span> {menuItem.submenu && <small><b></b></small>}</a>
            }

                {menuItem.submenu &&  
                    <ul id="submenu" className={this.state.setIndex === indexP? "active":""} >
                        {
                        menuItem.submenu?.map((item, index)=>{
                            return <li  key={index} onClick={()=>this.submenuActive(index)}>
                                    {/*<Link to={item.url} className={ this.state.subMenuActive === index? "active" : ""} ><span>{item.label}</span></Link></li>*/}

                                    <NavLink
                                    to={item.url}
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                    }
                                >{item.label}</NavLink></li>
                            })   
                        }
                    </ul> 
                }

            </li>
        )
    })
}
</ul>
)
}
}