import Filter from "../Filter";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Task from "./Task"
import Transactions from "./Transaction";
import Documents from "./Documents";
import Intractions from "./Intractions";
import Layout from "../../layout/Layout";
function BulkAction(){
return(
<div className="bulkAction">
<div className="search">
<Filter />
</div>
<div className="customTab">
<div className="intractiontype">
<label>Interaction Type</label>
<form>
<select></select>
<button type="button" className="fillBtn">Send</button>
</form>
</div>
<Tabs>
<TabList>
<Tab>Task</Tab>
<Tab>Transaction</Tab>
<Tab>Documents</Tab>
<Tab>Intractions</Tab>
</TabList>
<TabPanel> 
<Task />
</TabPanel>
<TabPanel>
<Transactions />
</TabPanel>
<TabPanel>
<Documents />
</TabPanel>
<TabPanel>
<Intractions />
</TabPanel>
</Tabs>
</div>
</div>
)
}
export default BulkAction;