import bin from '../../../../assets/images/delete.svg';
import downloaddocument from "../../../../assets/images/downloaddocument.svg";
import uploaddocument from '../../../../assets/images/uploaddocument.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken, postWithTokenFormData} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StatutoryDocAddEdit = () =>{

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
   
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [account_id, setAccountId] = useState("");
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [document, setDocument] = useState("");
    const [document_pre, setDocumentPre] = useState("");

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                setAccountId(location.state.account_id);
                setId(location.state.id);
                setName(location.state.name);
                EditDetails(location.state.id, location.state.account_id);
            }
        }
    }, [userToken]);
    

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
       

    const ChangeTab = (tab_url) => {
        const all_error_msg = [];
        if(location.state.id == null){
          all_error_msg.push("Please Create Account Profile First");
          onShowAlert("error", all_error_msg);
        }else{
          navigate(tab_url, {state: {id: location.state.account_id}})
        }
      }

      function onSelectSignFile(event){
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
          const reader = new window.FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = () => {
            //upload_profile(reader.result);
    
           // console.log(reader.result);
           setDocument(event.target.files[0]);
           setDocumentPre(reader.result);
    
            //console.log(event.target.files[0]);
    
          };
        }
    }

     const uploadFiels = (event) => {
        event.preventDefault();
        
        let flg = true;
        const all_error_msg = [];

      
        if(document_pre == ""){
            let err = "Please upload " + name + "document";
            all_error_msg.push(err);
            if(flg == true){flg = false;}
        }
    
        if (flg === true) {
                setLoading(true);
  
                let post_data = {
                    account_id: account_id,
                    id : id,
                    upload_file: document,
                    
                };
    
                postWithTokenFormData(API_URL, 'account-documents/upload', post_data, userToken).then((result) => {
                    if(result.status === true){
                          setLoading(false);
                          all_error_msg.push(result.message);
                          onShowAlert("success", all_error_msg);

                          setDocument("");
                          setDocumentPre("");

                          ChangeTab('/dashboard/account/accountdocument');
                         
                      }else{
                        setLoading(false);
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                      }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
           
    
               
          }else{
            onShowAlert("error", all_error_msg);
            
          } 
      }

      const EditDetails = (id, account_id) => {
        setLoading(true);
        const all_error_msg = [];

        postWithToken(API_URL, 'account-documents/download', {id: id, account_id: account_id}, userToken).then((result) => {
              console.log(result);
              setLoading(false);
              if(result.status === true){
                if(result.data.document_path != null){
                    setDocumentPre(result.data.document_path);

                }
                   
              }else{
                    all_error_msg.push(result.message);
                    onShowAlert("error", all_error_msg);
              }

        })
        .catch((error) => {
              console.log("Error : ", error);
        });
     } 

        
     return(

        <div className="accountProfileTabs">

          <CustomAlert
              header={"Add User"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer User</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                    <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                </div>

                <div className="customTab">
                    <div className="account_button_tab">
                        <button  className="active" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}  >Statutory</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/bankdocument')}} >Bank</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/partnerdocument')}} >Partner/ Director Details</button>
                    </div>

                    <div className="addowners">
                                <form onSubmit={uploadFiels}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="profile">
                                                <h2>Upload {name} Document</h2>
                                                <div className="signupload" style={{ position: 'relative', }}>
                                                    {
                                                        document_pre != "" ? 
                                                          <img src={document_pre} />
                                                        : <span>Attach {name}....</span>
                                                    }

                                                    <div className="partner_image_upload">
                                                        <input  type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectSignFile(e)} />
                                                        <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true" ></i>
                                                    </div>
                                                    
                                                </div>
                                             
                                                <div className="btngrp">
                                                    <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}} >Cancel</button>
                                                    <button type="submit" className="fillbtn" >Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                           </div>             


 
                </div>      
           </div>
        </div>        
     )
}
export default StatutoryDocAddEdit;