import bin from '../../../../../assets/images/delete.svg';
import downloaddocument from "../../../../../assets/images/downloaddocument.svg";
import uploaddocument from '../../../../../assets/images/uploaddocument.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../../../Service/service";

import CustomAlert from "../../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import ReactPaginate from 'react-paginate';
const AccountBankDocument =()=>{


    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
     
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [account_id, setAccountId] = useState("");
    const [bankList, setbankList] = useState({IsLoading : true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);

    const contactListhead =[ "Bank Name", "Branch Name", "Customer Name", "Document Available", "Document Status"];


    useEffect(() => {
      if(userToken != ''){
          if(location.state.id != null){
              setAccountId(location.state.id);
              BankListFetch(0,list_limit, location.state.id);
          }
      }
  }, [userToken]);



    const BankListFetch = (offset, limit, account_id) => {
      setLoading(true);
      postWithToken(API_URL, 'account-banks-list', {offset: offset, limit: limit, account_id: account_id}, userToken).then((result) => {
          console.log(result);
          setLoading(false);
          if(result.status === true){
              setbankList({IsLoading : false, list: result.data, total_count: result.total_count});
          }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 
       

    const ChangeTab = (tab_url) => {
        const all_error_msg = [];
        if(location.state.id == null){
          all_error_msg.push("Please Create Account Profile First");
          onShowAlert("error", all_error_msg);
        }else{
          navigate(tab_url, {state: {id: location.state.id}})
        }
      }

        const [itemOffset, setItemOffset] = useState(0);
        function PaginatedItems({ itemsPerPage }) {
            // Here we use item offsets; we could also use page offsets
            // following the API or data you're working with.
           
            // Simulate fetching items from another resources.
            // (This could be items from props; or items loaded in a local state
            // from an API endpoint with useEffect and useState)
            const endOffset = itemOffset + itemsPerPage;
            //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            //const currentItems = roleList.list.slice(itemOffset, endOffset);
            const pageCount = Math.ceil(bankList.total_count / itemsPerPage);
          
            // Invoke when user click to request another page.
            const handlePageClick = (event) => {
              const newOffset = (event.selected * itemsPerPage) % bankList.total_count;
              /*console.log(
                `User requested page number ${event.selected}, which is offset ${newOffset}`
              );*/

              setPageSelected(event.selected);
              BankListFetch(newOffset, list_limit, account_id);
              setItemOffset(newOffset);
            };
          
            return (
              <>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  forcePage={page_selected}
                />
              </>
            );
          }

       

     return(

      <>

      <CustomAlert
        header={"Bank Document Info"}
        btnText={"Accept"}
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
        />  
        {
          loading === true ?
          <div className="activityLoader">
                <GridLoader
                  color={color}
                  loading={loading}
                  cssOverride={override}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  Loader="GridLoader"
                /> 
         </div>     
         : null
        }


        <div className="accountProfileTabs">
            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer User</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                    <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                    <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                    <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                </div>

                <div className="customTab">
                    <div className="account_button_tab">
                        <button   onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}  >Statutory</button>
                        <button className="active" onClick={() => {ChangeTab('/dashboard/account/bankdocument')}} >Bank</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/partnerdocument')}} >Partner/ Director Details</button>
                    </div>

                      <div className="accountProdfileBranch">
                          <div className="tableResponsive">
                            <table>
                              <thead>
                                <tr>
                                {
                                contactListhead.map((item, index)=>{
                                return <th key={index}>{item}</th>
                                })
                                }
                                </tr>
                              </thead>
                             
                                  {
                                        bankList.IsLoading === false && bankList.list.length > 0 ?
                                        <tbody>
                                            {
                                                bankList.list.map((item, index) => {

                                                    return(
                                                      <tr key={index}>
                          
                                                      <td> {item.bank_name}</td>
                                                      <td>{item.branch_name}</td>
                                                      <td>{item.customer_name}</td>
                                                      <td>{item.document_path != null ?<a target="_blank" href={item.document_path} download><img src={downloaddocument}/></a> : null } <button type="button" onClick={() => {navigate('/dashboard/account/bankdoc', {state: {account_id: location.state.id, id: item.id}})}}><img src={uploaddocument}/></button></td>
                                                      <td>{item.document_path != null ? "Uploaded" : "Not Uploaded"}</td>
                                                      </tr>
                                                    );
                                                })
                                            }

                                    
                                    </tbody>
                                    : bankList.IsLoading === false && bankList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={5} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : bankList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }

                            </table>
                          </div>

                          <div className="pagination_div">
                             <PaginatedItems itemsPerPage={list_limit} />
                          </div>
                          
                      </div>      

                </div>

           </div>
        </div>  

        </>      
     )
}
export default AccountBankDocument;