import { Link } from "react-router-dom";
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const SmsIntraction = () =>{
      const navigate = useNavigate();

      const sendSms = () =>{
            navigate("/layout/smsnotification") 
      }

 
      const contactListhead =["Date & Time", "Account", "Job", "Cash Id", "Interaction Type","Interaction Details","Raised By","Action"];
      const contactlistdata =[
            {
               date:"12/14/2023",
               acc:"Jhon Deo",
               job:"Designer",
               id:"5425HFGHB",
               intraction:"Sms",
               intractionDetails:"Email Details",
               rise:"Admin",
               status:"Active"
            },
            {
                date:"12/14/2023",
                acc:"Jhon Deo",
                job:"Designer",
                id:"5425HFGHB",
                intraction:"Sms",
                intractionDetails:"Email Details",
                rise:"Admin",
                status:"Active"
               },
               {
                date:"12/14/2023",
                acc:"Jhon Deo",
                job:"Designer",
                id:"5425HFGHB",
                intraction:"Sms",
                intractionDetails:"Email Details",
                rise:"Admin",
                status:"Active"
               },
               {
                date:"12/14/2023",
               acc:"Jhon Deo",
               job:"Designer",
               id:"5425HFGHB",
               intraction:"Sms",
               intractionDetails:"Email Details",
               rise:"Admin",
               status:"Active"
                  },
                  {
                    date:"12/14/2023",
                    acc:"Jhon Deo",
                    job:"Designer",
                    id:"5425HFGHB",
                    intraction:"Sms",
                    intractionDetails:"Email Details",
                    rise:"Admin",
                    status:"Active"
                     },
                     {
                        date:"12/14/2023",
                        acc:"Jhon Deo",
                        job:"Designer",
                        id:"5425HFGHB",
                        intraction:"Sms",
                        intractionDetails:"Email Details",
                        rise:"Admin",
                        status:"Active"
                        },
                        {
                            date:"12/14/2023",
                            acc:"Jhon Deo",
                            job:"Designer",
                            id:"5425HFGHB",
                            intraction:"Sms",
                            intractionDetails:"Email Details",
                            rise:"Admin",
                            status:"Active"
                           },
                           {
                            date:"12/14/2023",
                            acc:"Jhon Deo",
                            job:"Designer",
                            id:"5425HFGHB",
                            intraction:"Sms",
                            intractionDetails:"Email Details",
                            rise:"Admin",
                            status:"Inactive"
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-envelope-o" aria-hidden="true"></i>Sms</h2>
                  <div className="right-action">
                  <form>
             
                      <input type="text" placeholder="Account"/>
                      <input type="text" placeholder="Job"/>
                      <input type="text" placeholder="Raised By"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                      </form>
                        <Link className="fillbtn"onClick={sendSms}>Send SMS</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index} >
                                
                                    <td> {item.date}</td>
                                    <td>{item.acc}</td>
                                    <td>{item.job}</td>
                                    <td>{item.id}</td>
                                    <td>{item.intraction}</td>
                                    <td>{item.intractionDetails}</td>
                                    <td>{item.rise}</td>
                                    <td className={item.status == "Active"? "active": "inactive"}>{item.status}</td>
        
                                    
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default SmsIntraction;