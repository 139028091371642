import profile_icon from "../../assets/images/profile.png";

import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken, postWithOutTokenWithoutData, postWithToken, postWithTokenFormData} from "../../Service/service";
import { setUserDataWithToken } from "../../Store/Reducers/CommonReducer";

import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import OtpInput from 'react-otp-input';
import $ from 'jquery';

const EditUser = () =>{

    const navigate = useNavigate();
    const location = useLocation();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
   
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [user_data, setUserData] = useState({IsLoading: true, list: []});
    const [modalEmailOpen, setModelEmailOpen] = useState(false);
    const [modalMobileOpen, setModelMobileOpen] = useState(false);
    const [mobile_otp, setMobileOtp] = useState('');
    const [email_otp, setEmailOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [email_verified, setEmailVerified] = useState(0);
    const [phone, setPhone] = useState("");
    const [phone_verified, setPhoneVerified] = useState(0);
    const [designation, setDesignation] = useState("");
    const [address_1, setAddressOne] = useState("");
    const [address_2, setAddressTwo] = useState("");
    const [pin_code, setPinCode] = useState("");
    const [country_id, setCountry] = useState("");
    const [state_id, setState] = useState("");
    const [city_id, setCity] = useState("");
    const [role_id, setRole] = useState("");
    const [profile_image, setProfileImage] = useState("");
    const [profile_image_pre, setProfileImagePre] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        CountryListFetch();
    }, []);
    useEffect(() => {
        if(userToken != ''){
            RoleListFetch();
            DataFetch();
        }
    }, [userToken]);

    function CloseMobileModel(){
        setModelMobileOpen(false);
    }

    function CloseEmailModel(){
        setModelEmailOpen(false);
    }

    const DataFetch = () => {
        setLoading(true);
        postWithToken(API_URL, 'user/view', {id: location.state.id}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setUserData({IsLoading : false, list: result.data});

                setCategory(result.data.category);
                setName(result.data.name);
                if(result.data.email != null){
                    setEmail(result.data.email);
                }
                setEmailVerified(result.data.is_email_verified);
                setPhone(result.data.phone);
                setPhoneVerified(result.data.is_phone_verified);
                setDesignation(result.data.designation);
                setAddressOne(result.data.address_1);
                setAddressTwo(result.data.address_2);
                setPinCode(result.data.pin_code);
                setCountry(result.data.country_id);
                if(result.data.country_id > 0){
                    fetchState(result.data.country_id);
                }
                setState(result.data.state_id);
                if(result.data.state_id > 0){
                    fetchCity(result.data.state_id);
                }
                setCity(result.data.city_id);

                if(result.data.category == 1){
                    setRole(result.data.roles[0].id);
                }

                if(result.data.profile_image != null){
                    setProfileImagePre(result.data.profile_image);
                }
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const RoleListFetch = () => {
        postWithToken(API_URL, 'active-roles-list', {}, userToken).then((result) => {
            if(result.status === true){
                setRoleList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const CountryListFetch = () => {
        postWithOutTokenWithoutData(API_URL, 'countries').then((result) => {
            if(result.status === true){
                    setCountryList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchState = (country_id) => {
        postWithOutToken(API_URL, 'states',{country_id: country_id}).then((result) => {
            if(result.status === true){
                    setStateList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    const fetchCity = (state_id) => {
        postWithOutToken(API_URL, 'cities',{state_id: state_id}).then((result) => {
            if(result.status === true){
                setCityList(result.data);
            }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }
  
    //alert start
  
    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 
  
  function onSelectFile(event) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const reader = new window.FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        //upload_profile(reader.result);

       // console.log(reader.result);
        setProfileImage(event.target.files[0]);
        setProfileImagePre(reader.result);

        console.log(event.target.files[0]);

      };
    }
  }

  const UpdateUser = (event) => {
    event.preventDefault();
    
    let flg = true;
    const all_error_msg = [];

    if(category == ''){
        all_error_msg.push("Please select User Category");
        if(flg == true){flg = false;}
    }

    if(name.search(/\S/) == -1){
        all_error_msg.push("Please enter user name");
        if(flg == true){flg = false;}
    }

    if(phone.search(/\S/) == -1){
        all_error_msg.push("Please enter mobile number");
        if(flg == true){flg = false;} 
    }else{
        if(phone_verified === 0){
            all_error_msg.push("Please verify your mobile number");
            if(flg == true){flg = false;}  
        }
    }

   if(email.search(/\S/) != -1){
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      if (reg.test(email) === false) {
        all_error_msg.push("Please enter valid email Id");
        if(flg == true){flg = false;}
      }else if(email_verified === 0){
          all_error_msg.push("Please verify your email");
            if(flg == true){flg = false;}
      }
    }   

    if(category == 1){
        if(designation.search(/\S/) == -1){
            all_error_msg.push("Please enter your designation");
            if(flg == true){flg = false;}  
        }
    
        if(role_id == ''){
            all_error_msg.push("Please select user role");
            if(flg == true){flg = false;}  
        }
    }

    if (flg === true) {
      setLoading(true);

      let post_data = {
        id: location.state.id,
        category: category,
        name: name,
        email: email,
        phone: phone,
        is_email_verified: email_verified,
        is_phone_verified: phone_verified,
        designation: designation,
        address_1: address_1,
        address_2: address_2,
        pin_code: pin_code,
        country_id: country_id,
        state_id: state_id,
        city_id: city_id,
        role_id: role_id,
        profile_image: profile_image,
      };

      postWithTokenFormData(API_URL, 'user/update', post_data, userToken).then((result) => {
          if(result.status === true){
                setLoading(false);
                if(location.state.page == "clientuser"){
                    navigate("/dashboard/clientuser");
                }else if(location.state.page == "executive"){
                    navigate("/dashboard/executive");
                }else{
                    navigate("/dashboard/contactlist");
                }
            }else{
              setLoading(false);
              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 
  }

   const GenrateMobileOtp = () => {

    let flg = true;
    const all_error_msg = [];

    if(phone.search(/\S/) == -1){
        all_error_msg.push("Please enter your mobile number");
        if(flg == true){flg = false;}
    }else{
        if(phone.length > 10 || phone.length < 10){
            all_error_msg.push("Please enter 10 digit mobile number");
            if(flg == true){flg = false;}
        }
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-phone-verify-otp-send', {phone: phone}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelMobileOpen(true);
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }

  }

  const VerifyMobileOtp = () =>{
    let flg = true;
    const all_error_msg = [];

    if(mobile_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your mobile number");
        if(flg == true){flg = false;}
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-phone-otp-verify', {phone: phone, otp: mobile_otp}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelMobileOpen(false);
                setPhoneVerified(1);
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }

  const GenrateEmailOtp = () => {
    let flg = true;
    const all_error_msg = [];

    //setModelEmailOpen(true);

    if(email.search(/\S/) == -1){
		all_error_msg.push("Please enter your email Id");
		if(flg == true){flg = false;}
	}else if(email.search(/\S/) != -1){
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(email) === false) {
			all_error_msg.push("Please enter valid email Id");
			if(flg == true){flg = false;}
		}
	}

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-email-verify-otp-send', {email: email}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelEmailOpen(true);
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }

  const VerifyEmailOtp = () =>{
    let flg = true;
    const all_error_msg = [];

    if(email_otp.length < 6){
        all_error_msg.push("Please enter 6 digit otp to verify your email");
        if(flg == true){flg = false;}
    }

    if(flg == true){
        setLoading(true);
        postWithToken(API_URL, 'temp-email-otp-verify', {email: email, otp: email_otp}, userToken).then((result) => {
            console.log(result);
            setLoading(false);
            if(result.status === true){
                setModelEmailOpen(false);
                setEmailVerified(1);
                
            }else{
                  all_error_msg.push(result.message);
                  onShowAlert("error", all_error_msg);
            }
    
        })
        .catch((error) => {
                console.log("Error : ", error);
        });
    }else{
        onShowAlert("error", all_error_msg);
    }
  }

    return(
        <div className="addUser" >

            <CustomAlert
              header={"Edit User"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

            <div class="pageTop"><h2><i class="fa fa-user-circle-o" aria-hidden="true"></i>User Edit</h2></div>
            <form onSubmit={UpdateUser}>
                <div className="photoUpload">
                    <div className="uploadePic">
                        {
                            profile_image_pre != '' ?

                            <img src={profile_image_pre} />

                            : <img src={profile_icon} />
                        }
                        
                    </div>
                    <div className="fileUpload">
                        <span>Upload Photo</span>
                        <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectFile(e)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label>Category<sub>*</sub></label>
                           
                            {
                                location.state.page == "clientuser" ?

                                <select value={category} onChange={(e) => {setCategory(e.target.value)}}>
                                    <option value="">Select Category</option>
                                    <option  key={'non_executive'} value="2">Non-Executive</option>
                                </select>
                                   
                                : location.state.page == "executive" ? 

                                <select value={category} onChange={(e) => {setCategory(e.target.value)}}>
                                    <option value="">Select Category</option>
                                    <option key={'executive'} value="1">Executive</option>
                                </select>

                                : 

                                <select value={category} onChange={(e) => {setCategory(e.target.value)}}>
                                    <option value="">Select Category</option>
                                    <option key={'executive'} value="1">Executive</option>
                                    <option  key={'non_executive'} value="2">Non-Executive</option>
                                </select>
                            }

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Name<sub>*</sub></label>
                            <input type="text" placeholder="Name" value={name} onChange={(event) => setName(event.target.value)}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Mobile Number<sub>*</sub></label>
                            {
                                phone_verified == 1 ?
                            <input type="text" placeholder="Mobile Number" value={phone} maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}} onChange={(event) => setPhone(event.target.value)} readOnly/>
                                :
                                <input type="text" placeholder="Mobile Number" value={phone} maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                    return true;
                            }else{event.preventDefault();}}} onChange={(event) => setPhone(event.target.value)}/>

                                }
                             {
                                phone_verified == 1 ?
                                <div>
                                <button type="button" className="verifiedfld" style={{width: 'auto', right: 50}}>Verified</button>
                                <button type="button" className="verifyfld" style={{marginLeft: 10, width: 'auto'}} onClick={() => setPhoneVerified(0)}>Edit</button>
                                </div>
                                : 
                                <button type="button" className="verifyfld" style={{width: 'auto'}} onClick={() => {GenrateMobileOtp()}}>Verify</button>
                             }
                            
                            
                        </div>
                    </div>
                    {
                        category == 1 ?
                        <>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Role<sub>*</sub></label>
                                    <select value={role_id} onChange={(e) => {setRole(e.target.value)}}>
                                        <option value="">Select Role</option>
                                        
                                        {roleList.length > 0 ?

                                            roleList.map((item, key) => {

                                                return(
                                                    <option key={'role_' + key} value={item.id}>{item.name}</option>
                                                );

                                            })

                                            : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Designation<sub>*</sub></label>
                                    <input type="text" placeholder="Designation" value={designation} onChange={(event) => setDesignation(event.target.value)}/>
                                </div>
                            </div>
                        </>
                      : null
                    }    
                    <div className="col-4">
                        <div className="form-group">
                            <label>EMail ID</label>
                            {
                                email_verified == 1 ?
                               <input type="text" placeholder="Email Id" value={email} onChange={(event) => setEmail(event.target.value)} readOnly/>
                            :
                               <input type="text" placeholder="Email Id" value={email} onChange={(event) => setEmail(event.target.value)}/>
                            }
                            {
                                email_verified == 1 ?
                                <div>
                                <button type="button" className="verifiedfld" style={{width: 'auto', right: 50}}>Verified</button>
                                <button type="button" className="verifyfld" style={{marginLeft: 10, width: 'auto'}} onClick={() => setEmailVerified(0)}>Edit</button>
                                </div>
                                :
                                <button type="button" className="verifyfld" style={{width: 'auto'}} onClick={() => GenrateEmailOtp()} >Verify</button>
                            }
                                                        
                        </div>
                    </div>
                    
                    <div className="col-4">
                        <div className="form-group">
                            <label>Address1</label>
                            <input type="text" placeholder="Address1" value={address_1} onChange={(event) => setAddressOne(event.target.value)}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Address2</label>
                            <input type="text" placeholder="Address2" value={address_2} onChange={(event) => setAddressTwo(event.target.value)}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Country</label>
                            <select value={country_id} onChange={(e) => {setCountry(e.target.value); fetchState(e.target.value)}}>
                                <option value="">Select Country</option>
                                {
                                    countryList.length > 0 ?

                                    countryList.map((item, key) => {

                                        return(
                                            <option key={'country_' + key} value={item.id}>{item.country_name}</option>
                                        );

                                    })

                                    : null
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label>Pincode</label>
                            <input type="text" placeholder="Pincode" value={pin_code} onChange={(event) => setPinCode(event.target.value)}/>
                        </div>
                    </div>
                    
                    <div className="col-4">
                        <div className="form-group">
                            <label>State</label>
                            <select value={state_id} onChange={(e) => {setState(e.target.value); fetchCity(e.target.value)}}>
                                <option value="">Select State</option>
                                {
                                    stateList.length > 0 ?
                                    stateList.map((item, key) => {
                                        return(
                                            <option key={'state_' + key} value={item.id}>{item.name}</option>
                                        );
                                    })

                                    : null
                                }
                            </select>
                        </div>
                    </div>
                        
                    <div className="col-4">
                    <div className="form-group">
                            <label>City</label>
                            <select value={city_id} onChange={(e) => {setCity(e.target.value)}}>
                                <option value="">Select City</option>
                                {
                                    cityList.length > 0 ?

                                    cityList.map((item, key) => {

                                        return(
                                            <option key={'city_' + key} value={item.id}>{item.name}</option>
                                        );

                                    })

                                    : null
                                }
                            </select>
                        </div>
                    </div>
                    
                </div>
                {/*<div className="col-4">
                    <div className="form-group">
                       <div className="flxFld">
                        <button type='submit'>Update</button>
                        </div>
                    
                    </div>
                            </div>*/}

                <div className="col-12">
                        <div className="form-group">
                                <div className="btnsec">
                                    <button type="button" className="fadebtn" onClick={() => {location.state.page == "clientuser" ? navigate("/dashboard/clientuser") : location.state.page == "executive" ?  navigate("/dashboard/executive") : navigate("/dashboard/contactlist")}}>Cancel</button>
                                    <button type="submit" className="fillbtn">Update</button>
                                </div>
                        </div>
                    </div>
            </form>


            <Modal isOpen={modalMobileOpen} onRequestClose={CloseMobileModel} contentLabel="Mobile OTP Modal" >
                <div className='modal_header'>
                Mobile Verification OTP <button onClick={CloseMobileModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody'>
                   
                        <div className='row'>
                            <div className='col-12 otp_input_parent_div' >
                                <OtpInput
                                    value={mobile_otp}
                                    onChange={setMobileOtp}
                                    numInputs={6}
                                    inputType={'tel'}
                                    renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                    renderInput={(props) => <input {...props} />}
                                    />
                            </div>
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseMobileModel}>Cancel</button>
                                    <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyMobileOtp} >Verify OTP</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>

            <Modal isOpen={modalEmailOpen} onRequestClose={CloseEmailModel} contentLabel="Email OTP Modal" >
                <div className='modal_header'>
                 Email Verification OTP <button onClick={CloseEmailModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody'>
                <form>
                    <div className='row'>
                        <div className='col-12 otp_input_parent_div'>
                            <OtpInput
                                value={email_otp}
                                onChange={setEmailOtp}
                                numInputs={6}
                                inputType={'tel'}
                                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                renderInput={(props) => <input {...props} />}
                                />
                        </div>
                                           
                        <div className='col-12'>
                            <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseEmailModel}>Cancel</button>
                                <button type='button' className='fillbtn' style={{width: 'auto'}} onClick={VerifyEmailOtp} >Verify OTP</button>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </Modal>

        </div>

    )
}
export default EditUser;