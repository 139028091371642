import { Link } from "react-router-dom";
import pdf from "../../assets/images/pdf.svg"
import xlx from "../../assets/images/xls.svg"
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const SystemHistory = () =>{
      const navigate = useNavigate();
      const contactListhead =["Date & Time",  "Module", "Action Type", "Action Details","Account", "Case ID","User"];
      const contactlistdata =[
            {
               date:"12/14/2023",
               acc:"Jhon Deo",
               job:"Designer",
               id:"Type",
               intraction:"Some Details",
               rise:"Admin",
               case:"542JGF",
            },
            {
                date:"12/14/2023",
               acc:"Jhon Deo",
               job:"Designer",
               id:"Type",
               intraction:"Some Details",
               rise:"Admin",
               case:"542JGF",
               },
               {
                date:"12/14/2023",
                acc:"Jhon Deo",
                job:"Designer",
                id:"Type",
                intraction:"Some Details",
                rise:"Admin",
                case:"542JGF",
               },
               {
                date:"12/14/2023",
                acc:"Jhon Deo",
                job:"Designer",
                id:"Type",
                intraction:"Some Details",
                rise:"Admin",
                case:"542JGF",
                  },
                  {
                    date:"12/14/2023",
                    acc:"Jhon Deo",
                    job:"Designer",
                    id:"Type",
                    intraction:"Some Details",
                    rise:"Admin",
                    case:"542JGF",
                     },
                     {
                        date:"12/14/2023",
                        acc:"Jhon Deo",
                        job:"Designer",
                        id:"Type",
                        intraction:"Some Details",
                        rise:"Admin",
                        case:"542JGF",
                        },
                        {
                            date:"12/14/2023",
                            acc:"Jhon Deo",
                            job:"Designer",
                            id:"Type",
                            intraction:"Some Details",
                            rise:"Admin",
                            case:"542JGF",
                           },
                           {
                            date:"12/14/2023",
                            acc:"Jhon Deo",
                            job:"Designer",
                            id:"Type",
                            intraction:"Some Details",
                            rise:"Admin",
                            case:"542JGF",
                              },
           

      ]
    return(
        <div className="accounthistory">
            <div className="pageTop">
                  <h2><i class="fa fa-file-text-o" aria-hidden="true"></i> System History</h2>
                  <div className="right-action">
                  <form>
             
                      <input type="text" placeholder="User"/>
                      <input type="text" placeholder="Module"/>
                      <input type="text" placeholder="Account"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>

                      </form>
                      <div className="todateformdate">
                      <input type="date" placeholder="Account"/>
                      <input type="date" placeholder="Module"/>
                      </div>
                        <Link className="xlxbtn"><img src={xlx}/></Link>
                        <Link className="pdfbtn"><img src={pdf}/></Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index} >
                                
                                    <td> {item.date}</td>
                                    <td>{item.acc}</td>
                                    <td>{item.job}</td>
                                    <td>{item.id}</td>
                                    <td>{item.intraction}</td>
                                    <td>{item.rise}</td>
                                    <td>{item.case}</td>
                                    
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default SystemHistory;