import { Link } from "react-router-dom";
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const PaymentLink = () =>{
      const navigate = useNavigate();
      const addService = () =>{
            navigate("/layout/linkdetails")
      }
    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const contactListhead =[checkbox(), "Date", "Account Name", "Invoice Number", "Case ID", "Status","Amount","Action"];
      const contactlistdata =[
            {
               checkbox:checkbox(),
               date:"12/12/2023",
               number:"Jhon Deo",
               ref:"RDFGF55485512",
               acname:"12/08/2023",
               status:"Success",
               caseid:"5000.0",
            },
            {
                  checkbox:checkbox(),
                  date:"12/12/2023",
                  number:"Jhon Deo",
                  ref:"RDFGF55485512",
                  acname:"12/08/2023",
                  status:"Reject",
                  caseid:"5000.0",
               },
               {
                  checkbox:checkbox(),
                  date:"12/12/2023",
                  number:"Jhon Deo",
                  ref:"RDFGF55485512",
                  acname:"12/08/2023",
                  status:"Success",
                  caseid:"5000.0",
               },
               {
                  checkbox:checkbox(),
                  date:"12/12/2023",
                  number:"Jhon Deo",
                  ref:"RDFGF55485512",
                  acname:"12/08/2023",
                  status:"Reject",
                  caseid:"5000.0",
                  },
                  {
                        checkbox:checkbox(),
                        date:"12/12/2023",
                        number:"Jhon Deo",
                        ref:"RDFGF55485512",
                        acname:"12/08/2023",
                        status:"Success",
                        caseid:"5000.0",
                     },
                     {
                        checkbox:checkbox(),
                        date:"12/12/2023",
                        number:"Jhon Deo",
                        ref:"RDFGF55485512",
                        acname:"12/08/2023",
                        status:"Success",
                        caseid:"5000.0",
                        },
                        {
                              checkbox:checkbox(),
                              date:"12/12/2023",
                              number:"Jhon Deo",
                              ref:"RDFGF55485512",
                              acname:"12/08/2023",
                              status:"Success",
                              caseid:"5000.0",
                           },
                           {
                              checkbox:checkbox(),
                              date:"12/12/2023",
                              number:"Jhon Deo",
                              ref:"RDFGF55485512",
                              acname:"12/08/2023",
                              status:"Reject",
                              caseid:"5000.0",
                              },
           

      ]
    return(
        <div className="joblist">
            <div className="pageTop">
                  <h2><i class="fa fa-headphones" aria-hidden="true"></i>Payment Link</h2>
                  <div className="right-action">
                  <form>
             
                      <input type="text" placeholder="Account"/>
                      <input type="text" placeholder="Job"/>
                      <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                      </form>
                        <Link className="fadebtn" onClick={addService}>New Payment</Link>
                        <Link className="fillbtn">Bulk action</Link>
                  </div>
            </div>
               <div className="tableResponsive">
                 <table>
                  <thead>
                        <tr>
                        {
                        contactListhead.map((item, index)=>{
                              return <th key={index}>{item}</th>
                        })
                        }
                        </tr>
                        
                  </thead>
                  <tbody>
                    {
                        contactlistdata.map((item, index)=>{
                              return <tr key={index}>
                                    <td> {item.checkbox}
                                    </td>
                                    <td> {item.date}</td>
                                    <td>{item.number}</td>
                                    <td>{item.ref}</td>
                                    <td>{item.acname}</td>
                                    <td className={item.status == "Success"? "active":"inactive"}>{item.status}</td>
                                    <td>{item.caseid}</td>
                                    <td><span className="copybtn"><i class="fa fa-clone" aria-hidden="true"></i> Copy</span></td>
                              </tr>
                        })
                    }
                  </tbody>
                 </table>
               </div>
               <div className="tablenav">
                <p>See all 12 result </p>
            </div>
            </div>
    )
}
export default PaymentLink;