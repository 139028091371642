import edit from "../../../../assets/images/edit.svg";
import bin from '../../../../assets/images/delete.svg';
import view from '../../../../assets/images/view.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

const AccountStatutory =()=>{

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = (id) => {
      
        setOpen(true);
        setDeleteIds(id);
    };
    
      const handleClose = () => {
        setOpen(false);
      };

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [modalStatutoryInfoOpen, setModelStatutoryInfoOpen] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [statutory_info, setStatutoryInfoData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [deleteIds, setDeleteIds] = useState(null);
    const [statutory_id, setStatutoryId] = useState(null);
    const [account_id, setAccountId] = useState("");

    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [is_document_available, setDocumentAvailable] = useState(1);

    const [edit_statutory, setEditStatutory] = useState(false);

    const [statutoryList, setStatutoryList] = useState({IsLoading : true, list: []});
    const [static_statutorys, setStaticStatutory] = useState([]);
    const [new_statutorys, setNewStatutorys] = useState([]);
    const [old_statutorys, setOldStatutorys] = useState([]);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                setAccountId(location.state.id);
                StatutoryListFetch(location.state.id); 
            }
        }
    }, [userToken]);

    
    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const ChangeTab = (tab_url) => {
            const all_error_msg = [];
            if(location.state.id == null){
                all_error_msg.push("Please Create Account Profile First");
                onShowAlert("error", all_error_msg);
            }else{
                navigate(tab_url, {state: {id: location.state.id}})
            }
      }

        const contactListhead =[ "Statutory Deails", "Statutory Number", "Document Available", "Action"];
               
        const reloadList = ()=>{
            StatutoryListFetch(location.state.id); 
        }
       
        const StatutoryListFetch = (account_id) => {
            setLoading(true);
            postWithToken(API_URL, 'account-statutory-list', {account_id: account_id}, userToken).then((result) => {
                console.log(result);
                setLoading(false);
                if(result.status === true){
                    setStatutoryList({IsLoading : false, list: result.data});
                    setStaticStatutory(result.data);
                    setOldStatutorys(result.new_documents);
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }
       
        const CreateStatutory = () => {
            
            let flg = true;
            const all_error_msg = [];

            if(old_statutorys.length > 0){
              old_statutorys.map((item, index) => {
                if(item.name.search(/\S/) == -1){
                  all_error_msg.push("Please enter Other Statutory Name");
                  if(flg == true){flg = false;}
                }

                if(item.number.search(/\S/) == -1){
                  all_error_msg.push("Please enter Other Statutory Number");
                  if(flg == true){flg = false;}
                }
              })
            }

            if(new_statutorys.length > 0){
              new_statutorys.map((item, index) => {
                if(item.name.search(/\S/) == -1){
                  all_error_msg.push("Please enter New Statutory Name or remove");
                  if(flg == true){flg = false;}
                }

                if(item.number.search(/\S/) == -1){
                  all_error_msg.push("Please enter New Statutory Number or remove");
                  if(flg == true){flg = false;}
                }
              })

            }
        
            if (flg === true) {
              setLoading(true);
        
                  let post_data = {
                    account_id: account_id,
                    static_statutorys: static_statutorys,
                    old_statutorys: old_statutorys,
                    new_statutorys: new_statutorys,
                  };
                  
                  postWithToken(API_URL, 'account-statutory/update', post_data, userToken).then((result) => {
                    if(result.status === true){
                          setLoading(false);
                          setNewStatutorys([]);
                          all_error_msg.push(result.message);
                          onShowAlert("success", all_error_msg);

                          reloadList();
                         
                      }else{
                        setLoading(false);
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                      }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                
              }else{
                onShowAlert("error", all_error_msg);
                
              } 
          }
         
         
         const deleteStatutory = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];
     
            postWithToken(API_URL, 'account-statutory/delete', {id: id, account_id: account_id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){
                        const newArray = old_statutorys.filter((item) => item.id !== id);
                        setOldStatutorys(newArray);
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
          
      }

      const chageStaticValue = (index, val, id, val_id) => {
          static_statutorys[index].number = val;
          $('#' + id).val(val);

          if(val.length > 0){
              $('#static_yes_no_' + val_id).removeClass('hide_class');
          }else{
            $('#static_yes_no_' + val_id).addClass('hide_class');
          }

      }

      const changeStaticCheckbox = (index, val, checked_id, unchecked_id) => {
        static_statutorys[index].is_document_available = val;
        $("#" + unchecked_id).prop("checked", false);
        $("#" + checked_id).prop("checked", true);
       
      }

      useEffect(() => {
        if(static_statutorys.length > 0){
              static_statutorys.map((item,index) => {

                $('#static_number_' + item.id).val(item.number);

                  if(item.is_document_available == 1){
                    $(".static_yes_" + item.id).prop("checked", true);

                  }else if(item.is_document_available == 0){
                    $(".static_no_" + item.id).prop("checked", true);
                  }

                  if(item.number == null){
                    $('#static_yes_no_' + item.id).addClass('hide_class');
                  }else{
                    $('#static_yes_no_' + item.id).removeClass('hide_class');
                  }

                })
        }
        if(old_statutorys.length > 0){
          old_statutorys.map((item,index) => {

            $('#old_number_' + item.id).val(item.number);
            $('#old_name_' + item.id).val(item.name);

              if(item.is_document_available == 1){
                $(".old_yes_" + item.id).prop("checked", true);

              }else if(item.is_document_available == 0){
                $(".old_no_" + item.id).prop("checked", true);
              }

              if(item.number == null){
                $('#old_yes_no_' + item.id).addClass('hide_class');
              }else{
                $('#old_yes_no_' + item.id).removeClass('hide_class');
              }

            })
        }
    }, [static_statutorys, old_statutorys]);

    const AddAnyOther = () => {
       if(new_statutorys.length > 0){

        let arr = {name: '', number: '', is_document_available: 0};
        new_statutorys.push(arr);
        setNewStatutorys(new_statutorys);

       }else{
        setNewStatutorys([{name: '', number: '', is_document_available: 0}]);
       }
    }

    const chageNewValue = (field_type, index, val, id) => {
      if(field_type == "number"){
        new_statutorys[index].number = val;


      }else if(field_type == "name"){
        new_statutorys[index].name = val;
      }
     
      $('#' + id).val(val);
    }

    const changeNewCheckbox = (index, val, checked_id, unchecked_id) => {
      new_statutorys[index].is_document_available = val;
      $("#" + unchecked_id).prop("checked", false);
      $("#" + checked_id).prop("checked", true);
    
    }
    const DeleteNewRow = (index) => {
      const arr = new_statutorys;
      arr.splice(index, 1);
      setNewStatutorys(arr);
      $('#new_' + index).remove();
    }

    const chageOldValue = (field_type, index, val, id, val_id) => {
      if(field_type == "number"){
        old_statutorys[index].number = val;

        if(val.length > 0){
          $('#old_yes_no_' + val_id).removeClass('hide_class');
        }else{
          $('#old_yes_no_' + val_id).addClass('hide_class');
        }

      }else if(field_type == "name"){
        old_statutorys[index].name = val;
      }
     
      $('#' + id).val(val);
    }

    const changeOldCheckbox = (index, val, checked_id, unchecked_id) => {
      old_statutorys[index].is_document_available = val;
      $("#" + unchecked_id).prop("checked", false);
      $("#" + checked_id).prop("checked", true);
    
    }

     return(
        <>

              <CustomAlert
                header={"Statutory Info"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }

               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete Statutory Details"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this Statutory
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    Disagree
                              </Button>
                              <Button onClick={() => {deleteStatutory(deleteIds)}} autoFocus>
                                    Agree
                              </Button>
                        </DialogActions>
                  </Dialog>


            <div className="accountProfileTabs">
                <div className="pageTop">
                    <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer Statutory</h2> 
                    <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountpartner')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
                </div>
                <div className="customTab">
                    <div className="account_button_tab">
                        <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                        <button  onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                        <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                        <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
                    </div>
                    <div className="accountstatutory">
                       
                        <div className="bankTable">
                            <div className="tableResponsive">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                            contactListhead.map((item, index)=>{
                                                return <th key={index}>{item}</th>
                                                })
                                            }
                                        </tr>

                                    </thead>
                                    {
                                        statutoryList.IsLoading === false && statutoryList.list.length > 0 ?
                                        <tbody>
                                            {
                                                static_statutorys.map((item, index) => {

                                                    return(
                                                        <tr key={'static_' + index}>
                                                            <td >{item.name}</td>
                                                            <td >
                                                               {edit_statutory === false ?
                                                                <input type="text" readOnly id={'static_number_' + item.id} onChange={(event) => {chageStaticValue(index, event.target.value, 'static_number_' + item.id, item.id)}}/>

                                                               :
                                                                <input type="text" id={'static_number_' + item.id} onChange={(event) => {chageStaticValue(index, event.target.value, 'static_number_' + item.id, item.id)}}/>
                                                               }
                                                              </td>
                                                            <td >
                                                              
                                                                  <div id={'static_yes_no_' + item.id} className="checkBoxGroup">
                                                                      <div className="checkBoxGroupItem">
                                                                      <input type="radio" className={'static_yes_' + item.id} id={'static_yes_' + index} name={'static-radio-group-' + index} onChange={() => {changeStaticCheckbox(index, 1, 'static_yes_' + index, 'static_no_' + index)}}/>
                                                                      <label for={'static_yes_' + index}>Yes</label>
                                                                      </div>
                                                                      <div className="checkBoxGroupItem">
                                                                      <input type="radio" className={'static_no_' + item.id} id={'static_no_' + index} name={'static-radio-group-' + index} onChange={() => {changeStaticCheckbox(index, 0, 'static_no_' + index, 'static_yes_' + index)}}/>
                                                                      <label for={'static_no_' + index}>No </label>
                                                                      </div>
                                                                  </div>
                                                                 
                                                            </td>
                                                            <td >
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }

                                           {
                                            old_statutorys.length > 0 ?
                                              old_statutorys.map((item, index) => {

                                                  return(
                                                      <tr id={'old_' + item.id} key={'old_' + item.id}>
                                                            <td >
                                                            {edit_statutory === false ?
                                                              <input type="text" readOnly id={'old_name_' + item.id} onChange={(event) => {chageOldValue('name',index, event.target.value, 'old_name_' + index, item.id)}}/>
                                                              :
                                                              <input type="text" id={'old_name_' + item.id} onChange={(event) => {chageOldValue('name',index, event.target.value, 'old_name_' + index, item.id)}}/>

                                                            }
                                                            </td>
                                                            <td >
                                                            {edit_statutory === false ?
                                                              <input type="text" readOnly id={'old_number_' + item.id} onChange={(event) => {chageOldValue('number',index, event.target.value, 'old_number_' + index, item.id)}}/>
                                                            :
                                                            <input type="text" id={'old_number_' + item.id} onChange={(event) => {chageOldValue('number',index, event.target.value, 'old_number_' + index, item.id)}}/>

                                                             }
                                                              </td>
                                                            <td >
                                                              <div id={'old_yes_no_' + item.id} className="checkBoxGroup">
                                                                  <div className="checkBoxGroupItem">
                                                                  <input type="radio" className={'old_yes_' + item.id} id={'old_yes_' + index} name={'old-radio-group-' + index} onChange={() => {changeOldCheckbox(index, 1, 'old_yes_' + index, 'old_no_' + index)}}/>
                                                                  <label for={'old_yes_' + index}>Yes</label>
                                                                  </div>
                                                                  <div className="checkBoxGroupItem">
                                                                  <input type="radio" className={'old_no_' + item.id} id={'old_no_' + index} name={'old-radio-group-' + index} onChange={() => {changeNewCheckbox(index, 0, 'old_no_' + index, 'old_yes_' + index)}}/>
                                                                  <label for={'old_no_' + index}>No </label>
                                                                  </div>
                                                              </div>
                                                            </td>
                                                            <td >
                                                                <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id)}><img src={bin}/></button>
                                                            </td>
                                                      </tr>
                                                  )
                                                })
                                                : null
                                            }

                                            {
                                              new_statutorys.length > 0 ?
                                              new_statutorys.map((item, index) => {

                                                return(
                                                     <tr id={'new_' + index} key={'new_' + index}>
                                                          <td ><input type="text" id={'new_name_' + index} onChange={(event) => {chageNewValue('name',index, event.target.value, 'new_name_' + index)}}/></td>
                                                          <td ><input type="text" id={'new_number_' + index} onChange={(event) => {chageNewValue('number',index, event.target.value, 'new_number_' + index)}}/></td>
                                                          <td >
                                                            <div className="checkBoxGroup">
                                                                <div className="checkBoxGroupItem">
                                                                <input type="radio" id={'new_yes_' + index} name={'new-radio-group-' + index} onChange={() => {changeNewCheckbox(index, 1, 'new_yes_' + index, 'new_no_' + index)}}/>
                                                                <label for={'new_yes_' + index}>Yes {item.number}</label>
                                                                </div>
                                                                <div className="checkBoxGroupItem">
                                                                <input type="radio" id={'new_no_' + index} name={'new-radio-group-' + index} onChange={() => {changeNewCheckbox(index, 0, 'new_no_' + index, 'new_yes_' + index)}}/>
                                                                <label for={'new_no_' + index}>No </label>
                                                                </div>
                                                            </div>
                                                          </td>
                                                          <td >
                                                              <button style={{ marginLeft: 6}} onClick={() => DeleteNewRow(index)}><img src={bin}/></button>
                                                          </td>
                                                    </tr>
                                                )
                                              })
                                              : null
                                            }
                                    
                                    </tbody>
                                    : statutoryList.IsLoading === false && statutoryList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={4} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : statutoryList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }
                                   
                                </table>

                            </div>

                                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>

                                  <div >
                                    <button type="button" className="addStatory" onClick={() => {AddAnyOther()}}>+ Add Any Other Document</button>
                                  </div>

                                  {
                                    edit_statutory === false ?
                                      <div >
                                        <button type="button" className="fillbtn" onClick={() => {setEditStatutory(true)}} >Edit Statutory</button>
                                      </div>
                                    :

                                    <div >
                                      <button type="button" className="fillbtn" onClick={() => {CreateStatutory()}} >Update Statutory</button>
                                     </div>

                                  }
                                </div>

                                <div className="tablenav">
                                    <div className="btnGroup">
                                        <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountpartner')}}>Previous</button>
                                        <button type="button" className="fillbtn" onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Next</button>
                                    </div>
                                </div>
                               
                        </div>

                    </div>
                </div>    
            </div>
        </>   
     )
}
export default AccountStatutory;