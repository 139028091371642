import { Link } from "react-router-dom"
import send from "../../../assets/images/send.svg"
import rupee from "../../../assets/images/rupee.svg"
function Transactions(){
   
    return(
        <div className="transction-table">
        <div className="tableResponsive">
            <table>
                <thead>
                    <tr>
                    {
                       /* taskHeading.map((item,index)=>{
                           return <th key={index}>{item}</th>
                        }) */
                    }
              
                </tr>
                </thead>

                <tbody>
                {
                    /*task.map((item, index)=>{
                        return <tr key={index}>
                            <td>{item}</td>
                            <td>
                              <input type="text" />
                            </td>
                            <td>
                            <input type="text" />
                            </td>
                            <td>
                            <input type="text" />
                            </td>
                            <td>
                            <input type="text" />
                            </td>
                            <td>
                            <Link>
                                <img src={rupee}/></Link>
                                <Link>
                                <img src={send}/></Link>
                            </td>
                        </tr>
                    })*/
                }
                </tbody>
                
            </table>
            
        </div>
        <div className="tablenav">
                <p>See all 12 result </p>
                <button type="button" className="fillBtn">Update</button>
            </div>
    </div>
    )
}

export default Transactions;