import edit from "../../../../assets/images/edit.svg";
import bin from '../../../../assets/images/delete.svg';
import view from '../../../../assets/images/view.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import Modal from 'react-modal';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux';
import {postWithToken} from "../../../../Service/service";

import CustomAlert from "../../../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReactPaginate from 'react-paginate';

const AccountBankDetails =()=>{

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = (id, type) => {

      setDeleteType(type);

      if(type == 'M'){
        const all_error_msg = [];
        let selected_arr = [];

        let checkbox_all_access_length = $('.bank_checkbox:checked').length;

        if(checkbox_all_access_length > 0){
                setOpen(true);
                $('.bank_checkbox:checked').each(function(){
                    selected_arr.push($(this).val());
                    
                });

                setDeleteIds(selected_arr);
        }else{
                all_error_msg.push("Please select atleast one row");
                onShowAlert("error", all_error_msg);
        }
      }else if(type == 'S'){
        setOpen(true);
        setDeleteIds(id);
      }
    
    };
    
      const handleClose = () => {
        setOpen(false);
      };

    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#C00305");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
  
    const dispatch = useDispatch();
    const location = useLocation();
  
    const { API_URL, userData, userToken} = useSelector(state => state.common);
    const [modalBankInfoOpen, setModelBankInfoOpen] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [bank_info, setBankInfoData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");
    const [deleteIds, setDeleteIds] = useState(null);
    const [bank_id, setBankId] = useState(null);
    const [account_id, setAccountId] = useState("");
    const [customer_name, setCustomerName] = useState("");
    const [type, setType] = useState("");
    const [account_type, setAccountType] = useState("");
    const [branch_name, setBranchName] = useState("");
    const [bank_name, setBankName] = useState("");
    const [bank_account_no, setBankAccountNo] = useState("");
    const [ifsc_code, setIFSC] = useState("");
    const accountTypeList = [{id: 1, val: 'Savings', name: 'Savings'},{id: 2, val: 'Current', name:'Current'},{id: 3, val: 'Salary', name: 'Salary'}, {id: 3, val: 'NRI', name: 'NRI'}]
    
    const [addBank, setAddBank] = useState(false);

    const [bankList, setbankList] = useState({IsLoading : true, list: [], total_count: 0});
    const [list_limit, setListLimit] = useState(20);
    const [page_selected, setPageSelected] = useState(null);

    useEffect(() => {
        if(userToken != ''){
            if(location.state.id != null){
                setAccountId(location.state.id);
                BankListFetch(0,list_limit, location.state.id); 
            }
        }
    }, [userToken]);


    function CloseBankInfoModel(){
      setModelBankInfoOpen(false);
    }

    //alert start
  
    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }
    
    //alert end 

    const checkbox = ()=>{
        return <div className="checkbox">
        <input class="styled-checkbox" id="styled-checkbox-all" type="checkbox" value="" onChange={() => CheckAll('styled-checkbox-all','bank_checkbox')}/>
        <label for="styled-checkbox-all"></label>
        </div>
        }
        const addBanks = (action_type) =>{
            if(action_type == "add"){
              setBankId(null);
            }

            setAddBank(true);
        }
       
        const reloadList = ()=>{
            setAddBank(false);
            setPageSelected(null);
            BankListFetch(0,list_limit, location.state.id); 
        }
        const contactListhead =[checkbox(), "Type", "Customer Name (Account Type)", "Bank, Branch Name", "Account No ", "IFSC Code", "Action"];
       
        const BankListFetch = (offset, limit, account_id) => {
            setLoading(true);
            postWithToken(API_URL, 'account-banks-list', {offset: offset, limit: limit, account_id: account_id}, userToken).then((result) => {
                //console.log(result);
                setLoading(false);
                if(result.status === true){
                    setbankList({IsLoading : false, list: result.data, total_count: result.total_count});
                }
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
        }

        const deleteBank = (id) => {
            handleClose();
            setLoading(true);
            const all_error_msg = [];

            if(deleteType == 'M'){
              /*console.log(id);

              if(id.length == 1){

                //console.log(itemOffset);
               
                let off = itemOffset;
                off = off - list_limit;

                //console.log(off);

                if(itemOffset > 0){
                  setItemOffset(off);
                 
                  setPageSelected(page_selected - 1);
                  BankListFetch(off, list_limit, account_id);
                }else{
                  setItemOffset(off);
                  setPageSelected(0);
                  BankListFetch(0, list_limit, account_id);
                }

              }else{

                let arr = bankList.list;

                id.map((ids, index) => {
                    let newArray = arr.filter((item) => item.id !== parseInt(ids));
                    arr = newArray;

                    if(id.length == (index + 1)){
                      setbankList({IsLoading : false, list: newArray, total_count: (bankList.total_count - id.length)});
                    }
                })
              }*/
             
              postWithToken(API_URL, 'account-banks/multiple-delete', {ids: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                                           
                          $("#styled-checkbox-all").prop("checked", false);
                          $(".bank_checkbox").prop("checked", false);

                      if(id.length == list_limit){
                          //console.log(itemOffset);
               
                          let off = itemOffset;
                          off = off - list_limit;

                          //console.log(off);

                          if(itemOffset > 0){
                            setItemOffset(off);
                          
                            setPageSelected(page_selected - 1);
                            BankListFetch(off, list_limit, account_id);
                          }else{
                            setItemOffset(off);
                            setPageSelected(0);
                            BankListFetch(0, list_limit, account_id);
                          }
                      }else{
                          let arr = bankList.list;

                          id.map((ids, index) => {
                              let newArray = arr.filter((item) => item.id !== parseInt(ids));
                              arr = newArray;

                              if(id.length == (index + 1)){

                                if(arr.length > 0){
                                  setbankList({IsLoading : false, list: newArray, total_count: (bankList.total_count - id.length)});

                                }else{
                                    let off = itemOffset;
                                    off = off - list_limit;

                                    if(itemOffset > 0){
                                      setItemOffset(off);
                                    
                                      setPageSelected(page_selected - 1);
                                      BankListFetch(off, list_limit, account_id);
                                    }else{
                                      setItemOffset(off);
                                      setPageSelected(0);
                                      BankListFetch(0, list_limit, account_id);
                                    }
                                }

                              }
                          })
                      }
                         
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }else if(deleteType == 'S'){
              postWithToken(API_URL, 'account-banks/delete', {id: id, account_id: account_id}, userToken).then((result) => {
                    //console.log(result);
                    setLoading(false);
                    if(result.status === true){
                          const newArray = bankList.list.filter((item) => item.id !== id);

                          if(newArray.length > 0){
                            setbankList({IsLoading : false, list: newArray, total_count: (bankList.total_count - 1)});

                          }else{
                              let off = itemOffset;
                              off = off - list_limit;

                              if(itemOffset > 0){
                                setItemOffset(off);
                              
                                setPageSelected(page_selected - 1);
                                BankListFetch(off, list_limit, account_id);
                              }else{
                                setItemOffset(off);
                                setPageSelected(0);
                                BankListFetch(0, list_limit, account_id);
                              }
                          }
                    }else{
                          all_error_msg.push(result.message);
                          onShowAlert("error", all_error_msg);
                    }

              })
              .catch((error) => {
                    console.log("Error : ", error);
              });
            }
        }


        const ChangeTab = (tab_url) => {
            const all_error_msg = [];
          
            if(location.state.id == null){
              all_error_msg.push("Please Create Account Profile First");
              onShowAlert("error", all_error_msg);
            }else{
              navigate(tab_url, {state: {id: location.state.id}})
            }
          }

          const CreateBankInfo = (event) => {
            event.preventDefault();
            
            let flg = true;
            const all_error_msg = [];

            if(type === ''){
                all_error_msg.push("Please select type");
                if(flg == true){flg = false;}
              }
        
            if(customer_name.search(/\S/) == -1){
                all_error_msg.push("Please enter Customer Name");
                if(flg == true){flg = false;}
            }
                  
        
            if(account_type === ''){
              all_error_msg.push("Please select account type");
              if(flg == true){flg = false;}
            }

            if(bank_name.search(/\S/) == -1){
                all_error_msg.push("Please enter bank name");
                if(flg == true){flg = false;}
              }
                         
        
            if(branch_name.search(/\S/) == -1){
              all_error_msg.push("Please enter branch name");
              if(flg == true){flg = false;}
            }

            if(bank_account_no.search(/\S/) == -1){
                all_error_msg.push("Please enter account number");
                if(flg == true){flg = false;}
              }

              if(ifsc_code.search(/\S/) == -1){
                all_error_msg.push("Please enter bank IFSC Code");
                if(flg == true){flg = false;}
              }

        
            if (flg === true) {
              setLoading(true);
        
                if(bank_id == null){
        
                  let post_data = {
                    account_id: account_id,
                    customer_name: customer_name,
                    type: type,
                    account_type: account_type,
                    branch_name: branch_name,
                    bank_name: bank_name,
                    bank_account_no: bank_account_no,
                    ifsc_code: ifsc_code,
                  };
        
                     postWithToken(API_URL, 'account-banks/create', post_data, userToken).then((result) => {
                        if(result.status === true){
                              setLoading(false);
                              all_error_msg.push(result.message);
                              onShowAlert("success", all_error_msg);

                              setCustomerName("");
                              setType("");
                              setAccountType("");
                              setBranchName("");
                              setBankName("");
                              setBankAccountNo("");
                              setIFSC("");

                              setPageSelected(null);

                              reloadList();
                             
                          }else{
                            setLoading(false);
                            all_error_msg.push(result.message);
                            onShowAlert("error", all_error_msg);
                          }
                      })
                      .catch((error) => {
                        console.log("Error : ", error);
                      });
                }else{
        
                  let post_data = {
                    id: bank_id,
                    account_id: account_id,
                    customer_name: customer_name,
                    type: type,
                    account_type: account_type,
                    branch_name: branch_name,
                    bank_name: bank_name,
                    bank_account_no: bank_account_no,
                    ifsc_code: ifsc_code,
                  };
        
                  
                  postWithToken(API_URL, 'account-banks/update', post_data, userToken).then((result) => {
                    if(result.status === true){
                          setLoading(false);
                          all_error_msg.push(result.message);
                          onShowAlert("success", all_error_msg);

                          reloadList();
                         
                      }else{
                        setLoading(false);
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                      }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                }
              }else{
                onShowAlert("error", all_error_msg);
                
              } 
          }


          const [itemOffset, setItemOffset] = useState(0);
          function PaginatedItems({ itemsPerPage }) {
              // Here we use item offsets; we could also use page offsets
              // following the API or data you're working with.
             
              // Simulate fetching items from another resources.
              // (This could be items from props; or items loaded in a local state
              // from an API endpoint with useEffect and useState)
              const endOffset = itemOffset + itemsPerPage;
              //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
              //const currentItems = roleList.list.slice(itemOffset, endOffset);
              const pageCount = Math.ceil(bankList.total_count / itemsPerPage);
            
              // Invoke when user click to request another page.
              const handlePageClick = (event) => {
                const newOffset = (event.selected * itemsPerPage) % bankList.total_count;
                /*console.log(
                  `User requested page number ${event.selected}, which is offset ${newOffset}`
                );*/
  
                setPageSelected(event.selected);
                BankListFetch(newOffset, list_limit, account_id);
                setItemOffset(newOffset);
              };
            
              return (
                <>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=" > "
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=" < "
                    renderOnZeroPageCount={null}
                    forcePage={page_selected}
                  />
                </>
              );
            }


      const CheckBoxEdit = () => {
              
              const all_error_msg = [];
      
              let checkbox_all_access_length = $('.bank_checkbox:checked').length;
      
              if(checkbox_all_access_length > 0){
                 
                  if(checkbox_all_access_length > 1){
                   all_error_msg.push("Please select only one row");
                   onShowAlert("error", all_error_msg);
                  }else{
                       $('.bank_checkbox:checked').each(function(){

                        EditBankDetails($(this).val());
                       });
                  }
              }else{
                      all_error_msg.push("Please select atleast one row");
                      onShowAlert("error", all_error_msg);
              }
            
       }       


     const EditBankDetails = (id) => {
            setLoading(true);
            const all_error_msg = [];

            postWithToken(API_URL, 'account-banks/view', {id: id, account_id: account_id}, userToken).then((result) => {
                  //console.log(result);
                  setLoading(false);
                  if(result.status === true){

                    setBankId(id);
                    setAccountId(result.data.account_id);
                    setCustomerName(result.data.customer_name);
                    setType(result.data.type);
                    setAccountType(result.data.account_type);
                    setBranchName(result.data.branch_name);
                    setBankName(result.data.bank_name);
                    setBankAccountNo(result.data.bank_account_no);
                    setIFSC(result.data.ifsc_code);

                    addBanks('edit');
                       
                  }else{
                        all_error_msg.push(result.message);
                        onShowAlert("error", all_error_msg);
                  }

            })
            .catch((error) => {
                  console.log("Error : ", error);
            });
     }  
     
     const Cancel = () => {
            setAddBank(false);
            reloadList();
     }

     const ViewBankInfo = (item) => {
        setBankInfoData(item);
        setModelBankInfoOpen(true);
     }

     const CheckAll = (id,checkbox_class) => {
        let checkbox_all_status = $('#' + id).is(":checked");

        if(checkbox_all_status === true){
            $("." + checkbox_class).prop("checked", true);
        }else{
            $("." + checkbox_class).prop("checked", false);
        }
    }

     return(

        <>

        <CustomAlert
                header={"Bank Info"}
                btnText={"Accept"}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                alertStyles={{}}
                headerStyles={{}}
                textStyles={{}}
                buttonStyles={{}}
                />  
                {
                  loading === true ?
                  <div className="activityLoader">
                        <GridLoader
                          color={color}
                          loading={loading}
                          cssOverride={override}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          Loader="GridLoader"
                        /> 
                 </div>     
                 : null
                }

               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  >
                        <DialogTitle id="responsive-dialog-title">
                        {"Delete Bank Details"}
                        </DialogTitle>
                        <DialogContent>
                              <DialogContentText>
                              Are you sure you want to delete this bank account
                              </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                              <Button autoFocus onClick={handleClose}>
                                    No
                              </Button>
                              <Button onClick={() => {deleteBank(deleteIds)}} autoFocus>
                                    Yes
                              </Button>
                        </DialogActions>
                  </Dialog>

        <div className="accountProfileTabs">
            <div className="pageTop">
                <h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Customer Bank</h2> 
                <button type='button' className='backBtn' onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}><i className="fa fa-angle-left" aria-hidden="true"></i> Back</button>
            </div>
            <div className="customTab">
                <div className="account_button_tab">
                <button  onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}  >Profile</button>
                <button className="active" onClick={() => {ChangeTab('/dashboard/account/accountbank')}} >Bank Details</button>
                <button onClick={() => {ChangeTab('/dashboard/account/accountpartner')}} >Partner/ Director Details</button>
                <button onClick={() => {ChangeTab('/dashboard/account/accountstatutory')}}>Statutory</button>
                <button onClick={() => {ChangeTab('/dashboard/account/accountbranch')}}>Branch</button>
                <button onClick={() => {ChangeTab('/dashboard/account/accountdocument')}}>Documents</button>
                <button onClick={() => {ChangeTab('/dashboard/account/accountuser')}}>User Accounts</button>
            </div>


            <div className="accountbankdetails">
                {addBank === false ? 
                    <div className="bankTable">
                        <div className="actionTable">
                            <div style={{width: 250}}></div>
                            <div className="tablebtngroup">
                                <button className="fillbtn" onClick={() => addBanks('add')}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add Bank</button>
                                <button className="fillbtn" onClick={() => CheckBoxEdit()} ><i className="fa fa-pencil" aria-hidden="true"></i></button>
                                <button className="fillbtn" onClick={() => handleClickOpen('','M')} ><i className="fa fa-trash" aria-hidden="true"></i></button>
                                <Link className="fillbtn"><i className="fa fa-print" aria-hidden="true"></i></Link>
                            </div>
                        </div>
                        <div className="tableResponsive">
                            <table>
                                <thead>
                                    <tr>
                                    {
                                        contactListhead.map((item, index)=>{
                                            let w = '';
                                            if(index == 6){
                                                  w = 175;
                                            }
                                            return (<th key={index} style={{width: w}}>{item}</th>)
                                        })
                                    }
                                    </tr>

                            </thead>
                           
                        {
                            bankList.IsLoading === false && bankList.list.length > 0 ?
                                <tbody>
                                    {
                                        bankList.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                <td> 
                                                        <div className="checkbox">
                                                            <input type="checkbox" class="styled-checkbox bank_checkbox" id={'checkbox-bank-' + item.id} value={item.id}  />
                                                            <label for={'checkbox-bank-' + item.id}></label>
                                                        </div>
                                                </td>
                                                <td >{item.type}</td>
                                                <td >{ item.customer_name}</td>
                                                <td >{item.bank_name}, {item.branch_name}</td>
                                                <td >{item.bank_account_no.replace(/.(?=.{4})/g, 'x')}</td>
                                                <td >{item.ifsc_code}</td>
                                                <td >
                                                    <button onClick={() => EditBankDetails(item.id)} ><img src={edit}/></button>
                                                    <button style={{ marginLeft: 6}} onClick={() => handleClickOpen(item.id,'S')}><img src={bin}/></button>
                                                    <button style={{ marginLeft: 6}} onClick={() => {ViewBankInfo(item)}}><img src={view}/></button> 
                                                </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    
                                    </tbody>
                                    : bankList.IsLoading === false && bankList.list.length == 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={7} style={{textAlign: "center"}}><b>No Record Found</b></td>
                                            </tr>
                                        </tbody>

                                    : bankList.IsLoading === true ?
                                      <tbody>
                                        <tr>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                            <td ><Skeleton height={24} /></td>
                                        </tr>
                                      </tbody>

                                : null }

                            </table>
                        </div>

                        <div className="tablenav">
                        
                            <div className="btnGroup">
                                <button type="button" className="fadebtn" onClick={() => {ChangeTab('/dashboard/account/accountprofile')}}>Previous</button>
                                <button type="button" className="fillbtn" onClick={() => {ChangeTab('/dashboard/account/accountpartner')}}>Next</button>
                            </div>
                        </div>

                        <div className="pagination_div">
                             <PaginatedItems itemsPerPage={list_limit} />
                        </div>

                </div>
                : null
                }


        {addBank === true ?
                <div className="addBank">
                    <form onSubmit={CreateBankInfo}>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group"><label>Type<sub>*</sub></label>
                                    <select value={type} onChange={(event) => setType(event.target.value)}>
                                        <option value="">Select Type</option>
                                        <option key={'self'} value="Self">Self</option>
                                        <option key={'link'} value="Link">Link</option>
                                   </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Customer Name</label>
                                    <input type="text" value={customer_name} onChange={(event) => setCustomerName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Account Type</label>
                                    <select value={account_type} onChange={(event) => setAccountType(event.target.value)}>
                                    <option value="">Select Account Type</option>
                                    {
                                        accountTypeList.length > 0 ?
                                        accountTypeList.map((item, key) => {
                                            return(
                                                <option key={'type_' + key} value={item.val}>{item.name}</option>
                                            );
                                        })
                                        : null
                                    }
                                   </select>

                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Bank Name</label>
                                    <input type="text" value={bank_name} onChange={(event) => setBankName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Branch Name</label>
                                    <input type="text" value={branch_name} onChange={(event) => setBranchName(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>Account Number</label>
                                    <input type="text" maxLength={16} value={bank_account_no} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
        return true;
}else{event.preventDefault();}}} onChange={(event) => setBankAccountNo(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label>IFSC Code</label>
                                    <input type="text" value={ifsc_code} onChange={(event) => setIFSC(event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="btnGroup">
                                    <button type="button" className="fadebtn" onClick={() => {Cancel()}}>Cancel</button>
                                    {
                                      bank_id == null ?
                                        <button type="submit" className="fillbtn" >Add Bank</button>
                                      :
                                        <button type="submit" className="fillbtn" >Update Bank</button>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                : null
                }

                </div>


        </div>
    </div>

           <Modal isOpen={modalBankInfoOpen} onRequestClose={CloseBankInfoModel} contentLabel="Mobile OTP Modal" >
                <div className='modal_header'>
                Bank Details <button onClick={CloseBankInfoModel} className='closebtn'><i class="fa fa-times" aria-hidden="true"></i>
                </button>
                </div>
                <div className='modalBody' style={{overflowY: 'auto', minHeight: 600, height: '85vh'}}>
                   
                        <div className='row'>
                            <div className='col-12' >

                              {
                                bank_info === null ?
                                <div>
                                <Skeleton height={24} style={{marginBottom: 20}} />
                                <Skeleton height={24} style={{marginBottom: 20}} />
                                <Skeleton height={24} style={{marginBottom: 20}} />
                                <Skeleton height={24} style={{marginBottom: 20}} />
                                <Skeleton height={24} style={{marginBottom: 20}} />
                                </div>
                                : 

                                <div className="row">
                                    <div className="col-12">
                                        <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                          <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Type : </label>
                                           <span style={{display: 'flex', flex: 1}}>{bank_info.type}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Customer Name : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.customer_name} </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Type : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.account_type}</span>  
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Bank Name : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.bank_name}</span>  
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Branch Name : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.branch_name}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>Account Number : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.bank_account_no}</span> 
                                        </div>
                                    </div>
                                    <div className="col-12">
                                    <div class="form-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <label style={{fontWeight: 700, display: 'flex', flex: 1}}>IFSC Code : </label>
                                            <span style={{display: 'flex', flex: 1}}>{bank_info.ifsc_code}</span> 
                                        </div>
                                    </div>

                                </div>
                              }
                                
                            </div>
                                            
                            <div className='col-12'>
                                <div className='form-group' style={{display: 'flex', justifyContent: 'center', gap: 20, marginTop: 25}}>
                                    <button type='button' className='fadebtn' style={{width: 'auto'}} onClick={CloseBankInfoModel}>Close</button>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </Modal>

    </>

     )
}
export default AccountBankDetails;