import { Link } from "react-router-dom";
import bin from '../../assets/images/delete.svg';
import searchicon from '../../assets/images/searchicon.svg';
import { useNavigate } from "react-router-dom";
const AccountPayment =()=>{
      const navigate = useNavigate();
    const checkbox = ()=>{
        return <div className="checkbox">
        <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1"/>
        <label for="styled-checkbox-1"></label>
        </div>
      }
      const addPayment =() =>{
            navigate("/layout/addpayment")
      }
      const viewPayment =() =>{
            navigate("/layout/viewpayment")  
      }
    const contactListhead =[checkbox(),"Date", "Receipt No", "Account Name", "Invoice Number", "Case ID","Job","Amount", "Action"];
    const contactlistdata =[
          {  checkbox:checkbox(),
             name:"12/14/2023",
             contact:"455GHFG" ,
             account:"Jhon Deo" ,
             designation:"Designer" ,
             status:"HGHH54141",
             pstatus:"541.00"  ,
             date:"85512"    
          },
          {checkbox:checkbox(),
            name:"12/14/2023",
            contact:"455GHFG" ,
            account:"Jhon Deo" ,
            designation:"Designer" ,
            status:"HGHH54141",
            pstatus:"541.00"  ,
            date:"85512"    
             },

             { checkbox:checkbox(),
                 name:"12/14/2023",
             contact:"455GHFG" ,
             account:"Jhon Deo" ,
             designation:"Designer" ,
             status:"HGHH54141",
             pstatus:"541.00"  ,
             date:"85512"   
             },
             { checkbox:checkbox(),
                name:"12/14/2023",
             contact:"455GHFG" ,
             account:"Jhon Deo" ,
             designation:"Designer" ,
             status:"HGHH54141",
             pstatus:"541.00"  ,
             date:"85512"   
                },
                { checkbox:checkbox(),
                     name:"12/14/2023",
                contact:"455GHFG" ,
                account:"Jhon Deo" ,
                designation:"Designer" ,
                status:"HGHH54141",
                pstatus:"541.00"  ,
                date:"85512"   
                   },
                   {  
                    checkbox:checkbox(),
                     name:"12/14/2023",
                   contact:"455GHFG" ,
                   account:"Jhon Deo" ,
                   designation:"Designer" ,
                   status:"HGHH54141",
                   pstatus:"541.00"  ,
                   date:"85512"   
                      },
                      { checkbox:checkbox(), 
                         name:"12/14/2023",
                      contact:"455GHFG" ,
                      account:"Jhon Deo" ,
                      designation:"Designer" ,
                      status:"HGHH54141",
                      pstatus:"541.00"  ,
                      date:"85512"      
                         },
                         {  
                         checkbox:checkbox(),
                         name:"12/14/2023",
                         contact:"455GHFG" ,
                         account:"Jhon Deo" ,
                         designation:"Designer" ,
                         status:"HGHH54141",
                         pstatus:"541.00"  ,
                         date:"85512"   
                            },
    ]
      return(
        <div className="caseAccount">
             <div className="pageTop">
                  <h2><i class="fa fa-user-circle-o" aria-hidden="true"></i> Payment</h2>
                  <div className="right-action">
                        <form>
                                <input type="text" placeholder="Account"/>
                                <input type="text" placeholder="Job"/>
                                <input type="text" placeholder="Period"/>
                                <button type="submit">
                                    <img src={searchicon}/>
                                </button>
                        </form>
                        <Link className="fadebtn" onClick={addPayment}> Add Payment</Link>
                        <Link className="fillbtn"> Bulk Action</Link>
                  </div>
            </div>
            <div className="tableResponsive">
       <table>
        <thead>
              <tr>
              {
              contactListhead.map((item, index)=>{
                    return <th key={index}>{item}</th>
              })
              }
              </tr>
              
        </thead>
        <tbody>
          {
              contactlistdata.map((item, index)=>{
                    return <tr key={index} onClick={viewPayment}>
                         <td> {item.checkbox}</td>
                          <td> {item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.account}</td>
                          <td >{item.designation}</td>
                          <td >{item.status}</td>
                          <td>{item.pstatus}</td>
                          <td>{item.date}</td>
                          <td><Link><img src={bin}/></Link></td>
                    </tr>
              })
          }
        </tbody>
       </table>
     </div>
     <div className="tablenav">
      <p>See all 12 result </p>
  </div>
        </div>
      )
}
export default AccountPayment;