import { useState, } from "react";
import { useNavigate } from "react-router-dom";

 const AddAccountCases = () =>{
const navigation = useNavigate()
    const addnow = () =>{
        navigation('/layout/accounttab')
    }
      return(
         <div className="addcaseaccount">
        <div className="pageTop"><h2><i className="fa fa-user-circle-o" aria-hidden="true"></i> Add Cases</h2></div>
            <form>
            <div className="row">
            <div className="col-4">
            <div className="form-group"><label>Service Name<sub>*</sub></label>
            <select>
                <option>Select Service</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Product Name</label>
            <select>
                <option>Select Product</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Financial Year</label>
            <select>
                <option>Select Year</option>
            </select>
            </div>
            </div>
            <div className="col-4">
            <div className="form-group"><label>Assigned </label>
            <input type="text" value="" />
            </div>
            </div>
            <div className="col-12">
            <div className="btnGroup">
                <button type="button" className="fillbtn" onClick={addnow}>Add Cases</button>
            </div>
            </div>
            </div>
            </form>
        
         </div>
      )
 }
 export default AddAccountCases;