import logo from '../assets/images/logo.svg';
import envolope from '../assets/images/envolop.svg';
import call from '../assets/images/call.svg';
import React, {useEffect, useState, CSSProperties} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import withRouter from '../component/withRouter';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import {postWithOutToken} from "../Service/service";

import CustomAlert from "../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";

const ForgotPasswordOtp = (props) =>{

  const [otp, setOtp] = useState('');

  const location = useLocation();

  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#C00305");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;

  const { API_URL, userData} = useSelector(state => state.common);

  const [isLoading, setIsLoading] = useState(false);
  const [open_err, setOpenErr] = useState(false);
  const [err_msg, setErrMsg] = useState("");

    //alert start

    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end  


  const VerifyOtp = () =>{
    
    let flg = true;
    const all_error_msg = [];
    if(otp.length < 6){
      all_error_msg.push("Please enter six-digit otp code");
      if(flg == true){flg = false;}
    }

    if (flg === true) {
      setLoading(true);
      postWithOutToken(API_URL, 'forgot-password-check-otp', {email: location.state.email, otp: otp}).then((result) => {
          if(result.status === true){
                setLoading(false);
                setOtp("");               
                navigate("/ResetPassword", {state: {email: location.state.email}});
            }else{

              all_error_msg.push(result.message);
              onShowAlert("error", all_error_msg);
              setLoading(false);
            }
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
      }else{
        onShowAlert("error", all_error_msg);
        
      } 

  }
 
   return(
    <div className="authwapper">
        <div className="container">
        <CustomAlert
              header={"OTP Verification"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  

              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
        <div className="loginfrom">
       <h1>OTP Verification </h1  >

                {/*<div className="form-group flxotp">
                    <input type="text" placeholder="" />
                    <input type="text" placeholder="" />
                    <input type="text" placeholder="" />
                    <input type="text" placeholder="" />
                    <input type="text" placeholder="" />
                    <input type="text" placeholder="" />
                </div>
                <button type="submit"className="fill-btn">
                <Link to="/newpassword">Verify</Link>
                
   </button>*/}

              <div className="form-group flxotp">

                              <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType={"tel"}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                  />

              </div>
              <button type="submit"className="fill-btn" onClick={VerifyOtp} >Verify</button>
          </div>
          <div className="companyinfo">
            <a href=''>
              <img src={logo}/>
            </a>
            <h2>Ashirbad Cloud</h2>
            <p>A construct & concept thereof</p>
            <div className='contactinfo'>
              <a href='mailto:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={envolope}/>
                souvik.mukherjee@ashirbadtax.com</a><br/>
                <a href='tel:souvik.mukherjee@ashirbadtax.com'>
              <img className='' src={call}/>
              +91 0000000000</a>
            </div>
          </div>
        </div>
         
    </div>
  )
}
export default withRouter(ForgotPasswordOtp);